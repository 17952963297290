import React, { useReducer } from 'react';
import { Tag } from 'antd';
import produce from 'immer';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TableMainText, TableSecondaryText } from 'styles';

import { DEFAULT_PAGE_SIZE } from 'appConstants';
import { StyledTable, TableTimestamp } from 'components';
import StatusTag from 'components/StatusTag';
import { useAxios } from 'hooks';

interface CustomerLoyaltyTableState {
  page: any;
  sort_by: any;
  order: any;
  size: any;
  search_string?: any;
  customer_id: string;
}
const paramsReducer = (state: CustomerLoyaltyTableState, action): CustomerLoyaltyTableState =>
  produce(state, draft => {
    switch (action.type) {
      case 'TABLE':
        draft.page = action.pagination;

        if (action.sorter) {
          draft.sort_by = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        }

        if (action.size) {
          draft.size = action.size;
        }
        break;

      case 'SEARCH':
        draft.search_string = action.value;
        break;
      default:
        break;
    }
  });

const CustomerLoyaltyTable = ({ token, customerId }) => {
  const [params, paramsDispatch] = useReducer(paramsReducer, {
    page: 1,
    order: null,
    sort_by: null,
    size: DEFAULT_PAGE_SIZE,
    customer_id: customerId
  });

  const [loading, response, error] = useAxios(`/v4/dash/customers/${customerId}/loyalty_programs`, {
    headers: { Authorization: `Bearer ${token}` },
    params,
    //@ts-ignore
    external: true
  });

  const onTableChange = (pagination, fitlers, sorter) => {
    paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter });
  };

  const onPageSizeChange = (current, pageSize) => {
    paramsDispatch({ type: 'TABLE', pagination: current, size: pageSize });
  };

  if (error) {
    return null;
  }

  return (
    <StyledTable<any>
      rowKey={x => x.id}
      loading={loading}
      dataSource={response?.data || []}
      onChange={onTableChange}
      selectedRowKeys={[]}
      pagination={{
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSize: params.size,
        total: response?.meta?.total_count || 0,
        onShowSizeChange: onPageSizeChange
      }}
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text, record) => (
            <>
              <TableMainText>{text}</TableMainText>
              <TableSecondaryText>ID: {record.id}</TableSecondaryText>
            </>
          )
        },
        {
          title: 'Status',
          dataIndex: 'customer_state',
          key: 'customer_state',
          render: (text, record) => {
            const isForced = record.customer.forced_state;
            const isForcedMin = record.customer.tier?.reason === 'forced_min';

            return (
              <>
                <StatusTag state={record.customer.state} />
                <br />
                {isForced && <Tag>Forced</Tag>}
                {isForcedMin && <Tag>Forced to min</Tag>}
              </>
            );
          }
        },
        {
          title: 'Joined at',
          key: 'joined_at',
          // @ts-ignore
          render: (_, record) => <TableTimestamp timestamp={record.customer.joined_at} />
        },
        {
          title: 'Tenure',
          key: 'tenure',
          render: (_, record) => moment(record.customer.joined_at).fromNow(true)
        },
        {
          title: 'Tier',
          dataIndex: 'tier',
          key: 'tier',
          render: (_, record) => {
            const isForced = record.customer.forced_tier;
            const isForcedMin = record.customer.tier?.reason === 'forced_min';

            return (
              <>
                {record.customer.tier?.name}
                <br />
                {isForced && <Tag>Forced</Tag>}
                {isForcedMin && <Tag>Forced to min</Tag>}
              </>
            );
          }
        },
        {
          title: 'Points Balance',
          dataIndex: 'point_balance',
          key: 'point_balance',
          render: (_, record) => record.customer.point_balance
        },
        {
          title: 'Lifetime Points',
          dataIndex: 'lifetime_point_balance',
          key: 'lifetime_point_balance',
          render: (_, record) => record.customer.lifetime_point_balance
        }
      ]}
    />
  );
};

CustomerLoyaltyTable.propTypes = {
  customerId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired
};

export default CustomerLoyaltyTable;
