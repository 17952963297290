import useSWR from 'swr';

import { IReward } from 'containers/Reward/types';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useReward = (id: number) => {
  const { data: { data } = {} } = useSWR<IDetailResponse<IReward>>(
    id ? `/v4/dash/rewards/${id}` : null,
    fetcher
  );

  return { data };
};

export const useRewardList = (params: object, shouldFetch: boolean = true) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IReward>>(
    shouldFetch ? `/v4/dash/rewards?${buildQuery(params)}` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};

export const useRewardsWithIds = (ids: number[] = []) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IReward>>(
    ids.length > 0 ? `/v4/dash/rewards?id=${ids.join(',')}&limit=1000` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};
