import { cdnURL } from 'utils/helpers';

//DO NOT CHANGE FILENAME IT IS USED TO CHECK FOR EQUALITY
const Failed = [
  {
    filename: '0dd314bf-a9be-411a-8536-1a7456d26733.png',
    image_url: cdnURL('scratch_card/try_again_1.png')
  },
  {
    filename: 'dada987f-9853-4b93-a969-d1a611fe0e75.png',
    image_url: cdnURL('scratch_card/try_again_2.png')
  },
  {
    filename: 'bb6b3c83-9f49-42a3-a1e0-a1b0215ee879.png',
    image_url: cdnURL('scratch_card/try_again_3.png')
  }
];

const Finished = [
  {
    filename: '25abe0fc-5bfd-4f19-b91e-ac8658aea294.png',
    image_url: cdnURL('scratch_card/you_won_1.png')
  },
  {
    filename: 'e16b5a35-f135-4438-a717-54beccff9df8.png',
    image_url: cdnURL('scratch_card/you_won_2.png')
  },
  {
    filename: 'b5b3d3d5-e084-46f4-bd88-88eeb68ad083.png',
    image_url: cdnURL('scratch_card/you_won_3.png')
  }
];

const Pregame = [
  {
    filename: '186d447b-4f4c-4aae-a8aa-fe95b4e7cd7d.png',
    image_url: cdnURL('scratch_card/pregame_1.png')
  },
  {
    filename: '5dbf14fa-8d40-4d04-88bb-4c62051e258f.jpeg',
    image_url: cdnURL('scratch_card/pregame_2.png')
  },
  {
    filename: '6379d728-7dbf-4c32-b596-b15637ec8652.jpeg',
    image_url: cdnURL('scratch_card/pregame_3.png')
  }
];

const Background = [
  {
    filename: '64d4c84b-acff-4180-870d-d1c7841c8bd5.png',
    image_url: cdnURL('scratch_card/background.png')
  }
];

export { Failed, Finished, Pregame, Background };
