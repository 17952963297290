import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  SEARCH_TAGS,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL
} from './constants';

export const tagsGet = (payload?) => {
  return {
    type: GET_TAGS,
    payload
  };
};

export const tagGet = payload => {
  return {
    type: 'GET_TAG',
    payload
  };
};

export const tagsGetSuccess = payload => {
  return {
    type: GET_TAGS_SUCCESS,
    payload
  };
};

export const tagsGetFail = error => {
  return {
    type: GET_TAGS_FAIL,
    error
  };
};

export const tagsSearch = payload => {
  return {
    type: SEARCH_TAGS,
    payload
  };
};

export const tagsSearchSuccess = payload => {
  return {
    type: SEARCH_TAGS_SUCCESS,
    payload
  };
};

export const tagsSearchFail = error => {
  return {
    type: SEARCH_TAGS_FAIL,
    error
  };
};

export const deleteTag = tag => ({
  type: DELETE_TAG,
  endpoint: `/v4/dash/tags/${tag.id}`,
  types: [DELETE_TAG, DELETE_TAG_SUCCESS, DELETE_TAG_FAIL],
  method: 'delete',
  params: tag,
  notification: {
    error: err => {
      message.error(err.message || 'Something went wrong!', DURATION_TIMER);
    }
  },
  nextAction: tagsGet()
});
