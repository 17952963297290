export const patchRewardTransaction = (payload, callback) => {
  return {
    type: 'PATCH_REWARD_TRANSACTION',
    payload,
    callback
  };
};

export const getRewardTransaction = payload => {
  return {
    type: 'GET_REWARD_TRANSACTION',
    payload
  };
};

export const onRevertRedemptionToIssued = (payload, callback) => {
  return {
    type: 'REVERT_REDEMPTION_TO_ISSUED',
    payload,
    callback
  };
};

export const rewardTransactionGetSuccess = payload => {
  return {
    type: 'GET_REWARD_TRANSACTION_SUCCESS',
    payload
  };
};

export const rewardTransactionFailed = payload => {
  return {
    type: 'GET_REWARD_TRANSACTION_FAILED',
    payload
  };
};
