import * as Sentry from '@sentry/react';
import { message } from 'antd';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import { put, select } from 'redux-saga/effects';

import { API_ROOT } from 'appConstants';
import { userSignOut } from 'containers/App/actions';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { checkPayloadForFile } from 'utils/prepareFormData';

import { browserHistory } from '../store';

const okStatuses = [
  200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 300, 301, 302, 304, 305, 306, 307, 308
];

export const callApi = ({
  endpoint = undefined,
  method = undefined,
  params = undefined,
  headers = undefined,
  formdata = undefined
} = {}) => {
  const baseURL = API_ROOT;
  const url = endpoint;

  const query = {
    baseURL,
    method,
    url
  };
  if (headers) query.headers = headers;

  const methods = ['put', 'post', 'patch'];
  query.method = method || 'get';

  if (!_isEmpty(params)) {
    if (methods.indexOf(method) === -1) {
      query.params = params;
    } else {
      const body = checkPayloadForFile(params, headers);
      query.data = body;
    }
  }
  if (!_isEmpty(formdata)) {
    const body = checkPayloadForFile(formdata, headers);
    // const body = new Promise.resolve(toFormData(formdata));
    // console.log('formData', body);
    // query.headers = {
    //   ...query.headers,
    //   'Content-Type': 'multipart/form-data'
    // };
    query.data = body;
  }

  return axios(query)
    .then(response => response)
    .catch(error => error.response);
};

export function* commonSaga(action) {
  let headers = null;
  const { noAuth, types, notification, callback, instantAction, nextAction, endpoint } = action;
  if (noAuth) {
    headers = {
      'Content-Type': 'application/json'
    };
  } else {
    const authUser = yield select(makeSelectAuthUser());
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authUser.bearer_token}`
    };
  }

  try {
    if (typeof endpoint !== 'string') {
      throw new Error('Specify a string endpoint URL.');
    }
    if (!types) {
      throw new Error('Specify Action types');
    }

    if (!Array.isArray(types) && types.length !== 3) {
      throw new Error('action.types should have minimum 3 actions');
    }
    const duplicate = types.filter((item, index, self) => self.indexOf(item) !== index);
    if (duplicate.length !== 0) {
      throw new Error(`[${duplicate}] consists multiple time in action.types`);
    }

    if (notification) {
      if (Object.keys(notification).includes('loading')) {
        setTimeout(notification.loading(), 1000);
      }
    }
    const response = yield callApi({ ...action, headers });
    if (process.env.NODE_ENV === 'production' && endpoint.includes('tenant_config')) {
      Sentry.configureScope(scope => {
        scope.setExtra('tenant', response?.data?.tenant?.name || 'ERROR GETTING TENANT NAME');
      });
    }
    if (response.status === 401) {
      yield put(userSignOut(browserHistory));
      message.info('Session expired. Please login again.');
      // Raise an error if respose status code returns 500,400,
    } else if (!okStatuses.includes(response.status)) {
      throw response;
    }
    yield put({ type: types[1], payload: response.data });
    // If There was a callback function you can use callBack
    if (action.notification) {
      if (Object.keys(action.notification).includes('success')) {
        setTimeout(action.notification.success(response.data), 1000);
      }
    }
    if (callback) {
      action.callback(response.data);
    }

    // Instant Action is the intercepting callback method within action
    if (instantAction) {
      instantAction(response.data);
    }

    // NextAction is to trigger the next Action chain within saga
    if (nextAction) {
      yield put({ ...nextAction });
    }
  } catch (e) {
    // console.log here will help to find not mocked requests in tests
    // which can cause tests crashes
    if (e?.status === 404) {
      console.error(e);
    }

    yield put({ type: types[2], payload: e.data, error: e });
    if (action.notification) {
      if (Object.keys(action.notification).includes('error')) {
        const message = e.data ? e.data : e.message ? e.message : 'Something went wrong';
        action.notification.error(message);
      }
    }
    if (callback) {
      action.callback(undefined, e.data);
    }
  }
}
