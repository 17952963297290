import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { FormItem, InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import { FItemSelect } from 'components/formik';
import CampaignSelectModal from 'components/modals/CampaignSelectModal';
import { policyOptions } from 'containers/Audience/utils';
import SelectedCampaign from 'containers/Rule/components/SelectedCampaign';
import { verticalLayout } from 'utils/formItemLayouts';

const Campaign = () => {
  const modal = useModal(CampaignSelectModal);
  const formik = useFormikContext<any>();

  const fieldPath = 'filters.campaign_enrolment[0].criteria';
  const selectedCampaignId = _get(formik.values, `${fieldPath}.eq`);

  const selectCampaign = () =>
    modal.show().then((campaign: any) => {
      formik.setFieldTouched(`${fieldPath}.eq`);
      formik.setFieldValue(`${fieldPath}.eq`, campaign.id);
    });

  const deleteCampaign = () => {
    formik.setFieldValue(`${fieldPath}.eq`, undefined);
  };

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
            style={{ width: 160 }}
          />
        </InputLimitWidth>
      </FlexContainer>
      <FormItem {...verticalLayout} label="Users who have enrolled in the campaign:">
        {selectedCampaignId && (
          <SelectedCampaign id={selectedCampaignId} entity="campaigns" onDelete={deleteCampaign} />
        )}
        <Button type="primary" ghost onClick={selectCampaign}>
          {selectedCampaignId ? 'Change' : 'Select'} campaign
        </Button>
      </FormItem>
    </>
  );
};

export default Campaign;
