import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'CATEGORY';

export const GET_CATEGORIES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATEGORIES`;
export const GET_CATEGORIES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATEGORIES_FAIL`;

export const DELETE_CATEGORY = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CATEGORY`;
export const DELETE_CATEGORY_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CATEGORY_SUCCESS`;
export const DELETE_CATEGORY_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_CATEGORY_FAIL`;

export const UPDATE_CATEGORY = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATEGORY`;
export const UPDATE_CATEGORY_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATEGORY_SUCCESS`;
export const UPDATE_CATEGORY_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATEGORY_FAIL`;

export const CREATE_CATEGORY = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATEGORY`;
export const CREATE_CATEGORY_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATEGORY_SUCCESS`;
export const CREATE_CATEGORY_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATEGORY_FAIL`;
