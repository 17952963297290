import React, { memo, useReducer, useEffect, ReactNode, Key } from 'react';
import { Col, Table, Tabs } from 'antd';
import produce from 'immer';
import _get from 'lodash/get';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_PAGE_SIZE } from 'appConstants';
import { SearchBar, StyledTitle } from 'components';
import { FlexContainer } from 'components/Layout/common';
import { useAxios } from 'hooks';
import { EventEmitter } from 'utils/events';

/**
 * ListTableComponent
 * Commonly used pattern
 * for rendering table with pagination,
 * search, tabs and additional modal component
 * @type {React.NamedExoticComponent<object>}
 * @param {object} search prop defines showing Search component with customized placeholder
 * @param {object} tabs prop defines showing Tabs component
 * @param {object} table prop defines showing Table component with customized columns
 * @param {string} apiUrl for getting table content data
 * @param {string} refreshEvent - event for uprating table content data
 * @param {React.ElementType} modalChildComponent - any modal component with open button
 * @param {string} defaultState - customized state value
 * @param {function} customParamsReducer - customized paramsReducer function
 * @param {object} customApiParams - additional params for getting table content data
 * @returns {React.ElementType}
 */

interface TabProps {
  title: string;
  key: string;
  activeKey?: string;
}

interface ColumnProps {
  title: string;
  key?: Key;
  dataIndex?: string;
  sorter?: boolean;
  width?: number;
  align?: 'left' | 'right' | 'center';
  render?: (text: any, record: any, index: number) => ReactNode | any;
}

interface ListTableComponentProps {
  search?: {
    placeholder: string;
  };
  tabs?: TabProps[];
  table: {
    columns: ColumnProps[];
    onRow?: (record: any, index: number) => any;
  };
  apiUrl: string;
  refreshEvent?: string;
  modalChildComponent?: ReactNode;
  defaultState?: string;
  debouncedOnSearch?: string;
  customApiParams?: {
    sort_by?: string;
    order?: string;
    exclude_assigned_to_batch_i?: number;
    partner_id?: number;
    inventory_id?: number[];
    code_generation_method?: string;
  };
  customParamsReducer?: (state: any, action: any) => any;
  scroll?: object;
}

const ListTableComponent = memo<ListTableComponentProps>(props => {
  const {
    search,
    tabs,
    table,
    apiUrl,
    refreshEvent,
    modalChildComponent,
    defaultState,
    customParamsReducer,
    customApiParams = {},
    scroll
  } = props;
  const [params, paramsDispatch] = useReducer(customParamsReducer || paramsReducer, {
    page: 1,
    order: 'desc',
    search_string: null,
    sort_by: 'created_at',
    size: DEFAULT_PAGE_SIZE,
    state: defaultState || null,
    ...customApiParams
  });

  const [loading, response, error, refresh] = useAxios(apiUrl, { params });
  const dataSource = _get(response, 'data', []);

  useEffect(() => {
    let unsubscribe;
    if (refreshEvent) {
      unsubscribe = EventEmitter.subscribe(refreshEvent, () => refresh());
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, []);

  const onTabChange = tab => paramsDispatch({ type: 'TAB', value: tab });
  const onTableChange = (pagination, filters, sorter) => {
    paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter });
  };
  const [debouncedOnSearch] = useDebouncedCallback(v => {
    paramsDispatch({ type: 'SEARCH', value: v });
  }, 500);

  const renderSearch = () => (
    <FlexContainer justifyContent="space-between">
      <Col span={12}>
        {search && (
          <SearchBar
            data-testid="SearchBar"
            onSearch={v => debouncedOnSearch(v.toLowerCase())}
            placeholder={search.placeholder}
            style={{ marginBottom: '20px', maxWidth: '500px', width: '100%' }}
          />
        )}
      </Col>
      {modalChildComponent && <Col>{modalChildComponent}</Col>}
    </FlexContainer>
  );

  const renderError = () => <StyledTitle>Sorry, something went wrong</StyledTitle>;

  const renderTabs = () => (
    <div data-testid="Tabs">
      <Tabs
        defaultActiveKey={tabs[0].key}
        activeKey={params.state || tabs?.[0].key}
        onChange={onTabChange}
        tabBarStyle={{ marginBottom: 0 }}
        items={tabs.map(({ title, key }) => ({ label: title, key }))}
      />
    </div>
  );

  const renderTable = () => (
    <Table
      loading={loading}
      rowKey={item => item.id}
      onChange={onTableChange}
      dataSource={dataSource}
      pagination={{
        current: params.page,
        pageSize: params.size,
        position: ['bottomRight'],
        total: _get(response, 'meta.count', 0)
      }}
      scroll={scroll}
      {...table}
    />
  );

  return (
    <div data-testid="ListTableComponent">
      {(search || modalChildComponent) && renderSearch()}
      {tabs && renderTabs()}
      {error && renderError()}
      {renderTable()}
    </div>
  );
});

const paramsReducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SEARCH':
        draft.search_string = action.value || null;
        draft.page = 1;
        break;
      case 'TAB':
        draft.state = action.value === 'all' ? null : action.value;
        draft.page = 1;
        break;
      case 'TABLE':
        draft.page = action.pagination;
        if (action.sorter && action.sorter.order) {
          draft['sort_by'] = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        }
        break;
      default:
        break;
    }
  });

export default ListTableComponent;
