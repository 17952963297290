import React, { memo, useMemo } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import { useAxios } from 'hooks';

interface LoyaltyTierSelectProps extends SelectProps {
  loyaltyId?: string | number;
  includeAll?: boolean;
}

export const LoyaltyTierSelect: React.FC<LoyaltyTierSelectProps> = ({
  loyaltyId,
  includeAll,
  value,
  ...props
}) => {
  const [loading, response, error] = useAxios(
    loyaltyId ? `/v4/dash/loyalty_programs/${loyaltyId}` : '/v4/dash/simple/loyalty'
  );

  const options = useMemo(() => {
    let arr: Array<{ value: any; label: any }> = [];

    if (loyaltyId) {
      arr = response?.data?.tiers?.map(tier => ({ value: tier.id, label: tier.name }));
    } else {
      arr = response?.data?.flatMap?.(program =>
        program?.tiers.map(tier => ({
          value: tier.id,
          label: `${program.name} / ${tier.name}`
        }))
      );
    }

    return arr || [];
  }, [loyaltyId, response]);

  if (error) {
    return null;
  }

  return (
    <Select
      value={value}
      loading={loading}
      filterOption={(input, option) =>
        (option.props.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
      onBlur={e => e.preventDefault()}
    >
      {includeAll && (
        <Select.Option key="all" value="all">
          All
        </Select.Option>
      )}
      {options.map(option => {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default memo(LoyaltyTierSelect);
