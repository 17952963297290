import useSWR from 'swr';

import { IAnyCampaign } from 'containers/Campaign/types';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useGLAccountIdentifier = (id: number) => {
  const { data: { data } = {} } = useSWR<IDetailResponse<IAnyCampaign>>(
    id ? `/v4/dash/lookups/:lookup_id/table_values/${id}` : null,
    fetcher
  );

  return { data };
};

export const useGLAccountIdentifierList = (params: object, shouldFetch: boolean = true) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IAnyCampaign>>(
    shouldFetch ? `/v4/dash/lookups/:lookup_id/table_values?${buildQuery(params)}` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};

export const useGLAccountIdentifierWithIds = (ids: number[] = []) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IAnyCampaign>>(
    ids.length > 0
      ? `/v4/dash/lookups/:lookup_id/table_values?id=${ids.join(',')}&limit=1000`
      : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};
