// Placeholders for the game campaigns
// They should be elsewhere but because they are being used in the Phone component for now
import {
  Backgrounds as PinataBackgrounds,
  BrokenPinata,
  CrackedPinata,
  Pinata
} from 'containers/Campaign/Games/HitThePinata/Assets';
import { LandingPageBackground } from 'containers/Campaign/Games/LandingPagePreview/Assets';
import {
  Backgrounds as PlinkoBackgrounds,
  Rewards as PlinkoRewards
} from 'containers/Campaign/Games/Plinko/Assets';
import {
  Background as QuizBackground,
  OutcomeImage as QuizOutcomeImage
} from 'containers/Campaign/Games/Quiz/Assets';
import {
  Failed,
  Background,
  Finished,
  Pregame
} from 'containers/Campaign/Games/ScratchCard/Assets';
import {
  Backgrounds as TreeBackgrounds,
  Gifts as TreeGifts,
  Trees
} from 'containers/Campaign/Games/ShakeTheTree/Assets';
import {
  Wheels,
  Pointers,
  Rewards,
  Backgrounds,
  WedgeColors
} from 'containers/Campaign/Games/SpinTheWheel/Assets';
import { cdnURL } from 'utils/helpers';

import Outcome from './assets/outcome';

export const landingPageInitialDisplayProperties = {
  translatable: {
    cta_button_text: 'Start Playing',
    headline: 'Headline',
    sub_headline: 'Sub headline',
    body_text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at dolor vitae sem faucibus congue. Donec nibh tortor, pellentesque a nisl vitae, volutpat feugiat nisl.',
    terms_and_conditions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue.'
  },
  static: {
    cta_button_colour: '#2665ee',
    cta_button_text_colour: '#ffffff',
    image: {
      type: 'image',
      value: LandingPageBackground[0]
    }
  }
} as const;

// These placeholders are also being used in form initialization for display_properties default values
export const placeholders = {
  hit_the_pinata: {
    translatable: {
      landing_page: landingPageInitialDisplayProperties.translatable,
      header: {
        value: {
          title: 'Hit the Piñata and Win!',
          description: 'Enjoy your membership reward'
        },
        type: 'text'
      },
      play_button_text: 'Start playing',
      outcome: {
        title: 'Congratulations!',
        description: 'You just won a reward!',
        button_text: 'View my wallet'
      },
      nooutcome: {
        title: 'The piñata is empty',
        description: 'Better luck next time',
        button_text: 'Try again'
      }
    },
    static: {
      landing_page: landingPageInitialDisplayProperties.static,
      header: {
        header_colour: '#000000',
        subheader_colour: '#000000'
      },
      play_button_colour: '#fee800',
      play_button_text_colour: '#000000',
      outcome: {
        type: 'image',
        value: {
          file: Outcome,
          filename: 'outcome.png'
        }
      },
      nooutcome: {},
      font_colour: '#000000',
      background_image: {
        type: 'image',
        value: PinataBackgrounds[0]
      },
      still_image: {
        type: 'image',
        value: Pinata[0]
      },
      cracking_image: {
        type: 'image',
        value: CrackedPinata[0]
      },
      opened_image: {
        type: 'image',
        value: BrokenPinata[0]
      },
      number_of_taps: 5,
      always_visible: false
    }
  },
  shake_the_tree: {
    translatable: {
      landing_page: landingPageInitialDisplayProperties.translatable,
      header: {
        value: {
          title: 'Shake The Tree and Win!',
          description: 'Enjoy your membership reward'
        },
        type: 'text',
        title: 'SShake The Tree and Win!',
        description: 'Enjoy your membership reward'
      },
      play_button_text: 'Start playing',
      outcome: {
        title: 'Congratulations!',
        description: 'You just won a reward!',
        button_text: 'View my wallet'
      },
      nooutcome: {
        title: 'No prizes fell.',
        description: 'Try again tomorrow',
        button_text: 'Try again'
      }
    },
    static: {
      landing_page: landingPageInitialDisplayProperties.static,
      header: {
        header_colour: '#000000',
        subheader_colour: '#000000'
      },
      play_button_colour: '#2665ee',
      play_button_text_colour: '#ffffff',
      outcome: {
        type: 'image',
        value: {
          file: Outcome,
          filename: 'outcome.png'
        }
      },
      nooutcome: {},
      font_colour: '#000000',
      background_image: {
        type: 'image',
        value: TreeBackgrounds[0]
      },
      tree_image: {
        type: 'image',
        value: Trees[0]
      },
      gift_image: {
        type: 'image',
        value: TreeGifts[0]
      },
      number_of_gifts_shown: 3,
      number_of_gifts_to_drop: 3,
      number_of_taps: 5,
      always_visible: false
    }
  },
  spin_the_wheel: {
    translatable: {
      landing_page: landingPageInitialDisplayProperties.translatable,
      header: {
        value: {
          title: 'Spin The Wheel',
          description: 'Spin the wheel and win a prize!'
        }
      },
      play_button_text: 'Spin!',
      outcome: {
        title: 'Congratulations!',
        description: 'You just won a reward!',
        button_text: 'View My Wallet'
      },
      nooutcome: {
        title: 'You did not win anything.',
        description: 'Try again tomorrow',
        button_text: 'Try again'
      }
    },
    static: {
      landing_page: landingPageInitialDisplayProperties.static,
      header: {
        header_colour: '#000000',
        subheader_colour: '#000000'
      },
      number_of_wedges: 4,
      wedges: [
        {
          position: 0,
          color: WedgeColors[0],
          image: '',
          has_reward: false
        },
        {
          position: 1,
          color: WedgeColors[1],
          image: '',
          has_reward: false
        },
        {
          position: 2,
          color: WedgeColors[2],
          image: '',
          has_reward: false
        },
        {
          position: 3,
          color: WedgeColors[3],
          image: '',
          has_reward: false
        }
      ],
      wheel_position: 'center',
      play_button_colour: '#2665ee',
      play_button_text_colour: '#ffffff',
      font_colour: '#000000',
      background_image: {
        type: 'image',
        value: {
          filename: Backgrounds[0].filename,
          image_url: Backgrounds[0].image_url,
          section: ''
        }
      },
      reward_image: {
        type: 'image',
        value: {
          filename: Rewards[0].filename,
          image_url: Rewards[0].image_url,
          section: ''
        }
      },
      rim_image: {
        type: 'image',
        value: {
          filename: Wheels[0].filename,
          image_url: Wheels[0].image_url,
          section: ''
        }
      },
      pointer_image: {
        type: 'image',
        value: {
          filename: Pointers[0].filename,
          image_url: Pointers[0].image_url,
          section: ''
        }
      },
      outcome: {
        type: 'image',
        value: {
          file: Outcome,
          filename: 'outcome.png'
        }
      },
      nooutcome: {},
      always_visible: false
    }
  },
  stamps: {
    header: {
      title: '',
      description: ''
    }
  },
  scratch_card: {
    translatable: {
      landing_page: landingPageInitialDisplayProperties.translatable,
      header: {
        value: {
          title: 'Scratch and Win!',
          description: 'Scratch the card to reveal your prize!'
        }
      },
      play_button_text: 'Start scratching!',
      outcome: {
        title: 'Congratulations!',
        description: 'You just won a reward!',
        button_text: 'View my wallet'
      },
      nooutcome: {
        title: 'You did not win anything.',
        description: 'Try again tomorrow',
        button_text: 'Try again'
      }
    },
    static: {
      landing_page: landingPageInitialDisplayProperties.static,
      header: {
        header_colour: '#000000',
        subheader_colour: '#000000'
      },
      font_colour: '#000000',
      background_image: {
        type: 'image',
        value: Background[0]
      },
      prescratch_image: {
        type: 'image',
        value: Pregame[0]
      },
      post_success_image: {
        type: 'image',
        value: Finished[0]
      },
      post_fail_image: {
        type: 'image',
        value: Failed[0]
      },
      play_button_colour: '#2665ee',
      play_button_text_colour: '#ffffff',
      outcome: {
        type: 'image',
        value: {
          file: Outcome,
          filename: 'outcome.png'
        }
      },
      nooutcome: {},
      always_visible: false
    }
  },
  quiz: {
    background_image: {
      type: 'image',
      value: QuizBackground[0]
    },
    nooutcome: {
      title: { en: { text: 'Sorry! Try again' } },
      description: { en: { text: 'Try again tomorrow' } },
      button_text: { en: { text: 'Try again' } }
    },
    outcome: {
      title: { en: { text: 'Congratulations!' } },
      description: { en: { text: 'You just won a reward!' } },
      button_text: { en: { text: 'View reward' } },
      outcome_image: {
        type: 'image',
        value: QuizOutcomeImage[0]
      }
    },
    landing_page: {
      body: { en: { text: 'Landing screen body' } },
      button_text: { en: { text: 'Start playing' } }
    }
  },
  survey: {
    background_image: {
      type: 'image',
      value: QuizBackground[0]
    },
    nooutcome: {
      title: { en: { text: 'Try again next time!' } },
      description: { en: { text: 'More surveys coming your way!' } },
      button_text: { en: { text: 'Close' } }
    },
    outcome: {
      title: { en: { text: 'Congratulations!' } },
      description: { en: { text: 'Click on the wallet to check out your prize' } },
      button_text: { en: { text: 'Wallet' } },
      outcome_image: {
        type: 'image',
        value: QuizOutcomeImage[0]
      }
    },
    landing_page: {
      body: { en: { text: 'Landing screen body' } },
      button_text: { en: { text: 'Start playing' } }
    }
  },
  quest: {
    translatable: {},
    static: {
      quest_success_image: {
        type: 'image',
        value: {
          filename: 'task_done.png',
          image_url: cdnURL('task_done.png')
        }
      }
    }
  },
  raffle: {
    translatable: {
      landing_page: {
        headline: 'Test raffles campaign',
        sub_headline: 'From 03 Oct 2022 onwards. Raffle draw on 31 Dec 2022.',
        enrolment_message: {
          type: 'rich_text',
          value:
            'Participate in the raffle and a chance to win the grand prize! Earn 3 raffle tickets for every transactions above $5. The grand prizes will be issued to winners after the draw to be held on December 31st, 2022.'
        }
      },
      raffle_ticket_outcome: {
        headline: 'Congratulations, you earned tickets!',
        sub_headline: 'Check the The Big Raffle campaign to see the earned tickets',
        claim_ticket_button_text: 'Claim tickets'
      },
      grand_prize_outcome: {
        headline: 'Congratulations, you earned a grand prize!',
        sub_headline: 'Claim now before the time runs out!',
        claim_ticket_button_text: 'Claim prize'
      }
    },
    static: {
      landing_page: {
        image: {
          type: 'image',
          value: {
            filename: 'win.png',
            image_url: cdnURL('raffle/landing_image.jpg')
          }
        }
      },
      raffle_ticket_outcome: {
        win_reward_graphic: {
          type: 'image',
          value: {
            filename: 'win.png',
            image_url: cdnURL('raffle/win.jpg')
          }
        }
      },
      grand_prize_outcome: {
        win_reward_graphic: {
          type: 'image',
          value: {
            filename: 'win.png',
            image_url: cdnURL('raffle/win.jpg')
          }
        }
      }
    }
  },
  plinko: {
    translatable: {
      landing_page: landingPageInitialDisplayProperties.translatable,
      header: {
        value: {
          title: 'Plinko!',
          description: 'Drop the ball and get some lucky bounces.'
        },
        type: 'text'
      },
      play_button_text: 'Drop the ball',
      outcome: {
        title: 'Congratulations!',
        description: 'You just won a reward!',
        button_text: 'View My Wallet'
      },
      nooutcome: {
        title: 'You did not win anything.',
        description: 'Try again tomorrow.',
        button_text: 'Try again.'
      }
    },
    static: {
      landing_page: landingPageInitialDisplayProperties.static,
      header: {
        header_colour: '#d9e3f0',
        subheader_colour: '#d9e3f0'
      },
      stage_color: '#D99B0C',
      ball_color: '#5BBE0D',
      game_duration: 3,
      play_button_colour: '#fee800',
      play_button_text_colour: '#000000',
      outcome: {
        type: 'image',
        value: {
          file: Outcome,
          filename: 'outcome.png'
        }
      },
      nooutcome: {},
      font_colour: '#000000',
      background_image: {
        type: 'image',
        value: PlinkoBackgrounds[0]
      },
      target_image: {
        type: 'image',
        value: PlinkoRewards[0]
      },
      always_visible: false
    }
  }
} as const;

export default placeholders;
