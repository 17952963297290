import React from 'react';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { useField, useFormikContext } from 'formik';

import { Select } from 'components/Layout/Form';

import FItem from './FItem';

interface SelectFieldProps extends SelectProps {
  name: string;
  label?: string;
  formItemProps?: FormItemProps;
}

const SelectField = ({ name, label, formItemProps, ...props }: SelectFieldProps) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <FItem meta={meta} label={label} {...formItemProps}>
      <Select
        id={name}
        value={field.value}
        onChange={v => formik.setFieldValue(field.name, v)}
        onBlur={() => formik.setFieldTouched(field.name)}
        showArrow
        {...props}
      />
    </FItem>
  );
};

SelectField.Option = Select.Option;
SelectField.OptGroup = Select.OptGroup;

export default SelectField;
