export const policyOptions = [
  {
    label: 'includes',
    value: 'include'
  },
  {
    label: 'excludes',
    value: 'exclude'
  }
];
