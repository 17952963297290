import React from 'react';
import { Col, Radio } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { useGenderList } from 'api/mappings';
import { FlexContainer } from 'components/Layout/common';

const Gender: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { data: genderList = [] } = useGenderList();

  return (
    <FlexContainer>
      <Radio.Group
        name="gender"
        value={_get(values, 'filters.gender[0].criteria[0].value')}
        onChange={e => {
          setFieldValue('filters.gender[0].criteria[0].value', e.target.value);
        }}
      >
        {genderList?.map(gender => (
          <Col key={gender.id} xs={{ span: 10, offset: 1 }}>
            <Radio key={gender.id} value={gender.id}>
              {gender.name}
            </Radio>
          </Col>
        ))}
      </Radio.Group>
    </FlexContainer>
  );
};

export default Gender;
