import React, { memo, useState } from 'react';
import { Card } from 'antd';
import { TableProps } from 'antd/lib/table';

import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import CampaignFilter from 'containers/Analytics/components/CampaignFilter';
import { useTenant } from 'hooks';

import MetricsCard from '../../components/MetricsCard';
import { CardsRow } from '../../styles';
import {
  CampaignsEngagementGraph,
  CampaignStackedHistogram,
  CampaignTableGraph,
  CampaignVSGraph
} from '../graphs';
import * as S from './styles';

const Referrals = () => {
  const { name: tenantName } = useTenant();
  const [filterParams, setFilterParams] = useState({});

  return (
    <>
      <CampaignFilter onChange={params => setFilterParams(params)} campaignType={'referral'} />
      <Card style={{ margin: '20px 0px 20px 0px' }}>
        <S.CardHeader>Key Metrics</S.CardHeader>
        <CardsRow gutter={[0, 16]}>
          <MetricsCard
            identifier="campaign_referral_customer_engagement"
            description="CUSTOMER ENGAGEMENTS"
            helpText="Total unique customer engagement for refferals campaigns."
            filterParams={filterParams}
          />
        </CardsRow>
      </Card>
      {/* TODO. Make it working correctly */}
      {/* <GridRenderer perRow={1} gutter={16}>
        <Cards md={24}>
          <ReferralsBI filterParams={filterParams} />
        </Cards>
      </GridRenderer> */}
      <CampaignsEngagementGraph
        identifier={[
          'campaign_referral_breakdown_by_date_engagement',
          'campaign_referral_breakdown_by_date_view',
          'campaign_referral_breakdown_by_date_completion'
        ]}
        filterParams={filterParams}
      />
      <Card style={{ margin: '20px 0px 20px 0px' }}>
        <S.CardHeader>Summary</S.CardHeader>
        <CardsRow gutter={[0, 16]}>
          <MetricsCard
            identifier="campaign_referral_total_active_campaign"
            description="TOTAL ACTIVE CAMPAIGNS"
            helpText="Total number of current active campaigns"
            filterParams={filterParams}
          />
          <MetricsCard
            identifier="campaign_referral_total_unique_view"
            description="TOTAL UNIQUE VIEWS"
            helpText="Number of unique users that viewed your campaign"
            filterParams={filterParams}
          />
          <MetricsCard
            identifier="campaign_referral_customer_engagement_rate"
            description="ENGAGEMENT RATE"
            helpText="Percentage of audience reached who completed the campaign"
            filterParams={filterParams}
          />
        </CardsRow>
      </Card>
      <GridRenderer perRow={1} gutter={16}>
        <S.Cards md={24}>
          <ReferralsTopByEngagementRateBI filterParams={filterParams} />
        </S.Cards>
      </GridRenderer>
      <S.SectionHeader>Customer Insights</S.SectionHeader>
      <GridRenderer perRow={1} gutter={16}>
        <CampaignVSGraph
          identifier="campaign_referral_new_vs_returning_user"
          title="New vs Returning Users"
          helpText="Percentages of first time users vs returning users"
          filterParams={filterParams}
        />
      </GridRenderer>
      {tenantName !== TENANTS.RAZER && (
        <GridRenderer perRow={2} gutter={16}>
          <CampaignStackedHistogram
            identifier="campaign_referral_age"
            title="Age"
            filterParams={filterParams}
          />
          <CampaignStackedHistogram
            identifier="campaign_referral_gender"
            title="Gender"
            filterParams={filterParams}
          />
          <ReferralsStateBI filterParams={filterParams} />
          <ReferralsClusterBI filterParams={filterParams} />
        </GridRenderer>
      )}
    </>
  );
};

const ReferralsTopByEngagementRateBI = ({ filterParams = {} }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    scroll: { x: 1500 },
    columns: [
      {
        title: 'Campaign Name',
        key: 'campaign_name',
        dataIndex: '[1]'
      },
      {
        title: (
          <TableHeader title="Total Views" tooltip="Total number views for Referrals campaign" />
        ),
        key: 'total_views',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      },
      {
        title: <TableHeader title="Audience Size" tooltip="Total reach for Referrals campaign" />,
        key: 'total_reach',
        dataIndex: '[3]',
        render: (text, record) => <span>{record[3]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Enrolled"
            tooltip="Total number of users enrolled in Referrals campaign"
          />
        ),
        key: 'user_enrolled',
        dataIndex: '[4]',
        render: (text, record) => <span>{record[4]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Unique Views"
            tooltip="Total number of unique views for Referrals campaign"
          />
        ),
        key: 'unique_view',
        dataIndex: '[5]',
        render: (text, record) => <span>{record[5]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Engagement Rate"
            tooltip="Total number of Referrals campaign engagements"
          />
        ),
        key: 'engagement_rate',
        dataIndex: '[6]',
        render: (text, record) => <span>{record[6]?.toLocaleString()}</span>
      },
      {
        title: <TableHeader title="Total referral" tooltip="Total number of Referrals campaigns" />,
        key: 'total_referral',
        dataIndex: '[7]',
        render: (text, record) => <span>{record[7]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_referral_top_campaign_by_performance"
        title="Performance"
        pagination={true}
        tableProps={tableProps}
        scrollX={true}
        filterParams={filterParams}
      />
    </>
  );
};

const ReferralsStateBI = ({ filterParams = {} }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    // scroll: { x: 'scroll' },
    columns: [
      {
        title: 'State Name',
        key: 'primary_cluster_state',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Engagements"
            tooltip="Total number of Referrals campaign engagements"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completions"
            tooltip="Total number of Referrals campaign completions"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[2]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_referral_state"
        title="Location - State"
        minHeight="320px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

const ReferralsClusterBI = ({ filterParams = {} }) => {
  const tableProps: TableProps<any> = {
    rowKey: (r: any) => r[0],
    columns: [
      {
        title: 'Cluster Name',
        key: 'primary_cluster',
        dataIndex: '[3]'
      },
      {
        title: (
          <TableHeader
            title="Engagements"
            tooltip="Total number of Referrals campaign engagements"
          />
        ),
        key: 'issued_count',
        dataIndex: '[1]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      },
      {
        title: (
          <TableHeader
            title="Completions"
            tooltip="Total number of Referrals campaign completions"
          />
        ),
        key: 'completed_count',
        dataIndex: '[2]',
        render: (text, record) => <span>{record[1]?.toLocaleString()}</span>
      }
    ]
  };
  return (
    <>
      <CampaignTableGraph
        identifier="campaign_referral_cluster"
        title="Location - Cluster"
        minHeight="320px"
        tableProps={tableProps}
        pagination={true}
        filterParams={filterParams}
      />
    </>
  );
};

interface TableHeaderProps {
  title: string;
  tooltip?: string;
}

const TableHeader = ({ title, tooltip }: TableHeaderProps) => {
  return <>{title}</>;
};

export default memo(Referrals);
