import React from 'react';

import { PageContent, PageHeader } from 'components/page';

import AudienceForm from '../form';

interface Props {}

const AudienceCreatePage = (props: Props) => {
  return (
    <>
      <PageHeader title="New Audience" />
      <PageContent>
        <AudienceForm />
      </PageContent>
    </>
  );
};

export default AudienceCreatePage;
