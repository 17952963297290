import produce from 'immer';

import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL
} from './constants';

const initialState = {
  loading: false,
  categories: null
};

function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CATEGORY:
    case UPDATE_CATEGORY:
    case GET_CATEGORIES: {
      return { ...state, loading: true };
    }

    case CREATE_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_FAIL:
    case UPDATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_FAIL: {
      return { ...state, loading: false };
    }

    case GET_CATEGORIES_SUCCESS: {
      return produce(state, draft => {
        draft.loading = false;
        draft.categories = {
          data: action.payload.data,
          meta: action.payload.meta
        };
      });
    }

    case GET_CATEGORIES_FAIL: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}

export default categoryReducer;
