import { Table } from 'antd';
import styled, { css } from 'styled-components';

import downIcon from '../assets/images/down.svg';
import upIcon from '../assets/images/up.svg';

export const StyledTable = styled(Table)`
  ${({ marginTop }: { marginTop?: boolean }) =>
    marginTop &&
    css`
      margin-top: 2rem;
    `};

  &.ant-table-wrapper {
    overflow: auto !important;

    td {
      font-size: 14px !important;
      line-height: 1.43 !important;
      color: #4a4a4a !important;
      position: relative;
      a {
        color: #000 !important;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .ant-table-thead th {
      background: #fff !important;
      border-top: solid 1px #e4e4e4;
      border-bottom: solid 1px #e4e4e4;
      font-weight: 600 !important;
    }

    .ant-table-row {
      &.is-gray {
        background-color: #f1f1f1;
      }
      &.hide-default-expand-icon {
        .ant-table-row-expand-icon,
        .ant-table-row-indent {
          display: none;
        }
      }
    }

    .ant-table-row-expand-icon {
      border: none;
      background: none;
      position: absolute;
      top: 50%;
      right: 10px;
      margin: 0;
      transform: translateY(-50%);
    }

    .ant-table-row-collapsed:after {
      content: url(${downIcon});
    }

    .ant-table-row-expanded:after {
      content: url(${upIcon});
    }

    .expand-icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      position: relative;
      cursor: pointer;
      &::before {
        content: url(${upIcon});
        width: 18px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.3s ease;
      }

      &.is-active {
        &::before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    .ant-table-body {
      overflow-y: auto !important;
    }

    .ant-table-content .ant-table-header {
      overflow-y: hidden;
    }

    .ant-table-row-indent {
      display: inline-block;
      vertical-align: top;
      height: 20px;

      &.indent-level-0 {
        display: none;
      }

      @media (min-width: 1200px) {
        padding-right: 40px;
      }
    }
  }

  .ant-tag {
    &:hover {
      opacity: 1;
    }
  }
  .spinner > * {
    top: 11px;
    height: 43px;
  }
`;

// this table was created for drag and drop rows. The main idea was
// to add white-space property to the existing antd Table.
export const TableWithNoWrapHeader = styled(Table)`
  thead.ant-table-thead {
    white-space: nowrap;
  }
  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  tbody tr:hover {
    cursor: ${props => (props.onRow ? 'pointer' : 'auto')};
  }
`;

const Cell = styled.div`
  display: inline-block;
`;

const ActionCell = styled(Cell)`
  display: flex;
  justify-content: space-around;
`;

export default StyledTable;
export { Cell, ActionCell };
