import React, { memo, useCallback, useMemo } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Card, Spin, Table } from 'antd';
import { Row } from 'antd';

import { GridItem } from 'components/Display/GridRenderer';
import GraphCardMenu from 'containers/Analytics/components/GraphCardMenu';
import { useMetabase, useCard, useAnalyticsContext } from 'containers/Analytics/hooks';
import { showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import { loyaltyMembershipDistribution } from './dummyData';

const IDENTIFIER = 'loyalty_flat_members_distribution_across_tier';

const calculateDiffPercentage = (
  current_user_count: number,
  previous_user_count: number
): number => {
  return (100 * (current_user_count - previous_user_count)) / previous_user_count;
};
interface IProps {
  filterParams: {
    loyalty_program_id: Number;
  };
}
interface ITableData {
  current_user_count: number;
  previous_user_count: number;
  loyalty_tier: string;
}

const LoyaltiesMembershipDistributionGraph: React.FC<IProps> = ({ filterParams }) => {
  const { card_id: questionId } = useCard(IDENTIFIER);
  const { loyalty_program_id } = filterParams;
  const dataParams = useMemo(() => ({ loyalty_program_id }), [loyalty_program_id]);

  const { data, tokenError, token, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );

  const { isDemoMode, cards } = useAnalyticsContext();

  let d: any;
  if (isDemoMode) {
    d = loyaltyMembershipDistribution;
  } else {
    d = data?.data?.rows.map((row: [number, number, string]) => ({
      current_user_count: row[0] || 0,
      previous_user_count: row[1] || 0,
      loyalty_tier: row[2],
      key: `${row[0] || 0}${row[1] || 0} ${row[2]}`
    }));
  }

  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  if (!showCard(cards, IDENTIFIER) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title="Members Distribution Across Tiers"
        bodyStyle={{ height: 348, padding: '10px' }}
        extra={<GraphCardMenu questionId={questionId} token={token} dataParams={{}} />}
      >
        {hasError && <Error retry={onRetry} />}
        {!data && !hasError && !isDemoMode && (
          <Row justify="center" align="middle" style={{ height: '300px' }}>
            <Spin size="large" />
          </Row>
        )}
        {((!hasError && !isDemoMode && data) || isDemoMode) && (
          <Table
            dataSource={d}
            size="middle"
            pagination={false}
            style={{ height: '100%' }}
            columns={[
              {
                title: 'Tier',
                key: 'loyalty_tier',
                dataIndex: 'loyalty_tier',
                render: tier => <>{tier}</>
              },
              {
                title: 'Previous User Count',
                key: 'previous_user_count',
                dataIndex: 'previous_user_count',
                render: prvCount => <>{prvCount}</>
              },
              {
                title: 'Current User Count',
                key: 'current_user_count',
                dataIndex: 'current_user_count',
                render: currCount => <>{currCount}</>
              },
              {
                title: 'Changes',
                key: 'change',
                dataIndex: '',
                render: (_, record: ITableData) => {
                  const change = calculateDiffPercentage(
                    record.current_user_count,
                    record.previous_user_count
                  );
                  if (change > 0) {
                    return (
                      <span style={{ color: '#46C18E' }}>
                        <CaretUpOutlined />
                        {change.toFixed(2) + '%'}
                      </span>
                    );
                  } else if (change < 0) {
                    return (
                      <span style={{ color: '#DF3856' }}>
                        <CaretDownOutlined />
                        {change.toFixed(2) + '%'}
                      </span>
                    );
                  } else {
                    return <span>{'0%'}</span>;
                  }
                }
              }
            ]}
          />
        )}
      </Card>
    </GridItem>
  );
};

export default memo(LoyaltiesMembershipDistributionGraph);
