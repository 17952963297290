import React from 'react';
import { Row, Col } from 'antd';
import _pickBy from 'lodash/pickBy';

import ChangesRow from './ChangesRow';

interface ChangesDetailProps {
  changes: { [key: string]: (number | string | null | object | boolean)[] };
}

const ChangesDetails = ({ changes }: ChangesDetailProps) => {
  // filter out all entries whose values are [null, null];
  const filteredChanges = _pickBy(changes, (val: any) => val.some(Boolean));
  return (
    <div>
      <Row gutter={16} style={{ fontWeight: 600, padding: '0 0 1rem' }}>
        <Col span={4}>Details</Col>
        <Col span={10}>Old</Col>
        <Col span={10}>New</Col>
      </Row>
      {Object.keys(filteredChanges).map(key => (
        <ChangesRow key={key} propertyName={key} data={filteredChanges[key]} />
      ))}
    </div>
  );
};

export default ChangesDetails;
