import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Result, Skeleton } from 'antd';
import { Col, Row } from 'antd';
import { useQueryParam, StringParam } from 'use-query-params';

import logo from 'containers/App/pages/ic_logo.svg';
import { useAxios } from 'hooks';

import CustomerDetail from './CustomerDetail';

const Error = ({ status, description }) => (
  <Result status={status} title={status} subTitle={description} />
);

const Wrapper = () => {
  const [tokenQuery] = useQueryParam('token', StringParam);
  const [loading, response, error] = useAxios('/v4/me', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenQuery}`
    },
    //@ts-ignore
    external: true
  });

  if (error) {
    return <Error status="500" description="Something is seriously wrong with the token" />;
  }

  const customerId = response?.data?.id;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header style={{ backgroundColor: '#ffffff' }}>
        <Row align="middle">
          <Col>
            <Link to={'/'} className="logo">
              <img src={logo} alt="Perx Logo" />
            </Link>
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ margin: '1.5rem 1rem 0' }}>
        <Skeleton loading={loading} active>
          {!tokenQuery ? (
            <Error status="500" description="You need a token bruh" />
          ) : customerId ? (
            <CustomerDetail token={tokenQuery} customerId={customerId} />
          ) : (
            <Error status="500" description="Can't find the customer ID in the payload" />
          )}
        </Skeleton>
      </Layout.Content>
    </Layout>
  );
};

export default Wrapper;
