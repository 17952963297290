import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Radio } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { RadioGroupProps } from 'antd/lib/radio';

import RHFItem from './RHFItem';

interface RHFItemRadioGroupProps {
  control: Control<any, any>;
  name: string;
  label: string;
  children: React.ReactNode;
  formItemProps: FormItemProps;
  radioGroupProps?: RadioGroupProps;
}

const RHFItemRadioGroup = ({
  control,
  name,
  children,
  label,
  formItemProps,
  radioGroupProps
}: RHFItemRadioGroupProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={label} {...formItemProps}>
      <Radio.Group
        value={field.value}
        onChange={evt => {
          field.onChange(evt.target.value);
          field.onBlur();
        }}
        {...radioGroupProps}
      >
        {children}
      </Radio.Group>
    </RHFItem>
  );
};

export default RHFItemRadioGroup;
