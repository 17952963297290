import { message } from 'antd';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { makeSelectAuthUser } from 'containers/App/selectors';

import {
  tagsGetSuccess,
  tagsGetFail,
  loyaltylistGetSuccess,
  loyaltylistGetFail,
  rewardsGetSuccess,
  rewardsGetFail,
  campaignsGetFail,
  campaignsGetSuccess,
  campaignGetSuccess,
  campaignGetFail,
  campaignCreateSuccess,
  campaignCreateFail,
  campaignUpdateSuccess,
  campaignUpdateFail,
  audiencesGetFail,
  audiencesGetSuccess,
  audienceInfoGetSuccess,
  audienceInfoGetFail,
  campaignSearchSuccess,
  campaignSearchFail,
  searchRewardSuccess,
  searchRewardFail,
  campaignOrderFail,
  campaignOrderSuccess,
  campaignDeactivateFail,
  campaignDeactivateSuccess,
  campaignActivateSuccess,
  campaignActivateFail,
  searchReward
} from './actions';
import {
  deactivateCampaign,
  activateCampaign,
  getTags,
  getAudienceList,
  getAudienceInfo,
  getCampaign,
  orderCampaign,
  createCampaign,
  updateCampaign,
  getCampaigns,
  searchCampaigns,
  getRewards,
  getLoyaltyList
} from './api';
import {
  CREATE_CAMPAIGN,
  GET_TAGS,
  GET_LOYALTY_LIST,
  GET_REWARDS,
  GET_CAMPAIGNS,
  GET_LOYALTY,
  GET_AUDIENCE_INFO,
  GET_AUDIENCES,
  EDIT_CAMPAIGN,
  SEARCH_CAMPAIGN,
  SEARCH_REWARD,
  ORDER_CAMPAIGN,
  DEACTIVATE_CAMPAIGN,
  ACTIVATE_CAMPAIGN
} from './constants';

function* campaignDeactivate(action) {
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(deactivateCampaign, `${data.id}`, data, headers);
    yield put(campaignDeactivateSuccess(response.data));
    if (data.page && data.page === 'list') {
      yield call(campaignsGet, {});
    }
    message.success('Successfully deactivated!');
  } catch (e) {
    yield put(campaignDeactivateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* campaignActivate(action) {
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(activateCampaign, data.id, data, headers);
    yield put(campaignActivateSuccess(response.data));
    if (data.page && data.page === 'list') {
      yield call(campaignsGet, {});
    }
    message.success('Successfully activated!');
  } catch (e) {
    yield put(campaignActivateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* tagsGet() {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getTags, headers);
    yield put(tagsGetSuccess(response.data));
  } catch (e) {
    yield put(tagsGetFail(e.data));
  }
}

function* audiencesGet() {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getAudienceList, headers);
    yield put(audiencesGetSuccess(response.data));
  } catch (e) {
    yield put(audiencesGetFail(e.data));
  }
}

function* audienceInfoGet(action) {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getAudienceInfo, headers);
    yield put(audienceInfoGetSuccess(response.data));
  } catch (e) {
    yield put(audienceInfoGetFail(e.data));
  }
}

function* campaignGet(action) {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCampaign, `${action.payload}`, headers);
    yield put(campaignGetSuccess(response.data));
    yield put(tagsGetSuccess({ data: response.data.data.tags }));
  } catch (e) {
    yield put(campaignGetFail(e.data));
  }
}

function* campaignOrder(action) {
  // TODO: separte axios as API service
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      orderCampaign,

      data,
      headers
    );
    yield put(campaignOrderSuccess(response.data));
  } catch (e) {
    yield put(campaignOrderFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* campaignCreate(action) {
  // TODO: separte axios as API service
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  let cancelMessage;
  try {
    cancelMessage = message.loading('Submitting data...', 0);
    const response = yield call(createCampaign, data, headers);
    setTimeout(cancelMessage, 200);
    yield put(campaignCreateSuccess(response.data));
    //yield call(history.push, "/p/catalogues/list");
    yield call(action.history.push, `/p/loyalty/show/${response.data.data.id}`);
  } catch (e) {
    setTimeout(cancelMessage, 200);
    yield put(campaignCreateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* campaignUpdate(action) {
  const authUser = yield select(makeSelectAuthUser());
  let data = action.payload;

  if (data && data.code_generation_method === 'multiple') {
    if (!data.user_generated) {
      data.code_generation_method = 'multiple_with_user_upload';
    }
  }

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  let cancelMessage;
  try {
    cancelMessage = message.loading('Submitting data...', 0);
    const response = yield call(updateCampaign, data.id, data, headers);

    setTimeout(cancelMessage, 200);

    yield put(campaignUpdateSuccess(response.data));

    //yield call(history.push, "/p/rewards/list");
    yield call(action.history.push, `/p/loyalty/show/${data.id}`);
  } catch (e) {
    setTimeout(cancelMessage, 200);
    yield put(campaignUpdateFail(e.data));
    message.error(e.data.message || 'something went wrong');
  }
}

function* campaignsGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCampaigns, params, headers);
    yield put(campaignsGetSuccess(response.data));
  } catch (e) {
    yield put(campaignsGetFail(e.data));
  }
}

function* campaignsSearch(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchCampaigns, params, headers);
    yield put(campaignSearchSuccess(response.data));
  } catch (e) {
    yield put(campaignSearchFail(e.data));
  }
}

function* rewardsGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getRewards, params, headers);
    yield put(rewardsGetSuccess(response.data));
  } catch (e) {
    yield put(rewardsGetFail(e.data));
  }
}

function* loyaltyListGet(action) {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getLoyaltyList, headers);
    yield put(loyaltylistGetSuccess(response.data));
  } catch (e) {
    yield put(loyaltylistGetFail(e.data));
  }
}

function* rewardSearch(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchReward, params, headers);
    yield put(searchRewardSuccess(response.data));
  } catch (e) {
    yield put(searchRewardFail(e.data));
  }
}

function* loyaltySaga() {
  yield takeLatest(GET_TAGS, tagsGet);
  yield takeLatest(GET_LOYALTY_LIST, loyaltyListGet);
  yield takeLatest(GET_REWARDS, rewardsGet);
  yield takeLatest(CREATE_CAMPAIGN, campaignCreate);
  yield takeLatest(GET_CAMPAIGNS, campaignsGet);
  yield takeLatest(GET_LOYALTY, campaignGet);
  yield takeLatest(EDIT_CAMPAIGN, campaignUpdate);
  yield takeLatest(GET_AUDIENCE_INFO, audienceInfoGet);
  yield takeLatest(GET_AUDIENCES, audiencesGet);
  yield takeLatest(SEARCH_CAMPAIGN, campaignsSearch);
  yield takeLatest(SEARCH_REWARD, rewardSearch);
  yield takeLatest(ORDER_CAMPAIGN, campaignOrder);
  yield takeLatest(DEACTIVATE_CAMPAIGN, campaignDeactivate);
  yield takeLatest(ACTIVATE_CAMPAIGN, campaignActivate);
}

export default loyaltySaga;
