import React, { useCallback, useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Col, Row } from 'antd';
import { Modal } from 'antd';

import DeletableButton from 'containers/Analytics/components/DeletableButton';
import CampaignSelectModal from 'containers/Rule/components/CampaignSelectModal';
import SelectedCampaign from 'containers/Rule/components/SelectedCampaign';

const CampaignIdFilter = ({ campaignType, filterParams, setFilterParams }) => {
  const [modalCampaignsOpen, setCampaignsModalOpen] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaignSelectModalOpen, setCampaignSelectModalOpen] = useState(false);
  const resetSelectedCampaigns = useCallback(() => {
    setSelectedCampaigns([]);
  }, [setSelectedCampaigns]);

  useEffect(() => {
    if (!filterParams?.campaign_id) {
      setSelectedCampaigns([]);
    }
  }, [filterParams]);

  const applyFilters = useCallback(() => {
    const newFilter = { ...filterParams };
    if (selectedCampaigns.length) {
      newFilter['campaign_id'] = selectedCampaigns.map(campaign => campaign.id).join(',');
    } else {
      delete newFilter['campaign_id'];
    }

    setFilterParams(newFilter);
    closeAllModals();
    // eslint-disable-next-line
  }, [selectedCampaigns]);

  const deleteFilter = useCallback(
    (deleteId: number) => {
      const filteredCampaigns = selectedCampaigns.filter(campaign => campaign.id !== deleteId);
      setSelectedCampaigns(filteredCampaigns);
    },
    [selectedCampaigns]
  );

  const deleteAndApply = useCallback(
    (deleteId: number) => {
      const filteredCampaigns = selectedCampaigns.filter(campaign => campaign.id !== deleteId);
      setSelectedCampaigns(filteredCampaigns);
      const newFilter = { ...filterParams };
      if (filteredCampaigns.length) {
        newFilter['campaign_id'] = filteredCampaigns.map(campaign => campaign.id).join(',');
      } else {
        delete newFilter['campaign_id'];
      }

      setFilterParams(newFilter);
    },
    // eslint-disable-next-line
    [selectedCampaigns]
  );

  const closeAllModals = () => {
    // as types of filterable-by expands add here
    if (modalCampaignsOpen) {
      setCampaignsModalOpen(false);
    }
  };

  return (
    <>
      <CampaignSelectModal
        isOpen={campaignSelectModalOpen}
        closeModal={() => setCampaignSelectModalOpen(false)}
        onChooseCampaign={campaign => {
          if (!selectedCampaigns.find(campaignFound => campaignFound.id === campaign.id)) {
            setSelectedCampaigns([...selectedCampaigns, campaign]);
          }
        }}
        campaignType={campaignType}
        tabs={['all', 'active', 'approved', 'inactive', 'ended']}
      />
      <Modal
        maskClosable={false}
        width="1020px"
        open={modalCampaignsOpen} // as types of filterable-by expands add here
        title="Campaigns"
        onCancel={closeAllModals}
        footer={[
          <Button key="reset" onClick={resetSelectedCampaigns}>
            Reset
          </Button>,
          <Button key="submit" type="primary" onClick={applyFilters}>
            Apply
          </Button>
        ]}
      >
        <>
          <p style={{ marginBottom: '19px' }}>Select the campaigns to filter by.</p>
          {selectedCampaigns &&
            selectedCampaigns.map(currCampaign => (
              <div key={currCampaign.id} style={{ marginBottom: '0.5rem' }}>
                <SelectedCampaign id={currCampaign.id} entity="campaigns" onDelete={deleteFilter} />
              </div>
            ))}
          <div
            style={{ color: '#2665EE', fontWeight: 700, marginTop: '1.2rem', cursor: 'pointer' }}
            onClick={() => setCampaignSelectModalOpen(true)}
          >
            <PlusCircleOutlined /> Include another campaign
          </div>
        </>
      </Modal>

      <Row style={{ margin: '1.5rem 0' }}>
        <Col span={6}>Campaigns</Col>
        <Col span={18}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedCampaigns &&
              selectedCampaigns.map(currCampaign => (
                <DeletableButton
                  name={currCampaign.name}
                  key={currCampaign.id}
                  onClick={deleteAndApply.bind(null, currCampaign.id)}
                />
              ))}
            <Button onClick={() => setCampaignsModalOpen(true)}>Add Campaigns</Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CampaignIdFilter;
