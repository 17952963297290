import useSWR from 'swr';

import { IAnyCampaign } from 'containers/Campaign/types';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useCampaign = (id: number) => {
  const { data: { data } = {} } = useSWR<IDetailResponse<IAnyCampaign>>(
    id ? `/v4/dash/campaigns/${id}` : null,
    fetcher
  );

  return { data };
};

export const useCampaignList = (params: object, shouldFetch: boolean = true) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IAnyCampaign>>(
    shouldFetch ? `/v4/dash/campaigns?${buildQuery(params)}` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};

export const useCampaignsWithIds = (ids: number[] = []) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<IAnyCampaign>>(
    ids.length > 0 ? `/v4/dash/campaigns?id=${ids.join(',')}&limit=1000` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};
