import { Button } from 'antd';
import styled from 'styled-components';

const DashedButton = styled(Button)`
  background-color: rgba(38, 101, 238, 0.05);
  border: 1px dashed #2665ee;
  color: #2665ee;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;

  :hover {
    background-color: rgba(38, 101, 238, 0.2);
  }
`;

export default DashedButton;
