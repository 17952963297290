import _snakeCase from 'lodash/snakeCase';

import { getLineDemoData } from 'containers/Analytics/utils';

const getDemoDataAndLabels = (dateRange, datePart, selectedOption) => {
  let demoLines = [];
  let demoLabels = {};
  let perDayRange: [number, number] = [14000, 28000];

  switch (selectedOption) {
    case 'age':
      demoLines = ['65+', '18-24', '25-34', '35-44', '45-54', '56-64', 'Unknown', '0-18'];
      perDayRange = [14000, 28000];
      break;
    case 'gender':
      demoLines = ['Male', 'Female', 'Unknown'];
      perDayRange = [5000, 17000];
      break;
    case 'ethnicity':
      demoLines = ['Chinese', 'Malay', 'Indian', 'Others', 'Unknown'];
      perDayRange = [6000, 16000];
      break;
  }

  demoLines.forEach(line => {
    demoLabels[_snakeCase(line)] = line;
  });

  return {
    data: getLineDemoData(dateRange, datePart, perDayRange, demoLines),
    labels: demoLabels
  };
};

export default getDemoDataAndLabels;
