import React from 'react';

import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { FItemDatePicker, FItemSelect } from 'components/formik';
import { policyOptions } from 'containers/Audience/utils';

const MemberSince = () => {
  const fieldPath = 'filters.account_confirmed_at[0].criteria';

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
      </FlexContainer>
      <Label bold>Users who signed up between</Label>
      <div style={{ display: 'flex' }}>
        <FItemDatePicker
          name={`${fieldPath}.gteq`}
          aria-label="Member since from"
          momentFormat="YYYY-MM-DD"
        />
        <Label style={{ margin: '0 8px' }}>and</Label>
        <FItemDatePicker
          name={`${fieldPath}.lteq`}
          aria-label="Member since to"
          momentFormat="YYYY-MM-DD"
        />
      </div>
    </>
  );
};

export default MemberSince;
