import React, { memo, FunctionComponent } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

type DeleteableButtonProps = {
  onClick: any;
  name: string;
};

const DeletableButton: FunctionComponent<DeleteableButtonProps> = ({ onClick, name }) => {
  return (
    <StyledButton>
      {name}
      <p onClick={onClick}>&nbsp;X</p>
    </StyledButton>
  );
};

export default memo(DeletableButton);

const StyledButton = styled(Button)`
  &&& {
    background-color: #f5f5f5;
    color: #9b9b9b;
    cursor: pointer;
    font-size: 14px;
    padding: 0 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #d9d9d9;
    p {
      display: inline;
      &:hover {
        color: #508bfa;
      }
    }
  }
`;
