// @ts-nocheck
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import abilitySaga from 'containers/Ability/saga';
import appSaga from 'containers/App/saga';
import bulkUploadSaga from 'containers/BulkAction/saga';
import campaignSaga from 'containers/Campaign/saga';
import catalogueSaga from 'containers/Catalogue/saga';
import categorySaga from 'containers/Category/saga';
import customerSaga from 'containers/Customer/saga';
import loyaltySaga from 'containers/Loyalty/saga';
import merchantSaga from 'containers/Merchant/saga';
import permissionsSaga from 'containers/Permissions/saga';
import rewardSaga from 'containers/Reward/saga';
import rewardTransactionSaga from 'containers/RewardTransaction/saga';
import settingSaga from 'containers/Setting/saga';
import tagSaga from 'containers/Tag/saga';

import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, thunkMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];
  const composeEnhancers =
    typeof window === 'object' &&
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
      : compose;

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  store.runSaga(appSaga);
  store.runSaga(campaignSaga);
  store.runSaga(abilitySaga);
  store.runSaga(catalogueSaga);
  store.runSaga(merchantSaga);
  store.runSaga(rewardSaga);
  store.runSaga(customerSaga);
  store.runSaga(tagSaga);
  store.runSaga(categorySaga);
  store.runSaga(loyaltySaga);
  store.runSaga(settingSaga);
  store.runSaga(bulkUploadSaga);
  store.runSaga(rewardTransactionSaga);
  store.runSaga(permissionsSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(createReducer(store.injectedReducers));
      });
    }
  }

  return store;
}

export const browserHistory = createBrowserHistory();

const initialState = {};

export const store = configureStore(initialState, browserHistory);
