import React from 'react';
import { Menu } from 'antd';

import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAuthUser } from 'hooks';

interface GraphCardExtraProps {
  token?: string;
  dataParams?: object;
  helpText?: string;
  questionId?: number;
  dateRange?: any;
}

const GraphCardExtra = (props: GraphCardExtraProps) => {
  const { questionId } = props;

  const { email = '' } = useAuthUser();

  return (
    <ActionsMenu
      menu={
        <Menu>
          {email.endsWith('@perxtech.com') && (
            <Menu.Item key="debug">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                Debug Metabase
              </a>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default GraphCardExtra;
