import axios from 'axios';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';

import { tagsGetSuccess, tagsGetFail, tagsSearchSuccess, tagsSearchFail } from './actions';
import { GET_TAGS, SEARCH_TAGS, DELETE_TAG } from './constants';

const getTags = (url, headers) => {
  return axios
    .get(url, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

const searchTags = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

function* tagSearch(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchTags, `${API_ROOT}/v4/dash/tags`, params, headers);
    yield put(tagsSearchSuccess(response.data));
  } catch (e) {
    yield put(tagsSearchFail(e.data));
  }
}

function* tagsGet(action) {
  const authUser = yield select(makeSelectAuthUser());

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getTags, `${API_ROOT}/v4/dash/simple/tags?size=1000`, headers);
    yield put(tagsGetSuccess(response.data));
  } catch (e) {
    yield put(tagsGetFail(e.data));
  }
}

function* tagSaga() {
  yield takeLatest(GET_TAGS, tagsGet);
  yield takeLatest(SEARCH_TAGS, tagSearch);
  yield takeLatest(DELETE_TAG, commonSaga);
}

export default tagSaga;
