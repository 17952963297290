import React from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Radio, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import get from 'lodash/get';
import moment from 'moment-timezone';

import { useCustomPropertyList } from 'api/mappings';
import { TENANTS } from 'appConstants';
import DeleteIconButton from 'components/DeleteIconButton/DeleteIconButton';
import Label from 'components/Text/Label';
import {
  FItemDatePicker,
  FItemInput,
  FItemInputNumber,
  FItemRadioGroup,
  FItemSelect
} from 'components/formik';
import { makeSelectTenantConfig } from 'containers/App/selectors';
import { verticalLayout } from 'utils/formItemLayouts';

import CustomFilterField from './CustomFilterField';

interface CustomFilterOperator {
  label: string;
  key: string;
  type: 'boolean' | 'input' | 'number_input' | 'select' | 'multi_select' | 'date_select';
  values?: { key: string | number; label: string }[];
  placeholder?: string;
}

interface CustomFilter {
  type: 'boolean' | 'date' | 'integer' | 'string';
  label: string;
  operators: CustomFilterOperator[];
  formatter?: string;
}

const monthOptions = moment.months().map((month, index) => ({ key: index + 1, label: month }));

const weekdayOptions = moment.weekdays().map((weekday, index) => ({ key: index, label: weekday }));

export const customFilterTemplates: CustomFilter[] = [
  {
    type: 'boolean',
    label: 'Boolean',
    operators: [
      {
        label: 'equals',
        key: 'eq',
        type: 'boolean'
      }
    ]
  },
  {
    type: 'date',
    label: 'Date',
    operators: [
      {
        label: 'Day of week',
        key: 'day_of_week',
        type: 'multi_select',
        values: weekdayOptions,
        placeholder: 'Select days'
      },
      {
        label: 'Equals',
        key: 'eq',
        type: 'date_select'
      },
      {
        label: 'Greater than',
        key: 'gt',
        type: 'date_select'
      },
      {
        label: 'In months',
        key: 'in_months',
        type: 'multi_select',
        values: monthOptions,
        placeholder: 'Select months'
      },
      {
        label: 'Less than',
        key: 'lt',
        type: 'date_select'
      },
      {
        label: 'Not in months',
        key: 'not_in_months',
        type: 'multi_select',
        values: monthOptions,
        placeholder: 'Select months'
      }
    ],
    formatter: 'datetime'
  },
  {
    type: 'integer',
    label: 'Number',
    operators: [
      {
        label: 'equals',
        key: 'eq',
        type: 'number_input'
      },
      {
        label: 'is greater than',
        key: 'gt',
        type: 'number_input'
      },
      {
        label: 'is less than',
        key: 'lt',
        type: 'number_input'
      }
    ]
  },
  {
    type: 'string',
    label: 'String',
    operators: [
      {
        label: 'equals',
        key: 'eq',
        type: 'input'
      },
      {
        label: 'does not equal to',
        key: 'neq',
        type: 'input'
      },
      {
        label: 'in',
        key: 'in',
        type: 'input'
      },
      {
        label: 'not_in',
        key: 'not_in',
        type: 'input'
      }
    ]
  }
];

const typeOptions = customFilterTemplates.map(({ type, label }) => ({ value: type, label }));

const Personal = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const tenantConfig = useSelector(makeSelectTenantConfig());

  const { data } = useCustomPropertyList();

  const customAttributesArrayPath = `filters.custom_attributes.0.criteria`;

  return (
    <>
      <FieldArray
        name={customAttributesArrayPath}
        render={({ push, remove }) => {
          return (
            <div style={{ maxWidth: 600 }}>
              {get(values, customAttributesArrayPath)
                ?.map((_, index) => {
                  const fieldPath = `${customAttributesArrayPath}.${index}`;
                  const type = get(values, `${fieldPath}.type`);

                  const selectedFilterTemplate = customFilterTemplates.find(
                    item => item.type === type
                  );

                  const operatorOptions = selectedFilterTemplate?.operators?.map(item => ({
                    value: item.key,
                    label: item.label
                  }));

                  const operator = get(values, `${fieldPath}.operator`);
                  const selectedOperatorTemplate = selectedFilterTemplate?.operators.find(
                    item => item.key === operator
                  );
                  const selectedOperatorType = selectedOperatorTemplate?.type;

                  return (
                    <div key={index} style={{ position: 'relative' }}>
                      <DeleteIconButton
                        onClick={() => remove(index)}
                        style={{ position: 'absolute', top: 8, right: -24 }}
                      />
                      <FItemSelect
                        name={`${fieldPath}.type`}
                        aria-label={`Custom attribute #${index + 1}: type`}
                        options={typeOptions}
                        onChange={value => {
                          setFieldValue(`${fieldPath}.type`, value);
                          setFieldValue(`${fieldPath}.attribute`, undefined);
                          setFieldValue(
                            `${fieldPath}.operator`,
                            value === 'boolean' ? 'eq' : undefined
                          );
                          setFieldValue(`${fieldPath}.selected_values`, undefined);
                        }}
                      />
                      <FItemInput
                        name={`${fieldPath}.attribute`}
                        aria-label={`Custom attribute #${index + 1}: attribute`}
                        label="Enter key name and value details"
                        placeholder="Key name"
                        formItemProps={{ ...verticalLayout }}
                        style={{ width: '100%' }}
                      />
                      <Row gutter={16}>
                        {type !== 'boolean' && (
                          <Col xs={24} md={12}>
                            <FItemSelect
                              name={`${fieldPath}.operator`}
                              aria-label={`Custom attribute #${index + 1}: operator`}
                              options={operatorOptions}
                              onChange={value => {
                                setFieldValue(`${fieldPath}.operator`, value);
                                setFieldValue(`${fieldPath}.selected_values`, undefined);
                              }}
                              placeholder="Select relation"
                            />
                          </Col>
                        )}
                        <Col xs={24} md={12}>
                          {type === 'boolean' && (
                            <FItemRadioGroup
                              name={`${fieldPath}.selected_values`}
                              aria-label={`Custom attribute #${index + 1}: value`}
                            >
                              <Label bold>is</Label>
                              <Radio value={true}>True</Radio>
                              <Radio value={false}>False</Radio>
                            </FItemRadioGroup>
                          )}
                          {type === 'integer' && (
                            <FItemInputNumber
                              name={`${fieldPath}.selected_values`}
                              aria-label={`Custom attribute #${index + 1}: value`}
                              min={0}
                              step={1}
                              style={{ width: '100%' }}
                              placeholder="Enter number"
                            />
                          )}
                          {type === 'string' && (
                            <FItemInput
                              name={`${fieldPath}.selected_values`}
                              aria-label={`Custom attribute #${index + 1}: value`}
                              style={{ width: '100%' }}
                              placeholder="Key value"
                            />
                          )}
                          {type === 'date' && (
                            <>
                              {selectedOperatorType === 'date_select' && (
                                <FItemDatePicker
                                  name={`${fieldPath}.selected_values`}
                                  aria-label={`Custom attribute #${index + 1}: value`}
                                  momentFormat="DD-MM-YYYY"
                                  placeholder="DD-MM-YYYY"
                                  format="DD-MM-YYYY"
                                />
                              )}
                              {selectedOperatorType === 'select' && (
                                <FItemSelect
                                  name={`${fieldPath}.selected_values`}
                                  aria-label={`Custom attribute #${index + 1}: value`}
                                  options={selectedOperatorTemplate.values?.map(
                                    ({ key, label }) => ({
                                      value: key,
                                      label
                                    })
                                  )}
                                  placeholder={selectedOperatorTemplate.placeholder}
                                />
                              )}
                              {selectedOperatorType === 'multi_select' && (
                                <FItemSelect
                                  mode="multiple"
                                  name={`${fieldPath}.selected_values`}
                                  aria-label={`Custom attribute #${index + 1}: value`}
                                  options={selectedOperatorTemplate.values?.map(
                                    ({ key, label }) => ({
                                      value: key,
                                      label
                                    })
                                  )}
                                  placeholder={selectedOperatorTemplate.placeholder}
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  );
                })
                .reduce(
                  (acc, item, index) =>
                    acc.length === 0
                      ? [item]
                      : [...acc, <Divider key={`divider-${index}`} />, item],
                  []
                )}
              <Button
                type="primary"
                ghost
                onClick={() =>
                  push({
                    type: 'string',
                    attribute: null,
                    operator: null,
                    selected_values: null
                  })
                }
                icon={<PlusOutlined />}
              >
                Add custom properties
              </Button>
              <Divider />
            </div>
          );
        }}
      />
      {tenantConfig && tenantConfig.name === TENANTS.STARHUB && (
        <>
          &nbsp;&nbsp;
          <Checkbox
            checked={!!values.membership}
            onChange={evt => {
              const v = evt.target.checked
                ? [
                    {
                      type: 'membership',
                      criteria: {
                        eq: 'Hub Club'
                      }
                    }
                  ]
                : null;
              setFieldValue('membership', v);
            }}
          />
          <Divider />
        </>
      )}
      {data
        ?.map(item => <CustomFilterField key={item.key} fieldMapping={item} />)
        ?.reduce(
          (acc, item, index) =>
            !acc?.length ? [item] : [...acc, <Divider key={`divider-${index}`} />, item],
          []
        )}
    </>
  );
};

export default Personal;
