import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { FieldArray, useFormikContext } from 'formik';

import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';

const AgeGroup: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <>
      <FieldArray name="filters.age">
        {({ push, remove }) => (
          <>
            {values?.filters?.age
              .map((ageGroup, index) => (
                <FlexContainer key={index} center margin="0 0 5px">
                  <InputLimitWidth>
                    <Select
                      value={values?.filters?.age?.[index]?.criteria?.policy}
                      onSelect={value =>
                        setFieldValue(`filters.age[${index}].criteria.policy`, value)
                      }
                      placeholder="Includes"
                      data-testid="age-include-select"
                    >
                      <Select.Option value="include" data-testid="age-include-select-include">
                        includes
                      </Select.Option>
                      <Select.Option value="exclude" data-testid="age-include-select-exclude">
                        excludes
                      </Select.Option>
                    </Select>
                  </InputLimitWidth>
                  ages from&nbsp;&nbsp;
                  <InputLimitWidth width="80">
                    <Select
                      onChange={value =>
                        setFieldValue(`filters.age[${index}].criteria.gteq`, value)
                      }
                      value={values?.filters?.age?.[index]?.criteria?.gteq}
                      data-testid="from-age-select"
                      placeholder="18"
                    >
                      {Array.from(Array(100).keys())
                        .map(i => i + 1)
                        .map((age, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={age}
                              data-testid={`from-age-select__${age}`}
                            >
                              {age}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </InputLimitWidth>
                  to&nbsp;&nbsp;
                  <InputLimitWidth width="80">
                    <Select
                      onChange={value =>
                        setFieldValue(`filters.age[${index}].criteria.lteq`, value)
                      }
                      value={values?.filters?.age?.[index]?.criteria?.lteq}
                      data-testid="to-age-select"
                      placeholder="35"
                    >
                      {Array.from(Array(100).keys())
                        .map(i => i + 1)
                        .map((age, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={age}
                              data-testid={`to-age-select__${age}`}
                            >
                              {age}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </InputLimitWidth>
                  <DeleteOutlined onClick={() => remove(index)} style={{ color: '#ff4d4f' }} />
                </FlexContainer>
              ))
              .reduce((acc, item, index) => {
                return acc === null
                  ? [item]
                  : [...acc, <strong key={`separator-${index}`}>and</strong>, item];
              }, null)}
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                push({
                  type: 'age',
                  criteria: {
                    gteq: '',
                    lteq: '',
                    policy: 'include'
                  }
                })
              }
            >
              Add Range
            </Button>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default AgeGroup;
