import React, { useReducer } from 'react';
import { Select, Spin } from 'antd';
import produce from 'immer';
import _debounce from 'lodash/debounce';
import useSWR from 'swr';

import API from 'utils/request';

const fetchRewards = async ([url, params]: [string, object]) => {
  const res = await API.get(url, { params });
  return res.data;
};

interface RewardState {
  page: any;
  size: any;
  search_string?: any;
}

const reducer = (state: RewardState, action): RewardState =>
  produce(state, draft => {
    switch (action.type) {
      case 'SEARCH':
        draft.search_string = action.payload;
        draft.page = 1;
        break;
      default:
        break;
    }
  });

export const RewardSelect = ({
  mode = null,
  placeholder = 'Please select a reward',
  preSelected = false,
  value,
  ...props
}) => {
  const [params, dispatch] = useReducer(reducer, {
    search_string: null,
    page: 1,
    size: 100
  });
  const {
    data: rewards,
    isLoading,
    isValidating
  } = useSWR(['/v4/dash/rewards', params], fetchRewards, {
    revalidateOnFocus: false
  });

  const onSearch = val => dispatch({ type: 'SEARCH', payload: val });

  return (
    <Select
      {...props}
      showSearch
      loading={!rewards || isValidating}
      disabled={!rewards || isValidating}
      value={!rewards || isValidating ? [] : value}
      placeholder={placeholder}
      style={{ width: '100%' }}
      mode={mode}
      onSearch={_debounce(onSearch, 500)}
      onBlur={e => e.preventDefault()}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
    >
      {rewards?.data.map((option, index) => (
        <Select.Option key={option.id} value={option.id}>
          {option.name} (ID: {option.id})
        </Select.Option>
      ))}
    </Select>
  );
};

export default RewardSelect;
