import React from 'react';
import { Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';

import { shortenTick } from 'containers/Analytics/pages/graphs/ComboGraph/components/AreaGraphSection';
import { colorGenerator } from 'containers/Analytics/utils';

interface AreaGraphProps {
  graphData: object[];
  labels?: { [key: string]: string };
  height?: number;
  withFill?: boolean;
}

const AreaGraph: React.FC<AreaGraphProps> = ({
  graphData,
  labels = {},
  height = 200,
  withFill = false
}) => {
  const color = colorGenerator();
  let graphs = [];

  //Extract number of graphs to render from data
  const firstRow = graphData?.[0];
  if (!!firstRow) {
    const keys = Object.keys(firstRow);
    const graphKeys = keys.filter(key => key !== 'name');

    graphs = graphKeys.map(graphKey => ({
      name: graphKey,
      color: color.next().value
    }));
  }

  const CustomTooltip = props => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const sorted = [...payload].sort((a, b) => b.value - a.value);

      return (
        <TooltipWrapper>
          <h4>{label}</h4>
          <div>
            {sorted.map((line, index) => (
              <LegendWrapper key={index}>
                <Indicator color={line.stroke} />{' '}
                {`${labels[line.name] || _startCase(line.name)} : ${line.value}`}
              </LegendWrapper>
            ))}
          </div>
        </TooltipWrapper>
      );
    }

    return null;
  };

  return (
    <Spin spinning={!graphData}>
      {!_isEmpty(graphData) ? (
        <ResponsiveContainer height={height}>
          <AreaChart data={graphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              {withFill &&
                graphs.map((g, index) => (
                  <linearGradient key={index} id={g.name} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={g.color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={g.color} stopOpacity={0} />
                  </linearGradient>
                ))}
            </defs>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={shortenTick} allowDecimals={false} />
            <CartesianGrid strokeDasharray={'1 5'} horizontal={false} />
            <Tooltip content={<CustomTooltip />} />
            {graphs.map((g, index) => (
              <Area
                key={index}
                type="monotone"
                dataKey={g.name}
                stroke={g.color}
                fillOpacity={1}
                fill={`url(#${g.name})`}
              />
            ))}
            <Legend
              verticalAlign={'top'}
              height={63}
              payload={graphs.map(g => ({
                value: labels[g.name] || _startCase(g.name),
                type: 'line',
                id: g.name,
                color: g.color
              }))}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Spin>
  );
};

export default AreaGraph;

const TooltipWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 1rem;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Indicator = styled.div`
  display: inline-block;
  background: ${props => (props.color ? props.color : '#8884d8')};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
`;
