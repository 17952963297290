import useSWR from 'swr';

import { fetcher } from './utils';

export interface ISimpleListResponse<T> {
  data: T[];
}

export interface ISimpleLoyalty {
  id: number;
  name: string;
  tiers: Array<{
    id: number;
    name: string;
    minimum_accumulation_value: number;
    forced_only: boolean;
  }>;
}

export const useSimpleLoyaltyList = () => {
  const { data, isLoading } = useSWR<ISimpleListResponse<ISimpleLoyalty>>(
    '/v4/dash/simple/loyalty',
    fetcher
  );

  return { data: data?.data, isLoading };
};

export interface ISimpleCatalog {
  id: number;
  name: string;
}

export const useSimpleCatalogList = () => {
  const { data, isLoading } = useSWR<ISimpleListResponse<ISimpleCatalog>>(
    '/v4/dash/simple/catalogs',
    fetcher
  );

  return { data: data?.data, isLoading };
};
