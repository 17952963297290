import useSWR from 'swr';

import { IPaginatedResponse } from 'utils/types';

import { fetcher } from './utils';

export interface ICategory {
  id?: number;
  usage?: string[];
  parent_id?: number;
  count?: number;
  children?: ICategory[];
  usage_match?: boolean;
  updated_at?: string;
  title_en?: string;
  title_th?: string;
  title_zh?: string;
  title_vi?: string;
  title_zh_hans?: string;
  title_zh_hant?: string;
  description_en?: string;
  description_th?: string;
  description_zh?: string;
  description_vi?: string;
  description_zh_hans?: string;
  description_zh_hant?: string;
  parent_label?: string;
  label?: string;
  value?: number;
}

export const useCategoryList = (params?: object) => {
  const url = `/v4/dash/categories`;

  const { data, isLoading, mutate, error } = useSWR<IPaginatedResponse<ICategory>>(
    { url, params },
    fetcher
  );

  return { data: data?.data, isLoading, mutate, error };
};
