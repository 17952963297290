import React, { memo, useRef } from 'react';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Col, Tooltip } from 'antd';

import { FlexContainer, FilePreview } from 'components/Layout/common';
import StyledButton from 'components/StyledButton';

export interface UploadSingleFileFiledProps {
  name: string;
  value?: any;
  acceptedFormats: any[];
  sampleUrl: string;
  changeFieldValue: (...args: any[]) => any;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  isDisabled?: boolean;
}

const UploadSingleFileFiled = memo(
  ({
    name,
    value,
    acceptedFormats,
    sampleUrl,
    changeFieldValue,
    textAlign,
    isDisabled = false
  }: UploadSingleFileFiledProps) => {
    const inputEl = useRef(null);

    const renderAcceptedFormats = () => {
      let acceptedFormatsString = '';
      if (Array.isArray(acceptedFormats)) {
        acceptedFormatsString = acceptedFormats.join(',');
      }
      return acceptedFormatsString.length ? (
        <p
          style={{
            fontWeight: 300,
            padding: `10px 0 0`,
            marginBottom: `0`
          }}
        >
          Accepted Format: {acceptedFormatsString} files only
        </p>
      ) : (
        ``
      );
    };

    const renderSampleLink = () => {
      return sampleUrl && sampleUrl.length ? (
        <Col>
          <p
            style={{
              color: `#fb7901`,
              cursor: `pointer`,
              fontWeight: 300,
              marginBottom: `0`
            }}
          >
            <a
              style={{ color: `#fb7901` }}
              href={sampleUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              Download Sample
            </a>
          </p>
        </Col>
      ) : (
        ``
      );
    };

    const renderSingleFilePreview = value => {
      return value ? (
        <FilePreview>
          <PaperClipOutlined style={{ fontSize: '15px' }} />
          <p>
            {value.filename && value.filename.length > 10 ? (
              <Tooltip title={value.filename}>{value.filename.slice(0, 10) + '...'}</Tooltip>
            ) : (
              value.filename
            )}
          </p>
          <DeleteOutlined
            style={{ fontSize: '15px' }}
            onClick={() => changeFieldValue(name, null)}
          />
        </FilePreview>
      ) : (
        ''
      );
    };

    return (
      <div style={{ textAlign }}>
        <div style={{ display: 'inline-block' }}>
          <input
            id={name}
            ref={inputEl}
            name={name}
            type="file"
            accept={acceptedFormats.join(',')}
            style={{
              position: 'absolute',
              left: '-100vw',
              opacity: 0,
              width: 0,
              height: 0
            }}
            onChange={(e: any) => {
              e.preventDefault();
              const files = [...e.target.files];
              changeFieldValue(name, {
                file: files[0],
                filename: files[0].name
              });
            }}
          />
          <div>
            <FlexContainer alignItems="center" paddingVertical={0} hasMiddleSpaces>
              <Col>
                {value ? (
                  <>{renderSingleFilePreview(value)}</>
                ) : (
                  <label htmlFor={name} style={{ display: 'block' }}>
                    <StyledButton
                      key="uploadFile"
                      type="primary"
                      margin="0"
                      onClick={() => {
                        inputEl.current.click();
                      }}
                    >
                      Choose File
                    </StyledButton>
                  </label>
                )}
              </Col>
              {sampleUrl && (
                <Col>
                  <span style={{ opacity: 0.5 }}>or</span>
                </Col>
              )}
              {renderSampleLink()}
            </FlexContainer>
            {renderAcceptedFormats()}
          </div>
        </div>
      </div>
    );
  }
);

export default UploadSingleFileFiled;
