import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Catalogue';

export const GET_CATALOGUES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUES`;
export const GET_CATALOGUES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUES_SUCCESS`;
export const GET_CATALOGUES_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUES_FAIL`;

export const GET_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUE`;
export const GET_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUE_SUCCESS`;
export const GET_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CATALOGUE_FAIL`;

export const CREATE_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATALOGUE`;
export const CREATE_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATALOGUE_SUCCESS`;
export const CREATE_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CATALOGUE_FAIL`;

export const ACTIVATE_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CATALOGUE`;
export const ACTIVATE_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CATALOGUE_SUCCESS`;
export const ACTIVATE_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CATALOGUE_FAIL`;

export const DEACTIVATE_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CATALOGUE`;
export const DEACTIVATE_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CATALOGUE_SUCCESS`;
export const DEACTIVATE_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CATALOGUE_FAIL`;

export const ORDER_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CATALOGUE`;
export const ORDER_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CATALOGUE_SUCCESS`;
export const ORDER_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CATALOGUE_FAIL`;

export const UPDATE_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATALOGUE`;
export const UPDATE_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATALOGUE_SUCCESS`;
export const UPDATE_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_CATALOGUE_FAIL`;

export const GET_REWARDS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS`;
export const GET_REWARDS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_SUCCESS`;
export const GET_REWARDS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_FAIL`;

export const GET_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS`;
export const GET_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_SUCCESS`;
export const GET_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_FAIL`;

export const SAVE_SORTED_REWARDS_STORE = `${PROJECT_NAME}/${CONTAINER_NAME}/SAVE_SORTED_REWARDS_STORE`;
export const GET_SORTED_REWARDS_STORE = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_SORTED_REWARDS_STORE`;

export const SEARCH_CATALOGUE = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CATALOGUE`;
export const SEARCH_CATALOGUE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CATALOGUE_FAIL`;
export const SEARCH_CATALOGUE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CATALOGUE_SUCCESS`;

export const SEARCH_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD`;
export const SEARCH_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_SUCCESS`;
export const SEARCH_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_FAIL`;

export const GET_AUDIENCES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES`;
export const GET_AUDIENCES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_SUCCESS`;
export const GET_AUDIENCES_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_FAIL`;

export const GET_AUDIENCE_INFO = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO`;
export const GET_AUDIENCE_INFO_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_SUCCESS`;
export const GET_AUDIENCE_INFO_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_FAILED`;
