import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'BulkAction';
export const GET_PROGRESS_LIST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PROGRESS_LIST`;
export const GET_PROGRESS_LIST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PROGRESS_LIST_SUCCESS`;
export const GET_PROGRESS_LIST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PROGRESS_LIST_FAIL`;

export const POST_BULK_FILE = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_BULK_FILE`;
export const POST_BULK_FILE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_BULK_FILE_SUCCESS`;
export const POST_BULK_FILE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_BULK_FILE_FAIL`;
