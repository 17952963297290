import React from 'react';
import { DatePicker, TimePicker, Row, Col } from 'antd';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import styled from 'styled-components';

import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

interface Props {
  value: string | object;
  onChange: (date: Moment) => void;
  onBlur?: (e: React.SyntheticEvent<Element, Event>) => void;
  disabled?: boolean;
  datePlaceholder?: string;
  timePlaceholder?: string;
  style?: React.CSSProperties;
}

const DateTimeField = ({
  value,
  onChange,
  disabled = false,
  datePlaceholder = 'DD MMM YYYY',
  timePlaceholder = 'HH:mm',
  style,
  onBlur
}: Props) => {
  const onDateChange = (value, _) => {
    if (value) {
      const newValue = value.set({
        day: value.get('day'),
        month: value.get('month'),
        year: value.get('year')
      });
      onChange(newValue);
    } else {
      onChange(undefined);
    }
  };

  const onTimeChange = (value, _) => {
    if (value) {
      const newValue = value.set({
        hour: value.get('hour'),
        minute: value.get('minute'),
        second: 0
      });
      onChange(newValue);
    } else {
      onChange(undefined);
    }
  };
  return (
    <Row gutter={4} style={style}>
      <Col span={12}>
        <StyledDatePicker
          disabled={disabled}
          showTime={false}
          value={value && moment(value)}
          placeholder={datePlaceholder}
          onChange={onDateChange}
          onBlur={onBlur}
          format={DATE_FORMAT}
        />
      </Col>
      <Col span={12}>
        <StyledTimePicker
          disabled={disabled}
          value={value && moment(value)}
          placeholder={timePlaceholder}
          onChange={onTimeChange}
          format={TIME_FORMAT}
        />
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  &&& {
    width: 100%;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  &&& {
    width: 100%;
  }
`;

export default DateTimeField;
