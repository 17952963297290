import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { shallowEqual, useDispatch } from 'react-redux';
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Badge, Layout, Menu } from 'antd';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { FEATURES, IS_PRODUCTION, TENANTS } from 'appConstants';
import { FeatureFlag } from 'components/FeatureFlag';
import MenuIcon from 'components/Header/MenuIcon';
import Ability from 'containers/Ability';
import Analytics from 'containers/Analytics';
import { CustomBadge } from 'containers/App/CustomBadge';
import { getNecessities } from 'containers/App/actions';
import IdleHandler from 'containers/App/components/IdleHandler';
import UserMenu from 'containers/App/components/UserMenu';
import { Logo, Sider } from 'containers/App/style';
import Audience from 'containers/Audience';
import BDOTransferRequests from 'containers/BDO/transfer-requests';
import { useBDOLoyaltyEnabled } from 'containers/BDO/utils';
import { getLoyaltyPrograms } from 'containers/Loyalty/actions';
import { getPermissions } from 'containers/Permissions/actions';
import { useFeatureFlags, useTenant, useTypedSelector } from 'hooks';
import ForbiddenPage from 'pages/public/AccessDenied';
import { DashboardLoading, PageLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';
import { lazyWithRetry } from 'utils/lazyWithRetry';

import ReloadMessage from '../components/ReloadMessage';
import { getFilteredMenu } from '../getMenu';
import { usePendo } from '../usePendo';
import POSableLogo from './POSable_logo.png';
import AffirmLogo from './affirm_logo.png';
import { ReactComponent as PerxLogo } from './ic_logo.svg';
import PoweredByPerx from './powered_by_perx.png';
import RushLogo from './rush_logo.png';
import ZealLogo from './zeal_logo.png';
import './index.less';
import { fetcher } from 'api/utils';

import ErrorFallback from '../components/ErrorFallback';

const Settings = lazyWithRetry(() => import('containers/Setting'));
const Leaderboards = lazyWithRetry(() => import('containers/Leaderboards'));
const Marketplace = lazyWithRetry(() => import('containers/Marketplace/pages/Marketplace'));
const Report = lazyWithRetry(() => import('containers/Report'));
const Reward = lazyWithRetry(() => import('containers/Reward'));
const Catalogue = lazyWithRetry(() => import('containers/Catalogue'));
const Merchant = lazyWithRetry(() => import('containers/Merchant'));
const Campaign = lazyWithRetry(() => import('containers/Campaign'));
const Loyalty = lazyWithRetry(() => import('containers/Loyalty'));
const Customer = lazyWithRetry(() => import('containers/Customer'));
const BulkAction = lazyWithRetry(() => import('containers/BulkAction'));
const Rules = lazyWithRetry(() => import('containers/Rule'));
const Inventory = lazyWithRetry(() => import('containers/Inventory'));
const Partners = lazyWithRetry(() => import('containers/Partners'));
const Badges = lazyWithRetry(() => import('containers/Badge'));
const UserProfile = lazyWithRetry(() => import('containers/UserProfile'));
const Microsite = lazyWithRetry(() => import('containers/Microsite'));
const QuickSight = lazyWithRetry(() => import('containers/Analytics/QuickSight'));

const Forbidden = () => <ForbiddenPage />;

const ProtectedReward = props => (
  <Ability renderNoAccess={Forbidden} access="rewards">
    <Reward {...props} />
  </Ability>
);

const ProtectedMarketplace = props => (
  <Ability renderNoAccess={Forbidden} access="rewards">
    <Marketplace {...props} />
  </Ability>
);

const ProtectedCatalog = props => (
  <Ability renderNoAccess={Forbidden} access="catalogs">
    <Catalogue {...props} />
  </Ability>
);

const ProtectedCampaign = props => (
  <Ability renderNoAccess={Forbidden} access="campaigns">
    <Campaign {...props} />
  </Ability>
);

const ProtectedMerchant = props => (
  <Ability renderNoAccess={Forbidden} access="merchants">
    <Merchant {...props} />
  </Ability>
);

const ProtectedLoyalty = props => (
  <Ability renderNoAccess={Forbidden} access="loyalties">
    <Loyalty {...props} />
  </Ability>
);

const ProtectedPartner = props => (
  <Ability renderNoAccess={Forbidden} access="partners">
    <Partners {...props} />
  </Ability>
);

const ProtectedSettings = props => (
  <Ability renderNoAccess={Forbidden} access="settings">
    <Settings {...props} />
  </Ability>
);

const ProtectedInventory = props => (
  <Ability renderNoAccess={Forbidden} access="inventories">
    <Inventory {...props} />
  </Ability>
);

const IndexPage = () => {
  usePendo();

  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const permissions = useTypedSelector(state => state.permissions.permissions, shallowEqual);
  const { name: tenantName, features } = useTenant();
  const [collapsed, setCollapsed] = useState(true);
  const { currentTheme } = useThemeSwitcher();
  const makerCheckerPermission = features[FEATURES.MAKER_CHECKER.key];

  const {
    audience: showAudience,
    catalogs: showCatalogs,
    inventories: showInventory,
    reward_budgeting: showPartners,
    rewards_marketplace: showMarketplace,
    leaderboards: showLeaderboards,
    badges: showBadges,
    loyalties: showLoyalties,
    microsite: showMicrosite,
    merchants: showMerchants,
    quicksight: showQuickSight
  } = useFeatureFlags();

  const [selectedMenu, setSelectedMenu] = useState(
    _isEmpty(permissions) ? '' : Object.keys(permissions)[0]
  );

  const onSidebarToggle = () => setCollapsed(prev => !prev);

  const onMenuClick = evt => setSelectedMenu(evt.key);

  useEffect(() => {
    // get audience list
    // get tags
    // get labaels
    // get categories
    dispatch(getNecessities());
    dispatch(getLoyaltyPrograms());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPermissions());
    // eslint-disable-next-line
  }, [tenantName]);

  const bdoLoyaltyEnabled = useBDOLoyaltyEnabled();

  const menu = useMemo(() => {
    // every time permission changes, get the filtered menu items from MENU
    const modifiedPermissions = {
      ...permissions,
      quicksight: true,
      marketplace: showMarketplace
    };
    let newMenu = getFilteredMenu(modifiedPermissions);

    if (!showAudience) {
      newMenu = newMenu.filter(x => x.key !== 'audience');
    }

    if (!showCatalogs) {
      newMenu = newMenu.filter(x => x.key !== 'catalogs');
    }

    if (!showInventory) {
      newMenu = newMenu.filter(x => x.key !== 'inventories');
    }

    if (!showMarketplace) {
      newMenu = newMenu.filter(x => x.key !== 'marketplace');
    }

    if (!showPartners) {
      newMenu = newMenu.filter(x => x.key !== 'partners');
    }

    if (!showLeaderboards) {
      newMenu = newMenu.filter(x => x.key !== 'leaderboards');
    }

    if (!showBadges) {
      newMenu = newMenu.filter(x => x.key !== 'badges');
    }

    if (!showLoyalties) {
      newMenu = newMenu.filter(x => x.key !== 'loyalties');
    }

    if (!showMicrosite) {
      newMenu = newMenu.filter(x => x.key !== 'microsites');
    }

    if (!showMerchants) {
      newMenu = newMenu.filter(x => x.key !== 'merchants');
    }

    if (!bdoLoyaltyEnabled) {
      newMenu = newMenu.filter(x => x.key !== 'transfer_requests');
    }

    if (!showQuickSight) {
      newMenu = newMenu.filter(x => x.key !== 'quicksight');
    }

    return newMenu;
    // eslint-disable-next-line
  }, [bdoLoyaltyEnabled, permissions, tenantName, features]);

  useEffect(() => {
    const activeMenuItem = menu.find(element => location.pathname.includes(element.url));
    if (activeMenuItem && activeMenuItem.key) {
      setSelectedMenu(_ => activeMenuItem.key);
    }
  }, [location.pathname, menu]);

  const showPoweredByPerx = tenantName === TENANTS.CWC;

  if (!tenantName) {
    return <DashboardLoading />;
  }

  return (
    <Layout>
      <IdleHandler />
      <ScrollableSider
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        onCollapse={onSidebarToggle}
        style={{
          height: '100vh',
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 20, // need this to put sider on top of forms\' bottom bar, TODO: reevaluate after looking into BottomBar
          display: 'flex',
          flexDirection: 'column'
        }}
        className={clsx(currentTheme)}
      >
        <Logo>
          <Link to={'/'}>
            <AppLogo />
          </Link>
        </Logo>
        <UserMenu />
        <Menu
          theme="dark"
          mode="inline"
          style={{ borderRight: 'none' }}
          onClick={onMenuClick}
          selectedKeys={[selectedMenu]}
          items={menu.map(item => ({
            key: item.key,
            label: (
              <NavLink to={item.url}>
                {item.description}{' '}
                {makerCheckerPermission && item.description === 'Customers' ? (
                  <CustomBadge />
                ) : null}
              </NavLink>
            ),
            icon: <MenuIcon src={item.svg} className="menu-icon" />
          }))}
        />
        {showPoweredByPerx && <PoweredBy alt="Powered by Perx" src={PoweredByPerx} />}
      </ScrollableSider>
      <Layout
        style={{
          //overflow should be unset to allow position sticky items to work in content
          overflowX: 'unset',
          //min-width is set to auto for flex items cause it to expand based on content. This performs same function as overflow-x hidden
          minWidth: 0
        }}
      >
        <Layout.Content>
          <Sentry.ErrorBoundary
            fallback={({ error, eventId }) =>
              error.name === 'ChunkLoadError' ? (
                <ReloadMessage />
              ) : (
                <ErrorFallback eventId={eventId} />
              )
            }
          >
            <Suspense fallback={<PageLoading />}>
              <Switch>
                {!_isEmpty(permissions) && menu[0] && (
                  <Redirect exact from={`/p`} to={menu[0].url} />
                )}
                <Route path={`${match.url}/reports`} component={Report} />
                <Route
                  path={`${match.url}/marketplace`}
                  component={showMarketplace ? ProtectedMarketplace : null}
                />
                <Route path={`${match.url}/rewards`} component={ProtectedReward} />
                <Route path={`${match.url}/catalogues`} component={ProtectedCatalog} />
                {showMerchants && (
                  <Route path={`${match.url}/merchants`} component={ProtectedMerchant} />
                )}
                <Route path={`${match.url}/campaigns`} component={ProtectedCampaign} />
                {/*<Route path={`${match.url}/badges`} component={ProtectedBadge} />*/}
                {showBadges && <Route path={`${match.url}/badges`} component={Badges} />}
                <Route
                  path={`${match.url}/leaderboards`}
                  component={() => (
                    <FeatureFlag flags={[FEATURES.LEADERBOARDS]}>
                      <Leaderboards />
                    </FeatureFlag>
                  )}
                />
                {showLoyalties && (
                  <Route path={`${match.url}/loyalty`} component={ProtectedLoyalty} />
                )}
                <Route path={`${match.url}/settings`} component={ProtectedSettings} />
                <Route path={`${match.url}/customers`} component={Customer} />
                <Redirect from={`${match.url}/bulkaction`} to={`${match.url}/bulk_actions`} />
                <Route path={`${match.url}/bulk_actions`} component={BulkAction} />
                <Route path={`${match.url}/business_intelligence`} component={Analytics} />
                {showQuickSight && (
                  <Route path={`${match.url}/quicksight`} component={QuickSight} />
                )}
                <Route path={`${match.url}/rules`} component={Rules} />
                <Route path={`${match.path}/inventories`} component={ProtectedInventory} />
                <Route
                  path={`${match.path}/partners`}
                  component={showPartners ? ProtectedPartner : null}
                />
                <Route
                  path={`${match.path}/microsite`}
                  component={showMicrosite ? Microsite : null}
                />
                <Route path={`${match.path}/audiences`} component={Audience} />
                <Route path={`${match.path}/user-profile`} component={UserProfile} />
                <Route path={`${match.path}/transfer-requests`} component={BDOTransferRequests} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </Sentry.ErrorBoundary>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const ScrollableSider = styled(Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    .ant-menu {
      flex: 1;
      overflow-y: auto;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
const PoweredBy = styled.img`
  margin: 0 auto;
  width: 64px;
`;

export default IndexPage;

const AppLogo = () => {
  const { name: tenantName } = useTenant();
  let logo;

  switch (tenantName) {
    case TENANTS.ZEAL:
      logo = IS_PRODUCTION ? (
        <LogoImage src={ZealLogo} alt="Logo" width={'50'} />
      ) : (
        <LogoImage src={POSableLogo} alt="Logo" width={'50'} />
      );
      break;
    case TENANTS.RUSH:
    case TENANTS.CWC:
      logo = <LogoImage src={RushLogo} alt="Logo" style={{ width: 120 }} />;
      break;
    case TENANTS.AFFIRM:
      logo = <LogoImage src={AffirmLogo} alt={'Logo'} width={'85'} />;
      break;
    default:
      logo = <PerxLogo width={'50px'} />;
      break;
  }

  return logo;
};

const LogoImage = styled.img`
  max-width: 100%;
`;
