import React from 'react';
import { Radio } from 'antd';
import { useField, useFormikContext } from 'formik';

import { FormItem } from 'components/Layout/Form';

const RadioField = ({ name, ...props }) => {
  const formik = useFormikContext();
  const [field] = useField(name);
  return (
    <Radio
      onChange={evt => {
        formik.setFieldTouched(field.name, true, false);
        formik.setFieldValue(field.name, evt.target.value);
      }}
      value={field.value}
      {...props}
    />
  );
};

const RadioGroupField = ({
  name,
  showValidateSuccess,
  children,
  label,
  formItemProps,
  radioGroupProps
}) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const isTouched = meta?.touched || false;
  const hasError = meta.error !== undefined && isTouched;
  const isValid = !meta.error && isTouched;
  return (
    <FormItem
      label={label}
      validateStatus={hasError ? 'error' : isValid && showValidateSuccess ? 'success' : undefined}
      hasFeedback={isValid}
      help={(hasError && <li>{meta.error}</li>) || (isValid && '')}
      colon={false}
      {...formItemProps}
    >
      <Radio.Group
        value={field.value}
        onChange={evt => {
          formik.setFieldTouched(field.name, true, false);
          formik.setFieldValue(field.name, evt.target.value);
        }}
        {...radioGroupProps}
      >
        {children}
      </Radio.Group>
    </FormItem>
  );
};

export { RadioGroupField };
export default RadioField;
