import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Input, InputProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import RHFItem from './RHFItem';

interface RHFItemInputProps extends InputProps {
  control: Control<any, any>;
  name: string;
  label?: string;
  formItemProps?: FormItemProps;
}

const RHFItemInput = ({
  control,
  name,
  type,
  label,
  formItemProps,
  ...props
}: RHFItemInputProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={label} htmlFor={name} {...formItemProps}>
      <Input type={type} id={name} prefix={<span />} suffix={<span />} {...field} {...props} />
    </RHFItem>
  );
};

export default RHFItemInput;
