import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Button } from 'antd';
import { Tabs } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import LocaleSwitcher from 'components/LocaleSwitcher';
import StyledTitle from 'components/Title';
import useGetDetail from 'hooks/useGetDetail';

const BreadCrumbs = ({ location, entity_id }) => {
  const pathSnippets = useMemo(() => {
    let idOccurrences = [];

    const snippets = location.pathname
      .split('/')
      .slice(2)
      .filter(p => p !== 'show' && p !== 'edit' && p !== 'insight' && p !== 'create')
      .filter(p => {
        // remove all id params except the first for the case of urls
        // like /partners/show/:partnerId/wallet-transaction-history/:walletId
        const isId = /^\d+$/.test(p);

        if (isId) {
          idOccurrences.push(p);
          return idOccurrences.length === 1;
        }

        return true;
      });

    if (location.pathname.endsWith(entity_id) && location.pathname.includes('/edit/')) {
      snippets.push('edit');
    }
    if (location.pathname.endsWith(entity_id) && location.pathname.includes('/insight/')) {
      snippets.push('insight');
    }

    return snippets;
  }, [location.pathname, entity_id]);

  const entity = () => {
    switch (pathSnippets[0]) {
      case 'loyalties':
        return 'loyalty_programs';
      case 'catalogues':
        return 'catalogs';
      case 'rules':
        return 'transaction_rules';
      case 'audiences':
        return 'v4_audiences';
      default:
        return pathSnippets[0];
    }
  };
  const item = useGetDetail(entity_id, entity());

  const breadcrumbItems = pathSnippets.map((path, index) => {
    const label =
      +entity_id === +path
        ? item?.data?.name || item?.data?.title || item?.data?.id || '...'
        : _startCase(path);

    return (
      <Breadcrumb.Item key={path}>
        {index === pathSnippets.length - 1 ? (
          label
        ) : (
          <Link
            to={`${location.pathname.substring(0, location.pathname.indexOf(path))}${path}`.replace(
              /(edit|insight)/,
              'show'
            )}
          >
            {label}
          </Link>
        )}
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb style={{ fontSize: '12px' }}>{breadcrumbItems}</Breadcrumb>;
};

interface ITab {
  key: string;
  label: React.ReactNode;
}
interface PageHeaderProps {
  title?: string;
  entity_id?: string | number;
  tabs?: ITab[];
  defaultTab?: string;
  onTabChange?: (p: string) => void;
  renderExtra?;
  primaryAction?;
  showPrimaryAction?;
  customAction?;
  isShowLocaleSwitcher?: boolean;
  disablePrimaryAction?: boolean;
  isSticky?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title = 'Loading...',
  entity_id,
  tabs,
  defaultTab,
  onTabChange = () => {},
  renderExtra,
  primaryAction,
  showPrimaryAction = true,
  customAction,
  isShowLocaleSwitcher = false,
  disablePrimaryAction = false,
  isSticky = false
}) => {
  const location = useLocation();
  // tabs format = [{key: String, label: String}, ...];
  //primaryAction = {type: enum[primary, secondary], label: String, icon: antd icon component, onClick: function}
  const [activeTab, setActiveTab] = useState(
    tabs ? (tabs.find(el => el?.key === defaultTab) ? defaultTab : tabs[0].key) : null
  );
  const subpath = location.pathname.split('/').pop();

  const match = useRouteMatch();
  const history = useHistory();

  const changeTab = tab => {
    setActiveTab(tab);
    onTabChange(tab);

    if (match.path === '/p/settings') {
      if (history.location.pathname === location.pathname) {
        if (tab === 'general' && location.pathname.includes('show')) {
          const splitUrlToArray = location.pathname.split('/');
          const listUrlPath = splitUrlToArray[splitUrlToArray.length - 3];
          history.push(`${match.path}/general/${listUrlPath}`);
        }

        if (tab === 'users') {
          history.push(`${match.path}/users/individual_users`);
        }
      }
    }
  };

  useEffect(() => {
    if (!_isEmpty(tabs) && tabs.find(el => el?.key === defaultTab)) {
      setActiveTab(defaultTab); // VS-3392
    }
    // eslint-disable-next-line
  }, [tabs, subpath]);

  return (
    <HeaderContainer tabs={!!tabs} isSticky={isSticky} data-testid="page-header">
      <Helmet titleTemplate={'Perx | %s'}>
        <title>{title}</title>
      </Helmet>
      <TopWrapper>
        <TopMain>
          <BreadCrumbs {...{ location, entity_id }} />
          <StyledTitle>{title}</StyledTitle>
          {entity_id && (
            <>
              <div>
                ID: <span className={'text-muted'}>{entity_id}</span>
              </div>
            </>
          )}
        </TopMain>
        <TopActions>
          <ActionsBtnGroup>
            {customAction && typeof customAction === 'function' ? customAction() : <> </>}
            {showPrimaryAction && primaryAction && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="large"
                  type={primaryAction.type ?? 'primary'}
                  icon={primaryAction?.icon}
                  onClick={primaryAction?.onClick}
                  {...primaryAction?.props}
                  disabled={disablePrimaryAction}
                >
                  {primaryAction?.label}
                </Button>
              </div>
            )}
          </ActionsBtnGroup>
          {isShowLocaleSwitcher && (
            <div style={{ marginTop: primaryAction ? '20px' : '0' }}>
              <LocaleSwitcher />
            </div>
          )}
        </TopActions>
      </TopWrapper>
      {typeof renderExtra === 'function' && <ExtraContainer>{renderExtra()}</ExtraContainer>}
      {tabs && Array.isArray(tabs) && (
        <Tabs
          defaultActiveKey={tabs[0]?.key}
          activeKey={activeTab}
          onTabClick={changeTab}
          tabBarStyle={{ marginBottom: 0, marginTop: '1rem' }}
          items={tabs}
        />
      )}
    </HeaderContainer>
  );
};

PageHeader.propTypes = {
  // @ts-ignore for some reason there is some incompatibility with the props defined above
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node
    })
  ),
  onTabChange: PropTypes.func,
  defaultTab: PropTypes.string,
  title: PropTypes.string,
  entity_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryAction: PropTypes.shape({
    type: PropTypes.oneOf([
      'link',
      'primary',
      'danger',
      'default',
      'disabled',
      'lime',
      'draft',
      'active',
      'pending',
      'approved',
      'ended',
      'inactive'
    ]),
    label: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func
  }),
  renderExtra: PropTypes.func,
  customAction: PropTypes.func,
  isShowLocaleSwitcher: PropTypes.bool
  // selectedLanguage: PropTypes.string,
  // setSelectedLanguage: PropTypes.func
};

export default PageHeader;

const HeaderContainer = styled.div<{ tabs?: boolean; isSticky?: boolean }>`
  padding: ${props => (props.tabs ? '2rem 2rem 0' : '2rem')};
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 100;
    `}
`;

const TopWrapper = styled.div`
  display: flex;
`;

const TopMain = styled.div`
  flex: 1;
`;

const ActionsBtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-end;
`;

const TopActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ExtraContainer = styled.div`
  margin-top: 1rem;
`;
