export const fixDateWithTimezone = function (date, time) {
  let currentTimezone = { offset: 0 };
  if (time.match(/[+-][0-9]{2}:[0-9]{2}$/)) {
    let m = time.match(/([+-][0-9]{2}:[0-9]{2})$/)[0].split(':');
    currentTimezone = {
      offset: parseInt(m[0], 0) + parseInt(m[1], 0) / 60.0
    };
  }
  let dateStr =
    date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/)[0] + 'T' + time.match(/^[0-9]{2}:[0-9]{2}/) + 'Z';
  let timeOffset = -currentTimezone.offset * 3600 * 1000;
  let mm = new Date(new Date(dateStr) - 0 + timeOffset);

  let pad = function (x) {
    return x < 10 ? '0' + x : x;
  };
  let newDate = mm.getUTCFullYear() + '-' + pad(mm.getUTCMonth() + 1) + '-' + pad(mm.getUTCDate());
  let newTime = pad(mm.getUTCHours()) + ':' + pad(mm.getUTCMinutes());

  return newDate + 'T' + newTime + 'Z';
};

export const fixDateWithTimezoneParam = function (params, date_field, time_field) {
  if (params[date_field] && params[time_field]) {
    params[date_field] = fixDateWithTimezone(params[date_field], params[time_field]);
  }
};
