import React from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const STROKE_COLOR = '#10D994';
const format = n => n.toLocaleString(undefined);

export interface BalanceProps {
  current: number;
  total: number;
  showText: boolean;
}

const Balance = (props: BalanceProps) => {
  const { current, total, showText } = props;
  const percent = Math.round((current / total) * 100);

  return (
    <div>
      <Progress
        percent={percent}
        strokeColor={STROKE_COLOR}
        size="small"
        showInfo={false}
        strokeLinecap={'square'}
        type="line"
        strokeWidth={6}
      />
      {showText && (
        <Text>
          {format(current || 0)} of {format(total || 0)}
        </Text>
      )}
    </div>
  );
};

Balance.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  showText: PropTypes.bool
};

Balance.defaultProps = {
  current: 0,
  total: 0,
  showText: true
};

export default Balance;

const Text = styled.span`
  font-size: 0.75rem;
  line-height: 18px;
  color: #9b9b9b;
`;
