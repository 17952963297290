import React, { createContext, useMemo, useState } from 'react';
import { Button, Card } from 'antd';
import styled from 'styled-components';

import { GridItem } from 'components/Display/GridRenderer';
import { DatePart } from 'containers/Analytics/components/DatePartSelect';
import { useAnalyticsContext } from 'containers/Analytics/hooks';
import { GraphHeader, CardTitle } from 'containers/Analytics/styles';

interface IComboGraphProps {
  sections: any[];
  title: any;
  icon?: any;
  filterParams?: object;
  ctaText?: string;
  ctaAction?(): any;
  ctaStyle?: any;
  occupies?: number;
  withDatePart?: boolean;
  alignContentToBottom?: boolean;
}

interface ContextFields {
  filter: {
    datepart?: DatePart;
    loyalty_program_id?: number;
  };
  // filter dedicated for "**_kpi" metabase cards
  kpiFilter?: {
    datepart?: null;
    loyalty_program_id?: number;
  };
  extraData?: {
    noActiveCampaign?: boolean;
    setNoActiveCampaign?: any;
  };
}

export const ComboContext = createContext<ContextFields>({
  filter: {},
  extraData: {},
  kpiFilter: {}
});

const ComboGraph: React.FC<IComboGraphProps> = ({
  sections = [],
  title,
  filterParams,
  icon,
  ctaText,
  ctaAction,
  ctaStyle = {},
  occupies = 1,
  withDatePart = false,
  alignContentToBottom = false
}) => {
  const { datePart } = useAnalyticsContext();

  // noActiveCampaign is a boolean value intended to pass data between
  // the child sibling components of ComboGraph.
  // specifically, this was created to pass data from <ValueSection> to <AreaGraphSection>
  const [noActiveCampaign, setNoActiveCampaign] = useState(false);

  const dataParams = useMemo(
    () => (withDatePart ? { ...filterParams, datepart: datePart } : { ...filterParams }),
    [filterParams, datePart, withDatePart]
  );

  // kpi metabase cards does not need datepart query parameter.
  const kpiParams = useMemo(() => ({ ...filterParams, datepart: null }), [filterParams]);

  return (
    <GridItem occupies={occupies}>
      <Card
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <GraphHeader>
          <GraphTitle>
            {!!icon && <TitleIcon>{icon}</TitleIcon>}
            <CardTitle>{title}</CardTitle>
          </GraphTitle>
          {/*<GraphExtra>*/}
          {/*  <GraphCardMenu questionId={questionId} token={token} dataParams={dataParams} />*/}
          {/*</GraphExtra>*/}
        </GraphHeader>
        <ComboGraphContent stickToBottom={alignContentToBottom}>
          <ComboContext.Provider
            value={{
              filter: dataParams,
              extraData: { noActiveCampaign, setNoActiveCampaign },
              kpiFilter: kpiParams
            }}
          >
            {sections.map((s, index) => (
              <SectionWrapper key={index}>{s}</SectionWrapper>
            ))}
          </ComboContext.Provider>
          {!!ctaText && !!ctaAction && (
            <CTAWrapper>
              <Button block type={'default'} onClick={ctaAction} style={ctaStyle}>
                {ctaText}
              </Button>
            </CTAWrapper>
          )}
        </ComboGraphContent>
      </Card>
    </GridItem>
  );
};

export default ComboGraph;

const GraphTitle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const TitleIcon = styled.div`
  && > i > svg {
    width: 1.5rem;
  }
  margin-right: 0.5rem;
`;

const CTAWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
`;

const SectionWrapper = styled.div`
  && + && {
    margin-top: 1rem;
  }
`;

const ComboGraphContent = styled.div<{ stickToBottom }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${params => (params.stickToBottom ? 'flex-end' : 'space-between')};
`;
