import React, { useMemo, memo } from 'react';
import { useSpring, animated } from 'react-spring';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import { showCard } from 'containers/Analytics/utils';

import { getAnalyticsCardData } from '../pages/graphs/dummyData';

const formatNumber = (number, isInteger = false) => {
  return isNaN(number)
    ? 0
    : isInteger
    ? parseFloat(number.toFixed(0)).toLocaleString()
    : parseFloat(number.toFixed(2)).toLocaleString();
};
const getPercentageChange = (prev = 0, curr = 0, isPercentage = false) => {
  if (isPercentage) return curr - prev;
  else if (prev === 0 && curr > 0) return 100;
  else if (prev === 0 && curr === 0) return 0;
  return ((curr - prev) / prev) * 100;
};

interface MetricsCardProps {
  identifier: string;
  description: string;
  extraParams?: any;
  isPercentage?: boolean;
  filterParams?: any;
  helpText?: string;
  isInteger?: boolean;
}
const MetricsCard: React.FC<MetricsCardProps> = ({
  identifier,
  description,
  extraParams,
  isPercentage = false,
  filterParams,
  helpText,
  isInteger
}) => {
  const { isDemoMode, cards } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);

  const dataParams = useMemo(
    () => ({ ...filterParams, ...extraParams }),
    [filterParams, extraParams]
  );

  const { data, dataError } = useMetabase(questionId, dataParams);

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <MetricCol xs={24} sm={12} md={8} lg={6} xl={4}>
      <Spin spinning={!data && !isDemoMode && !dataError}>
        <Graph
          data={data}
          description={description}
          isInteger={isInteger}
          isPercentage={isPercentage}
          helpText={helpText}
          isDemoMode={isDemoMode}
        />
      </Spin>
    </MetricCol>
  );
};

interface GraphProps {
  data;
  description;
  isInteger;
  isPercentage;
  helpText;
  isDemoMode;
}
const Graph: React.FC<GraphProps> = memo(
  ({ data, description, isInteger, isPercentage, helpText, isDemoMode }) => {
    let [curr, prev] = data?.data?.rows[0] || [];

    if (isDemoMode) {
      // This needs to be done before generating currProps, prevProps...
      [curr, prev] = getAnalyticsCardData(description.toLowerCase());
    }

    const currProps = useSpring({
      from: { val: 0, opacity: 0 },
      to: { val: curr || 0, opacity: 1 },
      config: { delay: 1000 }
    });
    const percentChange = getPercentageChange(prev, curr, isPercentage);

    const changeProps = useSpring({
      from: { val: 0, opacity: 0 },
      to: { val: Math.abs(percentChange), opacity: 1 },
      config: { delay: 1000 }
    });

    // For some cards, there is no prev, and we need to check for undefined, otherwise if value is 0, we display no data
    // if (!curr && !prev && !isDemoMode) {
    if (curr === undefined && !isDemoMode) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />;
    }

    return (
      <>
        <Content>
          <Top>
            <Description>{description}</Description>
            <CountContainer>
              <AnimatedCount style={currProps}>
                {
                  /*
              //@ts-ignore */
                  currProps.val.interpolate(x => formatNumber(x, isInteger))
                }
              </AnimatedCount>
            </CountContainer>
          </Top>

          {prev !== null && prev !== undefined && (
            <Bottom>
              <PrevNumber>
                {percentChange > 0 && (
                  <>
                    <span style={{ color: '#91dc5a' }}>
                      <ArrowUpOutlined />
                      <animated.span style={changeProps}>
                        {
                          /*
                  //@ts-ignore */
                          changeProps.val.interpolate(x => `${formatNumber(x)}%`)
                        }
                      </animated.span>
                      <Label> since previous period</Label>
                    </span>
                  </>
                )}
                {percentChange < 0 && (
                  <>
                    <span style={{ color: '#d0021b' }}>
                      <ArrowDownOutlined />
                      <animated.span style={changeProps}>
                        {
                          /*
                  //@ts-ignore */
                          changeProps.val.interpolate(x => `${formatNumber(x)}%`)
                        }
                      </animated.span>
                      <Label> since previous period</Label>
                    </span>
                  </>
                )}
                {percentChange === 0 && <span>0%</span>}
              </PrevNumber>
            </Bottom>
          )}
        </Content>
      </>
    );
  }
);

MetricsCard.propTypes = {
  description: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  extraParams: PropTypes.object,
  isInteger: PropTypes.bool,
  isPercentage: PropTypes.bool,
  helpText: PropTypes.string,
  filterParams: PropTypes.object
};

Graph.propTypes = {
  data: PropTypes.object,
  description: PropTypes.string.isRequired,
  isInteger: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isDemoMode: PropTypes.bool
};

export default memo(MetricsCard);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex: 1;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  margin-left: 16px;
  padding-left: 20px;
`;

const CountContainer = styled.div`
  text-align: left;
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const AnimatedCount = styled(animated.div)`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  display: inline-block;
  line-height: 100%;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #737373;
  line-height: 1;
  text-align: left;
  flex-basis: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const PrevNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  ${AnimatedCount} {
    font-size: 0.875rem;
    line-height: 1.14rem;
  }
`;

const Top = styled.div`
  margin-bottom: 0em;
  align-self: left;
  position: relative;
  text-align: left;
  > div {
    text-align: left;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-self: left;
  flex-shrink: 0;
  flex-grow: 0;
  ${PrevNumber}:first-child {
    text-align: left;
  }
  ${PrevNumber}:last-child {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Label = styled.small`
  font-size: 12px;
  color: #9b9b9b;
  line-height: 11px;
`;

const MetricCol = styled(Col)`
  flex-grow: 1;
  &:nth-child(6) ~ & {
    border-top: 1px solid #f0f0f0;
  }

  @media screen and (max-width: 1199px) {
    &:nth-child(4) ~ & {
      border-top: 1px solid #f0f0f0;
    }
  }

  @media screen and (max-width: 991px) {
    &:nth-child(3) ~ & {
      border-top: 1px solid #f0f0f0;
    }
  }

  @media screen and (max-width: 767px) {
    &:nth-child(2) ~ & {
      border-top: 1px solid #f0f0f0;
    }
  }

  @media screen and (max-width: 575px) {
    &:nth-child(1) ~ & {
      border-top: 1px solid #f0f0f0;
    }
  }
`;
