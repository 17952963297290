import React from 'react';
import { useParams } from 'react-router';

import { PageContent, PageHeader } from 'components/page';
import { useAxios } from 'hooks';

import AudienceForm from '../form';

interface Props {}

const AudienceEditPage = (props: Props) => {
  const { id: audienceId } = useParams<{ id: string }>();
  const [, response] = useAxios(`/v4/dash/v4_audiences/${audienceId}`);

  return (
    <div>
      <PageHeader title={response?.data.name} entity_id={audienceId} />
      {response?.data && (
        <PageContent>
          <AudienceForm initialValues={response?.data} isEditMode />
        </PageContent>
      )}
    </div>
  );
};

export default AudienceEditPage;
