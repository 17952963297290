import React, { useMemo, useCallback } from 'react';
import { Spin, Card, Empty } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid } from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error/Error';
import GraphCardExtra from '../../components/GraphCardExtra';
import { ageData } from './dummyData';

interface HistogramGraphProps {
  identifier: string;
  title: string;
  helpText?: string;
  filterParams?: any;
}

const HistogramGraph = ({ identifier, title, filterParams, helpText }: HistogramGraphProps) => {
  const { isDemoMode, cards } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, isDataValidating, token } =
    useMetabase(questionId, dataParams);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <Graph data={data} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const Graph = ({ data }) => {
  const color = colorGenerator();
  const { isDemoMode } = useAnalyticsContext();
  if (_isEmpty(data?.data?.rows) && !isDemoMode)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  let d = data?.data?.rows.map(row => ({ value: row[0], name: row[1] }));
  if (isDemoMode) {
    d = ageData;
  }

  const fillColor = color.next().value;

  return (
    <ResponsiveContainer>
      <BarChart data={d}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" stroke="#e4e4e4" />
        <XAxis
          dataKey="name"
          tickLine={false}
          tick={<CustomAgeTick />}
          padding={{ left: 16, right: 16 }}
          stroke="#9b9b9b"
        />
        <YAxis axisLine={false} tickLine={false} tick={<CustomCountTick />} />
        <Tooltip
          wrapperStyle={{
            background: fillColor
          }}
          content={<CustomTooltip />}
        />
        <Bar dataKey="value" fill={fillColor} barSize={37} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export interface TickProps {
  x?: any;
  y?: any;
  payload?: any;
}

export interface ToolTipProps {
  active?: any;
  label?: any;
  payload?: any;
}

const CustomAgeTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dy={16} fill="#777777" fontSize="12" textAnchor="middle">
        {payload.value}
      </text>
    </g>
  );
};

const CustomCountTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dx={-24} fill="#777777" fontSize="12" textAnchor="middle">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  if (active && payload && payload.length > 0) {
    return (
      <div style={{ color: '#ffffff', padding: '0.5rem', borderRadius: 2 }}>
        <div>{payload[0].value.toLocaleString()}</div>
      </div>
    );
  }
  return null;
};

export default HistogramGraph;
