import React, { useMemo } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import PropTypes from 'prop-types';

import { useRoles } from 'containers/Setting/Users/api';

interface RoleSelectProps {
  mode?: SelectProps['mode'];
  placeholder?: string;
  includeAll?: boolean;
  name: string;
  value;
  onChange;
}
export const RoleSelect = ({
  mode,
  placeholder = !mode ? 'Please select role' : 'Please select at least one role',
  includeAll = false,
  ...props
}: RoleSelectProps) => {
  const [loading, response, error] = useRoles();
  const value = useMemo(() => props.value?.map(_ => _.toString()) || [], [props.value]);

  if (error) {
    return null;
  }

  const filterOption = (input: string, option) => {
    const opt: string = (option?.props?.children || '').toString().toLowerCase();
    return opt.indexOf(input.toLocaleLowerCase()) >= 0;
  };

  return (
    <Select
      allowClear
      showSearch
      loading={loading}
      placeholder={placeholder}
      style={{ width: '100%' }}
      mode={mode}
      {...props}
      value={value}
      filterOption={filterOption}
      onBlur={e => e.preventDefault()}
    >
      {includeAll && (
        <Select.Option key="all" value="all">
          All
        </Select.Option>
      )}
      {response?.data.map(option =>
        // VS-3569
        option.state === 'active' ? (
          <Select.Option key={option.id} value={String(option.id)}>
            {option.name}
          </Select.Option>
        ) : (
          ''
        )
      )}
    </Select>
  );
};

RoleSelect.propTypes = {
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  includeAll: PropTypes.bool
};

export default RoleSelect;
