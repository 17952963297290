import React, { ReactElement } from 'react';
import { Route, Switch as RouteSwitch, useRouteMatch } from 'react-router-dom';

import { IS_PRODUCTION } from 'appConstants';
import SubHeader from 'components/SubHeader';
import { PageContent } from 'components/page';
import { usePermissions } from 'hooks';
import ForbiddenPage from 'pages/public/AccessDenied';
import { StepsLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';

import { Stamps, Games, Referrals, Surveys, Quizzes, RewardPromo, LandingPage } from './Campaign';

const readyForProduction = ['stamps', 'games'];

const wrapSubHeader = (campaign: ReactElement) => {
  // returns a ReactElement which is intended to be used
  // as a Route component.

  const featureTabs = [
    { key: 'stamps', label: 'Stamps' },
    { key: 'games', label: 'Games' },
    { key: 'referrals', label: 'Referrals' },
    { key: 'surveys', label: 'Surveys' },
    { key: 'quizzes', label: 'Quizzes' },
    { key: 'rewardpromo', label: 'Reward Promo' }
  ];

  const campaignNavigationTabs = [
    ...(IS_PRODUCTION ? featureTabs.filter(ft => readyForProduction.includes(ft.key)) : featureTabs)
  ];

  return ({ location }) => (
    <>
      <SubHeader
        subTabs={campaignNavigationTabs}
        defaultSubTab={location.pathname.split('/').pop()}
      />
      {campaign}
    </>
  );
};

const Campaigns = () => {
  const match = useRouteMatch();

  const { loading: permissionsLoading, canView } = usePermissions('business_intelligence');

  if (permissionsLoading) {
    return <StepsLoading />;
  }

  if (!canView) {
    return <ForbiddenPage />;
  }

  const featureRoutes = [
    {
      key: 'stamps',
      route: (
        <Route key={'stamps'} path={`${match.path}/stamps`} component={wrapSubHeader(<Stamps />)} />
      )
    },
    {
      key: 'games',
      route: (
        <Route key={'games'} path={`${match.path}/games`} component={wrapSubHeader(<Games />)} />
      )
    },
    {
      key: 'referrals',
      route: (
        <Route
          key={'referrals'}
          path={`${match.path}/referrals`}
          component={wrapSubHeader(<Referrals />)}
        />
      )
    },
    {
      key: 'surveys',
      route: (
        <Route
          key={'surveys'}
          path={`${match.path}/surveys`}
          component={wrapSubHeader(<Surveys />)}
        />
      )
    },
    {
      key: 'quizzes',
      route: (
        <Route
          key={'quizzes'}
          path={`${match.path}/quizzes`}
          component={wrapSubHeader(<Quizzes />)}
        />
      )
    },
    {
      key: 'rewardpromo',
      route: (
        <Route
          key={'rewardpromo'}
          path={`${match.path}/rewardpromo`}
          component={wrapSubHeader(<RewardPromo />)}
        />
      )
    }
  ];

  return (
    <PageContent style={{ overflow: 'visible' }}>
      <div className="container">
        <RouteSwitch>
          <Route exact path={`${match.url}`} component={LandingPage} />
          {featureRoutes.map(r => r.route)}
          <Route component={NotFoundPage} />
        </RouteSwitch>
      </div>
    </PageContent>
  );
};

export default Campaigns;
