import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, message, Space, Select, SelectProps } from 'antd';

import { useSimpleBrandList } from 'api/brands';
import { postBrand } from 'containers/Setting/General/Brands/api';

interface BrandSelectProps extends SelectProps {
  onChange: (value: number | number[]) => void;
}

export const BrandSelect = (props: BrandSelectProps) => {
  const { data: brands = [], mutate } = useSimpleBrandList();

  const [name, setName] = useState('');
  const [createLoading, setCreateLoading] = useState(false);

  const handeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCreateBrand = async () => {
    try {
      setCreateLoading(true);
      const { data: newBrand } = await postBrand({ name });

      if (newBrand) {
        let newValue;

        if (props.mode === 'multiple') {
          newValue = [...(props.value || []), newBrand.id];
        } else {
          newValue = newBrand.id;
        }

        mutate({ data: [...brands, newBrand] });
        props.onChange(newValue);
      }

      message.success('Brand created');
    } catch (err) {
      console.error('Error creating brand');
      message.error('Error creating brand');
    } finally {
      setName('');
      setCreateLoading(false);
    }
  };

  const filterOption = (input: string, option) =>
    (option?.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Select
      filterOption={filterOption}
      showArrow
      showSearch
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input placeholder="Enter a name" value={name} onChange={handeNameChange} />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={handleCreateBrand}
              disabled={!name || createLoading}
              loading={createLoading}
            >
              Add brand
            </Button>
          </Space>
        </>
      )}
      {...props}
      value={props.value || undefined}
      onBlur={e => e.preventDefault()}
    >
      {brands?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BrandSelect;
