import { takeLatest } from 'redux-saga/effects';

import { commonSaga } from 'middleware/api';

import { GET_CATEGORIES, DELETE_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY } from './constants';

function* categorySaga() {
  yield takeLatest(CREATE_CATEGORY, commonSaga);
  yield takeLatest(UPDATE_CATEGORY, commonSaga);
  yield takeLatest(GET_CATEGORIES, commonSaga);
  yield takeLatest(DELETE_CATEGORY, commonSaga);
}

export default categorySaga;
