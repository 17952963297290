import React, { memo } from 'react';
import { Empty } from 'antd';
import _isEmpty from 'lodash/isEmpty';

import { useAnalyticsContext } from 'containers/Analytics/hooks';

import { getMapData } from '../dummyData';

/**
 * Alternate implementation which fetches a static image of the map using google static map service
 */
const StaticGmap = ({ data }) => {
  const { isDemoMode } = useAnalyticsContext();
  if (_isEmpty(data) && !isDemoMode) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  /** @type {number[][]} */
  let graphData = data;
  if (isDemoMode) {
    graphData = getMapData();
  }
  //some of the points are all over the place
  graphData = graphData.filter(p => p[0] <= 90 && p[0] >= -90);
  let url =
    'https://maps.googleapis.com/maps/api/staticmap?size=910x300&key=AIzaSyAdjFZHY0rT2C5JbkDm02VQV3JvsNurNyY&style=feature:poi|visibility:off&markers=size:tiny|';
  while (graphData.length > 0) {
    const rIndex = Math.floor(Math.random() * graphData.length);
    const item = graphData.splice(rIndex, 1)[0];
    const rItem = `${item[0]},${item[1]}|`;
    if (rItem.length + url.length > 8192) {
      break;
    }
    url += rItem;
  }

  /** @type React.CSSProperties */
  const style = {
    overflow: 'hidden',
    objectFit: 'cover'
  } as React.CSSProperties;

  return <img src={url} style={style} alt="map" />;
};

export default memo(StaticGmap);
