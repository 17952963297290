import React, { memo, useMemo, useCallback } from 'react';
import { Card, Spin, Tooltip, Badge, Empty } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GridItem } from 'components/Display/GridRenderer';
import { useMetabase, useCard, useAnalyticsContext } from 'containers/Analytics/hooks';
import { vsGraphData } from 'containers/Analytics/pages/graphs/dummyData';
import { GraphContent } from 'containers/Analytics/styles';
import { showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardExtra from '../../components/GraphCardExtra';

const CampaignVSGraph = ({ identifier, title, helpText, filterParams }) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, isDataValidating, token } =
    useMetabase(questionId, dataParams);
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    if (data || isDemoMode) return <Graph {...data} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        style={{ height: '300px' }}
        bodyStyle={{ padding: '4px 45px 20px 25px' }}
        headStyle={{ borderBottom: '0px', padding: '0px 40px 0px 25px' }}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent>{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

CampaignVSGraph.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  filterParams: PropTypes.object
};

export default memo(CampaignVSGraph);

const Graph = memo((data: any) => {
  const { isDemoMode } = useAnalyticsContext();
  let d = (data?.data?.rows || []).map(row => ({
    name: 'users',
    new: row[1],
    returning: row[2],
    total: row[0]
  }));

  if (isDemoMode) {
    d = vsGraphData;
  } else {
    if (_isEmpty(data?.data?.rows)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const Bar = styled.div`
    display: inline-block;
    text-align: center;
    padding: 30px 0;
    font-size: 12px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  `;

  const CustomBadge = styled(Badge)`
    .ant-badge-status-dot {
      height: 14px;
      width: 14px;
    }
    .ant-badge-status-text {
      font-size: 15px;
      font-weight: 500;
      margin-left: 5px;
      margin-right: 5px;
    }
  `;
  const Description = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #737373;
    line-height: 22px;
    text-align: left;
    flex-basis: 100%;
    margin-bottom: 16px;
  `;
  const Label = styled.div`
    font-size: 25px;
    font-weight: normal;
    line-height: 29px;
    text-align: center;
  `;
  const Detail = styled.div`
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 11px;
    text-align: left;
  `;

  const calculatePercentage = (number, total) => {
    if (total < 1) {
      return '0';
    }
    return ((number / total) * 100).toFixed() + '%';
  };

  return (
    <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
      <Description>Total users:</Description>
      <div style={{ maxWidth: '98%', height: '100px' }}>
        {d[0].new > 0 && (
          <Tooltip title={`New Users: ${d[0].new}`}>
            <Bar
              style={{
                height: '100px',
                width: calculatePercentage(d[0].new, d[0].total),
                background: '#10D994'
              }}
            >
              <Label>{calculatePercentage(d[0].new, d[0].total)}</Label>
            </Bar>
          </Tooltip>
        )}
        {d[0].returning > 0 && (
          <Tooltip title={`Returning Users: ${d[0].returning}`}>
            <Bar
              style={{
                height: '100px',
                width: calculatePercentage(d[0].returning, d[0].total),
                background: '#FFA641'
              }}
            >
              <Label>{calculatePercentage(d[0].returning, d[0].total)}</Label>
            </Bar>
          </Tooltip>
        )}
      </div>
      <div style={{ textAlign: 'left', paddingTop: '50px' }}>
        <span style={{ verticalAlign: 'baseline', marginRight: '10px' }}>
          <CustomBadge color="#10D994" text={`${d[0].new}`} />
          <Detail>New Users</Detail>
        </span>
        <span style={{ verticalAlign: 'baseline', marginRight: '10px' }}>
          <CustomBadge color="#FFA641" text={`${d[0].returning}`} />
          <Detail>Returning User</Detail>
        </span>
      </div>
    </div>
  );
});
