import React, { memo } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { FieldArray, useField, useFormikContext } from 'formik';
import { formLayout } from 'styles';

import Input from '../StyledInput';
import FormItem from './FormItem';

interface Props {
  name?: string;
}

const CustomInputField = ({ name = 'custom_fields' }: Props) => {
  const formik = useFormikContext();
  const [field] = useField(name);

  const onAddField = arrayHelpers => () =>
    arrayHelpers.push({ type: 'input', name: undefined, value: undefined });
  const onDeleteField = (arrayHelpers, index) => () => arrayHelpers.remove(index);

  return (
    <Card title="Custom Fields" style={{ marginBottom: '1rem' }}>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {field.value.map((customField, index) => (
              <FormItem
                key={index}
                label={`Custom Field ${index + 1}`}
                name={`${field.name}.${index}`}
                {...formLayout}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Input
                      name={`${field.name}.${index}.name`}
                      value={field.value[index].name}
                      placeholder="Type in field name"
                      onChange={e =>
                        formik.setFieldValue(`${field.name}.${index}.name`, e.target.value, false)
                      }
                      onBlur={() =>
                        formik.setFieldTouched(`${field.name}.${index}.name`, true, false)
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      name={`${field.name}.${index}.value`}
                      value={field.value[index].value}
                      placeholder="Type in field value"
                      onChange={e =>
                        formik.setFieldValue(`${field.name}.${index}.value`, e.target.value, false)
                      }
                      onBlur={() =>
                        formik.setFieldTouched(`${field.name}.${index}.value`, true, false)
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <DeleteOutlined onClick={onDeleteField(arrayHelpers, index)} />
                  </Col>
                </Row>
              </FormItem>
            ))}
            <Row>
              <Button
                type="dashed"
                style={{ width: '100%' }}
                icon={<PlusOutlined />}
                onClick={onAddField(arrayHelpers)}
              >
                Add New
              </Button>
            </Row>
          </>
        )}
      />
    </Card>
  );
};

export default memo(CustomInputField);
