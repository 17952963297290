import React, { useMemo } from 'react';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { GridItem } from 'components/Display/GridRenderer';
import NumberDisplay from 'containers/Analytics/components/NumberDisplay/NumberDisplay';
import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import { CardTitle } from 'containers/Analytics/styles';
import { getRandomNumber, showCard } from 'containers/Analytics/utils';

interface UserValuesProps {
  identifier: string;
  title: string;
  extraParams?: any;
  isPercentage?: boolean;
  filterParams?: any;
  helpText?: string;
  isInteger?: boolean;
}
const UserValuesCard: React.FC<UserValuesProps> = ({
  identifier,
  title,
  extraParams,
  filterParams
}) => {
  const { isDemoMode, cards } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);

  const dataParams = useMemo(
    () => ({ ...filterParams, ...extraParams }),
    [filterParams, extraParams]
  );

  const { data, dataError, tokenError } = useMetabase(questionId, dataParams);
  const hasError = dataError || tokenError;

  const numbers = isDemoMode
    ? [getRandomNumber(10000, 9000), getRandomNumber(8000, 8500), getRandomNumber(1000000, 950000)]
    : data?.data?.rows?.[0];
  let newUsers, previousUsers, totalUsers;
  if (!_isEmpty(numbers)) {
    [newUsers, previousUsers, totalUsers] = numbers;
  }

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card style={{ minHeight: 160 }}>
        <Spin spinning={!data && !isDemoMode && !hasError}>
          <CardTitle style={{ marginBottom: '1rem' }}>{title}</CardTitle>
          <NumberDisplay
            value={totalUsers}
            description={'Total Registered Users'}
            icon={<TeamOutlined />}
          />
          <NumberDisplay
            value={newUsers}
            previousValue={previousUsers}
            description={'New users this period'}
            icon={<PlusOutlined />}
          />
        </Spin>
      </Card>
    </GridItem>
  );
};

UserValuesCard.propTypes = {
  title: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  extraParams: PropTypes.object,
  isInteger: PropTypes.bool,
  isPercentage: PropTypes.bool,
  helpText: PropTypes.string,
  filterParams: PropTypes.object
};

export default UserValuesCard;
