import React, { useCallback, useMemo } from 'react';
import { Card, Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardExtra from '../../components/GraphCardExtra';
import { ToolTipProps } from './HistogramGraph';
import { getTop5Data } from './dummyData';

interface TopNGraphProps {
  identifier: string;
  title: string;
  helpText?: string;
  n?: number;
  filterParams?: any;
}

const TopNGraph = ({ identifier, title, helpText, n = 5, filterParams }: TopNGraphProps) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, dataRevalidate, isDataValidating, token } = useMetabase(
    questionId,
    dataParams
  );
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    dataRevalidate();
  }, [dataRevalidate]);

  const hasError = tokenError || dataError;

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <Graph data={data} n={n} title={title} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const Graph = ({ data, n, title }) => {
  const color = colorGenerator();
  const { isDemoMode } = useAnalyticsContext();

  if (_isEmpty(data?.data?.rows) && !isDemoMode) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let d = data?.data?.rows.slice(0, n).map(row => ({
    issue_count: row[0],
    reward_name: row[1],
    merchant: row[2],
    id: row[3]
  }));

  if (isDemoMode) {
    const t = title.toLowerCase();
    const resource = t.includes('rewards')
      ? 'rewards'
      : t.includes('merchants')
      ? 'merchants'
      : t.includes('catalogs')
      ? 'catalogs'
      : 'others';
    d = getTop5Data(resource);
  }

  const formatXAxis = value => {
    return value.toLocaleString();
  };

  return (
    <ResponsiveContainer>
      <BarChart data={d} layout="vertical" margin={{ bottom: 20, right: 50 }}>
        <CartesianGrid horizontal={false} strokeDasharray="3 3" stroke="#e7e7e7" />
        <XAxis
          type="number"
          dataKey="issue_count"
          tickLine={false}
          stroke="#e4e4e4"
          tick={{ fontSize: 12 }}
          tickFormatter={formatXAxis}
        />
        <YAxis type="category" dataKey="reward_name" hide />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Bar dataKey="issue_count" fill={color.next().value} maxBarSize={10}>
          <LabelList dataKey="reward_name" content={renderBarGraphLabel} />
          <LabelList dataKey="issue_count" content={renderCountLabel} position="right" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

TopNGraph.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  filterParams: PropTypes.object
};

export default TopNGraph;

const renderBarGraphLabel = props => {
  const { x, y, height, value } = props;
  return (
    <g>
      <text
        x={x}
        y={y - height}
        fill="#4a4a4a"
        fontSize={10}
        textAnchor="start"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

const labelSize = 10;
const renderCountLabel = props => {
  const { x, y, value, height, width } = props;
  return (
    <g>
      <text
        x={x + width + labelSize / 2}
        y={y + height / 2}
        fill="#4a4a4a"
        fontSize={labelSize}
        textAnchor="start"
        dominantBaseline="middle"
      >
        {value.toLocaleString()}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  if (active && payload && payload.length > 0) {
    return (
      <div style={{ background: '#202c46', padding: '0.5rem', color: '#ffffff', borderRadius: 2 }}>
        <div>{payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#e4e4e4', fontSize: 11 }}>{label}</div>
        {payload[0].payload.merchant && (
          <div style={{ color: '#e6e6e6', fontSize: 11 }}>{payload[0].payload.merchant}</div>
        )}
      </div>
    );
  }
  return null;
};
