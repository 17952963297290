import React from 'react';
import { useHistory, useParams } from 'react-router';
import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';

import { StatusTag, TableActions, WrapperPanel } from 'components';
import AudienceFilters from 'components/AudienceInfo/AudienceInfoFilters';
import AuditLog from 'components/AuditLog';
import RenderInGrid from 'components/Layout/RenderInGrid';
import { PageContent, PageHeader } from 'components/page';
import { useAxios, usePermissions } from 'hooks';
import API from 'utils/request';

interface Props {}

const AudienceShowPage = (props: Props) => {
  const { id: segmentId } = useParams<{ id: string }>();
  const history = useHistory();
  const { canEdit } = usePermissions('audiences');
  const [, response] = useAxios(`/v4/dash/v4_audiences/${segmentId}`);
  const data = response?.data;

  const goToEditPage = () => {
    history.push(`/p/audiences/edit/${segmentId}`);
  };

  const handleExport = () => {
    API.post(`/v4/dash/v4_audiences/${segmentId}/export`)
      .then(() => {
        message.info('The exported file will be sent to your email.');
      })
      .catch(() => {
        message.error('Error exporting audience.');
      });
  };

  return (
    <>
      <PageHeader
        title={data?.name}
        entity_id={data?.id}
        customAction={() => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableActions
              actions={[
                {
                  title: 'Export',
                  onClick: handleExport
                }
              ]}
            />
            {canEdit && (
              <Button
                icon={<EditOutlined />}
                type="primary"
                size="large"
                onClick={goToEditPage}
                style={{ marginLeft: 16 }}
              >
                Edit
              </Button>
            )}
          </div>
        )}
      />
      <PageContent>
        <WrapperPanel title="Essentials">
          <RenderInGrid description="Status" content={<StatusTag state={data?.state} />} />
          <RenderInGrid description="Audience name" content={data?.name} />
        </WrapperPanel>
        <WrapperPanel title="File Upload & Filters">
          <RenderInGrid
            description="Files uploaded"
            content={
              <div>
                <div>
                  Include:{' '}
                  {data?.user_groups
                    .filter(group => group.list_type === 'allow_list')
                    .map(group => group.name)
                    .join(', ') || '–'}
                </div>
                <div>
                  Exclude:{' '}
                  {data?.user_groups
                    .filter(group => group.list_type === 'deny_list')
                    .map(group => group.name)
                    .join(', ') || '–'}
                </div>
              </div>
            }
          />
          <RenderInGrid description="Filters" content={<AudienceFilters data={data} />} />
        </WrapperPanel>
        {/* BE Not implemented yet */}
        {false && <AuditLog entity="v4_audiences" id={segmentId} isChanges2 />}
      </PageContent>
    </>
  );
};

export default AudienceShowPage;
