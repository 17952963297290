import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  SEARCH_TAGS,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_FAIL
} from './constants';

const initialState = {
  loading: false,
  tags: []
};

function tagReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_TAGS:
    case GET_TAGS: {
      return {
        ...state,
        loading: true
      };
    }
    case SEARCH_TAGS_SUCCESS:
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tags: action.payload.data
      };
    }
    case SEARCH_TAGS_FAIL:
    case GET_TAGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export default tagReducer;
