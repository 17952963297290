import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useAnalyticsContext from '../useAnalyticsContext';

const DemoAlert = () => {
  const { isDemoMode } = useAnalyticsContext();
  return isDemoMode ? (
    <AlertBar>
      {' '}
      <InfoCircleOutlined style={{ marginRight: '0.6em' }} />
      Demo mode activated
    </AlertBar>
  ) : null;
};

const AlertBar = styled.div`
  font-size: 0.7rem;
  color: white;
  background-color: #2665ee;
  padding: 0.3rem;
  padding-left: 2.2rem;
`;

export default DemoAlert;
