import React, { useMemo, useCallback } from 'react';
import { Card, Spin } from 'antd';

import { GridItem } from 'components/Display/GridRenderer';
import Error from 'containers/Analytics/components/Error';
import GraphCardExtra from 'containers/Analytics/components/GraphCardExtra';
import { useMetabase, useAnalyticsContext } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';

import ReactSimpleMap from './ReactSimpleMap';

// TODO: this is boilerplate code being reused from other graphs. The only difference is the last line of the renderContext method.
const MapChart = ({ questionId, title, helpText, filterParams }) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, isDataValidating, token } =
    useMetabase(questionId, dataParams);
  const { dateRange } = useAnalyticsContext();
  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <ReactSimpleMap data={data?.data?.rows} />;
  };

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

export default MapChart;
