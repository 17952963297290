import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { SelectProps } from 'antd/lib/select';

import { Select } from 'components/Layout/Form';

import { PerxState } from 'MyTypes';

export interface AudienceListSelectProps extends SelectProps {
  name?: string;
  showValuesOutside?: boolean;
  mode?;
}

export const AudienceListSelect = (props: AudienceListSelectProps) => {
  const { mode, placeholder = 'Please select audience list' } = props;

  const loading = useSelector((state: PerxState) => state.global.loading);
  const { data: audienceLists } = useSelector(
    (state: PerxState) => state.global.audienceLists,
    shallowEqual
  );

  return (
    <Select
      allowClear
      showSearch
      loading={loading}
      placeholder={placeholder}
      style={{ width: '100%' }}
      mode={mode}
      {...props}
      onBlur={e => e.preventDefault()}
    >
      {audienceLists.map(option => {
        return (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default AudienceListSelect;
