import axios, { AxiosResponse } from 'axios';

/**
 * Represents a api.
 * @constructor
 */
interface ApiQuery {
  url?: string;
  headers?: any;
  params?: any;
  body?: any;
  method?: any;
}
export default function api<T = any>({
  url = '',
  headers = '',
  params = '',
  body = '',
  method = ''
}: ApiQuery = {}): Promise<AxiosResponse<T>> {
  const methods = ['put', 'post', 'patch', 'delete'];

  if (body) {
    if (methods.indexOf(method) !== -1) {
      switch (methods[methods.indexOf(method)]) {
        case 'put':
          return axios
            .put<T>(url, body, {
              headers
            })
            .then(response => {
              return response;
            })
            .catch(error => {
              throw error.response;
            });
        case 'post':
          return axios
            .post<T>(url, body, {
              headers
            })
            .then(response => {
              return response;
            })
            .catch(error => {
              throw error.response;
            });
        case 'delete':
          return axios({
            method: 'delete',
            url: url,
            data: body,
            headers: headers
          })
            .then(response => {
              return response;
            })
            .catch(error => {
              throw error.response;
            });
        case 'patch':
          return axios
            .patch<T>(url, body, {
              headers
            })
            .then(response => {
              return response;
            })
            .catch(error => {
              throw error.response;
            });
        default:
          break;
      }
    }
  } else {
    return axios
      .get(url, {
        params: params,
        headers
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error.response;
      });
  }
}
