import _random from 'lodash/random';
import moment, { Moment } from 'moment-timezone';

import { DatePart } from 'containers/Analytics/components/DatePartSelect';

export function abbreviateNumber(value: number): string {
  let newValue = '' + value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor(('' + value).length / 3);
    let shortValue: string = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue =
        '' +
        parseFloat(
          (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
        );
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (parseInt(shortValue, 10) % 1 !== 0) shortValue = parseInt(shortValue, 10).toFixed(1);
    newValue = `${shortValue}${suffixes[suffixNum]}`;
  }
  return newValue;
}

export function getDatePart(dayDiff: number, currentDatePart: DatePart): DatePart {
  if (dayDiff === 0) {
    return DatePart.Daily;
  }
  if (currentDatePart === DatePart.Quarterly && dayDiff < 120 && dayDiff >= 30) {
    return DatePart.Monthly;
  } else if (
    (currentDatePart === DatePart.Monthly || currentDatePart === DatePart.Quarterly) &&
    dayDiff < 30 &&
    dayDiff >= 7
  ) {
    return DatePart.Weekly;
  } else if (
    (currentDatePart === DatePart.Weekly ||
      currentDatePart === DatePart.Monthly ||
      currentDatePart === DatePart.Quarterly) &&
    dayDiff < 7 &&
    dayDiff >= 1
  ) {
    return DatePart.Daily;
  }

  return currentDatePart;
}

const GRAPH_COLORS = [
  '#2665EE',
  '#2E41CE',
  '#009AFF',
  '#8DCEFF',
  '#DA7835',
  '#DF923D',
  '#DA7835',
  '#802251',
  '#A42D59',
  '#DB3E67',
  '#E996B1',
  '#296371',
  '#39859D',
  '#56BEE4',
  '#98DEF6',
  '#0011C4',
  '#4918D1',
  '#9125E0',
  '#C78FEF'
] as const;

type GraphColorType = (typeof GRAPH_COLORS)[number];

export function* colorGenerator(): Generator<GraphColorType> {
  let i = 0;
  while (true) {
    yield GRAPH_COLORS[i];
    i = (i + 1) % GRAPH_COLORS.length;
  }
}

export function showCard(
  cards: { identifier: string; card_id: number; id: number }[],
  identifier: string
): boolean {
  return !!cards.find(element => element?.identifier === identifier);
}

export function getRandomNumber(min: number, max: number) {
  if (min > max) {
    return _random(max, min);
  } else {
    return _random(min, max);
  }
}

export const getDemoValues = (
  perUnitRange: [number, number],
  previousPercentageRange?: [number, number],
  dateRange?: [Moment, Moment],
  periodUnit?: DatePart,
  maxValue?: number
) => {
  const range = dateRange && periodUnit ? dateRange[1].diff(dateRange[0], periodUnit) : 1;

  const baseValue = getRandomNumber(perUnitRange[0], perUnitRange[1]) * range;
  const mainValue = !!maxValue ? Math.min(baseValue, maxValue) : baseValue;

  if (previousPercentageRange) {
    const prevValue = Math.round(
      mainValue *
        ((100 - getRandomNumber(previousPercentageRange[0], previousPercentageRange[1])) / 100)
    );

    return [mainValue, prevValue];
  } else {
    return mainValue;
  }
};

export const getLineDemoData = (
  dateRange: [Moment, Moment],
  periodUnit: DatePart,
  perDayRange: [number, number],
  lineNames: string[]
) => {
  const getValue = (reductionStep: number) => {
    let multiplier;

    switch (periodUnit) {
      case DatePart.Hourly:
        multiplier = 1 / 24;
        break;
      case DatePart.Weekly:
        multiplier = 7;
        break;
      case DatePart.Monthly:
        multiplier = 30;
        break;
      default:
        multiplier = 1;
    }

    const reduction = Math.max((100 - 30 * reductionStep) / 100, 0.1);

    return getRandomNumber(perDayRange[0] * reduction, perDayRange[1] * reduction) * multiplier;
  };

  const dateFormat = getAxisDateFormat(periodUnit, dateRange);
  const XTicks = [];

  const currentDate = moment(dateRange[0]).startOf(periodUnit);

  do {
    XTicks.push(currentDate.format(dateFormat));
    currentDate.add(1, periodUnit);
  } while (currentDate.isBefore(dateRange[1]));

  return XTicks.map(tick => {
    const entry = { name: tick };

    lineNames.forEach((line, index) => {
      entry[line] = getValue(index);
    });
    return entry;
  });
};

export const getAxisDateFormat = (period: DatePart, dateRange: [Moment, Moment]) => {
  const numberOfYears = dateRange[1].diff(dateRange[0], 'years', true);
  let dateFormat;

  switch (period) {
    case DatePart.Hourly:
      dateFormat = 'H:mma DD MMM';
      break;
    case DatePart.Daily:
      dateFormat = numberOfYears > 1 ? 'DD MMM YYYY' : 'DD MMM';
      break;
    case DatePart.Weekly:
      dateFormat = numberOfYears > 1 ? 'DD MMM YYYY' : 'DD MMM';
      break;
    case DatePart.Monthly:
      dateFormat = numberOfYears > 1 ? 'MMM YYYY' : 'MMM';
      break;
    case DatePart.Quarterly:
      break;
    default:
      dateFormat = 'DD MMM';
      break;
  }

  return dateFormat;
};

export const DATERANGE: [Moment, Moment] = [
  moment().subtract(15, 'days'),
  moment().subtract(1, 'days')
];
