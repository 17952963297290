import { useEffect, useMemo, useRef } from 'react';

import { useFeatureFlags, useTenant, useUserTenants } from 'hooks';

import useAuth from './useAuthUser';

const envData = {
  production: 'prod',
  development: 'dev',
  staging: 'stag'
};

const env =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname?.endsWith('.io')
      ? 'staging'
      : 'production'
    : process.env.NODE_ENV;
const shortEnvName = envData[env];

export const usePendo = () => {
  const initialized = useRef(false);
  const authUser = useAuth();
  const { pendo_configuration: pendoEnabled } = useFeatureFlags();
  const tenantData = useTenant();
  const { data: userTenants } = useUserTenants();
  const visitorTenants =
    userTenants?.length > 0 && userTenants.map(tenant => tenant.oauth_application_name).join(',');

  const pendoData = useMemo(() => {
    if (authUser?.id && tenantData?.id) {
      const { localization } = tenantData;

      return {
        visitor: {
          id: `${shortEnvName}-${authUser.id}`,
          email: authUser?.email,
          role: authUser?.roles?.map(role => role.name).join(','),
          access_group: authUser?.access_groups?.map(accessGroup => accessGroup.name).join(','),
          status: authUser?.status,
          other_tenants: visitorTenants
        },
        account: {
          id: `${shortEnvName}-${tenantData?.id}`,
          name: tenantData.displayName,
          timezone: localization.timezone,
          currency: localization.currency,
          country: localization.country,
          available_locales: localization.availableLocales.locales.join(','),
          blackcomb_url: tenantData.blackcomb_url,
          environment: env
        }
      };
    } else {
      return null;
    }
  }, [authUser, tenantData, visitorTenants]);

  useEffect(() => {
    try {
      if (
        pendoEnabled &&
        pendoData &&
        !initialized.current &&
        process.env.NODE_ENV !== 'development'
      ) {
        console.debug('Initializing Pendo');

        (window as any)?.pendo?.initialize(pendoData);
        (window as any)?.pendo?.identify(pendoData);

        initialized.current = true;
      }
    } catch (error) {
      console.error(error);
    }
  }, [pendoData, pendoEnabled]);

  return pendoData;
};
