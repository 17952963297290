import styled from 'styled-components';

const ModalFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 0 0;
  padding-bottom: 0;
  border-top: 1px solid rgb(227, 227, 227);
  button {
    &:not(last-child) {
      margin-right: 0.5rem;
    }
  }
`;

export default ModalFormFooter;
