import useSWR from 'swr';

import { I_Badge } from 'containers/Badge/interface';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useBadge = (id: number) => {
  const { data: { data } = {} } = useSWR<IDetailResponse<I_Badge>>(
    id ? `/v4/dash/badges/${id}` : null,
    fetcher
  );

  return { data };
};

export const useBadgeList = (params: Record<string, any>) => {
  const { data: { data, meta } = {}, isLoading } = useSWR<IPaginatedResponse<I_Badge>>(
    `/v4/dash/badges?${buildQuery(params)}`,
    fetcher
  );

  return { data, meta, isLoading };
};
