import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import { useAxios } from 'hooks';

import ComboGraph from '../ComboGraph/ComboGraph';
import AreaGraphSection from '../ComboGraph/components/AreaGraphSection';
import ValuesSection from '../ComboGraph/components/ValuesSection';

const LoyaltyComboGraph = () => {
  const [chosenLoyaltyProgramId, setChosenLoyaltyProgramId] = useState<number | null>(null);
  const [loading, response] = useAxios('/v4/dash/loyalty_programs');

  useEffect(() => {
    // response.data could be an empty array.
    // the default loyalty option should be the recently created one, which
    // max ordering value.
    if (response && response.data.length > 0) {
      const maxOrdering = Math.max(...response.data.map(loyalty => loyalty.ordering));
      const defaultLoyaltyProgram = response.data.find(loyalty => loyalty.ordering === maxOrdering);
      if (defaultLoyaltyProgram) {
        setChosenLoyaltyProgramId(defaultLoyaltyProgram.id);
      }
    }
  }, [response]);

  const filterParams = {
    loyalty_program_id: chosenLoyaltyProgramId
  };

  return (
    <>
      <ComboGraph
        sections={[
          <ValuesSection
            identifier={'overview_flat_loyalty_point_earn_burn_kpi'}
            showChange
            demoValues={[
              {
                name: 'Points earned',
                value: [6000, 11000],
                previous: [5, 15]
              },
              {
                name: 'Points burned',
                value: [1000, 5000],
                previous: [-5, 10]
              }
            ]}
          />,
          <AreaGraphSection
            identifier={'overview_flat_loyalty_point_earn_burn_breakdown'}
            withFill={false}
            demoLines={['earned', 'burned']}
          />
        ]}
        title={
          <>
            <LoyaltyTitleDropdown
              value={chosenLoyaltyProgramId}
              onChange={setChosenLoyaltyProgramId}
              loading={loading}
              dropdownMatchSelectWidth={false}
            >
              {response &&
                response.data.length > 0 &&
                response.data.map(loyaltyProgram => (
                  <Select.Option value={loyaltyProgram.id} key={loyaltyProgram.id}>
                    {loyaltyProgram.name}
                  </Select.Option>
                ))}
            </LoyaltyTitleDropdown>{' '}
            loyalty points
          </>
        }
        withDatePart
        filterParams={filterParams}
      />
    </>
  );
};

const LoyaltyTitleDropdown = styled(Select)`
  .ant-select-selection {
    border: none;
    border-radius: 0px;
    background: transparent;
    font-size: 18px;
    max-width: 200px;

    .ant-select-selection__rendered {
      margin-right: 30px;
      margin-left: 0;
      border-bottom: 1px dashed;
    }
  }
`;

export default LoyaltyComboGraph;
