import React, { useCallback, useMemo } from 'react';
import { Spin } from 'antd';
import _groupBy from 'lodash/groupBy';
import moment from 'moment';

import { GridItem, GridRenderer } from 'components/Display/GridRenderer';
import { DatePart } from 'containers/Analytics/components/DatePartSelect';
import Error from 'containers/Analytics/components/Error';
import NumberDisplay from 'containers/Analytics/components/NumberDisplay/NumberDisplay';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import ComboGraph from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import AreaGraph from 'containers/Analytics/pages/graphs/GraphOnly/AreaGraph';
import { getDemoValues, getLineDemoData, getRandomNumber } from 'containers/Analytics/utils';

interface ActiveUsersProps {
  filterParams?: object;
  occupies?: number;
}

const ActiveUsers: React.FC<ActiveUsersProps> = ({ filterParams, occupies = 2 }) => {
  const valuesIdentifier = 'overview_flat_active_user';
  const KPIIdentifier = 'overview_flat_average_active_user_kpi';
  const lineIdentifier = 'overview_flat_overall_breakdown_by_date_user';

  const { isDemoMode, dateRange, datePart } = useAnalyticsContext();
  const dataParams = useMemo(
    () => ({
      ...filterParams,
      datepart: datePart
    }),
    [filterParams, datePart]
  );

  //Values Data START
  const { card_id: valuesId } = useCard(valuesIdentifier);

  const {
    data: valuesResponse,
    dataError: valuesDataError,
    tokenError: valuesTokenError,
    tokenRevalidate: valuesTokenRevalidate,
    dataRevalidate: valuesDataRevalidate
  } = useMetabase(valuesId, filterParams);

  const hasValuesError = valuesDataError || valuesTokenError;
  const valuesRetry = useCallback(() => {
    valuesTokenRevalidate();
    valuesDataRevalidate();
  }, [valuesTokenRevalidate, valuesDataRevalidate]);

  const valuesData = isDemoMode
    ? getDemoValues([10000, 25000], [5, 25], dateRange, datePart, getRandomNumber(350000, 450000))
    : valuesResponse?.data?.rows?.[0];
  //Values Data END

  //KPI Data START
  const { card_id: KPIId } = useCard(KPIIdentifier);

  const {
    data: KPIResponse,
    dataError: KPIDataError,
    tokenError: KPITokenError,
    tokenRevalidate: KPITokenRevalidate,
    dataRevalidate: KPIDataRevalidate
  } = useMetabase(KPIId, dataParams);

  const hasKPIError = KPIDataError || KPITokenError;
  const KPIRetry = useCallback(() => {
    KPITokenRevalidate();
    KPIDataRevalidate();
  }, [KPITokenRevalidate, KPIDataRevalidate]);

  const KPIData = isDemoMode
    ? getDemoValues([10000, 25000], [-5, 50])
    : KPIResponse?.data?.rows?.[0];
  //KPI Data END

  //Line Data START
  const { card_id: lineId } = useCard(lineIdentifier);

  const {
    data: lineData,
    dataError: lineDataError,
    tokenError: lineTokenError,
    tokenRevalidate: lineTokenRevalidate,
    dataRevalidate: lineDataRevalidate
  } = useMetabase(lineId, dataParams);

  const hasLineError = lineDataError || lineTokenError;
  const lineRetry = useCallback(() => {
    lineTokenRevalidate();
    lineDataRevalidate();
  }, [lineTokenRevalidate, lineDataRevalidate]);

  // isDemoMode is used inside useMemo since there is an intersection between
  // the dep arrays of lineDemoData and processedLiveData.

  // generate demo data for AreaGraph.
  const lineDemoData = useMemo(
    () => getLineDemoData(dateRange, datePart, [15000, 35000], ['active_users']),
    [datePart, dateRange]
  );

  // generate live data for AreaGraph
  const processedLiveData = useMemo(
    () => processData(lineData?.data, datePart),
    [lineData?.data, datePart]
  );

  const lineGraphData = isDemoMode ? lineDemoData : processedLiveData;
  //Line Data END

  return (
    <ComboGraph
      occupies={occupies}
      title={'Active Users'}
      sections={[
        <GridRenderer perRow={2} gutter={16}>
          <GridItem>
            <Spin spinning={!hasValuesError && !valuesData}>
              {hasValuesError ? (
                <Error retry={valuesRetry} />
              ) : (
                <NumberDisplay
                  value={valuesData?.[0]}
                  previousValue={valuesData?.[1]}
                  description={'Total Active Users'}
                />
              )}
            </Spin>
          </GridItem>
          <GridItem>
            <Spin spinning={!hasKPIError && !KPIData}>
              {hasKPIError ? (
                <Error retry={KPIRetry} />
              ) : (
                <NumberDisplay
                  value={KPIData?.[0]}
                  previousValue={KPIData?.[1]}
                  description={'Average Active Users'}
                />
              )}
            </Spin>
          </GridItem>
        </GridRenderer>,
        ...(hasLineError
          ? [<Error retry={lineRetry} />]
          : [<AreaGraph graphData={lineGraphData} />])
      ]}
    />
  );
};

export default ActiveUsers;

export const processData = (data, period): { name: string; active_users: number }[] => {
  //row data format: [value ,date]
  const rowData = data?.rows;
  const byYear = _groupBy(rowData, row => moment(row[1]).format('YYYY'));
  const numberOfYears = Object.keys(byYear).length;
  let dateFormat;

  switch (period) {
    case DatePart.Hourly:
      dateFormat = 'H:mma DD MMM';
      break;
    case DatePart.Daily:
      dateFormat = numberOfYears > 1 ? 'DD MMM YYYY' : 'DD MMM';
      break;
    case DatePart.Weekly:
      dateFormat = numberOfYears > 1 ? 'DD MMM YYYY' : 'DD MMM';
      break;
    case DatePart.Monthly:
      dateFormat = numberOfYears > 1 ? 'MMM YYYY' : 'MMM';
      break;
    case DatePart.Quarterly:
      break;
    default:
      dateFormat = 'DD MMM';
      break;
  }

  return rowData?.map(r => {
    return {
      name: moment(r[1]).format(dateFormat),
      active_users: r[0]
    };
  });
};
