import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useSWRConfig } from 'swr';

import API from 'utils/request';

export const buildQuery = (params: Record<string, any>) => {
  const usp = new URLSearchParams(omitBy(params, isNil));

  usp.sort();

  return usp.toString();
};

export const fetcher = url => API(url).then(res => res.data);

export const useSWRUtils = () => {
  const { mutate } = useSWRConfig();

  /**
   * Officially proposed way to clear cache:
   * https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
   */
  const clearCacheData = useCallback(() => {
    mutate(
      key => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false } // do not revalidate
    );
  }, [mutate]);

  return { clearCacheData };
};
