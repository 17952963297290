import React from 'react';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { FieldMetaProps } from 'formik';

import StyledFormItem from 'components/Form/StyledFormItem';
import { FormItem } from 'components/Layout/Form';

export interface FItemProps extends FormItemProps<any> {
  meta?: FieldMetaProps<any>;
  styledFormItem?: boolean;
  showValidateSuccessForStyledFormItem?: boolean;
}

const FItem = ({
  meta,
  styledFormItem = false,
  showValidateSuccessForStyledFormItem = false,
  ...props
}: FItemProps) => {
  const isTouched = meta?.touched || false;
  const hasError = meta?.error !== undefined && isTouched;
  const isValid = !meta?.error && isTouched;

  return !!styledFormItem ? (
    <StyledFormItem
      validateStatus={
        hasError
          ? 'error'
          : isValid && !!showValidateSuccessForStyledFormItem
          ? 'success'
          : undefined
      }
      hasFeedback={isValid}
      help={(hasError && <li>{meta.error}</li>) || (isValid && '')}
      colon={false}
      {...props}
    />
  ) : (
    <FormItem
      validateStatus={hasError ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError ? meta?.error : undefined}
      colon={false}
      {...props}
    />
  );
};

export default FItem;
