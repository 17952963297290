import _cloneDeep from 'lodash/cloneDeep';

export const campaignNormalizeData = campaign => {
  const clonedCampaign = _cloneDeep(campaign);

  if (clonedCampaign.campaign_type === 'stamp') {
    if (clonedCampaign.outcomes && clonedCampaign.outcomes.length > 0) {
      clonedCampaign.initialSlottedRewards = clonedCampaign.outcomes; // prepare for initial reward, it will get preprocessed inside campaign mechanic page
    }
  }

  if (clonedCampaign.campaign_type === 'game') {
    if (clonedCampaign.game_type === 'survey' || clonedCampaign.game_type === 'quiz') {
      clonedCampaign.campaign_type = clonedCampaign.game_type;
    }
  }

  return clonedCampaign;
};
