import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, Col, Divider, Input, message, Row, Typography } from 'antd';
import { Field, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';

import { FormItem } from 'components/Form/FormikFields';
import { userSignIn } from 'containers/App/actions';
// @ts-ignore
import { ReactComponent as PerxLogo } from 'pages/public/login_logo.svg';
// @ts-ignore
import heroImgSrc from 'pages/public/main_image.jpg';
import API from 'utils/request';

const { Title, Text, Paragraph } = Typography;

interface Props {}

const validationSchema = yup.object().shape({
  email: yup.string().required('Please enter an email address'),
  password: yup.string().required('Please enter a password'),
  password_confirmation: yup.string().required('Please repeat password'),
  tenant_name: yup.string().required('Please enter a tenant name')
});

const Registration = (props: Props) => {
  const initialValues = {
    email: '',
    password: '',
    password_confirmation: '',
    tenant_name: ''
  };

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const submitForm = async (values, formikHelpers: FormikHelpers<any>) => {
    try {
      await API.post('/users', values);
      try {
        dispatch(
          userSignIn(
            {
              email: values.email,
              password: values.password
            },
            history,
            location,
            formikHelpers.setSubmitting
          )
        );
      } catch (error2) {
        history.push('/signin');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error creating an account');
    }
  };

  return (
    <Page>
      <PerxLogo />
      <FormPanel>
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting }) =>
            false ? (
              <div>
                <Title level={3}>Please wait while we’re setting up your account</Title>
                <Text>Just a moment. Creating your account...</Text>
                {/* @ts-ignore */}
                <HeroImage src={heroImgSrc} alt="Customers Engagement" />
                <Text>
                  Influence and drive customer actions
                  <br />
                  <strong>that matter</strong>
                </Text>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <Title level={3}>Create your free Perx account</Title>
                <Paragraph style={{ marginBottom: 32 }}>
                  Already have an account? <a href="signin">Log in</a>
                </Paragraph>
                {/* <Text type="secondary">* indicates required fields</Text> */}
                <Row>
                  <Col xs={12}>
                    <Field name="email">
                      {({ field }) => (
                        <FormItem name={field.name} htmlFor={field.name} label="Email">
                          <Input
                            size="large"
                            type="email"
                            id={field.name}
                            disabled={isSubmitting}
                            {...field}
                          />
                        </FormItem>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col xs={12}>
                    <Field name="password">
                      {({ field }) => (
                        <FormItem name={field.name} htmlFor={field.name} label="Password">
                          <Input
                            size="large"
                            type="password"
                            id={field.name}
                            disabled={isSubmitting}
                            {...field}
                          />
                        </FormItem>
                      )}
                    </Field>
                  </Col>
                  <Col xs={12}>
                    <Field name="password_confirmation">
                      {({ field }) => (
                        <FormItem name={field.name} htmlFor={field.name} label="Confirm password">
                          <Input
                            size="large"
                            type="password"
                            id={field.name}
                            disabled={isSubmitting}
                            {...field}
                          />
                        </FormItem>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col xs={12}>
                    <Field name="tenant_name">
                      {({ field }) => (
                        <FormItem name={field.name} htmlFor={field.name} label="Tenant name">
                          <Input size="large" {...field} id={field.name} disabled={isSubmitting} />
                          <Paragraph
                            type="secondary"
                            style={{ marginTop: 8, marginBottom: 4, lineHeight: '22px' }}
                          >
                            Please enter your company name.
                            <br />
                            You can change it at anytime.
                          </Paragraph>
                        </FormItem>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  style={{ marginTop: 8 }}
                >
                  Create
                </Button>
                <Paragraph type="secondary" style={{ marginTop: '1rem' }}>
                  By continuing, you’re agreeing to the{' '}
                  <a href="https://www.perxtech.com/privacy/" target="_blank" rel="noreferrer">
                    Perx Terms of Service
                  </a>
                </Paragraph>
              </form>
            )
          }
        </Formik>
      </FormPanel>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 16px;
`;
const FormPanel = styled.div`
  margin-top: 32px;
  max-width: 624px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px 48px;
`;
const HeroImage = styled.image`
  width: 276px;
  height: 276px;
`;

export default Registration;
