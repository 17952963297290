import React from 'react';

import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { FItemInputNumber, FItemSelect } from 'components/formik';
import { policyOptions } from 'containers/Audience/utils';
import { useAxios } from 'hooks';

const LoyaltyPoints: React.FC = () => {
  const [, response] = useAxios('/v4/dash/simple/loyalty');

  const fieldPath = 'filters.loyalty_points_balance.criteria';

  const options = response?.data?.map(program => ({
    label: program.name,
    value: String(program.id)
  }));

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
      </FlexContainer>
      <Label bold style={{ display: 'inline-block', marginBottom: 8 }}>
        Users with points balance:
      </Label>
      <div style={{ flex: '1 1 auto', display: 'flex' }}>
        <FItemSelect
          name={`${fieldPath}.eq`}
          aria-label="Select loyalty program"
          placeholder="Select loyalty program"
          options={options}
        />
        <Label style={{ margin: '0 0.5rem' }} bold>
          From
        </Label>
        <FItemInputNumber
          name={`${fieldPath}.gteq`}
          aria-label="Points balance from"
          min={0}
          step={1}
        />
        <Label style={{ margin: '0 0.5rem' }} bold>
          to
        </Label>
        <FItemInputNumber
          name={`${fieldPath}.lteq`}
          aria-label="Points balance to"
          min={0}
          step={1}
        />
      </div>
    </>
  );
};

export default LoyaltyPoints;
