import React from 'react';
import _trim from 'lodash/trim';

import { StatusTag } from 'components';

interface CellProps {
  propertyName: string;
  data: (number | string | null | object | boolean)[];
}

const ImageText = ({ data, small = false }) => {
  if (data.type === 'text') {
    return <Text data={data} />;
  } else if (data.type === 'image') {
    return <Image data={data} small={small} />;
  } else {
    return <Fallback data={data} />;
  }
};

const Image = ({ data, small = false }) => (
  <>
    {data.value && data.value.image_url && (
      <img
        style={{ maxWidth: '100%', maxHeight: small ? '50px' : '200px' }}
        src={data.value.image_url}
        alt=""
      />
    )}
    {data.value && data.value.file && (
      <img
        style={{ maxWidth: '100%', maxHeight: small ? '50px' : '200px' }}
        src={data.value.file}
        alt=""
      />
    )}
    {data.value && data.value.filename && (
      <p style={{ margin: 0, wordBreak: 'break-all' }}>{data.value.filename}</p>
    )}
  </>
);

const Text = ({ data }) => (
  <>
    {data.value && (data.value.title || data.value.description) ? (
      <>
        {data.value && data.value.title && (
          <p>
            <strong>Title:</strong>
            <br />
            {data.value.title}
          </p>
        )}
        {data.value && data.value.description && (
          <p>
            <strong>Description:</strong>
            <br />
            {data.value.description}
          </p>
        )}
      </>
    ) : (
      '-'
    )}
  </>
);

const Outcome = ({ data }) => (
  <>
    {data.title && (
      <p>
        <strong>Title:</strong>
        <br />
        {data.title}
      </p>
    )}
    {data.button_text && (
      <p>
        <strong>Button Text:</strong>
        <br />
        {data.button_text}
      </p>
    )}
    {data.description && (
      <p>
        <strong>Description:</strong>
        <br />
        {data.description}
      </p>
    )}
  </>
);

const Fallback = ({ data }) => {
  if (Array.isArray(data)) {
    return <p>{JSON.stringify(data, null, 1)}</p>;
  }
  return <p>{_trim(JSON.stringify(data), '"')}</p>;
};

const Pre = ({ data }) => (
  <pre>
    <code>{data}</code>
  </pre>
);

const Cell = ({ propertyName, data }: CellProps) => {
  const renderContent = (propertyName, data) => {
    switch (propertyName) {
      case 'identifier':
        return <Pre data={data} />;
      case 'state':
        return <StatusTag state={data} />;
      case 'header':
        return <Text data={data} />;
      case 'outcome':
      case 'nooutcome':
        return <Outcome data={data} />;
      case 'tree_image':
      case 'background_image':
      case 'opened_image':
      case 'still_image':
      case 'cracking_image':
        return <ImageText data={data} />;
      case 'gift_image':
        return <ImageText data={data} small />;
      default:
        return <Fallback data={data} />;
    }
  };
  return <div>{renderContent(propertyName, data)}</div>;
};

export default Cell;
