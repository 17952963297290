import React, { memo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { Radio, Row } from 'antd';
import _toSnakeCase from 'lodash/snakeCase';

import { changeAppLocale } from 'containers/App/actions';
import { useTypedSelector } from 'hooks';

interface LocaleSwitcherProps {
  style?: object;
}

const LocaleSwitcher = ({ style = {} }: LocaleSwitcherProps) => {
  const dispatch = useDispatch();
  const locales = useTypedSelector(
    state => state.global?.localeSwitcherLocales?.locales,
    shallowEqual
  );
  const selectedLocale = useTypedSelector(state => state.global?.selectedLocale);

  if (locales == null || locales.length <= 0) return null;

  return (
    <Row justify="end" align="middle" style={style} data-testid="lang-switcher">
      <span style={{ marginRight: '1rem' }}>Language</span>
      <Radio.Group
        value={selectedLocale}
        onChange={evt => dispatch(changeAppLocale(evt.target.value))}
        buttonStyle="solid"
      >
        {locales.map(l => (
          <Radio.Button key={l} value={_toSnakeCase(l)}>
            {l}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Row>
  );
};

export default memo(LocaleSwitcher);
