import React from 'react';
import { animated, useSpring } from 'react-spring';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import _isNil from 'lodash/isNil';
import styled from 'styled-components';

interface NumberDisplayProps {
  value: number;
  description: string;
  icon?: any;
  previousValue?: number;
  isPercentage?: boolean;
  identifier?: string;
}

const NumberDisplay: React.FC<NumberDisplayProps> = ({
  icon,
  description,
  value,
  previousValue,
  isPercentage = false,
  identifier
}) => {
  const percentChange = getPercentageChange(previousValue, value, isPercentage);

  const needSuffix = identifier === 'overview_flat_loyalty_point_earn_burn_kpi';

  const currProps = useSpring({
    from: { val: 0, opacity: 0 },
    to: { val: value || 0, opacity: 1 },
    config: { delay: 1000 }
  });

  const changeProps = useSpring({
    from: { val: 0, opacity: 0 },
    to: { val: Math.abs(percentChange), opacity: 1 },
    config: { delay: 1000 }
  });

  return (
    <NumbersWrapper>
      <IconColumn>{icon}</IconColumn>
      <NumbersColumn>
        <Value>
          <AnimatedCount style={currProps}>
            {/*@ts-ignore*/}
            {currProps.val.interpolate(x => formatNumber(x, !isPercentage, needSuffix))}
          </AnimatedCount>
        </Value>
        <ValueSubtext>{description}</ValueSubtext>
        {!_isNil(previousValue) && (
          <Delta>
            {percentChange > 0 && (
              <span style={{ color: '#46C18E' }}>
                <CaretUpOutlined />
                <animated.span style={changeProps}>
                  {/*@ts-ignore*/}
                  {changeProps.val.interpolate(x => `${formatNumber(x)}%`)}
                </animated.span>
              </span>
            )}
            {percentChange < 0 && (
              <span style={{ color: '#DF3856' }}>
                <CaretDownOutlined />
                <animated.span style={changeProps}>
                  {/*@ts-ignore*/}
                  {changeProps.val.interpolate(x => `${formatNumber(x)}%`)}
                </animated.span>
              </span>
            )}
            {percentChange === 0 && <span>0%</span>}
            <span style={{ marginLeft: '0.5rem' }}>vs previous period</span>
          </Delta>
        )}
      </NumbersColumn>
    </NumbersWrapper>
  );
};

export default NumberDisplay;

const NumbersWrapper = styled.div`
  display: flex;
  && + && {
    margin-top: 1.5rem;
  }
`;

const IconColumn = styled.div`
  i {
    color: #9b9b9b;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const NumbersColumn = styled.div``;

const Value = styled.div`
  margin-bottom: 0.5rem;
`;

const ValueSubtext = styled.div`
  color: #9b9b9b;
  font-size: 0.75rem;
`;

const AnimatedCount = styled(animated.div)`
  font-size: 1.875em;
  color: #000000;
  display: inline-block;
  line-height: 100%;
`;

const Delta = styled.div`
  color: #9b9b9b;
  font-size: 0.75rem;
  margin-top: 0.25rem;

  ${AnimatedCount} {
    font-size: 0.875rem;
    line-height: 1.14rem;
  }
`;

const formatNumber = (number, isInteger = false, needSuffix = false) => {
  return isInteger
    ? needSuffix
      ? parseFloat(number.toFixed(0)).toLocaleString('en-US', {
          // @ts-ignore
          notation: 'compact',
          compactDisplay: 'short'
        })
      : parseFloat(number.toFixed(0)).toLocaleString()
    : parseFloat(number.toFixed(2)).toLocaleString();
};

const getPercentageChange = (prev = 0, curr = 0, isPercentage = false) => {
  if (isPercentage) return curr - prev;
  else if (prev === 0 && curr > 0) return 100;
  else if (prev === 0 && curr === 0) return 0;
  return ((curr - prev) / prev) * 100;
};
