import React, { useState } from 'react';
import { Col, Tabs } from 'antd';
import { FieldArray } from 'formik';

import { WrapperPanel } from 'components';
import { FlexContainer } from 'components/Layout/common';

import AudienceFilters from './AudienceFilters';
import AudienceEssentials from './components/AudienceEssentials';
import AudienceSetup from './components/AudienceSetup';

interface LoyaltyAudienceProps {
  isEditMode?: boolean;
}

const tabItems = [
  { label: 'Filter customers', key: 'filter' },
  { label: 'Upload a list', key: 'upload' }
];

const LoyaltyAudience: React.FC<LoyaltyAudienceProps> = props => {
  const [activeTab, setActiveTab] = useState('filter');

  return (
    <FlexContainer
      style={{ marginTop: '0', marginBottom: '0' }}
      hasMiddleSpaces
      data-testid="LoyaltyAudience"
    >
      <Col span={24}>
        <WrapperPanel title="Essentials">
          <AudienceEssentials />
        </WrapperPanel>
        <WrapperPanel title="Audience list">
          <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
          {activeTab === 'filter' && <AudienceFilters />}
          {activeTab === 'upload' && <FieldArray name="user_groups" component={AudienceSetup} />}
        </WrapperPanel>
      </Col>
    </FlexContainer>
  );
};

export default LoyaltyAudience;
