import { takeLatest, all } from 'redux-saga/effects';

import { commonSaga } from 'middleware/api';

import {
  CREATE_REWARD,
  APPROVE_REWARD,
  DEACTIVATE_REWARD,
  UPDATE_REWARD,
  GET_REWARDS,
  GET_REWARD,
  GET_BRANDS,
  GET_AUDIENCES,
  GET_TAGS,
  GET_MERCHANTS,
  GET_LOYALTY_LIST,
  SEARCH_REWARD,
  UPLOAD_AUDIENCE,
  DUPLICATE_REWARD,
  GET_AUDIENCE_INFO,
  GET_PARTNER
} from './constants';

function* rewardSaga() {
  yield all([
    takeLatest(CREATE_REWARD, commonSaga),
    takeLatest(UPDATE_REWARD, commonSaga),
    takeLatest(APPROVE_REWARD, commonSaga),
    takeLatest(DEACTIVATE_REWARD, commonSaga),
    takeLatest(GET_REWARDS, commonSaga),
    takeLatest(GET_REWARD, commonSaga),
    takeLatest(GET_TAGS, commonSaga),
    takeLatest(GET_BRANDS, commonSaga),
    takeLatest(GET_AUDIENCES, commonSaga),
    takeLatest(GET_MERCHANTS, commonSaga),
    takeLatest(GET_LOYALTY_LIST, commonSaga),
    takeLatest(SEARCH_REWARD, commonSaga),
    takeLatest(UPLOAD_AUDIENCE, commonSaga),
    takeLatest(DUPLICATE_REWARD, commonSaga),
    takeLatest(GET_AUDIENCE_INFO, commonSaga),
    takeLatest(GET_PARTNER, commonSaga)
  ]);
}

export default rewardSaga;
