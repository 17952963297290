// @ts-check
import React, { memo, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { Popover } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import NoData from 'containers/Analytics/components/NoData';
import { useAnalyticsContext } from 'containers/Analytics/hooks';

import { getMapData } from '../dummyData';

const geoUrl =
  'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-50m.json';
const StyledComposableMap = styled(ComposableMap)`
  height: 100%;
  width: 100%;
`;

const ReactSimpleMap = ({ data }) => {
  const { isDemoMode } = useAnalyticsContext();
  const [zoom, setZoom] = useState(1);
  if (_isEmpty(data) && !isDemoMode) {
    return <NoData />;
  }

  const onZoomEnd = position => setZoom(position.zoom);

  /** @type number[][]}} */
  let graphData = data;
  if (isDemoMode) {
    graphData = getMapData();
  }
  //some of the points are all over the place
  graphData = graphData.filter(p => p[0] <= 90 && p[0] >= -90);
  const bb = new BoundingBox(graphData);
  const center = bb.center;
  const scale = bb.scale;
  const popOverContent = contents => {
    return <p>City: {contents[5]}</p>;
  };

  return (
    <StyledComposableMap
      projectionConfig={{
        // @ts-ignore types are not up to date
        center: [center.longitude, center.latitude],
        scale
      }}
    >
      <ZoomableGroup zoom={zoom} onMoveEnd={onZoomEnd} center={[center.longitude, center.latitude]}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />
            ))
          }
        </Geographies>
        {graphData.map((m, k) => (
          <Marker key={k} coordinates={[m[1], m[0]]}>
            {m[5] && (
              <Popover content={popOverContent(m)} title={m[6]}>
                <circle r={10} fill="#ff0000" stroke="#ffffff" strokeWidth={2} />
              </Popover>
            )}
            {!m[5] && <circle r={10} fill="#ff0000" stroke="#ffffff" strokeWidth={2} />}
          </Marker>
        ))}
      </ZoomableGroup>
    </StyledComposableMap>
  );
};

/**
 * Basic bounding box implementation
 * It does not handle the meridian 12 case.
 * It helps computing center and approximate scale for a list  of points
 */
class BoundingBox {
  /**
   * @param {number[][]} ps
   */

  static R = 6371e3; // metres
  /**
   * @param {number} a
   */
  static toRad = a => (a / 180) * Math.PI;
  north: number;
  south: number;
  east: number;
  west: number;

  constructor(ps) {
    this.north = null;
    this.south = null;
    this.east = null;
    this.west = null;
    if (ps) {
      /**
       * @param {any} p
       */
      ps.forEach(p => this.extend({ latitude: p[0], longitude: p[1] }));
    }
  }

  /**
   * @param {{ latitude: number; longitude: number; }} p
   */
  extend(p) {
    if (this.north === null || this.north < p.latitude) {
      this.north = p.latitude;
    }
    if (this.south === null || this.south > p.latitude) {
      this.south = p.latitude;
    }
    if (this.east === null || this.east < p.longitude) {
      this.east = p.longitude;
    }
    if (this.west === null || this.west > p.longitude) {
      this.west = p.longitude;
    }
  }

  /** @returns {{latitude:number, longitude:number}} */
  get center() {
    if (this.north === null) {
      return { latitude: null, longitude: null };
    }
    return { latitude: (this.south + this.north) / 2, longitude: (this.east + this.west) / 2 };
  }

  /** @returns {number} */
  get size() {
    const φ1 = BoundingBox.toRad(this.north);
    const φ2 = BoundingBox.toRad(this.south);
    const Δφ = φ2 - φ1;
    const Δλ = BoundingBox.toRad(this.east - this.west);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return BoundingBox.R * c;
  }

  /**
   * There is no real science behind those numbers but it is the best compromize I could find without loading overly complicated sdks.
   * @returns {number}
   */
  get scale() {
    const size = Math.max(this.size / 1000, 200);
    const scale = Math.max(2000000 / size, 400);
    return scale;
  }
}

export default memo(ReactSimpleMap);
