import { Input as antInput } from 'antd';
import styled, { css } from 'styled-components';

const defaultInput = css`
  .ant-input,
  .ant-input-number {
  }

  &.ant-input,
  .ant-input-number-input {
  }

  &.ant-input-number {
    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }

  &.ant-input-number.ant-input-number-focused {
    box-shadow: none !important;
  }
`;

const Input = styled(antInput)`
  ${defaultInput};
`;

export default Input;
