import { useContext } from 'react';

import { AnalyticsContext } from 'containers/Analytics/AnalyticsProvider';

const useAnalyticsContext = () => {
  const state = useContext(AnalyticsContext);
  if (state === undefined) throw new Error('Analytics state is undefined');
  return state;
};

export default useAnalyticsContext;
