import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { FormItem, InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import { FItemSelect } from 'components/formik';
import { BadgeSelectModal } from 'components/modals';
import { policyOptions } from 'containers/Audience/utils';
import SelectedBadge from 'containers/Rule/components/SelectedBadge';
import { verticalLayout } from 'utils/formItemLayouts';

const Badge = () => {
  const modal = useModal(BadgeSelectModal);
  const formik = useFormikContext<any>();

  const fieldPath = 'filters.earned_badges[0].criteria';
  const selectedBadgeId = _get(formik.values, `${fieldPath}.eq`);

  const selectBadge = () =>
    modal.show().then((campaign: any) => {
      formik.setFieldTouched(`${fieldPath}.eq`);
      formik.setFieldValue(`${fieldPath}.eq`, campaign.id);
    });

  const deleteBadge = () => {
    formik.setFieldValue(`${fieldPath}.eq`, undefined);
  };

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
      </FlexContainer>
      <FormItem {...verticalLayout} label="Users who earned the badge:">
        {selectedBadgeId && <SelectedBadge id={selectedBadgeId} onDelete={deleteBadge} />}
        <Button type="primary" ghost onClick={selectBadge}>
          {selectedBadgeId ? 'Change' : 'Select'} badge
        </Button>
      </FormItem>
    </>
  );
};

export default Badge;
