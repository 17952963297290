import React from 'react';
import { Badge } from 'antd';
import useSWR from 'swr';

import { fetcher } from 'api/utils';

export const CustomBadge: React.FC = () => {
  const { data, isLoading, mutate } = useSWR('v4/dash/customer_requests?status=pending', fetcher);

  if (data?.meta?.count > 0) {
    return <Badge status="processing" />;
  }
  return null;
};
