import React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import styled from 'styled-components';

import { componentAdjustment } from './Layout/common';

const StyledCard = styled(Card)`
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  // TODO. Get rid of this.
  // This is quite a weird hack.
  // If you remove it, it will break some styles.
  ${componentAdjustment}
`;

interface WrapperPanelProps extends CardProps {
  rightTitleNode?: React.ReactNode;
}

const WrapperPanel = ({ rightTitleNode, ...restProps }: WrapperPanelProps) => {
  return (
    <StyledCard {...restProps} extra={rightTitleNode}>
      {restProps.children}
    </StyledCard>
  );
};

export default WrapperPanel;
