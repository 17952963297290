import React from 'react';
import _sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import { ColorDot } from 'containers/Analytics/styles';

interface ITier {
  color: string;
  name?: string;
  dataKey: string;
  value: number;
}

const LoyaltyTooltip: React.FC<{
  payload?: any;
  type?: string;
  label?: string;
  showTotal?: boolean;
  title: string;
}> = ({ payload = [], title, showTotal = false }) => {
  if (!payload) return null;

  const sortedPayload = _sortBy(payload, o => -o.value);
  const total = sortedPayload.reduce((a: number, b: ITier) => +a + +b.value, 0);
  return (
    <div style={{ padding: '1rem' }}>
      <table>
        <tbody>
          <tr style={{ height: '30px' }}>
            <td colSpan={2} align={'right'}>
              {title}:
            </td>
            <td>{payload?.[0]?.payload?.attribute}</td>
          </tr>
          {sortedPayload.map((tier: ITier, index: number) => (
            <tr key={`tier-${index}`}>
              <td style={{ paddingRight: '5px', lineHeight: '12px' }}>
                <ColorDot color={tier.color} />
              </td>
              <td style={{ width: 'auto', paddingRight: '30px' }}>
                <b>{tier.name || tier.dataKey}</b>
              </td>
              <td align="right">{tier.value.toLocaleString()}</td>
            </tr>
          ))}
          {showTotal && (
            <tr>
              <td></td>
              <td>
                <b>Sub Total</b>
              </td>
              <td align="right">{total.toLocaleString()}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

LoyaltyTooltip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string,
  title: PropTypes.string
};

export default LoyaltyTooltip;
