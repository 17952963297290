import { cdnURL } from 'utils/helpers';

export const Pointers = [
  {
    filename: 'pointer_1.png',
    image_url: cdnURL('pointer1.png')
  },
  {
    filename: 'pointer_2.png',
    image_url: cdnURL('pointer2.png')
  },
  {
    filename: 'pointer_3.png',
    image_url: cdnURL('pointer3.png')
  }
];

export const Wheels = [
  {
    filename: 'wheel_1.png',
    image_url: cdnURL('wheel1.png')
  },
  {
    filename: 'wheel_2.png',
    image_url: cdnURL('wheel2.png')
  },
  {
    filename: 'wheel_3.png',
    image_url: cdnURL('wheel3.png')
  }
];

export const Rewards = [
  {
    filename: 'reward_icon.png',
    image_url: cdnURL('post-stamp-2.png')
  }
];

export const Backgrounds = [
  {
    filename: 'background1.png',
    image_url: cdnURL('stamp-bg-10.png')
  },
  {
    filename: 'background2.png',
    image_url: cdnURL('stamp-bg-11.png')
  },
  {
    filename: 'background3.png',
    image_url: cdnURL('stamp-bg-12.png')
  }
];

export const WedgeColors = [
  '#ca01b3',
  '#f82165',
  '#f86521',
  '#cab301',
  '#80ed12',
  '#35fe4c',
  '#07de9a',
  '#079ade',
  '#354cfe',
  '#7f12ed'
];
