import React, { useCallback, useContext, useMemo } from 'react';
import { Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _startCase from 'lodash/startCase';
import moment from 'moment';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';

import { DatePart } from 'containers/Analytics/components/DatePartSelect';
import Error from 'containers/Analytics/components/Error';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { campaignOverviewTypes } from 'containers/Analytics/pages/Campaign/LandingPage';
import { ComboContext } from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import { colorGenerator, getLineDemoData, showCard } from 'containers/Analytics/utils';

interface AreaGraphSectionProps {
  identifier: string;
  withFill?: boolean;
  withDatePart?: boolean;
  demoLines?: string[];
  demoDataRange?: [number, number];
}

const AreaGraphSection: React.FC<AreaGraphSectionProps> = ({
  identifier,
  withFill = false,
  demoLines = [],
  demoDataRange
}) => {
  const { isDemoMode, cards, dateRange, datePart } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);
  const { filter, extraData } = useContext(ComboContext);

  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    filter
  );

  const color = colorGenerator();

  const isCampaignOverviewPage =
    campaignOverviewTypes.findIndex(val => val.graphIdentifier === identifier) !== -1;

  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  let graphData = [];
  let graphs = [];
  const dRange = demoDataRange ? demoDataRange : [18000, 35000];

  const demoData = useMemo(
    () => getLineDemoData(dateRange, datePart, [dRange[0], dRange[1]], demoLines),
    // eslint-disable-next-line
    [datePart, dateRange, demoLines]
  );
  if (isDemoMode) {
    graphs = demoLines.map(line => {
      return {
        name: _snakeCase(line),
        color: color.next().value
      };
    });
    graphData = demoData;
  } else if (!data) {
    graphData = [];
  } else {
    const period = filter?.datepart;

    const d = data.data;

    // leave out the time colunm while creating graphs
    if (d) {
      graphs = d.cols.slice(0, d.cols.length - 1).map(col => {
        return {
          name: _snakeCase(col.name),
          color: color.next().value
        };
      });
      const sameYear = moment(dateRange[0]).format('YYYY') === moment(dateRange[1]).format('YYYY');
      graphData = processGraphData(d, period, sameYear);
    }
  }

  const CustomTooltip = props => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      return (
        <TooltipWrapper>
          <h4>{label}</h4>
          <div>
            {payload.map((line, index) => (
              <LegendWrapper key={index}>
                <Indicator color={line.stroke} />{' '}
                {`${_startCase(line.name)} : ${line.value.toLocaleString()}`}
              </LegendWrapper>
            ))}
          </div>
        </TooltipWrapper>
      );
    }

    return null;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return (
      <>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Subtext>Coming Soon</Subtext>} />
      </>
    );
  }

  if (isCampaignOverviewPage && extraData.noActiveCampaign) {
    return (
      <>
        <Spin spinning={!data && !isDemoMode}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Subtext>There isn't an active campaign during the date range specified.</Subtext>
            }
          />
        </Spin>
      </>
    );
  }

  return (
    <>
      {hasError ? (
        <Error retry={onRetry} />
      ) : (
        <Spin spinning={!data && !isDemoMode}>
          {!_isEmpty(graphData) || isDemoMode ? (
            <>
              {/* the <Legend /> component had to be taken out of
                ResponsiveContainer so that it doesn't overlap
                with graph lines */}
              <Legend
                verticalAlign="top"
                height={63}
                wrapperStyle={{ position: 'initial' }}
                payload={graphs.map(g => ({
                  value: _startCase(g.name),
                  type: 'line',
                  id: g.name,
                  color: g.color
                }))}
              />
              <ResponsiveContainer height={200}>
                <AreaChart data={graphData} margin={{ top: 4, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    {withFill &&
                      graphs.map(g => (
                        <linearGradient id={g.name} x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor={g.color} stopOpacity={0.8} />
                          <stop offset="95%" stopColor={g.color} stopOpacity={0} />
                        </linearGradient>
                      ))}
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={shortenTick} allowDecimals={false} />
                  <CartesianGrid strokeDasharray={'1 5'} horizontal={false} />
                  <Tooltip
                    content={<CustomTooltip />}
                    allowEscapeViewBox={{ x: true, y: true }}
                    wrapperStyle={{ zIndex: 100 }}
                  />
                  {graphs.map((g, index) => (
                    <Area
                      key={index}
                      type="monotone"
                      dataKey={g.name}
                      stroke={g.color}
                      fillOpacity={1}
                      fill={`url(#${g.name})`}
                    />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      )}
    </>
  );
};

export default AreaGraphSection;

const TooltipWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 1rem;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Indicator = styled.div`
  display: inline-block;
  background: ${props => (props.color ? props.color : '#8884d8')};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
`;

const Subtext = styled.span`
  font-size: 0.9rem;
  color: #8a8a8a;
`;

export const shortenTick = value => {
  let toReturn = value;
  const m = 1000000;
  const k = 1000;

  if (value >= m) {
    const remainder = toReturn % m;
    toReturn = (toReturn / m).toFixed(remainder > 0 ? 2 : 0) + 'mil';
  } else if (value >= k) {
    const remainder = toReturn % k;
    toReturn = (toReturn / k).toFixed(remainder > 0 ? 2 : 0) + 'k';
  }

  return toReturn;
};

const processGraphData = (data, period, sameYear) => {
  const colCopy = [...data.cols];
  const timeColIndex = colCopy.length - 1;

  // Remove time column
  const columns = colCopy.slice(0, colCopy.length - 1);

  const rowData = [...data.rows].sort((a, b) =>
    moment(a[timeColIndex]).diff(moment(b[timeColIndex]), period)
  );

  const graphData = rowData.map(r => {
    let rowD = {
      name: moment(r[timeColIndex]).format(sameYear ? 'D MMM' : 'D MMM YYYY')
    };
    switch (period) {
      case DatePart.Hourly:
        rowD = {
          name: moment(r[timeColIndex]).format(sameYear ? 'H:mma D MMM' : 'H:mma D MMM YYYY')
        };
        break;
      case DatePart.Daily:
      case DatePart.Weekly:
        rowD = {
          name: moment(r[timeColIndex]).format(sameYear ? 'D MMM' : 'D MMM YYYY')
        };
        break;
      case DatePart.Monthly:
        rowD = {
          name: moment(r[timeColIndex]).format(sameYear ? 'MMM' : 'MMM YYYY')
        };
        break;
      default:
        rowD = {
          name: moment(r[timeColIndex]).format(sameYear ? 'MMM' : 'MMM YYYY')
        };
        break;
    }
    columns.forEach((c, index) => {
      rowD[_snakeCase(c.name)] = r[index];
    });

    return rowD;
  });

  return graphData;
};
