import { useCallback, useMemo, useReducer } from 'react';
import { TablePaginationConfig } from 'antd';
import produce from 'immer';

import { DEFAULT_PAGE_SIZE } from 'appConstants';

export interface ParamsState {
  page: number;
  size: number;
  state?: string;
  search_string?: string;
  order?: 'asc' | 'desc';
  sort_by?: string;
}

export const defaultParams: ParamsState = {
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  state: null,
  search_string: null,
  order: null,
  sort_by: null
};

const paramsReducer = (state: ParamsState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SEARCH':
        draft.search_string = action.value;
        draft.page = 1;
        break;
      case 'STATE':
        draft.state = action.value === 'all' ? null : action.value;
        draft.page = 1;
        break;
      case 'TABLE':
        draft.page = action.pagination;
        if (action.sorter?.order && action.sorter?.columnKey) {
          draft.sort_by = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        } else {
          draft.sort_by = null;
          draft.order = null;
        }
        if (action.size) {
          draft.size = action.size;
        }
        break;
      case 'PAGE_SIZE':
        draft.page = action.payload.page;
        draft.size = action.payload.size;
        break;
      case 'RESET':
      default:
        return action.payload;
    }
  });

/**
 * The hook implements state management for paginated list queries for Perx API.
 * It supports handling search, table (pagination) and state (record status) change.
 * It could not fit in some cases, therefore check whether query API of the endpoint matches ParamsState.
 */
export const useListState = (initialParams: Partial<ParamsState>) => {
  const [params, dispatch] = useReducer(paramsReducer, { ...defaultParams, ...initialParams });

  const handleSearch = useCallback((value: string) => {
    dispatch({ type: 'SEARCH', value });
  }, []);

  const handleStateChange = useCallback(tab => {
    dispatch({ type: 'STATE', value: tab });
  }, []);

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    dispatch({ type: 'TABLE', pagination: pagination.current, sorter });
  }, []);

  const handleShowSizeChange = useCallback((current, size) => {
    dispatch({ type: 'PAGE_SIZE', payload: { current, size } });
  }, []);

  const resetParams = useCallback(() => {
    dispatch({ type: 'RESET', payload: { ...defaultParams, ...initialParams } });
  }, [initialParams]);

  const pagination: TablePaginationConfig = useMemo(
    () => ({
      current: params.page,
      pageSize: params.size,
      position: ['bottomRight'],
      showSizeChanger: true,
      showQuickJumper: true,
      onShowSizeChange: handleShowSizeChange
    }),
    [params, handleShowSizeChange]
  );

  return { pagination, params, handleSearch, handleStateChange, handleTableChange, resetParams };
};

export default useListState;
