import React from 'react';
import { Select, SelectProps } from 'antd';

import { useSimpleCatalogList } from 'api/simple';

interface CatalogueSelectProps extends SelectProps {}

export const CatalogueSelect = (props: CatalogueSelectProps) => {
  const { data = [], isLoading } = useSimpleCatalogList();

  const filterOption = (input: string, option) =>
    (option?.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Select
      filterOption={filterOption}
      showArrow
      showSearch
      loading={isLoading}
      {...props}
      value={props.value || undefined}
      onBlur={e => e.preventDefault()}
    >
      {data?.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CatalogueSelect;
