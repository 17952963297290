import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty, Radio, Spin } from 'antd';
import { Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { getDatePart } from 'containers/Analytics/utils';

import DatePartSelect, { DatePart } from '../../components/DatePartSelect';
import Error from '../../components/Error/Error';
import { TickProps, ToolTipProps } from './HistogramGraph';
import { getOverviewBreakdownData } from './dummyData';

const QUESTIONS_IDENTIFIERS = {
  impressions: 'overview_overall_breakdown_by_date_impression',
  users: 'overview_overall_breakdown_by_date_user',
  rewards: 'overview_overall_breakdown_by_date_reward',
  revenue: 'overview_overall_breakdown_by_date_revenue'
};

const OverviewBreakdownGraph = () => {
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const [tab, setTab] = useState('rewards');
  const [, setTooltipMessage] = useState('How many rewards you have issued to your users.');
  const { isDemoMode, dateRange } = useAnalyticsContext();
  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');
  const { card_id: questionId } = useCard(QUESTIONS_IDENTIFIERS[tab]);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    useMemo(() => ({ datepart }), [datepart])
  );

  const onTabChange = useCallback(evt => {
    setTab(evt.target.value);
    changeTooltipMessage(evt.target.value);
  }, []);

  const onDatepartChange = useCallback(val => setDatepart(val), []);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (!data && !isDemoMode) return <Spin size="large" />;
    return <Graph data={data} diff={diff} datepart={datepart} tab={tab} />;
  };

  const changeTooltipMessage = targetTab => {
    switch (targetTab) {
      case 'rewards':
        setTooltipMessage('How many rewards you have issued to your users.');
        break;
      case 'users':
        setTooltipMessage('How many users are engaged with the platform.');
        break;
      case 'impressions':
        setTooltipMessage(
          'How engaged your users are with the platform. This is a count of the number of times any of the Perx v4 APIs are engaged by a user.'
        );
        break;
      case 'revenue':
        setTooltipMessage(
          'How much you have made from selling rewards. The forumla is (selling price * number of paid rewards issued) in the time period selected.'
        );
        break;
      default:
        setTooltipMessage('How many rewards you have issued to your users.');
        break;
    }
  };

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
    // eslint-disable-next-line
  }, [diff]);

  return (
    <div
      style={{
        width: '100%',
        padding: '2rem',
        backgroundColor: '#ffffff',
        border: '1px solid #e8e8e8',
        borderRadius: '4px'
      }}
    >
      <Row justify="space-between" style={{ marginBottom: '4rem' }}>
        <Radio.Group name="tab" value={tab} onChange={onTabChange}>
          <Radio.Button value="rewards">Rewards</Radio.Button>
          <Radio.Button value="users">Users</Radio.Button>
          <Radio.Button value="impressions">Impressions</Radio.Button>
          <Radio.Button value="revenue">Revenue</Radio.Button>
        </Radio.Group>

        <DatePartSelect value={datepart} onChange={onDatepartChange} />
      </Row>
      <div
        style={{
          height: 300,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '0.4rem'
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

const CustomDateTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} fill="#777" fontSize="12" textAnchor="middle">
        {moment(payload.value).format('MMM D')}
      </text>
    </g>
  );
};

const CustomHourTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} fill="#777" fontSize="12" textAnchor="middle">
        {moment(payload.value).format('MMM D, h:mm a')}
      </text>
    </g>
  );
};

const CustomCountTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dx={-40} fill="#777" fontSize="12" textAnchor="middle">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};
const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  if (active && payload && payload.length > 0) {
    return (
      <div style={{ background: '#fb7901', padding: '0.5rem', color: '#ffffff', borderRadius: 2 }}>
        <div>{payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#e4e4e4', fontSize: 11 }}>
          {moment(label).format('MMM D YYYY, h:mm a')}
        </div>
      </div>
    );
  }
  return null;
};

const getInterval = (diff, datepart) => {
  if (datepart === 'hour') {
    if (diff <= 0) return 0;
    else return 'preserveStartEnd';
  } else if (datepart === 'day') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 5;
    else if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else if (datepart === 'week') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 2;
    else if (diff <= 50) return 5;
    else return 'preserveStartEnd';
  } else if (datepart === 'month') {
    if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else {
    return 'preserveStartEnd';
  }
};

const Graph = ({ data, diff, datepart, tab }) => {
  const { dateRange, isDemoMode } = useAnalyticsContext();
  // if (!data) return <NoData />;
  let d = (data?.data?.rows || []).map(row => ({ value: row[0] ? row[0] : 0, date: row[1] }));

  if (isDemoMode) {
    const modifiers = {
      impressions: 10,
      users: 5,
      rewards: 1,
      revenue: 50
    };

    d = getOverviewBreakdownData(dateRange[0], dateRange[1], modifiers[tab], datepart);
  } else {
    if (_isEmpty(data?.data?.rows)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let xAxis = (
    <XAxis
      dataKey="date"
      interval={getInterval(diff, datepart)}
      tick={<CustomDateTick />}
      padding={{ left: 16, right: 16 }}
      stroke="#9b9b9b"
    />
  );

  if (datepart === 'hour') {
    xAxis = (
      <XAxis
        dataKey="date"
        interval={0}
        tick={<CustomHourTick />}
        padding={{ left: 16, right: 80 }}
        stroke="#9b9b9b"
      />
    );
  }
  return (
    <ResponsiveContainer>
      <LineChart data={d}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" stroke="#e4e4e4" />
        {xAxis}
        <YAxis axisLine={false} tickLine={false} tick={<CustomCountTick />} width={80} />
        <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#fb7901' }} />
        <Line
          dataKey="value"
          stroke="#fb7901"
          activeDot={{ r: diff > 30 ? 2 : 5 }}
          dot={{ r: diff > 30 ? 2 : 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default OverviewBreakdownGraph;
