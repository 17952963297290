/*
 *  TODO::
 *  This hook won't be needed when `react-router` ships with
 *  its own hook implementation.
 */
import { useContext } from 'react';
import { __RouterContext } from 'react-router';

const useRouter = () => useContext(__RouterContext);

export default useRouter;
