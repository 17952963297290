import { useEffect, useState } from 'react';
import axios from 'axios';

import API from 'utils/request';

const useGetDetail = (id, entity) => {
  const [result, setResult] = useState(null);
  const controller = new AbortController();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(
          `/v4/dash/${entity === 'loyalty' ? 'loyalty_programs' : entity}/${id}`,
          { signal: controller.signal }
        );
        setResult(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchAutomationTrigger = async () => {
      try {
        const response = await API.get(`/v4/dash/automation/${entity}/${id}`, {
          signal: controller.signal
        });
        setResult(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchLoyalty = async () => {
      try {
        const response = await API.get('/v4/dash/simple/loyalty', { signal: controller.signal });
        const result = response.data.data.flatMap(program => program.tiers).find(x => x.id === id);
        if (result) {
          setResult(result);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    if (id && entity === 'app_triggers') {
      fetchAutomationTrigger();
    } else if (id && entity === 'loyalty_tier') {
      fetchLoyalty();
    } else if (id && entity !== 'loyalty_tier') {
      fetchData();
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [entity, id]);

  if (result) {
    return result;
  }
  return null;
};

export default useGetDetail;
