import {
  GET_AUTHORIZATION,
  GET_AUTHORIZATION_SUCCESS,
  GET_AUTHORIZATION_FAIL,
  GET_TENANT_VIEW_CONFIG,
  GET_TENANT_VIEW_CONFIG_SUCCESS,
  GET_TENANT_VIEW_CONFIG_FAIL
} from './constants';

export const getAbility = (payload?) => {
  return {
    type: GET_AUTHORIZATION,
    payload
  };
};

export const getAbilitySuccess = payload => {
  return {
    type: GET_AUTHORIZATION_SUCCESS,
    payload
  };
};

export const getAbilityFailed = error => {
  return {
    type: GET_AUTHORIZATION_FAIL,
    error
  };
};

export const getViewConfig = (payload?) => {
  return {
    type: GET_TENANT_VIEW_CONFIG,
    endpoint: `/v4/dash/tenant_config`,
    types: [GET_TENANT_VIEW_CONFIG, GET_TENANT_VIEW_CONFIG_SUCCESS, GET_TENANT_VIEW_CONFIG_FAIL],
    noAuth: false,
    payload: payload
    // method: 'post',
    // notification: {
    //   error: () => message.error('Something Went Wrong!'),
    // },
    // callback: callBack,
  };
};
