import React, { useCallback, useState } from 'react';
import { SelectProps, Spin } from 'antd';

import { useLoyaltyIdentifierList } from 'api/program';
import { Select } from 'components/Layout/Form';

interface IdentifierSelectProps extends SelectProps {
  showId?: boolean;
  params?: {
    name: any[];
  };
}

export const LoyaltySelect = ({ params, showId, ...props }: IdentifierSelectProps) => {
  const [search, setSearch] = useState(null);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const handleBlur = useCallback(e => {
    e.preventDefault();
    setSearch(null);
  }, []);

  const { data = [], isLoading } = useLoyaltyIdentifierList({ search_string: search, ...params });

  const options = data
    .filter(item => item.name.toLowerCase().includes(search?.toLowerCase() || ''))
    .map(item => ({
      value: item.id,
      label: showId ? `${item.name} (ID: ${item.id})` : item.name
    }));

  return (
    <Select
      mode="multiple"
      onBlur={handleBlur}
      onSearch={handleSearch}
      options={options}
      loading={isLoading}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      {...props}
    />
  );
};

export default LoyaltySelect;
