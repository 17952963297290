import React, { memo } from 'react';
import { MoreOutlined } from '@ant-design/icons';

import { Popover } from 'components';

interface Action {
  title: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export interface TableActionsProps {
  actions?: Action[] | any;
  testId?: string;
  'aria-label'?: string;
}

const TableActions = ({ actions, testId, ...props }: TableActionsProps) => {
  return (
    <Popover
      actionList={actions}
      button={
        <MoreOutlined
          style={{ fontSize: '1.5rem', cursor: 'pointer' }}
          data-testid={testId}
          aria-label={props['aria-label']}
        />
      }
    />
  );
};

export default memo(TableActions);
