// UserSelect.tsx
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Select, Spin } from 'antd';
import _debounce from 'lodash/debounce';

import API from 'utils/request';

/* fetch users API */
const fetchUsers = async (searchString: string, page: number, size: number) => {
  const res = await API.get(`/v4/dash/users`, {
    params: { page, size, search_string: searchString }
  });
  return res.data.data;
};

export const UserSelect = ({ onChange, value, placeholder, ...props }) => {
  const [searchString, setSearchString] = useState<string>('');
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string | number | null>(value);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const isMounted = useRef(true);

  /* Component will unmount, update the ref */
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  /* Debounced function */
  const onSearch = useCallback(
    _debounce(async (val: string) => {
      setSearchString(val);
      setPage(1);
    }, 500),
    []
  );

  /* infinite scrolling */
  const handleScroll = async e => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    const size = 25;

    if (hasMore && !isLoading && scrollTop + offsetHeight >= scrollHeight) {
      setIsLoading(true);
      try {
        const newUsers = await fetchUsers(searchString, page + 1, size);
        if (newUsers.length < size) setHasMore(false);

        // Check if the component is still mounted before updating state
        if (isMounted.current) {
          setUsers(prevUsers => [...prevUsers, ...newUsers]);
          setPage(prevPage => prevPage + 1);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  /* handle user selection */
  const handleSelect = selectedUserId => {
    onChange(!selectedUserId ? null : selectedUserId);
    setSearchString('');
    setShouldRefetch(true);
  };

  /* clearing the input */
  const handleClear = () => {
    if (selectValue && !shouldRefetch) {
      onChange(null);
      setSelectValue(null);
      setSearchString('');
      setShouldRefetch(true);
    }
  };

  /* fetch initial users and update selected value */
  useEffect(() => {
    const fetchInitialUsers = async () => {
      try {
        const initialUsers = await fetchUsers(searchString, 1, 25);
        if (isMounted.current) {
          setUsers(initialUsers);
          setShouldRefetch(false);
        }
      } catch (error) {
        console.warn('Error fetching users');
      }
    };

    const updateSelectValue = () => {
      setSelectValue(prevValue => {
        if (prevValue !== value) return value;
        return prevValue;
      });
    };

    if (searchString !== '' || shouldRefetch) {
      updateSelectValue();
      fetchInitialUsers();
    }
  }, [value, searchString, shouldRefetch]);

  return (
    <Select
      showSearch
      value={selectValue}
      loading={isLoading}
      placeholder={isLoading ? 'Loading...' : placeholder}
      style={{ width: '100%' }}
      onSearch={onSearch}
      onPopupScroll={handleScroll}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      onSelect={handleSelect}
      onClear={handleClear}
      allowClear
      {...props}
    >
      {users.map(user => (
        <Select.Option key={user.id} value={user.id}>
          {user.email}
        </Select.Option>
      ))}
    </Select>
  );
};
