import React, { useCallback, useMemo, useState } from 'react';
import { TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import PropTypes from 'prop-types';

import { PartnerListResponse } from 'containers/Partners/types';
import { useAxios } from 'hooks';

interface PartnerSelectProps extends TreeSelectProps<number> {
  isSelectSubPartner?: boolean;
}

export const PartnerSelect: React.FC<PartnerSelectProps> = ({
  placeholder = 'Please select a partner',
  value,
  isSelectSubPartner = false,
  ...props
}) => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [expandKey, setExpandKey] = useState<string[]>([]);
  const [loading, response, error] = useAxios<PartnerListResponse>('/v4/dash/partners', {
    params: {
      parent_only: true,
      search_string: searchString,
      state: 'active'
    }
  });

  const onSearch = useCallback(val => setSearchString(val), []);
  const onSelect = useCallback(() => setSearchString(null), []);

  const treeData = useMemo(() => {
    const options = response?.data?.map(({ id, title, children = [] }) => ({
      id,
      key: id,
      value: id,
      title,
      fullTitle: title,

      children: children.map(sub => ({
        id: sub.id,
        key: sub.id,
        value: sub.id,
        title: sub.title,
        fullTitle: `${title} > ${sub.title}`
      }))
    }));

    return options || [];
  }, [response]);

  if (error) {
    return null;
  }

  const onChangePartner = (value, label: any, extra: any) => {
    const selectedNode = treeData.find(({ id }) => id === value);

    setExpandKey([value.toString()]);
    if (selectedNode?.children?.length) {
      props.onChange(selectedNode?.children[0]?.id, null, null);
    } else {
      props.onChange(value, label, extra);
    }
  };

  const onTreeExpand = expandValue => {
    setExpandKey(expandValue);
  };

  return (
    <TreeSelect
      // TODO. Enable when API will return correct result on search
      // showSearch
      {...props}
      value={value}
      loading={loading}
      placeholder={placeholder}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      // onSearch={_debounce(onSearch, 400)}
      onSearch={onSearch}
      onSelect={onSelect}
      onBlur={() => setSearchString(null)}
      onTreeExpand={onTreeExpand}
      treeExpandedKeys={expandKey}
      treeData={treeData}
      disabled={loading}
      treeNodeLabelProp="fullTitle"
      onChange={isSelectSubPartner ? onChangePartner : props.onChange}
    />
  );
};

PartnerSelect.propTypes = {
  placeholder: PropTypes.string
};

export default PartnerSelect;
