import { useMemo } from 'react';
import moment from 'moment';

import { METABASE_API_ROOT } from 'appConstants';
import { useAnalyticsContext } from 'containers/Analytics/hooks';
import { useAxios, useTenant } from 'hooks';

const useMetabase = (questionId: number, dataParams, options = { needDateRange: true }) => {
  const { name: tenantName } = useTenant();
  const { dateRange, isDemoMode } = useAnalyticsContext();
  const { needDateRange } = options;

  const shouldFetchToken = Boolean(!isDemoMode && tenantName && questionId);

  // technically [isTokenLoading, tokenRes, tokenError, tokenRefetch]
  const [isTokenValidating, tokenRes, tokenError, tokenRevalidate] = useAxios(
    shouldFetchToken ? '/v4/dash/report_token' : null,
    {
      method: 'POST',
      data: { type: 'question', id: questionId, params: { timezone: 'Asia/Singapore' } }
    }
  );

  const params = useMemo(() => {
    let startDate, endDate, diff, prevStart, prevEnd;

    if (needDateRange) {
      startDate = moment(dateRange[0]).local().startOf('day').utc();
      endDate = moment(dateRange[1]).local().add(1, 'day').startOf('day').utc();
      diff = endDate.diff(startDate, 'days');
      prevStart = moment(startDate).subtract(diff, 'days');
      prevEnd = startDate;
    }

    return {
      ...(needDateRange
        ? {
            start_date: startDate.format('YYYY-MM-DD HH:mm:ss'),
            end_date: endDate.format('YYYY-MM-DD HH:mm:ss'),
            previous_start_date: prevStart.format('YYYY-MM-DD HH:mm:ss'),
            previous_end_date: prevEnd.format('YYYY-MM-DD HH:mm:ss')
          }
        : {}),
      ...(dataParams ? dataParams : {})
    };
    // eslint-disable-next-line
  }, [dateRange, dataParams]);

  const [isDataValidating, data, error, dataRevalidate] = useAxios(
    tokenRes?.token ? `${METABASE_API_ROOT}/api/embed/card/${tokenRes.token}/query` : null,
    { params }
  );

  return {
    data,
    token: tokenRes?.token,
    dataError: error || data?.error,
    tokenError,
    tokenRevalidate,
    dataRevalidate,
    isDataValidating,
    isTokenValidating
  };
};

export default useMetabase;
