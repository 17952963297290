import React from 'react';
import { Card } from 'antd';
import { useField } from 'formik';
import { formLayout } from 'styles';

import { HIDE_ON_PRODUCTION } from 'appConstants';

import FormItem from '../StyledFormItem';
import Input from '../StyledInput';

const TitleField = ({ name = 'og_title' }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  return (
    <FormItem
      label="Title"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Input allowClear placeholder="Please input your social media title" {...field} />
    </FormItem>
  );
};

const DescriptionField = ({ name = 'og_description' }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  return (
    <FormItem
      label="Description"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Input.TextArea
        placeholder="Please input your social media description"
        autoSize={{ minRows: 4, maxRows: 8 }}
        {...field}
      />
    </FormItem>
  );
};

const IOSLinkField = ({ name = 'al_ios_url' }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;
  return (
    <FormItem
      label="iOS Link"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Input allowClear placeholder="Type in your iOS link here" {...field} />
    </FormItem>
  );
};

const AndroidLinkField = ({ name = 'al_android_url' }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;
  return (
    <FormItem
      label="Android Link"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Input allowClear placeholder="Type in your Android link here" {...field} />
    </FormItem>
  );
};

const WebLinkField = ({ name = 'og_url' }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;
  return (
    <FormItem
      label="Website URL"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Input allowClear placeholder="Type in your website URL here" {...field} />
    </FormItem>
  );
};

const SocialMediaField = () => {
  if (HIDE_ON_PRODUCTION) return null;
  return (
    <Card title="Social Media" style={{ marginBottom: '1rem' }}>
      <TitleField />
      <DescriptionField />
      <IOSLinkField />
      <AndroidLinkField />
      <WebLinkField />
    </Card>
  );
};

export default SocialMediaField;
