import _random from 'lodash/random';
import moment from 'moment';

import { getRandomNumber } from 'containers/Analytics/utils';

const ageData = [
  {
    value: 51491,
    name: '-18'
  },
  {
    value: 44197,
    name: '18-24'
  },
  {
    value: 75546,
    name: '25-34'
  },
  {
    value: 64927,
    name: '35-44'
  },
  {
    value: 45749,
    name: '45-54'
  },
  {
    value: 14000,
    name: '56-64'
  },
  {
    value: 9989,
    name: '65+'
  },
  {
    value: 17003,
    name: 'Unknown'
  }
];

const genderData = [
  {
    value: 82794,
    name: 'Male'
  },
  {
    value: 56304,
    name: 'Female'
  },
  {
    value: 4000,
    name: 'Unknown'
  },
  {
    value: 2097,
    name: 'Unspecific'
  }
];

const userPlansData = [
  {
    value: 23000,
    name: 'postpaid'
  },
  {
    value: 28000,
    name: 'prepaid'
  },
  {
    value: 5000,
    name: 'others'
  }
];

const raceData = [
  { value: 1239246, name: 'Malay', color: '#40A9FF' },
  { value: 758643, name: 'Others', color: '#2665EE' },
  { value: 730515, name: 'Chinese', color: '#FFC53D' },
  { value: 184783, name: 'Indian', color: '#FF7A45' },
  { value: 36565, name: 'Unknown', color: '#36CFC9' }
];

const viewingChannelData = [
  { value: 34865869, name: 'loyalty', color: '#40A9FF' },
  { value: 21072983, name: 'campaigns', color: '#2665EE' },
  { value: 7268979, name: 'catalogs', color: '#FFC53D' },
  { value: 2785588, name: 'rewards', color: '#FF7A45' },
  { value: 1013320, name: 'search', color: '#36CFC9' },
  { value: 63146, name: 'merchants', color: '#9254DE' }
];

const topRewards = [
  {
    issue_count: 457830,
    reward_name: '1 Night Complimentary Stay',
    merchant: 'W Hotel Sentosa'
  },
  {
    issue_count: 328340,
    reward_name: 'Free N95 Mask',
    merchant: '3M'
  },
  {
    issue_count: 213279,
    reward_name: '10% off Buffet Menu',
    merchant: 'Fullerton Hotel'
  },
  {
    issue_count: 198076,
    reward_name: 'Complimentary Chewy Puffs',
    merchant: 'BeardPapa'
  },
  {
    issue_count: 99999,
    reward_name: '$30 Grocery Voucher',
    merchant: 'NTUC'
  }
];

const topMerchants = [
  {
    issue_count: 25854,
    reward_name: 'Wan Chai'
  },
  {
    issue_count: 19623,
    reward_name: 'Mathilde Bakery'
  },
  {
    issue_count: 11663,
    reward_name: 'Mei Speakerhouse'
  },
  {
    issue_count: 8939,
    reward_name: 'Guo Fu Hotpot'
  },
  {
    issue_count: 7267,
    reward_name: 'Ikea'
  }
];

const topCatalogues = [
  {
    issue_count: _random(50000, 80000),
    reward_name: '11.11 Offers'
  },
  {
    issue_count: _random(40000, 50000),
    reward_name: 'For Gadget Geeks'
  },
  {
    issue_count: _random(20000, 35000),
    reward_name: 'Christmas Catalogue'
  },
  {
    issue_count: _random(10000, 20000),
    reward_name: 'New Year Specials'
  },
  {
    issue_count: _random(5000, 10000),
    reward_name: 'Home Lifestyle'
  }
];

const stackedHistogramGenderData = [
  { count: 1606, tier: 'Yellow', attribute: 'male' },
  { count: 1543, tier: 'Business', attribute: 'male' },
  { count: 6234, tier: 'Gold', attribute: 'male' },
  { count: 1734, tier: 'Unknown', attribute: 'male' },
  { count: 3442, tier: 'Dealer', attribute: 'male' },
  { count: 3555, tier: 'Yellow', attribute: 'female' },
  { count: 8552, tier: 'Gold', attribute: 'female' },
  { count: 2213, tier: 'Unknown', attribute: 'female' },
  { count: 3231, tier: 'Business', attribute: 'female' },
  { count: 1878, tier: 'Dealer', attribute: 'female' },
  { count: 1098, tier: 'Platinum', attribute: 'female' },
  { count: 4224, tier: 'Yellow', attribute: 'Unspecific' },
  { count: 2444, tier: 'Business', attribute: 'Unspecific' },
  { count: 1420, tier: 'Dealer', attribute: 'Unspecific' },
  { count: 4987, tier: 'Unknown', attribute: 'Unspecific' },
  { count: 2509, tier: 'Business', attribute: 'Unknown' },
  { count: 1214, tier: 'Unknown', attribute: 'Unknown' },
  { count: 5710, tier: 'Yellow', attribute: 'Unknown' },
  { count: 2564, tier: 'Gold', attribute: 'Unknown' },
  { count: 4635, tier: 'Dealer', attribute: 'Unknown' }
];

const stackedHistogramAgeData = [
  { count: 514, tier: 'Yellow', attribute: '0-18' },
  { count: 123, tier: 'unknown', attribute: '0-18' },
  { count: 309, tier: 'Yellow', attribute: '18-24' },
  { count: 563, tier: 'Business', attribute: '18-24' },
  { count: 254, tier: 'Gold', attribute: '18-24' },
  { count: 238, tier: 'Dealer', attribute: '18-24' },
  { count: 365, tier: 'unknown', attribute: '18-24' },
  { count: 136, tier: 'Yellow', attribute: '25-34' },
  { count: 543, tier: 'Gold', attribute: '25-34' },
  { count: 745, tier: 'Dealer', attribute: '25-34' },
  { count: 270, tier: 'Business', attribute: '25-34' },
  { count: 823, tier: 'unknown', attribute: '25-34' },
  { count: 314, tier: 'Yellow', attribute: '35-44' },
  { count: 911, tier: 'Business', attribute: '35-44' },
  { count: 355, tier: 'Gold', attribute: '35-44' },
  { count: 385, tier: 'Dealer', attribute: '35-44' },
  { count: 126, tier: 'unknown', attribute: '35-44' },
  { count: 199, tier: 'Platinum', attribute: '35-44' },
  { count: 900, tier: 'Business', attribute: '45-54' },
  { count: 149, tier: 'Yellow', attribute: '45-54' },
  { count: 753, tier: 'Dealer', attribute: '45-54' },
  { count: 344, tier: 'unknown', attribute: '45-54' },
  { count: 243, tier: 'Gold', attribute: '45-54' },
  { count: 443, tier: 'Yellow', attribute: '56-64' },
  { count: 174, tier: 'Business', attribute: '56-64' },
  { count: 165, tier: 'Dealer', attribute: '56-64' },
  { count: 176, tier: 'unknown', attribute: '56-64' },
  { count: 643, tier: 'Dealer', attribute: '65+' },
  { count: 210, tier: 'Yellow', attribute: '65+' },
  { count: 545, tier: 'unknown', attribute: '65+' },
  { count: 237, tier: 'Gold', attribute: 'Unknown' },
  { count: 537, tier: 'Yellow', attribute: 'Unknown' },
  { count: 131, tier: 'Business', attribute: 'Unknown' },
  { count: 459, tier: 'Dealer', attribute: 'Unknown' },
  { count: 1123, tier: 'unknown', attribute: 'Unknown' }
];

const getStackedHistogramData = (type = 'age') => {
  const data = [];

  let attributes;
  switch (type) {
    case 'age':
      attributes = ['0-18', '18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'Unknown'];
      break;
    case 'gender':
      attributes = ['Male', 'Female', 'Unspecified', 'Unknown'];
      break;
    default:
      attributes = [];
  }

  attributes.forEach(attribute => {
    data.push({ count: _random(50, 100), tier: 'Engagements', attribute });
    data.push({ count: _random(0, 20), tier: 'Completions', attribute });
  });

  return data;
};

const stackedHistogramRaceData = [
  { count: 453, tier: 'unknown', attribute: 'Chinese' },
  { count: 234, tier: 'Gold', attribute: 'Chinese' },
  { count: 762, tier: 'Yellow', attribute: 'Chinese' },
  { count: 234, tier: 'Business', attribute: 'Chinese' },
  { count: 505, tier: 'Dealer', attribute: 'Chinese' },
  { count: 133, tier: 'Platinum', attribute: 'Chinese' },
  { count: 791, tier: 'Yellow', attribute: 'Indian' },
  { count: 864, tier: 'unknown', attribute: 'Indian' },
  { count: 135, tier: 'unknown', attribute: 'Malay' },
  { count: 599, tier: 'Yellow', attribute: 'Malay' },
  { count: 688, tier: 'Gold', attribute: 'Malay' },
  { count: 191, tier: 'Business', attribute: 'Malay' },
  { count: 784, tier: 'Dealer', attribute: 'Malay' },
  { count: 219, tier: 'Yellow', attribute: 'Others' },
  { count: 236, tier: 'Business', attribute: 'Others' },
  { count: 232, tier: 'Gold', attribute: 'Others' },
  { count: 402, tier: 'Dealer', attribute: 'Others' },
  { count: 347, tier: 'unknown', attribute: 'Others' },
  { count: 527, tier: 'Yellow', attribute: 'Unknown' },
  { count: 257, tier: 'Gold', attribute: 'Unknown' },
  { count: 161, tier: 'Business', attribute: 'Unknown' },
  { count: 1122, tier: 'unknown', attribute: 'Unknown' },
  { count: 435, tier: 'Dealer', attribute: 'Unknown' }
];

const getLineChartMembershipOverTimeData = (
  startDate,
  endDate,
  modifier = 1,
  datepart = 'days'
) => {
  const data = [];

  const loyaltyTiers = {
    Yellow: [50000, 100000],
    Gold: [25000, 50000],
    Platinum: [15000, 30000],
    Dealer: [10000, 25000],
    Business: [10000, 20000]
  };
  const randomMod = _random(Math.ceil(0.2 * modifier), modifier);
  Object.keys(loyaltyTiers).forEach(tier => {
    for (let m = moment(startDate); m.isBefore(endDate); m.add(1, datepart)) {
      data.push({
        user_count: _random(loyaltyTiers[tier][0] * randomMod, loyaltyTiers[tier][1] * randomMod),
        loyalty_tier: tier,
        time: m.toString()
      });
    }
  });

  return data;
};

const lineChartMembershipOverTimeData = [
  { user_count: 63032, loyalty_tier: 'Gold', time: '2020-03-22T00:00:00Z' },
  { user_count: 65928, loyalty_tier: 'Yellow', time: '2020-03-22T00:00:00Z' },
  { user_count: 57910, loyalty_tier: 'Dealer', time: '2020-03-22T00:00:00Z' },
  { user_count: 86403, loyalty_tier: 'Platinum', time: '2020-03-22T00:00:00Z' },
  { user_count: 65792, loyalty_tier: 'Business', time: '2020-03-22T00:00:00Z' },
  { user_count: 86447, loyalty_tier: 'Platinum', time: '2020-03-23T00:00:00Z' },
  { user_count: 67910, loyalty_tier: 'Dealer', time: '2020-03-23T00:00:00Z' },
  { user_count: 65411, loyalty_tier: 'Business', time: '2020-03-23T00:00:00Z' },
  { user_count: 69541, loyalty_tier: 'Gold', time: '2020-03-23T00:00:00Z' },
  { user_count: 71216, loyalty_tier: 'Yellow', time: '2020-03-23T00:00:00Z' },
  { user_count: 45995, loyalty_tier: 'Yellow', time: '2020-03-24T00:00:00Z' },
  { user_count: 65785, loyalty_tier: 'Business', time: '2020-03-24T00:00:00Z' },
  { user_count: 86588, loyalty_tier: 'Platinum', time: '2020-03-24T00:00:00Z' },
  { user_count: 69397, loyalty_tier: 'Gold', time: '2020-03-24T00:00:00Z' },
  { user_count: 57881, loyalty_tier: 'Dealer', time: '2020-03-24T00:00:00Z' },
  { user_count: 75760, loyalty_tier: 'Business', time: '2020-03-25T00:00:00Z' },
  { user_count: 95313, loyalty_tier: 'Yellow', time: '2020-03-25T00:00:00Z' },
  { user_count: 57883, loyalty_tier: 'Dealer', time: '2020-03-25T00:00:00Z' },
  { user_count: 86652, loyalty_tier: 'Platinum', time: '2020-03-25T00:00:00Z' },
  { user_count: 69561, loyalty_tier: 'Gold', time: '2020-03-25T00:00:00Z' },
  { user_count: 57895, loyalty_tier: 'Dealer', time: '2020-03-26T00:00:00Z' },
  { user_count: 65752, loyalty_tier: 'Business', time: '2020-03-26T00:00:00Z' },
  { user_count: 79650, loyalty_tier: 'Gold', time: '2020-03-26T00:00:00Z' },
  { user_count: 85613, loyalty_tier: 'Yellow', time: '2020-03-26T00:00:00Z' },
  { user_count: 86823, loyalty_tier: 'Platinum', time: '2020-03-26T00:00:00Z' },
  { user_count: 55621, loyalty_tier: 'Yellow', time: '2020-03-27T00:00:00Z' },
  { user_count: 69707, loyalty_tier: 'Gold', time: '2020-03-27T00:00:00Z' },
  { user_count: 86905, loyalty_tier: 'Platinum', time: '2020-03-27T00:00:00Z' },
  { user_count: 57928, loyalty_tier: 'Dealer', time: '2020-03-27T00:00:00Z' },
  { user_count: 75154, loyalty_tier: 'Business', time: '2020-03-27T00:00:00Z' },
  { user_count: 86909, loyalty_tier: 'Platinum', time: '2020-03-28T00:00:00Z' },
  { user_count: 88474, loyalty_tier: 'Gold', time: '2020-03-28T00:00:00Z' },
  { user_count: 45818, loyalty_tier: 'Business', time: '2020-03-28T00:00:00Z' },
  { user_count: 74941, loyalty_tier: 'Yellow', time: '2020-03-28T00:00:00Z' },
  { user_count: 57928, loyalty_tier: 'Dealer', time: '2020-03-28T00:00:00Z' },
  { user_count: 99774, loyalty_tier: 'Gold', time: '2020-03-29T00:00:00Z' },
  { user_count: 86959, loyalty_tier: 'Platinum', time: '2020-03-29T00:00:00Z' },
  { user_count: 57930, loyalty_tier: 'Dealer', time: '2020-03-29T00:00:00Z' },
  { user_count: 65824, loyalty_tier: 'Business', time: '2020-03-29T00:00:00Z' },
  { user_count: 84428, loyalty_tier: 'Yellow', time: '2020-03-29T00:00:00Z' },
  { user_count: 57930, loyalty_tier: 'Dealer', time: '2020-03-30T00:00:00Z' },
  { user_count: 87028, loyalty_tier: 'Platinum', time: '2020-03-30T00:00:00Z' },
  { user_count: 89967, loyalty_tier: 'Gold', time: '2020-03-30T00:00:00Z' },
  { user_count: 94581, loyalty_tier: 'Yellow', time: '2020-03-30T00:00:00Z' },
  { user_count: 55840, loyalty_tier: 'Business', time: '2020-03-30T00:00:00Z' },
  { user_count: 65821, loyalty_tier: 'Business', time: '2020-03-31T00:00:00Z' },
  { user_count: 57930, loyalty_tier: 'Dealer', time: '2020-03-31T00:00:00Z' },
  { user_count: 89736, loyalty_tier: 'Gold', time: '2020-03-31T00:00:00Z' },
  { user_count: 34376, loyalty_tier: 'Yellow', time: '2020-03-31T00:00:00Z' },
  { user_count: 87052, loyalty_tier: 'Platinum', time: '2020-03-31T00:00:00Z' },
  { user_count: 57936, loyalty_tier: 'Dealer', time: '2020-04-01T00:00:00Z' },
  { user_count: 87344, loyalty_tier: 'Platinum', time: '2020-04-01T00:00:00Z' },
  { user_count: 79775, loyalty_tier: 'Gold', time: '2020-04-01T00:00:00Z' },
  { user_count: 75845, loyalty_tier: 'Business', time: '2020-04-01T00:00:00Z' },
  { user_count: 24138, loyalty_tier: 'Yellow', time: '2020-04-01T00:00:00Z' },
  { user_count: 57938, loyalty_tier: 'Dealer', time: '2020-04-02T00:00:00Z' },
  { user_count: 87396, loyalty_tier: 'Platinum', time: '2020-04-02T00:00:00Z' },
  { user_count: 89751, loyalty_tier: 'Gold', time: '2020-04-02T00:00:00Z' },
  { user_count: 44174, loyalty_tier: 'Yellow', time: '2020-04-02T00:00:00Z' },
  { user_count: 75879, loyalty_tier: 'Business', time: '2020-04-02T00:00:00Z' },
  { user_count: 87230, loyalty_tier: 'Platinum', time: '2020-04-03T00:00:00Z' },
  { user_count: 89075, loyalty_tier: 'Gold', time: '2020-04-03T00:00:00Z' },
  { user_count: 65606, loyalty_tier: 'Business', time: '2020-04-03T00:00:00Z' },
  { user_count: 57859, loyalty_tier: 'Dealer', time: '2020-04-03T00:00:00Z' },
  { user_count: 73728, loyalty_tier: 'Yellow', time: '2020-04-03T00:00:00Z' },
  { user_count: 75758, loyalty_tier: 'Business', time: '2020-04-04T00:00:00Z' },
  { user_count: 89232, loyalty_tier: 'Gold', time: '2020-04-04T00:00:00Z' },
  { user_count: 97864, loyalty_tier: 'Dealer', time: '2020-04-04T00:00:00Z' },
  { user_count: 87285, loyalty_tier: 'Platinum', time: '2020-04-04T00:00:00Z' },
  { user_count: 73990, loyalty_tier: 'Yellow', time: '2020-04-04T00:00:00Z' },
  { user_count: 57864, loyalty_tier: 'Dealer', time: '2020-04-05T00:00:00Z' },
  { user_count: 73111, loyalty_tier: 'Yellow', time: '2020-04-05T00:00:00Z' },
  { user_count: 87339, loyalty_tier: 'Platinum', time: '2020-04-05T00:00:00Z' },
  { user_count: 65783, loyalty_tier: 'Business', time: '2020-04-05T00:00:00Z' },
  { user_count: 99398, loyalty_tier: 'Gold', time: '2020-04-05T00:00:00Z' }
];

const tableLoyaltyEarnedBurnedPointsPerTier = {
  data: {
    rows: [
      ['Selangor', 823768, 34715283, 82768],
      ['unknown', 848278, 24907613, 823768],
      ['Sabah', 501318, 15253314, 823768],
      ['Johor', 314546, 12841804, 823768],
      ['Sarawak', 250969, 9741960, 8268],
      ['Perak', 248832, 8852616, 823768],
      ['Penang', 149502, 5811876, 823768],
      ['UNKNOWN-UNKNOWN', 105991, 5586685, 823768],
      ['Pahang', 124809, 4413474, 823768],
      ['Kedah', 136225, 4358599, 823768],
      ['Negeri Sembilan', 92213, 3407840, 823768],
      ['Melaka', 89134, 3405804, 0],
      ['Terenganu', 82453, 2586265, 0],
      ['NO USAGE-NO USAGE', 18302, 2332246, 0],
      ['Kelantan', 79124, 2295456, 0],
      ['Perlis', 16467, 501042, 0],
      ['0-0', 4735, 187872, 0]
    ]
  }
};

const histogramLoyaltyPrimaryCluster = {
  data: {
    rows: [
      [30, 'B021'],
      [30, 'T004'],
      [15, 'unknown'],
      [10, 'B012'],
      [10, 'S034']
    ]
  }
};

const histogramLoyaltyPrimaryState = {
  data: {
    rows: [
      [30, 'B021'],
      [30, 'T004'],
      [15, 'unknown'],
      [10, 'B012'],
      [10, 'S034']
    ]
  }
};

const loyaltyMembershipDistribution = [
  {
    current_user_count: 45792,
    previous_user_count: 39994,
    loyalty_tier: 'Gold'
  },
  {
    current_user_count: 57864,
    previous_user_count: 89938,
    loyalty_tier: 'Platinum'
  },
  {
    current_user_count: 5000,
    previous_user_count: 5000,
    loyalty_tier: 'Yellow'
  },
  {
    current_user_count: 0,
    previous_user_count: 50000,
    loyalty_tier: 'Yellow'
  }
];

const stackedHistogramLoyaltyPrimaryState = [
  { count: 1623, tier: 'Business', attribute: 'Johor' },
  { count: 199, tier: 'Dealer', attribute: 'Johor' },
  { count: 3664, tier: 'Gold', attribute: 'Johor' },
  { count: 5742, tier: 'N/A', attribute: 'Johor' },
  { count: 64, tier: 'Platinum', attribute: 'Johor' },
  { count: 1151, tier: 'Yellow', attribute: 'Johor' },
  { count: 86, tier: 'Business', attribute: 'Sabah' },
  { count: 18, tier: 'Dealer', attribute: 'Sabah' },
  { count: 3504, tier: 'Gold', attribute: 'Sabah' },
  { count: 4202, tier: 'N/A', attribute: 'Sabah' },
  { count: 39, tier: 'Platinum', attribute: 'Sabah' },
  { count: 14722, tier: 'Yellow', attribute: 'Sabah' },
  { count: 5553, tier: 'Business', attribute: 'Sarawak' },
  { count: 6555, tier: 'Dealer', attribute: 'Sarawak' },
  { count: 2431, tier: 'Gold', attribute: 'Sarawak' },
  { count: 15900, tier: 'N/A', attribute: 'Sarawak' },
  { count: 18, tier: 'Platinum', attribute: 'Sarawak' },
  { count: 8544, tier: 'Yellow', attribute: 'Sarawak' },
  { count: 8751, tier: 'Business', attribute: 'Selangor' },
  { count: 647, tier: 'Dealer', attribute: 'Selangor' },
  { count: 10563, tier: 'Gold', attribute: 'Selangor' },
  { count: 19379, tier: 'N/A', attribute: 'Selangor' },
  { count: 1942, tier: 'Platinum', attribute: 'Selangor' },
  { count: 34390, tier: 'Yellow', attribute: 'Selangor' },
  { count: 5479, tier: 'Business', attribute: 'unknown' },
  { count: 347, tier: 'Dealer', attribute: 'unknown' },
  { count: 6244, tier: 'Gold', attribute: 'unknown' },
  { count: 3110, tier: 'N/A', attribute: 'unknown' },
  { count: 1448, tier: 'Platinum', attribute: 'unknown' },
  { count: 36614, tier: 'Yellow', attribute: 'unknown' }
];

const stackedHistogramUserPlan = [
  { count: 249, tier: 'Prepaid', attribute: 'Unknown' },
  { count: 753, tier: 'Prepaid', attribute: 'Gold' },
  { count: 255, tier: 'Prepaid', attribute: 'Yellow' },
  { count: 159, tier: 'Postpaid', attribute: 'Dealer' },
  { count: 141, tier: 'Postpaid', attribute: 'Business' },
  { count: 138, tier: 'Postpaid', attribute: 'Yellow' },
  { count: 240, tier: 'Postpaid', attribute: 'Unknown' },
  { count: 738, tier: 'Postpaid', attribute: 'Gold' },
  { count: 644, tier: 'Others', attribute: 'Unknown' },
  { count: 416, tier: 'Others', attribute: 'Yellow' },
  { count: 339, tier: 'Others', attribute: 'Business' }
];

const loyaltyPointsOverTime = [
  { point_earned: 26102, point_burned: 24542, time: '2020-03-24T00:00:00Z' },
  { point_earned: 71489, point_burned: 56475, time: '2020-03-25T00:00:00Z' },
  { point_earned: 31467, point_burned: 34455, time: '2020-03-26T00:00:00Z' },
  { point_earned: 82547, point_burned: 87856, time: '2020-03-27T00:00:00Z' },
  { point_earned: 67468, point_burned: 53243, time: '2020-03-28T00:00:00Z' },
  { point_earned: 87248, point_burned: 87162, time: '2020-03-29T00:00:00Z' }
];

const tablePrimaryClusters = {
  data: {
    rows: [
      [477917, 'Boat Quay'],
      [451783, 'Chinatown'],
      [440790, 'Havelock Road'],
      [423729, 'Marina Square'],
      [402054, 'Raffles Place'],
      [387307, 'Suntec City'],
      [387049, 'Anson Road'],
      [375902, 'Chinatown'],
      [375379, 'Neil Road']
    ]
  }
};

const getEngagementCompletionData = () => {
  const locations = [
    ['Boat Quay'],
    ['Chinatown'],
    ['Havelock Road'],
    ['Marina Square'],
    ['Raffles Place'],
    ['Suntec City'],
    ['Anson Road'],
    ['Chinatown'],
    ['Neil Road']
  ];

  const rows = [];

  locations.forEach(l => {
    const engagements = _random(500, 3000);
    const completions = Math.round(engagements * (_random(10, 30) / 100));

    rows.push([engagements, completions, l[0]]);
  });

  const sortedRows = rows.sort(function (a, b) {
    return b[1] - a[1];
  });

  return {
    data: {
      rows: sortedRows
    }
  };
};

const tableCampaignEngagementRate = (removeIdColumn = false) => {
  const campaigns = [
    [477917, 'Complete and Win'],
    [451783, '11.11 Event'],
    [440790, '9.9 Event'],
    [423729, 'Campaign Name'],
    [402054, 'Be the first to win'],
    [387307, 'Another Campaign'],
    [387049, 'Christmas Event Campaign']
  ];

  const rows = [];

  campaigns.forEach(c => {
    const baseOpens = _random(40000, 60000);
    const baseReach = _random(120000, 180000);
    const baseUsers = Math.round(baseOpens * 0.65);
    const baseUnique = Math.round(baseUsers * 0.8);
    const baseIssued = Math.round(baseUsers * (_random(50, 70) / 100));
    const baseCompleted = Math.round(baseIssued * (_random(30, 70) / 100));
    const baseEngagement = (baseIssued / baseReach) * 100;
    const baseCompleteRate = baseIssued / baseCompleted;

    rows.push([
      ...(removeIdColumn ? [c[1]] : [...c]),
      baseOpens,
      baseReach,
      baseUsers,
      baseUnique,
      baseIssued,
      baseCompleted,
      baseEngagement,
      baseCompleteRate
    ]);
  });

  const sortedRow = rows.sort(function (a, b) {
    return b[8] - a[8];
  });

  return {
    data: {
      rows: sortedRow
    }
  };
};

const tableCampaignOverview = {
  data: {
    rows: [
      ['LMS Quiz Campaign', 'game', 709, 0],
      ['Quiz_campaign', 'game', 646, 1],
      ['Quiz with rule enhancement', 'game', 634, 1],
      ['Quiz test', 'game', 634, 3],
      ['Quiz with outcomes', 'game', 633, 1],
      ['Stamp with a dissapearing rule', 'stamp', 529, 1],
      ['Microsite_check_quest_campaign', 'quest', 375, 0],
      ['test_stamp_1', 'stamp', 365, 1],
      ['test survey ed', 'game', 349, 1],
      ['Quest 01', 'quest', 342, 0],
      ['Stamp_Campaign_ThaoPP', 'stamp', 336, 1],
      ['Game_campaign', 'game', 292, 1],
      ['Game_ThaoPP', 'game', 291, 2],
      ['Spin the wheel disappearing rule', 'game', 289, 0],
      ['Game_ThaoPP', 'game', 273, 0]
    ]
  }
};

const tableTopMerchants = {
  data: {
    rows: [
      ['Subway', 67235.48999999807, 33617.744999999035, 'MYR', 2, 17515, 10181],
      ['Deliveroo', 51058, 10211.6, 'MYR', 5, 6826, 0],
      ['Din Tai Fung', 32152.20999999975, 6430.44199999995, 'MYR', 5, 7083, 0],
      ['KFC', 16833.31999999981, 8416.659999999905, 'MYR', 2, 2701, 679],
      ['Lazada', 16489.41000000002, 16489.41000000002, 'MYR', 1, 2418, 0],
      ['Starbucks', 8627.140000000007, 1725.4280000000012, 'MYR', 5, 1031, 695],
      ['Ghee Hiang', 6064.349999999992, 2021.4499999999973, 'MYR', 3, 319, 1],
      ['Burger King', 3649.0000000000005, 3649.0000000000005, 'MYR', 1, 455, 272],
      ['The Coffee Bean & Tea Leaf', 2723.340000000001, 453.89000000000016, 'MYR', 6, 169, 8],
      ['Pizza Hut Delivery', 2247.75, 1123.875, 'MYR', 2, 972, 0],
      ['VitaHealth', 2109.3999999999996, 2109.3999999999996, 'MYR', 1, 106, 0],
      ['Tiger Sugar', 1812.2500000000005, 453.0625000000001, 'MYR', 4, 190, 70],
      ["Dunkin'", 1574.7, 314.94, 'MYR', 5, 281, 166],
      ['Baskin-Robbins', 1545.4199999999998, 386.35499999999996, 'MYR', 4, 496, 177],
      ['BIGbox ASIA', 1538.9000000000003, 1538.9000000000003, 'MYR', 1, 110, 0],
      ['Manhattan FISH MARKET', 1260.0300000000004, 630.0150000000002, 'MYR', 2, 178, 62],
      ['Inside Scoop', 1230.4600000000007, 615.2300000000004, 'MYR', 2, 314, 131],
      ['Mydin', 1087.7900000000002, 1087.7900000000002, 'MYR', 1, 191, 51],
      ["Mich'sology", 1042.2600000000002, 1042.2600000000002, 'MYR', 1, 179, 2],
      ['GSC', 951.3200000000003, 951.3200000000003, 'MYR', 1, 68, 0],
      ['YEES Yogurt', 863.0400000000004, 863.0400000000004, 'MYR', 1, 110, 37],
      ['Mat Toh Yau', 799.220000000001, 799.220000000001, 'MYR', 1, 178, 0],
      ['Kenny Rogers ROASTERS', 755.16, 377.58, 'MYR', 2, 139, 64],
      ['TGV Cinemas', 741.4700000000003, 741.4700000000003, 'MYR', 1, 53, 0],
      ['Croissant Taiyaki', 682.8600000000004, 682.8600000000004, 'MYR', 1, 114, 0],
      ['Pin Tea', 657.06, 657.06, 'MYR', 1, 151, 65],
      ['Pixajoy', 635.4999999999998, 105.91666666666663, 'MYR', 6, 1411, 0],
      ['BookXcess Online', 598.8000000000001, 598.8000000000001, 'MYR', 1, 121, 0],
      ['llaollao', 552.2100000000002, 276.1050000000001, 'MYR', 2, 104, 23],
      ['dahmakan', 380, 126.66666666666667, 'MYR', 3, 556, 0]
    ]
  }
};

const vsGraphData = [
  {
    name: 'users',
    new: 432,
    returning: 205,
    total: 637
  }
];

const getMerchantOverviewData = (startDate, endDate, tab, datepart = 'days') => {
  let ranges = [8000, 15000];
  switch (tab) {
    case 'views':
      ranges = [3500, 10500];
      break;
    case 'rewards':
      ranges = [6500, 25000];
      break;
    case 'revenue':
      ranges = [45000, 120000];
      break;
    default:
      ranges = [8000, 15000];
  }

  const data = [];

  for (let m = moment(startDate); m.isBefore(endDate); m.add(1, datepart)) {
    data.push([_random(ranges[0], ranges[1]), m.format()]);
  }
  return { data: { rows: data } };
};

function getTop5Data(resource) {
  if (resource === 'merchants') return topMerchants;
  else if (resource === 'rewards') return topRewards;
  else if (resource === 'catalogs') return topCatalogues;
  else return topRewards;
}

function getPieData(resource) {
  if (resource.includes('race')) return raceData;
  else if (resource.includes('viewing channel')) return viewingChannelData;
  else return raceData;
}

function getDonutData(resource) {
  if (resource.includes('user plans')) return userPlansData;
  else if (resource.includes('gender')) return genderData;
  return genderData;
}

const getDemographicsData = identifier => {
  if (identifier.includes('race')) return raceData;
  if (identifier.includes('gender')) return genderData;
  if (identifier.includes('age')) return ageData;

  return raceData;
};

function getHeatMapData(resource) {
  const data = [];

  for (let x = 0; x < 7; x++) {
    let day = x;

    for (let y = 0; y < 24; y++) {
      let value = getRandomNumber(10000, 120000);
      let time = y;

      data.push([value, day, time]);
    }
  }

  return data;
}

function getAnalyticsCardData(resource) {
  if (resource.includes('total revenue'))
    return [_random(700000, 1000000), _random(500000, 650000), 'SGD'];
  else if (resource.includes('avg. revenue per user'))
    return [_random(50, 100), _random(30, 50), 'SGD'];
  else if (resource.includes('avg. revenue per reward')) return [1035, 900, 'SGD'];
  else if (resource.includes('total users')) return [13709283, 12571113, null];
  else if (resource.includes('total active users')) return [452991, 345123, null];
  else if (resource.includes('total reward revenue')) return [468491.37, 460478.54, 'SGD'];
  else if (resource.includes('total views')) return [348381, 318556, null];
  else if (resource.includes('cost of issued rewards')) return [21000, null, 'SGD'];
  else if (resource.includes('no. of rewards issued')) return [21000];
  else if (resource.includes('rewards issued')) return [361282, 303005, null];
  else if (resource.includes('rewards redeemed')) return [80976, 71860, null];
  else if (resource.includes('redemption rate')) return [22.41, 23.72];
  else if (resource.includes('avg. merchant revenue')) return [1223, 699, 'SGD'];
  else if (resource.includes('avg. revenue per rewards')) return [1035, 1034, 'SGD'];
  else if (resource.includes('total merchant view rate')) return [77.11, 60, null];
  // NL useless as there is the same condition a few lines before
  // else if (resource.includes('redemption rate')) return [21.24, 36.74, null];
  else if (resource.includes('conversion rate')) return [77.11, 60, null];
  else if (resource.includes('customer engagements')) return [21.24];
  else if (resource.includes('top-line growth')) return [21000, null, 'SGD'];
  //default case
  return [10000, 5000, null];
}

function getOverviewBreakdownData(startDate, endDate, modifier = 1, datepart = 'days') {
  const data = [];
  const randomMod = _random(Math.ceil(0.2 * modifier), modifier);
  for (let m = moment(startDate); m.isBefore(endDate); m.add(1, datepart)) {
    data.push({ value: _random(20000 * randomMod, 25000 * randomMod), date: m.toString() });
  }
  return data;
}

function getMapData() {
  return [
    [4.3437625, 113.990869],
    [1.527481, 110.337245],
    [1.9100471, 113.6334138],
    [3.166689, 101.693227],
    [1.5431157, 103.5599741],
    [5.39663336, 100.29046356],
    [3.126892, 101.7228513],
    [2.3983251, 102.2186191],
    [3.1612634, 101.7369893],
    [1.8574785, 102.9327877],
    [6.1174907, 102.2384468],
    [3.818902, 103.326298],
    [1.5153108, 103.6857149],
    [5.39877, 100.3980329],
    [2.9856115, 101.7810392],
    [3.1026996, 101.5975805],
    [3.075733, 101.5850884],
    [3.1579395, 101.7491648],
    [3.1991263, 101.7368473],
    [3.2022202, 101.7317566],
    [3.0640409, 101.4510628],
    [3.1279552, 101.6889787],
    [1.4955448, 103.703906],
    [4595920.0, 101.090095],
    [1527785.0, 110.337279],
    [1509782.0, 110.336024],
    [4.8557459, 100.7305761],
    [2.2195191, 102.2342488],
    [4.677393, 101.1204969],
    [6.1179902, 102.2320897],
    [1.7551349, 102.657591],
    [2.290085, 111.829219]
    // [ 37.5665, 126.978],
  ];
}

function getTableData() {
  return [
    { id: 1, name: 'Stockholm, Sweden', views: 90915 },
    { id: 2, name: 'Siem Reap, Cambodia', views: 82237 },
    { id: 3, name: 'Lapland, Finland', views: 43923 },
    { id: 4, name: 'Tokyo, Japan', views: 38873 },
    { id: 5, name: 'Skövde, Sweden', views: 20122 }
  ];
}

function getTopMerchantsData() {
  return [
    { id: 1, name: 'IKEA', total_revenue: 29383819, currency: 'EUR', total_rewards: 1232394 },
    { id: 2, name: 'Toyota', total_revenue: 2658100, currency: 'JPY', total_rewards: 1100624 },
    {
      id: 3,
      name: 'Louis Vuitton',
      total_revenue: 1599000,
      currency: 'USD',
      total_rewards: 100092
    },
    { id: 4, name: 'Ooredoo', total_revenue: 897899, currency: 'USD', total_rewards: 78903 },
    { id: 5, name: 'Netflix', total_revenue: 120900, currency: 'USD', total_rewards: 52340 }
  ];
}

export function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getDummyFunnelChart(startDate, endDate, modifier = 1, datepart = 'days') {
  const mockData = getOverviewBreakdownData(startDate, endDate, modifier, datepart);
  return mockData
    .map(item => ({
      ...item,
      // name: `Reward ${randomNumber(1, 100)}`,
      // name: item.value,
      fill: getRandomColor()
    }))
    .sort((a, b) => b.value - a.value);
}

export const funnelChartDefaultColor = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c'];

export const getDefaultFunnelChartData = () => {
  return [
    {
      value: 145,
      name: 'Views'
    },
    {
      value: 21,
      name: 'Released'
    },
    {
      value: 12,
      name: 'Issued'
    },
    {
      value: 10,
      name: 'Redeemed'
    }
  ];
};

const pieChartData = [];
export {
  ageData,
  getTop5Data,
  pieChartData,
  genderData,
  viewingChannelData,
  raceData,
  stackedHistogramAgeData,
  stackedHistogramGenderData,
  stackedHistogramRaceData,
  lineChartMembershipOverTimeData,
  getLineChartMembershipOverTimeData,
  histogramLoyaltyPrimaryCluster,
  histogramLoyaltyPrimaryState,
  loyaltyMembershipDistribution,
  loyaltyPointsOverTime,
  stackedHistogramLoyaltyPrimaryState,
  stackedHistogramUserPlan,
  tableTopMerchants,
  tableCampaignOverview,
  tableLoyaltyEarnedBurnedPointsPerTier,
  tablePrimaryClusters,
  tableCampaignEngagementRate,
  vsGraphData,
  getMerchantOverviewData,
  getAnalyticsCardData,
  getPieData,
  getDonutData,
  getHeatMapData,
  getOverviewBreakdownData,
  getMapData,
  getTableData,
  getTopMerchantsData,
  getEngagementCompletionData,
  getStackedHistogramData,
  getDummyFunnelChart,
  getDemographicsData
};
