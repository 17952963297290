import { useEffect, useReducer, useState } from 'react';
import produce from 'immer';
import hash from 'object-hash';

interface UseAxiosState<T> {
  loading: boolean;
  response: T | null;
  error: any;
}

interface UseAxiosAction<T> {
  type: actions;
  payload?: T | any | null;
}

enum actions {
  REQUEST_START = 'REQUEST_START',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  REQUEST_FAILURE = 'REQUEST_FAILURE'
}

const reducer = <T>(state: UseAxiosState<T>, action: UseAxiosAction<T>): UseAxiosState<T> => {
  switch (action.type) {
    case actions.REQUEST_START:
      return produce(state, draft => {
        draft.loading = true;
      });
    case actions.REQUEST_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.response = action.payload;
        draft.error = null;
      });
    case actions.REQUEST_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = action.payload;
        draft.response = null;
      });
    default:
      return state;
  }
};

const useAxiosMock = <T>(
  url: string,
  simulatedResponse: T,
  delaySimulate: number = 1000,
  trigger?
): [boolean, T | null, any, () => void] => {
  const [innerTrigger, setInnerTrigger] = useState(0);
  const reduce: (prevState: UseAxiosState<T>, action: UseAxiosAction<T>) => UseAxiosState<T> =
    reducer;
  const [state, dispatch] = useReducer(reduce, {
    loading: false,
    response: null,
    error: null
  });
  const simulatedResponseHash = hash(simulatedResponse);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: actions.REQUEST_START });

      setTimeout(() => {
        if (url === 'error') {
          dispatch({ type: actions.REQUEST_FAILURE, payload: 'Simulated Error' });
        } else {
          dispatch({ type: actions.REQUEST_SUCCESS, payload: simulatedResponse });
        }
      }, delaySimulate); // Network delay configured
    };

    if (url) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, url, innerTrigger, delaySimulate, simulatedResponseHash]);

  return [
    state.loading,
    state.response,
    state.error,
    () => {
      setInnerTrigger(+new Date());
    }
  ];
};

export default useAxiosMock;
