import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import styled from 'styled-components';

import { colors } from 'assets/styles/commonStyle';

interface CustomFormItemProps extends FormItemProps {
  margin?: string;
}

const StyledFormItem = styled(Form.Item)<CustomFormItemProps>`
  &&& {
    margin: ${({ margin }) => (margin ? margin : '')};

    .ant-form-item-label {
      white-space: normal;
      color: ${colors.colorGrayDark};
      font-weight: 500;
      label {
        display: block;
        margin-right: 8px;
        line-height: 22px;
        &::after {
          content: none;
        }

        @media (min-width: 1600px) {
          padding-top: 8px;
        }
      }
      &:after {
        content: none !important;
      }
    }
  }
`;

StyledFormItem.defaultProps = {
  labelAlign: 'left',
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 }
};

export default StyledFormItem;
