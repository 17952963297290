import { Col } from 'antd';
import styled from 'styled-components';

export const Cards = styled(Col)`
  .ant-card {
    margin-bottom: 16px;
  }
`;
export const CardHeader = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
  margin-left: 16px;
`;
export const SectionHeader = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
  margin-top: 20px;
`;
