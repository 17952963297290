import React from 'react';
import { useFormikContext, useField } from 'formik';
import { formLayout } from 'styles';

import FormItem from 'components/Form/StyledFormItem';
import TagSelect from 'components/selects/TagSelect';

interface Props {
  label?: string;
  name?: string;
  isUseFormLayout?: boolean;
  dataTestid?: string;
}

const TagsField = ({
  label = 'Tags',
  name = 'tags',
  isUseFormLayout = true,
  dataTestid
}: Props) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onValueChange = val => formik.setFieldValue(field.name, val);

  return (
    <FormItem
      label={label}
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...(isUseFormLayout ? formLayout : {})}
      data-testid={dataTestid}
    >
      <TagSelect
        mode="multiple"
        showArrow
        value={field.value}
        placeholder="Please select your merchant tags"
        onChange={onValueChange}
        style={{ width: '100%' }}
      />
    </FormItem>
  );
};

export default TagsField;
