import React from 'react';
import { Spin } from 'antd';

import './loading.css';

export const DashboardLoading = props => {
  return (
    <div>
      <div className="placeholder placeholder--dark placeholder-tilebar" />
      <div style={{ clear: `both` }} />
      <div className="flex-container">
        <div className="sidebar">
          <div className="placeholder-icon placeholder--dark" />
          <div className="placeholder-icon placeholder--dark" />
          <div className="placeholder-icon placeholder--dark" />
          <div className="placeholder-icon placeholder--dark" />
          <div className="placeholder-icon placeholder--dark" />
          <div className="placeholder-icon placeholder--dark" />
        </div>
        <div className="right-pane">
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
        </div>
      </div>
    </div>
  );
};

export const StepsLoading = props => {
  return (
    <div>
      <div className="placeholder placeholder--dark placeholder-tilebar" />
      <div style={{ clear: `both` }} />
      <div className="flex-container">
        <div className="right-pane">
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
          <div className="placeholder placeholder--dark placeholder-short" />
          <div className="placeholder placeholder--dark" />
          <div className="placeholder placeholder--dark placeholder-card" />
        </div>
      </div>
    </div>
  );
};

export const PageLoading = props => {
  return (
    <div className="page-loading">
      <div className="page-loading__page-header" />
      <div className="page-loading__content">
        <Spin className="page-loading__spinner" size="large" />
      </div>
    </div>
  );
};
