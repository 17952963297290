import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Setting';

export const AMEND_AUDIENCE = `${PROJECT_NAME}/${CONTAINER_NAME}/AMEND_AUDIENCE`;
export const AMEND_AUDIENCE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/AMEND_AUDIENCE_SUCCESS`;
export const AMEND_AUDIENCE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/AMEND_AUDIENCE_FAIL`;
export const SET_AUDIENCE_ID = `${PROJECT_NAME}/${CONTAINER_NAME}/SET_AUDIENCE_ID`;

export const GET_ROLES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_ROLES`;
export const GET_ROLES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_ROLES_SUCCESS`;
export const GET_ROLES_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_ROLES_FAILED`;

export const GET_USERS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USERS`;
export const GET_USERS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USERS_SUCCESS`;
export const GET_USERS_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USERS_FAILED`;

export const GET_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USER`;
export const GET_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USER_SUCCESS`;
export const GET_USER_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_USER_FAILED`;

export const USER_CREATE = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_CREATE`;
export const USER_CREATE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_CREATE_SUCCESS`;
export const USER_CREATE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_CREATE_FAIL`;

export const USER_UPDATE = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_UPDATE`;
export const USER_UPDATE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_UPDATE_SUCCESS`;
export const USER_UPDATE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/USER_UPDATE_FAIL`;

export const DELETE_ROLE = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ROLE`;
export const DELETE_ROLE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ROLE_SUCCESS`;
export const DELETE_ROLE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ROLE_FAIL`;

export const ACTIVATE_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER`;
export const ACTIVATE_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER_SUCCESS`;
export const ACTIVATE_USER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER_FAIL`;

export const DEACTIVATE_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER`;
export const DEACTIVATE_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER_SUCCESS`;
export const DEACTIVATE_USER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER_FAIL`;

export const GET_FTP_FILE_LIST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_FTP_FILE_LIST`;
export const GET_FTP_FILE_LIST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_FTP_FILE_LIST_SUCCESS`;
export const GET_FTP_FILE_LIST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_FTP_FILE_LIST_FAIL`;

export const GET_LABELS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LABELS`;
export const GET_LABELS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LABELS_SUCCESS`;
export const GET_LABELS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LABELS_FAIL`;

export const DELETE_LABELS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_LABELS`;
export const DELETE_LABELS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_LABELS_SUCCESS`;
export const DELETE_LABELS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_LABELS_FAIL`;

export const CREATE_LABEL = 'CREATE_LABEL';
export const CREATE_LABEL_SUCCESS = 'CREATE_LABEL_SUCCESS';
export const CREATE_LABEL_FAIL = 'CREATE_LABEL_FAIL';

export const UPDATE_LABEL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_LABEL`;
export const UPDATE_LABEL_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_LABEL_SUCCESS`;
export const UPDATE_LABEL_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_LABEL_FAIL`;

export const DEACTIVATE_USER_GROUP = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER_GROUP`;
export const DEACTIVATE_USER_GROUP_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER_GROUP_SUCCESS`;
export const DEACTIVATE_USER_GROUP_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_USER_GROUP_FAILED`;

export const ACTIVATE_USER_GROUP = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER_GROUP`;
export const ACTIVATE_USER_GROUP_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER_GROUP_SUCCESS`;
export const ACTIVATE_USER_GROUP_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_USER_GROUP_FAILED`;
export const DEACTIVATE_ROLE = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_ROLE`;
export const DEACTIVATE_ROLE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_ROLE_SUCCESS`;
export const DEACTIVATE_ROLE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_ROLE_FAIL`;

export const UNLOCK_USER = `${PROJECT_NAME}/${CONTAINER_NAME}/UNLOCK_USER`;
export const UNLOCK_USER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UNLOCK_USER_SUCCESS`;
export const UNLOCK_USER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UNLOCK_USER_FAILED`;

export const ACTIVATE_ROLE = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_ROLE`;
export const ACTIVATE_ROLE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_ROLE_SUCCESS`;
export const ACTIVATE_ROLE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_ROLE_FAIL`;
