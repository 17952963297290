import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { TableProps } from 'antd/lib/table';
import styled from 'styled-components';

import AnalyticsCard from '../components/AnalyticsCard';
import { CardsRow } from '../styles';
import { TableGraph } from './graphs';

const Cards = styled(Col)`
  .ant-card {
    margin-bottom: 16px;
  }
`;

const Performance = () => {
  return (
    <Row gutter={[16, 16]}>
      <Cards md={24}>
        <StampsBI />
        <GamesBI />
        <InstantRewardBI />
        {/* <SurveyBI /> */}
      </Cards>
    </Row>
  );
};

const tooltips = {
  openPerc: 'Percentage of target audience who opened the invitation',
  startPerc: 'Percentage of target audience who started participating in the campaign'
};
const StampsBI = () => {
  const tableProps: TableProps<any> = {
    scroll: { x: 'scroll' },
    columns: [
      {
        title: 'Campaign Name',
        key: 'campaign_name',
        dataIndex: '[1]'
      },
      {
        title: (
          <TableHeader
            title="Top-Line Growth"
            tooltip="Difference between revenue from the Stamp campaign and the cost of issued rewards"
          />
        ),
        key: 'top_line_growth',
        dataIndex: '[2]'
      },
      {
        title: (
          <TableHeader
            title="ROI"
            tooltip="Percentage of Stamp campaigns profit out of cost of issued rewards"
          />
        ),
        dataIndex: '[3]'
      },
      {
        title: 'ROI Annualised',
        dataIndex: '[4]'
      },
      {
        title: 'Audience Size',
        dataIndex: '[5]'
      },
      {
        title: <TableHeader title="Open %" tooltip={tooltips.openPerc} />,
        dataIndex: '[6]'
      },
      {
        title: <TableHeader title="Start %" tooltip={tooltips.startPerc} />,
        dataIndex: '[7]'
      },
      {
        title: (
          <TableHeader
            title="Customer Engagement %"
            tooltip="Percentage of the audience reached who completed the campaign"
          />
        ),
        dataIndex: '[8]'
      },
      {
        title: <TableHeader title="Cost of Issued Rewards" />,
        dataIndex: '[9]'
      }
    ]
  };
  return (
    <>
      <SectionHeader>Stamp Campaigns Performance</SectionHeader>
      <CardsRow gutter={[0, 16]}>
        <AnalyticsCard
          identifier="campaign_stamp_customer_engagement"
          description="Customer Engagements"
          helpText="Total unique customer engagement across (multiple) stamp tries."
        />
        <AnalyticsCard
          identifier="campaign_stamp_top_line_growth"
          description="Top-line growth"
          helpText="Difference between revenue from Stamp campaigns and the cost of issued rewards"
        />
      </CardsRow>
      <TableGraph
        identifier="campaign_stamp_roi"
        title="Top 5 Campaigns by ROI"
        tableProps={tableProps}
        n={5}
        itemsPerRow={1}
      />
    </>
  );
};

const GamesBI = () => {
  const tableProps: TableProps<any> = {
    columns: [
      { title: 'Campaign Name', dataIndex: '[1]' },
      { title: 'Audience Size', dataIndex: '[2]' },
      { title: <TableHeader title="Open %" tooltip={tooltips.openPerc} />, dataIndex: '[3]' },
      { title: <TableHeader title="Start %" tooltip={tooltips.startPerc} />, dataIndex: '[4]' },
      { title: 'Complete', dataIndex: '[5]' },
      { title: 'Cost of Issued Rewards', dataIndex: '[6]' }
    ]
  };
  return (
    <>
      <SectionHeader>Game Campaigns Performance</SectionHeader>
      <CardsRow gutter={[0, 16]}>
        <AnalyticsCard
          identifier="campaign_game_customer_engagement"
          description="CUSTOMER ENGAGEMENTS"
        />
        <AnalyticsCard
          identifier="campaign_game_issued_reward_cost"
          description="Game Issued Reward Cost"
        />
      </CardsRow>
      <TableGraph
        identifier="campaign_game_roi"
        title="Top 5 Games by ROI"
        tableProps={tableProps}
        itemsPerRow={1}
      />
    </>
  );
};

const InstantRewardBI = () => {
  const tableProps: TableProps<any> = {
    columns: [
      { title: 'Campaign Name', key: 'campaign_name', dataIndex: '[1]' },
      { title: <TableHeader title="Audience Size" />, dataIndex: '[2]' },
      // { title: <TableHeader title="Open %" tooltip={tooltips.openPerc} />, dataIndex: '[1]' },
      // { title: <TableHeader title="Start %" tooltip={tooltips.startPerc} />, dataIndex: '[1]' },
      // { title: <TableHeader title="Issue" />, dataIndex: '[1]' },
      { title: <TableHeader title="Cost of Issued Rewards" />, dataIndex: '[4]' }
    ]
  };
  return (
    <>
      <SectionHeader>Instant Reward Campaigns Performance</SectionHeader>
      <CardsRow gutter={[0, 16]}>
        <AnalyticsCard
          identifier="campaign_instant_reward_issued"
          description="No. of rewards issued"
        />
        <AnalyticsCard
          identifier="campaign_instant_reward_issued_reward_cost"
          description="Cost of issued rewards"
        />
      </CardsRow>
      <TableGraph
        identifier="campaign_top_instant_reward_by_issuance_rate"
        title="Top Campaigns by Reward Issuance Rate"
        tableProps={tableProps}
        n={5}
        itemsPerRow={1}
      />
    </>
  );
};
interface TableHeaderProps {
  title: string;
  tooltip?: string;
}
const TableHeader = ({ title, tooltip }: TableHeaderProps) => {
  return <>{title}</>;
};

const SectionHeader = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
`;

export default memo(Performance);
