import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  GET_PROGRESS_LIST,
  GET_PROGRESS_LIST_SUCCESS,
  GET_PROGRESS_LIST_FAIL,
  POST_BULK_FILE,
  POST_BULK_FILE_SUCCESS,
  POST_BULK_FILE_FAIL
} from './constants';

export const getProgressList = payload => {
  return {
    type: GET_PROGRESS_LIST,
    payload
  };
};

export const getProgressListSuccess = payload => {
  return {
    type: GET_PROGRESS_LIST_SUCCESS,
    payload
  };
};

export const getProgressListFail = error => {
  return {
    type: GET_PROGRESS_LIST_FAIL,
    error
  };
};

export const postBulkFileList = (payload, callback, history) => {
  return {
    type: POST_BULK_FILE,
    endpoint: '/v4/dash/file_imports',
    types: [POST_BULK_FILE, POST_BULK_FILE_SUCCESS, POST_BULK_FILE_FAIL],
    method: 'post',
    formdata: payload,
    notification: {
      error: e => {
        message.error(e.message ? e.message : 'Some thing went wrong!', DURATION_TIMER);
      }
    },
    callback,
    instantAction: () => history.push('/p/bulkaction')
  };
};
