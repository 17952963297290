import useSWR from 'swr';

import { IDetailResponse } from 'utils/types';

import { fetcher } from './utils';

type TagStatus = 'active';

export interface ITag {
  id?: number;
  name: string;
  state?: TagStatus;
  description: string;
}

export const useTagList = () => {
  const url = '/v4/dash/simple/tags?size=1000';

  const { data, isLoading } = useSWR<IDetailResponse<ITag[]>>({ url }, fetcher);

  return { data: data?.data, isLoading };
};
