import isNil from 'lodash/isNil';

/**
 * Formatter for any number including decimals
 */
const numberFormat = new Intl.NumberFormat('en-US');

/**
 * Formatter for numbers with two decimal places
 */
const currencyFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const formatNumber = (num: number | undefined | null) =>
  isNil(num) ? '-' : numberFormat.format(num);

export const formatCurrency = (num: number | undefined | null) =>
  isNil(num) ? '-' : currencyFormat.format(num);
