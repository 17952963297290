import { makeSelectDefaultCurrency } from 'containers/App/selectors';

import useTypedSelector from './useTypedSelector';

const useDefaultCurrency = (): string | null => {
  const currency = useTypedSelector(makeSelectDefaultCurrency());
  return currency;
};

export default useDefaultCurrency;
