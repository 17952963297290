import { cdnURL } from 'utils/helpers';

export const Backgrounds = [
  {
    filename: 'tree_background.png',
    image_url: cdnURL('tree_background.png')
  }
];

export const Trees = [
  {
    filename: 'tree_image.png',
    image_url: cdnURL('tree_image.png')
  }
];

export const Gifts = [
  {
    filename: 'tree_gift.png',
    image_url: cdnURL('tree_gift.png')
  }
];
