import { cdnURL } from 'utils/helpers';

export const Backgrounds = [
  {
    filename: 'pinata_background.png',
    image_url: cdnURL('pinata_background.png')
  }
];

export const Pinata = [
  {
    filename: 'pinata_initial.png',
    image_url: cdnURL('pinata_initial.png')
  }
];

export const CrackedPinata = [
  {
    filename: 'pinata_cracked.png',
    image_url: cdnURL('pinata_cracked.png')
  }
];

export const BrokenPinata = [
  {
    filename: 'pinata_broken.png',
    image_url: cdnURL('pinata_broken.png')
  }
];
