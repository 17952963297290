import React, { useState } from 'react';
import { Button } from 'antd';
import { Row } from 'antd';
import _isEqual from 'lodash/isEqual';

import CampaignIdFilter from 'containers/Analytics/components/CampaignFilter/CampaignId';
import GameTypeFilter from 'containers/Analytics/components/CampaignFilter/GameType';
import CheckableTag from 'containers/Analytics/components/CheckableTag';
import { usePreviousValue } from 'hooks';

const CampaignFilter = ({ campaignType, onChange = params => {} }) => {
  const [filterParams, setFilterParams] = useState<{
    sub_type?: string;
    campaign_id?: string;
  }>({});
  const prevFilters = usePreviousValue(filterParams);
  const [showFilter, setShowFilter] = useState({ campaign: false, gameType: false });

  const handleChangeShowFilter = key => () => {
    setShowFilter(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const resetFilter = () => {
    setFilterParams({});
    onChange({});
  };

  const applyFilter = () => {
    onChange(filterParams);
  };

  const changeGameTypeFilter = value => {
    const newFilter = { ...filterParams };
    if (!!value) {
      newFilter.sub_type = value;
    } else {
      delete newFilter.sub_type;
    }

    setFilterParams(newFilter);
  };

  return (
    <>
      <div style={{ background: '#e7ebf2', padding: '1.25rem' }}>
        {campaignType === 'game' && (
          <CheckableTag checked={showFilter.gameType} onChange={handleChangeShowFilter('gameType')}>
            Game Type
          </CheckableTag>
        )}
        <CheckableTag checked={showFilter.campaign} onChange={handleChangeShowFilter('campaign')}>
          Campaigns
        </CheckableTag>

        {showFilter.campaign && (
          <div style={{ margin: '1.5rem 0' }}>
            <CampaignIdFilter
              campaignType={campaignType}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </div>
        )}
        {showFilter.gameType && (
          <div style={{ margin: '1.5rem 0' }}>
            <GameTypeFilter
              values={!!filterParams.sub_type ? filterParams.sub_type.split(',') : []}
              onChange={changeGameTypeFilter}
            />
          </div>
        )}

        {Object.values(showFilter).find(_ => _ === true) && (
          <Row justify="end" style={{ marginTop: '1rem' }}>
            <Button type="primary" ghost style={{ marginRight: 8 }} onClick={resetFilter}>
              Reset
            </Button>
            <Button
              type="primary"
              disabled={_isEqual(filterParams, prevFilters)}
              onClick={applyFilter}
            >
              Apply
            </Button>
          </Row>
        )}
      </div>
    </>
  );
};

export default CampaignFilter;
