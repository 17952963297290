import React, { useMemo } from 'react';

import { Feature } from 'appConstants';
import { useFeatureFlags } from 'hooks';

interface FeatureFlagProps {
  children: React.ReactNode | React.ReactNode[];
  flags: Feature[];
  allFlagsRequired?: boolean;
  altChildren?: React.ReactNode | React.ReactNode[];
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({
  flags,
  allFlagsRequired = true,
  children,
  altChildren = null
}) => {
  const features = useFeatureFlags();

  const passFlags = useMemo(
    () =>
      allFlagsRequired
        ? flags.every(e => features[e?.key] === true)
        : flags.some(e => features[e?.key] === true),
    [allFlagsRequired, features, flags]
  );

  return <>{passFlags ? children : altChildren}</>;
};

export default FeatureFlag;
