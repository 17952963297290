import { message } from 'antd';
import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import api from 'utils/api';
import { checkPayloadForFile } from 'utils/prepareFormData';

import { rewardTransactionFailed, rewardTransactionGetSuccess } from './actions';

function* getRewardTransactionAction(action) {
  const authUser = yield select(makeSelectAuthUser());
  let params = action.payload;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      (url, headers) => api({ url, headers }),
      `${API_ROOT}/v4/dash/customers/${params.customer_id || 1}/vouchers/${
        params.reward_transaction_id
      }`,
      headers
    );
    yield put(rewardTransactionGetSuccess(response.data));
  } catch (e) {
    yield put(rewardTransactionFailed(e.data));
  }
}

function* revertRedemptionToIssuedAction(action) {
  const authUser = yield select(makeSelectAuthUser());
  let params = action.payload;
  let callback = action.callback;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };
  const { customer_id, reward_transaction_id, state } = params;
  const body = { state };
  try {
    const response = yield call(
      (url, headers) => api({ url, headers, body, method: 'put' }),
      `${API_ROOT}/v4/dash/customers/${customer_id}/vouchers/${reward_transaction_id}`,
      headers
    );

    if (callback) {
      callback(response.data);
    }
  } catch (e) {
    message.error(e.data.message || 'Revert back to issued failed');
  }
}

function* patchRewardTransactionAction(action) {
  const authUser = yield select(makeSelectAuthUser());
  let params = action.payload;
  let callback = action.callback;

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      (url, body, headers) => {
        body = checkPayloadForFile(body, headers);
        return axios
          .put(url, body, {
            headers
          })
          .then(response => {
            return response;
          })
          .catch(error => {
            throw error.response;
          });
      },
      `${API_ROOT}/v4/dash/customers/${params.customer_id || 1}/vouchers/${
        params.reward_transaction_id
      }`,
      params,
      headers
    );
    message.info('successfully amended');
    if (callback) {
      callback(response.data);
    }
    yield put(rewardTransactionGetSuccess(response.data));
  } catch (e) {
    message.error(e.data.message || 'amendment failed');
    yield put(rewardTransactionFailed(e.data));
  }
}

function* rewardTransactionSaga() {
  yield takeLatest('GET_REWARD_TRANSACTION', getRewardTransactionAction);
  yield takeLatest('PATCH_REWARD_TRANSACTION', patchRewardTransactionAction);
  yield takeLatest('REVERT_REDEMPTION_TO_ISSUED', revertRedemptionToIssuedAction);
}

export default rewardTransactionSaga;
