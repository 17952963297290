import React from 'react';
import _kebabCase from 'lodash/kebabCase';

import * as S from './IconCheckbox.styles';

interface IconCheckboxProps {
  icon: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  style?: React.CSSProperties;
}

const IconCheckbox = ({
  icon,
  label,
  value,
  className,
  style,
  checked,
  onChange
}: IconCheckboxProps) => {
  const id = _kebabCase(value);

  return (
    <S.Container className={className} style={style}>
      <S.Input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <S.Label htmlFor={id} data-testid={`trigger-${id}`}>
        <S.Icon src={icon} />
        <S.LabelText>{label}</S.LabelText>
      </S.Label>
    </S.Container>
  );
};

export default IconCheckbox;
