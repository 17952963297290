import React, { memo } from 'react';
import _startCase from 'lodash/startCase';
import styled from 'styled-components';

interface PercentProps {
  name: string;
  percent: number;
  color: string;
}

const Percent = memo(({ name, percent, color }: PercentProps) => {
  return (
    <StyledPercent>
      <ColorInfo color={color}>
        {(percent * 100).toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        &#37;
      </ColorInfo>
      <small>{_startCase(name)}</small>
    </StyledPercent>
  );
});

const ColorInfo = styled.div<{ color: string }>`
  &::before {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    transform: translateY(-25%);
  }
`;

const StyledPercent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.67rem 0;

  div {
    flex-basis: 5.5rem;
    color: #232425;
    font-weight: 500;
    font-size: 1rem;
  }
  small {
    font-size: 0.667rem;
    opacity: 0.5;
    color: #232425;
    margin-left: 0.875rem;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export default Percent;
