import React from 'react';
import { FormItemProps as AntFormItemProps } from 'antd/lib/form';
import { useField } from 'formik';

import StyledFormItem from '../StyledFormItem';

interface FormItemProps extends AntFormItemProps {
  name?: string;
  showValidateSuccess?: boolean;
  children?: React.ReactNode;
}

const defaultFormItemLayout = {
  labelAlign: 'left',
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 }
} as const;

const FormikFormItem = ({ name, showValidateSuccess, children, ...props }: FormItemProps) => {
  const [, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  return (
    <StyledFormItem
      colon={false}
      validateStatus={
        meta.error && meta.touched
          ? 'error'
          : isValid && showValidateSuccess
          ? 'success'
          : undefined
      }
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...props}
    >
      {children}
    </StyledFormItem>
  );
};

const FormItem = ({ showValidateSuccess, children, name, ...props }: FormItemProps) => {
  if (name) {
    return (
      <FormikFormItem
        {...defaultFormItemLayout}
        name={name}
        showValidateSuccess={showValidateSuccess}
        {...props}
      >
        {children}
      </FormikFormItem>
    );
  }
  return (
    <StyledFormItem {...defaultFormItemLayout} colon={false} {...props}>
      {children}
    </StyledFormItem>
  );
};

export default FormItem;
