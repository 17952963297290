import { useEffect, useReducer, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import produce from 'immer';
import hash from 'object-hash';

import API from 'utils/request';

interface UseAxiosState<T> {
  loading: boolean;
  response: T | null;
  error: any;
}

interface UseAxiosAction<T> {
  type: actions;
  payload?: T | any | null;
}

enum actions {
  REQUEST_START = 'REQUEST_START',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  REQUEST_FAILURE = 'REQUEST_FAILURE'
}

const reducer = <T>(state: UseAxiosState<T>, action: UseAxiosAction<T>): UseAxiosState<T> => {
  switch (action.type) {
    case actions.REQUEST_START:
      return produce(state, draft => {
        draft.loading = true;
      });
    case actions.REQUEST_SUCCESS:
      return produce(state, draft => {
        draft.loading = false;
        draft.response = action.payload;
        draft.error = null;
      });
    case actions.REQUEST_FAILURE:
      return produce(state, draft => {
        draft.loading = false;
        draft.error = action.payload;
        draft.response = null;
      });
    default:
      return state;
  }
};

const useAxios = <T = any>(
  url: string,
  reqConfig: AxiosRequestConfig = {},
  trigger?
): [boolean, T, any, any] => {
  const [innerTrigger, setInnerTrigger] = useState(0); // to re-trigger the axios call
  const reduce: (prevState: UseAxiosState<T>, action: UseAxiosAction<T>) => UseAxiosState<T> =
    reducer;
  const [state, dispatch] = useReducer(reduce, {
    loading: false,
    response: null,
    error: null
  });

  const controller = new AbortController();
  const reqConfigHash = hash(reqConfig);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: actions.REQUEST_START });
      try {
        const response = await API.request<T>({ url, ...reqConfig, signal: controller.signal });
        // @ts-ignore
        if (response.data === 'An error occurred while running the query.') {
          // special case only for metabase query errors
          dispatch({ type: actions.REQUEST_FAILURE, payload: 'Metabase Error' });
        } else {
          dispatch({ type: actions.REQUEST_SUCCESS, payload: response.data });
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          dispatch({ type: actions.REQUEST_FAILURE, payload: err });
        }
      }
    };

    if (url) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
    // reqConfigHash is the hash of reqConfig object, by default react does === comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, url, reqConfigHash, innerTrigger]);

  return [
    state.loading,
    state.response,
    state.error,
    () => {
      setInnerTrigger(+new Date());
    }
  ];
};

export default useAxios;
