import { message } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';

import { GET_AUTHORIZATION } from 'containers/Ability/constants';
import { commonSaga } from 'middleware/api';
import API from 'utils/request';

import { getPermissionsSuccess } from '../Permissions/actions';
import { userSignOutSuccess, getNecessitiesSuccess, getNecessitiesError } from './actions';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD,
  VALIDATE_INIT_CHANGE_PASSWORD,
  INIT_CHANGE_PASSWORD,
  GET_NECESSITIES,
  USER_EXTEND_SESSION,
  TOKEN_SIGNIN,
  TOKEN_SIGNIN_SUCCESS,
  TOKEN_SIGNIN_FAIL,
  CODE_SIGNIN
} from './constants';
import { getFilteredMenu } from './getMenu';

function setEmail(email) {
  localStorage.setItem('email', email);
}

function setAuthToken(data) {
  localStorage.setItem('currentUser', 'true');
  localStorage.setItem('authUser', JSON.stringify(data));
}

function clearAuthToken() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('authUser');
}

function clearPendoSession() {
  (window as any)?.pendo?.clearSession();
}

export function* signIn(action) {
  try {
    //@ts-ignore
    message.destroy('idle-logout');
    if (action.method === 'code') {
      // AWS code signin, this is how clients log in via SSO
      const response = yield call(API.post, '/v4/dash/user_sessions', action.formdata);
      yield put({
        type: CODE_SIGNIN,
        payload: { ...response.data }
      });

      const userData = yield call(API.get, '/v4/dash/me');
      const userEmail = userData?.data?.data?.email;
      const authToken = { ...response.data, email: userEmail };
      yield put({
        type: action.types[1],
        payload: authToken
      });

      yield call(setEmail, userEmail);
      yield call(setAuthToken, authToken);
    } else {
      //Regular email and password signin
      const response = yield call(API.post, action.endpoint, action.formdata);
      yield put({
        type: action.types[1],
        payload: { ...response.data, email: action.formdata.email }
      });

      yield call(setEmail, action.formdata.email);
      yield call(setAuthToken, { ...response.data, email: action.formdata.email });
    }

    // for legacy purposes, TODO: cleanup
    yield put({ type: GET_AUTHORIZATION });

    const permissionResponse = yield call(API.get, '/v4/dash/authorizations');
    yield put(getPermissionsSuccess(permissionResponse.data));
    const permissions = yield select((state: any) => state.permissions.permissions);
    const menu = getFilteredMenu(permissions);

    yield call(action.notification.success);
    if (menu.length > 0 && action.location?.state?.referrer) {
      yield call(action.history.push, action.location.state.referrer);
    } else if (menu.length > 0) {
      yield call(action.history.push, `${menu[0].url}`);
    } else {
      yield call(action.history.push, `/p`);
    }
  } catch (error) {
    if (error?.response?.data?.code === 40301) {
      yield put({ type: action.types[3], payload: null, error: error?.response });
    } else if (error?.response?.data?.code === 41214) {
      yield put({ type: action.types[2], payload: null, error: error?.response });
      yield call(action.history.push, `/signin?error=403`);
    } else {
      yield put({ type: action.types[2], payload: null, error: error?.response });
      if (action.notification?.error) {
        yield call(action.notification.error, error?.response?.data);
      }
    }
  }
}

export function* extendSession(action) {
  try {
    const response = yield call(API.post, action.endpoint);
    const newAuth = {
      ...response.data,
      bearer_token: response.data.token,
      email: action.data.email
    };
    yield put({
      type: action.types[1],
      payload: newAuth
    });
    yield call(setAuthToken, newAuth);
  } catch (error) {
    yield put({ type: action.types[2], payload: null, error: error?.response });
    if (action.notification?.error) {
      yield call(action.notification.error, error?.response?.data);
    }
  }
}

export function* tokenSignin(action) {
  try {
    //call endpoint to obtain user email
    const userData = yield call(API.get, '/v4/dash/me');
    //renew token to obtain expiry time
    const renewedToken = yield call(API.post, '/v4/dash/user_sessions/extend');
    //create complete authUser object
    const newAuth = {
      ...renewedToken.data,
      bearer_token: renewedToken.data.token,
      email: userData.data.data.email
    };
    //set token to local storage
    yield call(setAuthToken, newAuth);
    yield put({
      type: TOKEN_SIGNIN_SUCCESS,
      payload: newAuth
    });

    //redirect user to intended page after processing token login
    action.history.push(action.location.pathname);
  } catch {
    yield put({ type: TOKEN_SIGNIN_FAIL });
  }
}

export function* signOut(action) {
  yield call(clearAuthToken);
  yield call(clearPendoSession);

  const isCurrentUser = yield select((state: any) => state.global.currentUser);
  if (isCurrentUser) {
    try {
      yield call(API.delete, '/v4/dash/user_sessions');
    } catch {
      message.error('Error revoking session token.');
    }
  }

  yield put(userSignOutSuccess({ currentUser: false }));

  const toUrlParam = obj => {
    const mdArray = Object.entries(obj);
    const formatted = mdArray.map(e => `${e[0]}=${e[1]}`);
    const str = formatted.join('&');

    return `?${str}`;
  };

  const urlParams = _isEmpty(action?.urlParams) ? '' : toUrlParam(action?.urlParams);
  yield call(action.history.push, `/signin${urlParams}`);
}

export function* necessitiesSaga(action) {
  try {
    const [
      tenantConfig,
      audienceLists,
      tags,
      categories,
      labels,
      user_tenants,
      custom_image_ratios
    ] = yield all(action.endpoints.map(x => fetchSingleRequest(x)));

    yield put(
      getNecessitiesSuccess({
        tenantConfig: tenantConfig
          ? {
              ...tenantConfig.data.data.tenant,
              features: tenantConfig.data.data.features,
              timezone: tenantConfig.data.data.timezone,
              localization: {
                availableLocales: {
                  locales: tenantConfig.data.data.available_locales.locales,
                  platformSupportedLocales:
                    tenantConfig.data.data.available_locales.platform_supported_locales
                },
                country: tenantConfig.data.data.country,
                provision_rate: tenantConfig.data.data.provision_rate,
                currency_conversion_rate: tenantConfig.data.data.currency_conversion_rate,
                currency: tenantConfig.data.data.currency,
                timezone: tenantConfig.data.data.timezone
              }
            }
          : null,
        bulkActions: tenantConfig?.data.bulk_actions || null,
        availableLocales: tenantConfig?.data?.data?.available_locales || null,
        selectedLocale: tenantConfig?.data?.data?.available_locales?.[0] || 'en',
        reportDownloads: tenantConfig?.data.report_downloads || null,
        scheduledReports: tenantConfig?.data.scheduled_reports || null,
        currency: tenantConfig?.data.currency || null,
        audienceLists: audienceLists?.data || null,
        tags: tags?.data || null,
        categories: categories?.data || null,
        labels: labels?.data || null,
        user_tenants: user_tenants?.data || {},
        custom_image_ratios: custom_image_ratios?.data || null
      })
    );
  } catch (error) {
    yield put(getNecessitiesError(error));
  }
}

function fetchSingleRequest(endpoint) {
  return API.get(endpoint.url).catch(e => {
    console.error(e);
    return null;
  });
}

function* appSaga() {
  yield takeLatest(SIGNIN_USER, signIn);
  yield takeLatest(USER_EXTEND_SESSION, extendSession);
  yield takeLatest(TOKEN_SIGNIN, tokenSignin);
  yield takeLatest(SIGNOUT_USER, signOut);
  yield takeLatest(VALIDATE_RESET_PASSWORD, commonSaga);
  yield takeLatest(RESET_PASSWORD, commonSaga);
  yield takeLatest(FORGET_PASSWORD, commonSaga);
  yield takeLatest(VALIDATE_INIT_CHANGE_PASSWORD, commonSaga);
  yield takeLatest(INIT_CHANGE_PASSWORD, commonSaga);

  yield takeLatest(GET_NECESSITIES, necessitiesSaga);
}

export default appSaga;
