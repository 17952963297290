import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Typography, notification } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import styled from 'styled-components';

import './AudienceFilters.css';
import { useCustomPropertyList } from 'api/mappings';
import { FEATURES } from 'appConstants';
import age from 'containers/Audience/assets/age.svg';
import badge from 'containers/Audience/assets/badge.svg';
import birthday from 'containers/Audience/assets/birthday.svg';
import campaign from 'containers/Audience/assets/campaign.svg';
import custom from 'containers/Audience/assets/custom.svg';
import email from 'containers/Audience/assets/email.svg';
import ethnicity from 'containers/Audience/assets/ethnicity.svg';
import gender from 'containers/Audience/assets/gender.svg';
import leaderboard from 'containers/Audience/assets/leaderboard.svg';
import location from 'containers/Audience/assets/location.svg';
import loyalty from 'containers/Audience/assets/loyalty.svg';
import membership from 'containers/Audience/assets/membership.svg';
import payment from 'containers/Audience/assets/payment.svg';
import points from 'containers/Audience/assets/points.svg';
import reward from 'containers/Audience/assets/reward.svg';
import { LOCATION_KEYS } from 'containers/Audience/constants';
import { useFeatureFlags, useTenant } from 'hooks';

import * as S from './Audience.styles';
import AgeGroup from './components/AgeGroup';
import Badge from './components/Badge';
import Birthday from './components/Birthday';
import Campaign from './components/Campaign';
import EmailTags from './components/EmailTags';
import FilterFormAudienceContainer from './components/FilterFormAudienceWrapper';
import Gender from './components/Gender';
import Leaderboard from './components/Leaderboard';
import Location from './components/Location';
import LoyaltyPoints from './components/LoyaltyPoints';
import LoyaltyTier from './components/LoyaltyTier';
import MemberSince from './components/MemberSince';
import PaymentFilters from './components/PaymentBased';
import Personal from './components/Personal';
import Race from './components/Race';
import Reward from './components/Reward';
import TargetingCriteria from './components/TargetingCriteria';

const { Title, Paragraph } = Typography;

const AudienceFilters = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [api, contextHolder] = notification.useNotification();

  const featureFlags = useFeatureFlags();
  const {
    audience_email_tags: audienceEmailTagsEnabled,
    generate_audience_from_transaction_breakdown_report: generateAudienceReport
  } = featureFlags;

  const { data: customFiltersData = [] } = useCustomPropertyList();
  const tenant = useTenant();
  const isKissht = ['kissht', 'kissht_preprod'].includes(tenant?.name);
  const paymentFiltersEnabled = isKissht;

  const locationCustomFiltersData = customFiltersData?.filter(c => LOCATION_KEYS.includes(c.key));
  const commonCustomFiltersData = customFiltersData?.filter(c => !LOCATION_KEYS.includes(c.key));

  /**
   * Checks if custom fields tab is open and then initializes fields with valid type and policy values.
   * All unused field objects will be purged on submit using the `doesFilterHaveValue` util.
   * */
  useEffect(() => {
    const customTabShouldBeOpen = commonCustomFiltersData?.some(item => filters?.[item.key]);

    if (customTabShouldBeOpen) {
      commonCustomFiltersData.forEach(item => {
        if (!values.filters?.[item.key]) {
          setFieldValue(`filters.${item.key}`, [
            {
              type: item.key,
              criteria: [
                {
                  values: [],
                  policy: 'include'
                }
              ]
            }
          ]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonCustomFiltersData]);

  const options = useMemo(() => {
    let arr = [
      {
        label: 'Age',
        value: 'age',
        icon: age,
        category: 'Demographics',
        component: <AgeGroup key="age" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.age', [
              {
                type: 'age',
                criteria: {
                  gteq: '18',
                  lteq: '60',
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.age', undefined);
          }
        }
      },
      {
        label: 'Birth Month',
        value: 'birthmonth',
        category: 'Demographics',
        icon: birthday,
        component: <Birthday key="birthmonth" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.birthmonth', [
              {
                type: 'birthmonth',
                criteria: [
                  {
                    values: []
                  }
                ]
              }
            ]);
          } else {
            setFieldValue('filters.birthmonth', undefined);
            setFieldValue('filters.current_birthmonth', undefined);
            setFieldValue('filters.current_birthday', undefined);
          }
        }
      },
      {
        label: 'Gender',
        value: 'gender',
        category: 'Demographics',
        icon: gender,
        component: <Gender key="gender" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.gender', [
              {
                type: 'gender',
                criteria: [
                  {
                    value: '1'
                  }
                ]
              }
            ]);
          } else {
            setFieldValue('filters.gender', undefined);
          }
        }
      },
      {
        label: 'Ethnicity',
        value: 'race',
        category: 'Demographics',
        icon: ethnicity,
        component: <Race key="race" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.race', [
              {
                type: 'race',
                criteria: [
                  {
                    policy: 'include',
                    values: []
                  }
                ]
              }
            ]);
          } else {
            setFieldValue('filters.race', undefined);
          }
        }
      },
      {
        label: 'Location',
        value: 'location',
        category: 'Demographics',
        icon: location,
        component: <Location key="location" customFiltersData={locationCustomFiltersData} />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.city', [
              {
                type: 'city',
                criteria: [
                  {
                    policy: 'include',
                    values: []
                  }
                ]
              }
            ]);
            setFieldValue('filters.states', [
              {
                type: 'state',
                criteria: [
                  {
                    policy: 'include',
                    values: []
                  }
                ]
              }
            ]);
            locationCustomFiltersData.forEach(item => {
              setFieldValue(`filters.${item.key}`, [
                {
                  type: item.key,
                  criteria: [
                    {
                      values: [],
                      policy: 'include'
                    }
                  ]
                }
              ]);
            });
          } else {
            setFieldValue('filters.city', undefined);
            setFieldValue('filters.states', undefined);
            locationCustomFiltersData.forEach(item => {
              setFieldValue(`filters.${item.key}`, undefined);
            });
          }
        }
      },
      {
        label: 'Badge',
        value: 'earned_badges',
        category: 'Engagement',
        icon: badge,
        component: <Badge key="earned_badges" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.earned_badges', [
              {
                type: 'value',
                criteria: {
                  eq: null,
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.earned_badges', undefined);
          }
        }
      },
      {
        label: 'Campaign',
        value: 'campaign_enrolment',
        category: 'Engagement',
        icon: campaign,
        component: <Campaign key="campaign_enrolment" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.campaign_enrolment', [
              {
                type: 'value',
                criteria: {
                  eq: null,
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.campaign_enrolment', undefined);
          }
        }
      },
      {
        label: 'Leaderboard',
        value: 'leaderboard_position',
        category: 'Engagement',
        icon: leaderboard,
        component: <Leaderboard key="leaderboard_position" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.leaderboard_position', [
              {
                type: 'value',
                criteria: {
                  eq: null,
                  gteq: null,
                  lteq: null,
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.leaderboard_position', undefined);
          }
        }
      },
      {
        label: 'Loyalty points',
        value: 'loyalty_points_balance',
        category: 'Engagement',
        icon: points,
        component: <LoyaltyPoints key="loyalty_points_balance" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.loyalty_points_balance', {
              type: 'value',
              criteria: {
                policy: 'include',
                eq: null,
                gteq: null,
                lteq: null
              }
            });
          } else {
            setFieldValue('filters.loyalty_points_balance', undefined);
          }
        }
      },
      {
        label: 'Loyalty tier',
        value: 'loyalty_tier',
        category: 'Engagement',
        icon: loyalty,
        component: <LoyaltyTier key="loyalty_tier" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.loyalty_tier', {
              criteria: {
                policy: 'include',
                loyalty_tier_ids: []
              }
            });
          } else {
            setFieldValue('filters.loyalty_tier', undefined);
          }
        }
      },
      {
        label: 'Member since',
        value: 'account_confirmed_at',
        category: 'Engagement',
        icon: membership,
        component: <MemberSince key="account_confirmed_at" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.account_confirmed_at', [
              {
                type: 'date',
                criteria: {
                  gteq: null,
                  lteq: null,
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.account_confirmed_at', undefined);
          }
        }
      },
      {
        label: 'Reward',
        value: 'earned_rewards',
        category: 'Engagement',
        icon: reward,
        component: <Reward key="earned_rewards" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.earned_rewards', [
              {
                type: 'value',
                criteria: {
                  eq: null,
                  policy: 'include'
                }
              }
            ]);
          } else {
            setFieldValue('filters.earned_rewards', undefined);
          }
        }
      },
      ...(audienceEmailTagsEnabled
        ? [
            {
              label: 'Email Tags',
              value: 'email_tags',
              category: 'Custom',
              icon: email,
              component: (
                <EmailTags
                  key="email_tags"
                  getValue={field => _get(values, field)}
                  setValue={(field, value) => {
                    setFieldValue(field, value);
                  }}
                />
              ),
              onChange: checked => {
                if (checked) {
                  setFieldValue('filters.email_tags', {
                    type: 'email_tags',
                    criteria: {
                      policy: 'include',
                      json_values: []
                    }
                  });
                } else {
                  setFieldValue('filters.email_tags', undefined);
                }
              }
            }
          ]
        : []),
      ...(paymentFiltersEnabled
        ? [
            {
              label: 'Payment based',
              value: 'payment',
              category: 'Custom',
              icon: payment,
              component: <PaymentFilters key="payment" />,
              onChange: checked => {
                if (checked) {
                  setFieldValue('filters.is_upi_enabled', [
                    {
                      type: 'value',
                      criteria: {
                        eq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.is_scan_pay_enabled', [
                    {
                      type: 'value',
                      criteria: {
                        eq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.is_bbps_enabled', [
                    {
                      type: 'value',
                      criteria: {
                        eq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.is_kyc_complete', [
                    {
                      type: 'value',
                      criteria: {
                        eq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.is_current_address_present', [
                    {
                      type: 'value',
                      criteria: {
                        eq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.days_past_dues', [
                    {
                      type: 'value',
                      criteria: {
                        gteq: null,
                        lteq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.payment_status', [
                    {
                      type: 'value',
                      criteria: {
                        eq: [],
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.bnpl_unpaid_emi_date', [
                    {
                      type: 'value',
                      criteria: {
                        gteq: null,
                        lteq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                  setFieldValue('filters.instaloan_unpaid_emi_date', [
                    {
                      type: 'value',
                      criteria: {
                        gteq: null,
                        lteq: null,
                        policy: 'include'
                      }
                    }
                  ]);
                } else {
                  setFieldValue('filters.is_upi_enabled', undefined);
                  setFieldValue('filters.is_scan_pay_enabled', undefined);
                  setFieldValue('filters.is_bbps_enabled', undefined);
                  setFieldValue('filters.is_kyc_complete', undefined);
                  setFieldValue('filters.is_current_address_present', undefined);
                  setFieldValue('filters.days_past_dues', undefined);
                  setFieldValue('filters.payment_status', undefined);
                  setFieldValue('filters.bnpl_unpaid_emi_date', undefined);
                  setFieldValue('filters.instaloan_unpaid_emi_date', undefined);
                }
              }
            }
          ]
        : []),
      {
        label: 'Custom',
        value: 'personal',
        category: 'Custom',
        icon: custom,
        component: <Personal key="personal" />,
        onChange: checked => {
          if (checked) {
            setFieldValue('filters.custom_attributes', [
              {
                type: 'custom_attributes',
                criteria: []
              }
            ]);

            commonCustomFiltersData.forEach(item => {
              setFieldValue(`filters.${item.key}`, [
                {
                  type: item.key,
                  criteria: [
                    {
                      values: [],
                      policy: 'include'
                    }
                  ]
                }
              ]);
            });
          } else {
            setFieldValue('filters.custom_attributes', undefined);

            commonCustomFiltersData.forEach(item => {
              setFieldValue(`filters.${item.key}`, undefined);
            });
          }
        }
      }
    ];

    if (!featureFlags[FEATURES.AUDIENCE_DEMOGRAPHICS_SELECTION.key]) {
      arr = arr.filter(x => x.category !== 'Demographics');
    }
    return arr;
  }, [
    commonCustomFiltersData,
    locationCustomFiltersData,
    setFieldValue,
    featureFlags,
    audienceEmailTagsEnabled,
    paymentFiltersEnabled,
    values
  ]);

  const filters = values?.filters;

  const activeFilterGroupKeys = useMemo(() => {
    const keys = [];

    // Demographics filters:

    if (filters?.age) {
      keys.push('age');
    }
    if (filters?.birthmonth || filters?.current_birthmonth || filters?.current_birthday) {
      keys.push('birthmonth');
    }
    if (filters?.gender) {
      keys.push('gender');
    }
    if (filters?.race) {
      keys.push('race');
    }
    if (
      filters?.city ||
      filters?.states ||
      locationCustomFiltersData.some(item => filters?.[item.key])
    ) {
      keys.push('location');
    }

    // Engagement filters:

    if (filters?.earned_badges) {
      keys.push('earned_badges');
    }
    if (filters?.campaign_enrolment) {
      keys.push('campaign_enrolment');
    }
    if (filters?.leaderboard_position) {
      keys.push('leaderboard_position');
    }
    if (filters?.loyalty_points_balance) {
      keys.push('loyalty_points_balance');
    }
    if (filters?.loyalty_tier) {
      keys.push('loyalty_tier');
    }
    if (filters?.account_confirmed_at) {
      keys.push('account_confirmed_at');
    }
    if (filters?.earned_rewards) {
      keys.push('earned_rewards');
    }

    // Custom filters:

    if (audienceEmailTagsEnabled && filters?.email_tags) {
      keys.push('email_tags');
    }
    if (
      filters?.is_upi_enabled ||
      filters?.is_scan_pay_enabled ||
      filters?.is_bbps_enabled ||
      filters?.is_kyc_complete ||
      filters?.is_current_address_present ||
      filters?.days_past_dues ||
      filters?.payment_status ||
      filters?.bnpl_unpaid_emi_date ||
      filters?.instaloan_unpaid_emi_date
    ) {
      keys.push('payment');
    }
    if (filters?.custom_attributes || commonCustomFiltersData.some(item => filters?.[item.key])) {
      keys.push('personal');
    }

    return keys;
  }, [commonCustomFiltersData, locationCustomFiltersData, filters, audienceEmailTagsEnabled]);

  const filterNodes = useMemo(() => {
    const keyedOptions = options.reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

    return activeFilterGroupKeys
      .map(key => {
        const option = keyedOptions[key];

        return (
          <FilterFormAudienceContainer
            key={option.value}
            title={option.label}
            icon={option.icon}
            onDeleteButtonClick={() => option.onChange(false)}
            hideDeleteButton={key === 'personal'}
          >
            {option.component}
          </FilterFormAudienceContainer>
        );
      })
      .reduce((acc, item, index) => {
        return !acc?.length
          ? [item]
          : [...acc, <S.FilterSeparator key={`separator-${index}`}>and</S.FilterSeparator>, item];
      }, []);
  }, [activeFilterGroupKeys, options]);

  useEffect(() => {
    const openNotification = () => {
      const btn = (
        <Link
          to={`/p/reports/downloads`}
          style={{ color: '#2665ee !important', fontSize: '14px', fontWeight: '500' }}
        >
          Take me to Reports
        </Link>
      );

      api.open({
        message: (
          <span style={{ fontSize: '16px', fontWeight: '500' }}>
            Create audience lists based on transaction data
          </span>
        ),
        description: (
          <span style={{ textAlign: 'justify' }}>
            Introducing a convenient way to create audience lists directly from transaction reports.
            Visit the Reports section, generate a Transaction - Breakdown report with your desired
            filters, and enable the option to create an audience list.
            <br /> What's even better? You can also access the generated list in the Audience
            section for applying additional filters.
          </span>
        ),
        btn,
        icon: <InfoCircleOutlined style={{ color: '#2665ee' }} />,
        style: { width: 500 },
        duration: 0
      });
    };

    if (generateAudienceReport) {
      openNotification();
    }
  }, [api, generateAudienceReport]);

  return (
    <Container>
      {contextHolder}
      <FilterOptionsContainer>
        <Title level={5}>Select filters to auto-generate an audience list</Title>
        <TargetingCriteria activeKeys={activeFilterGroupKeys} options={options} />
        {generateAudienceReport ? (
          <>
            <Paragraph>
              You can also generate audience lists based on transaction data directly from
              Transaction - Breakdown report.
            </Paragraph>
            <Link to={`/p/reports/downloads`}>Take me to Reports</Link>
          </>
        ) : null}
      </FilterOptionsContainer>
      <Divider />
      <FilterNodesContainer>{filterNodes}</FilterNodesContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
`;
const Divider = styled.div`
  border-left: 1px solid #f0f0f0;
`;
const FilterNodesContainer = styled.div`
  flex: 1 0 auto;
`;
const FilterOptionsContainer = styled.div`
  flex: 0 1 50%;
  max-width: 560px;
`;

export default AudienceFilters;
