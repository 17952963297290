import React from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { useField, useFormikContext } from 'formik';
import moment from 'moment-timezone';

import FItem from './FItem';

type FItemDatePickerProps = DatePickerProps & {
  name: string;
  label?: string;
  format?: string;
  placeholder?: string;
  formItemProps?: FormItemProps;
  /**
   * Meanwhile the `format` prop used to set format for showing the value in UI,
   * the `momentFormat` is supposed to define the format of the stored value
   */
  momentFormat?: string;
};

const FItemDatePicker = ({
  name,
  label,
  format = 'YYYY-MM-DD',
  momentFormat = 'DD-MM-YYYY',
  placeholder,
  formItemProps,
  ...props
}: FItemDatePickerProps) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <FItem meta={meta} label={label} htmlFor={name} {...formItemProps}>
      <DatePicker
        aria-label={label}
        value={field.value ? moment(field.value, momentFormat) : null}
        onChange={val => formik.setFieldValue(name, val ? val.format(momentFormat) : null)}
        onBlur={() => formik.setFieldTouched(name)}
        format={format}
        placeholder={placeholder}
        {...props}
      />
    </FItem>
  );
};

export default FItemDatePicker;
