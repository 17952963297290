import { TransferRequestStatus, TransferRequestType } from 'api/bdo/transfer-requests';

export const getColorForStatus = (status: TransferRequestStatus) => {
  switch (status) {
    case 'completed':
      return '#4ab682';
    case 'declined':
      return '#f5222d';
    case 'cancelled':
      return '#202c46';
    default:
      return '#2665ee';
  }
};

export const TransferRequestTypeLabels: Record<TransferRequestType, string> = {
  'BR-PT': 'BRC Points Transfer',
  'BR-SMAC': 'BRC to SMAC',
  'BR-CashCred': 'BRC to Cash Credit',
  'CC-BRCP': 'Credit Card to BRC Points',
  'CC-CashCred': 'Credit Card to Cash Credit',
  'CC-SMAC': 'Credit Card to SMAC'
};

type AccountType = 'BRC' | 'SMAC' | 'CC';

export const TransferRequestAccountTypes: Record<TransferRequestType, [AccountType, AccountType]> =
  {
    'BR-PT': ['BRC', 'BRC'],
    'BR-SMAC': ['BRC', 'SMAC'],
    'BR-CashCred': ['BRC', 'CC'],
    'CC-BRCP': ['CC', 'BRC'],
    'CC-CashCred': ['CC', 'CC'],
    'CC-SMAC': ['CC', 'SMAC']
  };
