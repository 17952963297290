import React, { memo, useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import styled from 'styled-components';

const SearchInput = styled(Input.Search)`
  display: flex;
  flex-basis: 556px;
  flex-grow: 0;
  & > input {
    font-weight: 200;
  }
  > .ant-input-suffix {
    display: none;
    visibility: hidden;
  }

  > .ant-input-lg {
    font-size: 1rem !important;
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex: 1;
  position: relative;
  width: 100%;
`;

interface SearchBarProps extends SearchProps {
  debounceDuration?: number;
}

export const SearchBar = ({
  onSearch = () => {},
  placeholder,
  debounceDuration = 500,
  ...props
}: SearchBarProps) => {
  const [inputVal, setInputVal] = useState(null);

  useDebounce(
    () => {
      //This will fire on mount without this condition. Using null because we want onSearch to fire on empty string
      if (inputVal !== null) {
        onSearch(inputVal);
      }
    },
    debounceDuration,
    [inputVal]
  );

  return (
    <Wrapper data-testid="search-bar">
      <SearchInput
        {...props}
        value={inputVal}
        placeholder={placeholder}
        size={props?.size || 'large'}
        onChange={evt => {
          setInputVal(evt.target.value);
        }}
        allowClear
        onSearch={() => {}}
        prefix={<SearchOutlined style={{ color: '#c0c0c0' }} />}
        suffix={null}
      />
    </Wrapper>
  );
};

export default memo(SearchBar);
