import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useQueryParam } from 'use-query-params';

import { getAbility } from 'containers/Ability/actions';
import { getPermissions } from 'containers/Permissions/actions';
import { DashboardLoading } from 'pages/public/Loading';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [token] = useQueryParam('token');
  const appLoading = useSelector(state => state.global.loading, shallowEqual);
  const currentUser = useSelector(state => state.global.currentUser, shallowEqual);
  const abilities = useSelector(state => state.ability.sections, shallowEqual);
  const permissions = useSelector(state => state.permissions.permissions, shallowEqual);

  useEffect(() => {
    if (_isEmpty(abilities) && currentUser) {
      dispatch(getAbility());
    }
    if (_isEmpty(permissions) && currentUser) {
      dispatch(getPermissions());
    }
  }, [abilities, dispatch, permissions, currentUser]);

  return (
    <Route
      {...rest}
      render={props =>
        // TODO: remove checking with currentUser
        currentUser ? (
          <Component {...props} />
        ) : appLoading || !!token ? (
          <DashboardLoading />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: {
                referrer: props.location
              }
            }}
          />
        )
      }
    />
  );
};

RestrictedRoute.propTypes = {
  component: PropTypes.any.isRequired // TODO: revisit the proptype
};

export default RestrictedRoute;
