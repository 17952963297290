import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableMainText, TableSecondaryText } from 'styles';

import { StatusTag, TableTimestamp } from 'components';
import DeleteIconButton from 'components/DeleteIconButton';
import { useGetDetail } from 'hooks';

interface SelectedCampaignProps {
  id;
  entity;
  onDelete;
  showFooterMsg;
  isCampaignRewardsSelected?;
  onLoad?;
}
const SelectedCampaign = ({
  id,
  entity,
  onDelete = () => {},
  showFooterMsg = false,
  isCampaignRewardsSelected = false,
  onLoad
}: SelectedCampaignProps) => {
  const item = useGetDetail(id, entity);

  useEffect(() => {
    if (item && !!onLoad && typeof onLoad === 'function') {
      onLoad(item);
    }
    // eslint-disable-next-line
  }, [item]);

  if (item) {
    return (
      <Wrapper>
        <Content>
          <Row justify="space-around" align="middle" gutter={8}>
            <Col span={6}>
              <Item>
                <TableMainText>{item.data.name}</TableMainText>
                <TableSecondaryText>ID:&nbsp;{item.data.id}</TableSecondaryText>
              </Item>
            </Col>
            <Col span={6}>
              <StatusTag state={item.data.state} />
            </Col>
            <Col span={5}>
              <TableTimestamp timestamp={item.data.start_date} />
            </Col>
            <Col span={5}>
              <TableTimestamp timestamp={item.data.end_date} />
            </Col>
            <Col span={2}>
              <DeleteIconButton onClick={() => onDelete(item.data.id)} />
            </Col>
          </Row>
        </Content>
        {showFooterMsg && (
          <Footer>
            Any {isCampaignRewardsSelected && 'selected'} rewards in this campaign will be a trigger
          </Footer>
        )}
      </Wrapper>
    );
  }
  return null;
};

SelectedCampaign.propTypes = {
  id: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  showFooterMsg: PropTypes.bool
};

export default SelectedCampaign;

const Wrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin: 0.5rem 1rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  background-color: rgba(251, 121, 1, 0.1);
  color: #4a4a4a;
  padding: 0.3525rem 1rem;
  border-top: 1px solid #d9d9d9;
  line-height: 1.5625;
  font-size: 0.75rem;
`;
