import React from 'react';
import { Spin } from 'antd';

import useAnalyticsContext from '../useAnalyticsContext';

const CustomSpin = ({ children }) => {
  const { cardsLoading } = useAnalyticsContext();

  if (cardsLoading)
    return (
      <div style={{ marginTop: '20%', textAlign: 'center' }}>
        <Spin delay={500} size="large" />
      </div>
    );

  return children;
};

export default CustomSpin;
