import React, { useEffect, createContext, useReducer, useCallback, useMemo } from 'react';
import moment from 'moment';

import { FEATURES, LIVE_TENANTS } from 'appConstants';
import { DatePart } from 'containers/Analytics/components/DatePartSelect';
import { useAxios, useTenant } from 'hooks';

const AnalyticsContext = createContext({
  dateRange: null,
  onDateRateChange: null,
  isDemoMode: true,
  cards: [],
  cardsLoading: false,
  datePart: DatePart.Daily,
  onDatePartChange: null
});

const initialState = {
  isDemoMode: false,
  dateRange: [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
  cards: [],
  datePart: DatePart.Daily
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CARDS':
      return { ...state, cards: action.payload };
    case 'DEMO_MODE':
      return { ...state, isDemoMode: action.payload };
    case 'DATERANGE':
      return { ...state, dateRange: action.payload };
    case 'DATEPART':
      return { ...state, datePart: action.payload };
    default:
      return state;
  }
};

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { name: tenantName, features } = useTenant();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isLive = features[FEATURES?.LIVE_BI_DATA?.key] || LIVE_TENANTS.includes(tenantName);
  const isDemoMode = !isLive;

  const [cardsLoading, response] = useAxios(
    !isDemoMode && tenantName ? '/v4/dash/metabase_cards' : null,
    {
      // TODO: Remove this after there is a better way to do this
      params: {
        size: 500
      }
    },
    tenantName
  );

  useEffect(() => {
    if (response?.data) dispatch({ type: 'CARDS', payload: response.data });
  }, [response]);

  useEffect(() => {
    // tenantName is not known until the async sideeffect has been done, therefore changing
    // demo mode status should be done in a useEffect hook
    if (isDemoMode) {
      // if the tennat is a "demo" tenant and demo is set to false, turn on the demo mode
      dispatch({ type: 'DEMO_MODE', payload: true });
    }
  }, [isDemoMode, tenantName]);

  const onDateRateChange = useCallback(
    dateRange => dispatch({ type: 'DATERANGE', payload: dateRange }),
    []
  );

  const onDatePartChange = useCallback(
    datePart => dispatch({ type: 'DATEPART', payload: datePart }),
    []
  );

  const contextValue = useMemo(
    () => ({ ...state, onDateRateChange, onDatePartChange, cardsLoading }),
    [state, onDateRateChange, onDatePartChange, cardsLoading]
  );

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

export { AnalyticsProvider, AnalyticsContext };
