import { removeDuplicates } from 'utils/helpers';

import {
  CREATE_MERCHANT,
  CREATE_MERCHANT_SUCCESS,
  CREATE_MERCHANT_FAIL,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT_FAIL,
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS,
  GET_MERCHANTS_FAIL,
  GET_SIMPLE_MERCHANTS,
  GET_SIMPLE_MERCHANTS_FAIL,
  GET_SIMPLE_MERCHANTS_SUCCESS,
  SEARCH_MERCHANT,
  SEARCH_MERCHANT_SUCCESS,
  SEARCH_MERCHANT_FAIL,
  UPLOAD_MERCHANT_LOCATION,
  UPLOAD_MERCHANT_LOCATION_SUCCESS,
  UPLOAD_MERCHANT_LOCATION_FAIL,
  GET_MERCHANT_BRANCH_LIST,
  GET_MERCHANT_BRANCH_LIST_SUCCESS,
  GET_MERCHANT_BRANCH_LIST_FAIL,
  GET_MERCHANT_BRANCH,
  GET_MERCHANT_BRANCH_SUCCESS,
  GET_MERCHANT_BRANCH_FAIL,
  POST_MERCHANT_BRANCH,
  POST_MERCHANT_BRANCH_SUCCESS,
  POST_MERCHANT_BRANCH_FAIL,
  PATCH_MERCHANT_BRANCH,
  PATCH_MERCHANT_BRANCH_SUCCESS,
  PATCH_MERCHANT_BRANCH_FAIL,
  PUT_MERCHANT_BRANCH,
  PUT_MERCHANT_BRANCH_SUCCESS,
  PUT_MERCHANT_BRANCH_FAIL,
  DELETE_MERCHANT_BRANCH,
  DELETE_MERCHANT_BRANCH_SUCCESS,
  DELETE_MERCHANT_BRANCH_FAIL,
  DELETE_ALL_MERCHANT_BRANCH_SUCCESS,
  DELETE_ALL_MERCHANT_BRANCH_FAIL,
  DELETE_ALL_MERCHANT_BRANCH,
  GET_MERCHANT_ACTIVATE_DEACTIVATE_FAIL,
  GET_MERCHANT_ACTIVATE_DEACTIVATE // GET_MERCHANT_ACTIVATE_DEACTIVATE_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  merchantsForSelector: [],
  merchants: {
    data: [],
    meta: {}
  },
  locations: {},
  location: {},
  merchant: {}
};

function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MERCHANTS: {
      return {
        ...state,
        loading: true,
        merchants: {
          data: [],
          meta: {}
        }
      };
    }
    case GET_MERCHANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        merchants: {
          data: removeDuplicates(action.payload.data, 'id'),
          meta: action.payload.meta
        }
      };
    }
    case GET_MERCHANT_ACTIVATE_DEACTIVATE: {
      return {
        ...state,
        loading: true
      };
    }
    // case GET_MERCHANT_ACTIVATE_DEACTIVATE_SUCCESS:{

    // }
    case GET_MERCHANT_ACTIVATE_DEACTIVATE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_MERCHANTS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_SIMPLE_MERCHANTS: {
      return {
        ...state,
        loading: true,
        merchantsForSelector: []
      };
    }
    case GET_SIMPLE_MERCHANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        merchantsForSelector: action.payload.data
      };
    }
    case GET_SIMPLE_MERCHANTS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPLOAD_MERCHANT_LOCATION:
    case CREATE_MERCHANT: {
      return {
        ...state,
        loading: true,
        merchant: {}
      };
    }
    case UPLOAD_MERCHANT_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        merchant: action.payload.data
      };
    }
    case CREATE_MERCHANT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case UPLOAD_MERCHANT_LOCATION_FAIL:
    case CREATE_MERCHANT_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_MERCHANT: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_MERCHANT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_MERCHANT_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_MERCHANT: {
      return {
        ...state,
        loading: true
      };
    }
    case SEARCH_MERCHANT_SUCCESS: {
      return {
        ...state,
        loading: false,
        merchants: action.payload
      };
    }
    case SEARCH_MERCHANT_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_MERCHANT_BRANCH_LIST:
    case GET_MERCHANT_BRANCH:
    case POST_MERCHANT_BRANCH:
    case PATCH_MERCHANT_BRANCH:
    case PUT_MERCHANT_BRANCH:
    case DELETE_ALL_MERCHANT_BRANCH:
    case DELETE_MERCHANT_BRANCH: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_MERCHANT_BRANCH_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        locations: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }
    case GET_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case POST_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case PUT_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case PATCH_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case DELETE_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case DELETE_ALL_MERCHANT_BRANCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    }
    case DELETE_ALL_MERCHANT_BRANCH_FAIL:
    case GET_MERCHANT_BRANCH_LIST_FAIL:
    case GET_MERCHANT_BRANCH_FAIL:
    case POST_MERCHANT_BRANCH_FAIL:
    case PUT_MERCHANT_BRANCH_FAIL:
    case PATCH_MERCHANT_BRANCH_FAIL:
    case DELETE_MERCHANT_BRANCH_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
}

export default merchantReducer;
