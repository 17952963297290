import useSWR from 'swr';

import { ILeaderboardForm } from 'containers/Leaderboards/interface';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useLeaderboard = (id: number) => {
  const { data: { data } = {} } = useSWR<IDetailResponse<ILeaderboardForm>>(
    id ? `/v4/dash/leaderboards/${id}` : null,
    fetcher
  );

  return { data };
};

export const useLeaderboardList = (params: object) => {
  const { data: { data, meta } = {}, isLoading } = useSWR<IPaginatedResponse<ILeaderboardForm>>(
    `/v4/dash/leaderboards?${buildQuery(params)}`,
    fetcher
  );

  return { data, meta, isLoading };
};
