import React from 'react';

import { ReactComponent as Logo } from './no_data.svg';

interface NoDataProps {
  style?: object;
  title?: string;
  hideDescription?: boolean;
}

const NoData = ({ style, title, hideDescription = false }: NoDataProps): JSX.Element => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...style
    }}
  >
    <Logo width="100%" height="100%" />
    <h3 style={{ marginTop: 8, marginBottom: 0, fontSize: 12.87 }}>
      {title || 'No Data Available'}
    </h3>
    <div style={{ fontSize: 11, minHeight: 11, textAlign: 'center' }}>
      {!hideDescription && 'Currently, there are no data available!'}
    </div>
  </div>
);

export default NoData;
