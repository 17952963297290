import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { userExtend } from 'containers/App/actions';
import { useAuthUser } from 'hooks';

const IdleHandler = () => {
  const authUser = useAuthUser();
  const dispatch = useDispatch();

  const getTimeout = () => {
    const now = moment();
    const expiry = moment(authUser.expires_at);

    const timeout = expiry.diff(now);
    return timeout;
  };

  const extendToken = () => {
    const timeToExpiry = getTimeout();
    const buffer = 30 * 1000; // buffer 30 seconds
    const refreshTime = timeToExpiry - buffer;
    const tokenTimeout = setInterval(() => {
      //do what when token expire
      dispatch(userExtend(authUser));
    }, refreshTime);

    return () => {
      clearTimeout(tokenTimeout);
    };
  };

  useEffect(() => {
    return extendToken();
    // eslint-disable-next-line
  }, [authUser]);

  return <div />;
};

export default IdleHandler;
