import React, { memo, useCallback, useMemo } from 'react';
import { Badge, Card, Empty, Tooltip } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GridItem } from 'components/Display/GridRenderer';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { vsGraphData } from 'containers/Analytics/pages/graphs/dummyData';
import { GraphContent } from 'containers/Analytics/styles';
import { showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardExtra from '../../components/GraphCardExtra';

const VSGraph = ({ identifier, title, helpText, filterParams }) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, token } = useMetabase(
    questionId,
    dataParams
  );
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    // Causes page to crash when date range selector is toggled
    // if (isDataValidating) return <Spin size="large" />;
    return <Graph {...data} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

VSGraph.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  filterParams: PropTypes.object
};

export default memo(VSGraph);

const Graph = memo((data: any) => {
  const { isDemoMode } = useAnalyticsContext();

  const allDataRowItemEmpty = data?.data?.rows?.every(arrVal => arrVal.every(val => !val));

  if ((_isEmpty(data?.data?.rows) || allDataRowItemEmpty) && !isDemoMode)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  let d = (data?.data?.rows || []).map(row => ({
    name: 'users',
    new: row[0],
    returning: row[1],
    total: row[0] + row[1]
  }));

  if (isDemoMode) {
    d = vsGraphData;
  }

  const Bar = styled.div`
    display: inline-block;
    text-align: center;
    padding: 30px 0;
    font-size: 12px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  `;

  const CustomBadge = styled(Badge)`
    .ant-badge-status-dot {
      height: 14px;
      width: 14px;
    }
    .ant-badge-status-text {
      margin-left: 5px;
      margin-right: 5px;
    }
  `;

  const calculatePercentage = (number, total) => {
    if (total < 1) {
      return '0';
    }
    return ((number / total) * 100).toFixed() + '%';
  };

  return (
    <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
      <div style={{ maxWidth: '98%' }}>
        {d[0].new > 0 && (
          <Tooltip title={`New Users: ${d[0].new}`}>
            <Bar
              style={{
                width: calculatePercentage(d[0].new, d[0].total),
                background: '#df5bba'
              }}
            >
              {d[0].new}
            </Bar>
          </Tooltip>
        )}
        {d[0].returning > 0 && (
          <Tooltip title={`Returning Users: ${d[0].returning}`}>
            <Bar
              style={{
                width: calculatePercentage(d[0].returning, d[0].total),
                background: '#53a8e2'
              }}
            >
              {d[0].returning}
            </Bar>
          </Tooltip>
        )}
      </div>
      <div style={{ textAlign: 'right' }}>
        <CustomBadge color="#df5bba" text="New Users" />
        <CustomBadge color="#53a8e2" text="Returning Users" />
      </div>
    </div>
  );
});
