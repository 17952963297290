export const EventEmitter = {
  events: {},
  dispatch: function (eventName, data) {
    if (!this.events[eventName]) {
      return;
    }
    this.events[eventName].forEach(callback => callback(data));
  },
  subscribe: function (eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);

    return () => {
      this.events[eventName] = this.events[eventName].filter(eventFn => callback !== eventFn);
    };
  }
};

export const EVENTS = {
  REFRESH_INVENTORY: 'REFRESH_INVENTORY',
  BATCH_CREATED_SUCCESS: 'BATCH_CREATED_SUCCESS',
  REFRESH_INVENTORY_REWARDS: 'REFRESH_INVENTORY_REWARDS',
  REFRESH_INVENTORY_BATCHES: 'REFRESH_INVENTORY_BATCHES',
  REFRESH_BATCH_REWARDS: 'REFRESH_BATCH_REWARDS',
  REFRESH_BATCH: 'REFRESH_BATCH',
  REWARD_UPDATED: 'REWARD_UPDATED',
  BATCH_UPDATED: 'BATCH_UPDATED',
  REWARD_ISSUED: 'REWARD_ISSUED',
  REWARD_ISSUE_ERROR: 'REWARD_ISSUE_ERROR',
  FEATURE_UPDATED: 'FEATURE_UPDATED',
  CUSTOMER_LOYALTY_UPDATED: 'CUSTOMER_LOYALTY_UPDATED'
};
