import { cdnURL } from 'utils/helpers';

export const Background = [
  {
    filename: 'quiz_background.png',
    image_url: cdnURL('quiz_background.png')
  }
];

// export const CardBackground = [
//   {
//     filename: 'quiz_card.png',
//     image_url: cdnURL('quiz_card.png')
//   }
// ];

export const OutcomeImage = [
  {
    filename: 'tree_gift.png',
    image_url: cdnURL('tree_gift.png')
  }
];
