import React from 'react';

import { useSimpleLoyaltyList } from 'api/simple';
import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { FItemSelect } from 'components/formik';
import { policyOptions } from 'containers/Audience/utils';

const LoyaltyTier: React.FC = () => {
  const { data: loyalties = [] } = useSimpleLoyaltyList();

  const fieldPath = 'filters.loyalty_tier.criteria';

  const options = loyalties.flatMap(program =>
    program.tiers.map(tier => ({
      label: `${program.name} / ${tier.name} `,
      value: tier.id
    }))
  );

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
      </FlexContainer>
      <Label
        bold
        style={{ display: 'inline-block', marginBottom: 8 }}
        htmlFor={`${fieldPath}.loyalty_tier_ids`}
      >
        Users who achieved
      </Label>
      <div style={{ flex: '1 1 auto' }}>
        <FItemSelect
          name={`${fieldPath}.loyalty_tier_ids`}
          mode="multiple"
          placeholder="Select program and tier"
          showArrow
          options={options}
        />
      </div>
    </>
  );
};

export default LoyaltyTier;
