import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { TableMainText, TableSecondaryText } from 'styles';

import { useLeaderboard } from 'api/leaderboards';
import { StatusTag, TableTimestamp } from 'components';
import DeleteIconButton from 'components/DeleteIconButton';

interface SelectedLeaderboardProps {
  id: number;
  onDelete: (id: number) => void;
}

const SelectedLeaderboard = ({ id, onDelete = () => {} }: SelectedLeaderboardProps) => {
  const { data } = useLeaderboard(id);

  if (data) {
    return (
      <Wrapper>
        <Content>
          <Row justify="space-around" align="middle" gutter={8}>
            <Col span={6}>
              <Item>
                <TableMainText>{data.internal_name}</TableMainText>
                <TableSecondaryText>ID:&nbsp;{data.id}</TableSecondaryText>
              </Item>
            </Col>
            <Col span={6}>
              <StatusTag state={data.state} />
            </Col>
            <Col span={5}>
              <TableTimestamp timestamp={data.start_date} />
            </Col>
            <Col span={5}>
              <TableTimestamp timestamp={data.end_date} />
            </Col>
            <Col span={2}>
              <DeleteIconButton onClick={() => onDelete(data.id)} />
            </Col>
          </Row>
        </Content>
      </Wrapper>
    );
  }

  return null;
};

export default SelectedLeaderboard;

const Wrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin: 0.5rem 1rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
