import React, { memo, useCallback, useState } from 'react';
import { Button, Col, Radio, Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { useImmerReducer } from 'use-immer';

import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import { PageContent } from 'components/page';
import { CategorySelect, MerchantSelect, RewardSelect } from 'components/selects';
import CheckableTag from 'containers/Analytics/components/CheckableTag';
import FunnelGraphWrapper from 'containers/Analytics/pages/graphs/FunnelGraph';
import { CardsRow } from 'containers/Analytics/styles';
import { useTenant } from 'hooks';

import AnalyticsCard from '../components/AnalyticsCard';
import {
  DonutPercentageChart,
  HeatMapGraph,
  HistogramGraph,
  PizzaPieChart,
  RewardsOverviewGraph,
  TopNGraph,
  VSGraph
} from './graphs';

// https://docs.google.com/spreadsheets/d/10DEmODF-HfZeLvDhCsGl7IXElHFHDvNFg8lXeV9WrDs/edit#gid=0

const initialState = {};

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_REWARD_TYPE':
      return void (draft['reward_type'] = action.payload);
    case 'SET_REDEMPTION_TYPE':
      return void (draft['redemption_type'] = action.payload);
    case 'SET_MERCHANT':
      return void (draft['merchant_account_id'] = action.payload);
    case 'SET_REWARD':
      return void (draft['reward_campaign_id'] = action.payload);
    case 'SET_CATEGORY':
      return void (draft['category_id'] = action.payload);
    case 'RESET':
      return initialState;
    default:
      break;
  }
};

const Rewards = () => {
  const [rewardFilters, dispatch] = useImmerReducer(reducer, initialState);
  const [showRewardName, setShowRewardName] = useState(false);
  const [showRewardType, setShowRewardType] = useState(false);
  const [showRedemptionType, setShowRedemptionType] = useState(false);
  const [showMerchant, setShowMerchant] = useState(false);
  const [showCategory] = useState(false);
  const { name: tenantName } = useTenant();

  const [filtersState, setFiltersState] = useState({});
  const onApplyFilters = useCallback(() => {
    setFiltersState({
      ...rewardFilters,
      ...(rewardFilters?.merchant_account_id?.length
        ? {
            merchant_account_id: rewardFilters.merchant_account_id.join(',')
          }
        : {}),
      ...(rewardFilters?.reward_campaign_id?.length
        ? {
            reward_campaign_id: rewardFilters.reward_campaign_id.join(',')
          }
        : {}),
      ...(rewardFilters?.category_id?.length
        ? {
            category_id: rewardFilters.category_id.join(',')
          }
        : {})
    });
  }, [rewardFilters]);
  return (
    <PageContent>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div style={{ background: '#e7ebf2', padding: '1.25rem' }}>
            <div>
              <CheckableTag
                checked={showRewardName}
                onChange={() => setShowRewardName(prev => !prev)}
              >
                Reward Name
              </CheckableTag>
              <CheckableTag
                checked={showRewardType}
                onChange={() => setShowRewardType(prev => !prev)}
              >
                Reward Type
              </CheckableTag>
              <CheckableTag
                checked={showRedemptionType}
                onChange={() => setShowRedemptionType(prev => !prev)}
              >
                Redemption Type
              </CheckableTag>
              <CheckableTag checked={showMerchant} onChange={() => setShowMerchant(prev => !prev)}>
                Merchant
              </CheckableTag>
              {/*<CheckableTag checked={showCategory} onChange={() => setShowCategory(prev => !prev)}>*/}
              {/*  Category*/}
              {/*</CheckableTag>*/}
            </div>

            {showRewardName && (
              <Row style={{ margin: '1.5rem 0' }} align="middle">
                <Col span={6}>Reward Name</Col>
                <Col span={18}>
                  <RewardSelect
                    allowClear
                    autoClearSearchValue
                    filterOption={false}
                    value={rewardFilters?.reward_campaign_id}
                    mode="multiple"
                    placeholder="Filter by rewards"
                    onChange={selectedRewardIds => {
                      dispatch({ type: 'SET_REWARD', payload: selectedRewardIds });
                    }}
                  />
                </Col>
              </Row>
            )}

            {showRewardType && (
              <Row style={{ margin: '1.5rem 0' }} align="middle">
                <Col span={6}>Reward Type</Col>
                <Col span={18}>
                  <Radio.Group
                    name="reward_type"
                    value={rewardFilters?.reward_type}
                    onChange={evt => {
                      dispatch({ type: 'SET_REWARD_TYPE', payload: evt.target.value });
                    }}
                  >
                    <Radio value="paid">Paid</Radio>
                    <Radio value="free">Free</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            )}

            {showRedemptionType && (
              <Row style={{ margin: '1.5rem 0' }} align="middle">
                <Col span={6}>Redemption Type</Col>
                <Col span={18}>
                  <Radio.Group
                    value={rewardFilters?.redemption_type}
                    name="redemption_type"
                    onChange={evt =>
                      dispatch({ type: 'SET_REDEMPTION_TYPE', payload: evt.target.value })
                    }
                  >
                    <Radio value="online">Online</Radio>
                    <Radio value="offline">Offline</Radio>
                    <Radio value="pin">Pin</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            )}
            {showMerchant && (
              <Row style={{ margin: '1.5rem 0' }} align="middle">
                <Col span={6}>Merchant</Col>
                <Col span={18}>
                  <MerchantSelect
                    value={rewardFilters?.merchant_account_id}
                    mode="multiple"
                    filterOption={false}
                    placeholder="Filter by merchants"
                    onChange={merchantIds => {
                      dispatch({ type: 'SET_MERCHANT', payload: merchantIds });
                    }}
                  />
                </Col>
              </Row>
            )}

            {showCategory && (
              <Row style={{ margin: '1.5rem 0' }} align="middle">
                <Col span={6}>Category</Col>
                <Col span={18}>
                  <CategorySelect
                    value={rewardFilters?.category_id || []}
                    onChange={id => {
                      dispatch({ type: 'SET_CATEGORY', payload: id });
                    }}
                    placeholder="Please select your categories"
                    usage="rewards"
                    multiple
                  />
                </Col>
              </Row>
            )}

            {(showRewardName ||
              showRewardType ||
              showRedemptionType ||
              showMerchant ||
              showCategory) && (
              <Row justify="end" style={{ marginTop: '1rem' }}>
                <Button
                  type="primary"
                  ghost
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    dispatch({ type: 'RESET' });
                    setFiltersState({});
                  }}
                >
                  Reset
                </Button>
                <Button type="primary" disabled={_isEmpty(rewardFilters)} onClick={onApplyFilters}>
                  Apply
                </Button>
              </Row>
            )}
          </div>
        </Col>

        <Col xs={24}>
          <RewardsOverviewGraph filterParams={filtersState} />
        </Col>
      </Row>

      <CardsRow>
        <AnalyticsCard
          identifier="reward_flat_total_reward_revenue"
          description="Total Reward Revenue"
          filterParams={filtersState}
          helpText="How much you have made from selling rewards. The formula is (selling price * number of paid rewards issued) in the time period selected."
        />
        <AnalyticsCard
          identifier="reward_flat_total_view"
          description="Total Views"
          filterParams={filtersState}
          helpText="Total number of rewards your users have viewed"
          isInteger
        />
        <AnalyticsCard
          identifier="reward_flat_issued"
          description="Rewards Issued"
          filterParams={filtersState}
          helpText="Total number of rewards your users have been issued"
          isInteger
        />
        <AnalyticsCard
          identifier="reward_flat_redemption"
          description="Rewards Redeemed"
          filterParams={filtersState}
          helpText="Total number of rewards your users have redeemed"
          isInteger
        />
        <AnalyticsCard
          identifier="reward_flat_redemption_rate"
          description="Redemption Rate"
          isPercentage
          filterParams={filtersState}
          helpText="How well your rewards have performed. The formula is (total number of reward vouchers redeemed / total number of reward vouchers issued)."
        />
        <AnalyticsCard
          identifier="reward_flat_engagement_rate"
          description="Browsing Rate"
          isPercentage
          filterParams={filtersState}
          helpText="How well your rewards have performed. The formula is (total number of reward vouchers redeemed / total number of reward vouchers issued)."
        />
      </CardsRow>

      <GridRenderer perRow={2} gutter={16}>
        <HeatMapGraph
          identifier="reward_flat_popular_issuance_day"
          title="Popular Issuance Days"
          filterParams={filtersState}
          helpText="Which specific day and timing users have been issued the most vouchers. The level of vouchers issued is indicated by the intensity of the colour spectrum."
        />
        <TopNGraph
          identifier="overview_flat_top_reward"
          title="Top 5 Popular Rewards"
          helpText="Top 5 popular rewards based on the number of vouchers issued"
        />
        <TopNGraph
          identifier="overview_flat_top_catalog"
          title="Top 5 Popular Catalogs"
          helpText="Top 5 popular catalogs based on the number of rewards views"
        />
        <TopNGraph
          identifier="reward_flat_top_categories"
          title="Top performing categories"
          helpText="Top performing categories based on the number of rewards views"
        />
        <FunnelGraphWrapper
          identifier="reward_flat_funnel"
          title="Funnel View"
          filterParams={filtersState}
          helpText="Funnel View Graph for (View, Issue, Redeem, Gift, Expire)"
        />
        {tenantName !== TENANTS.RAZER && (
          <HistogramGraph
            identifier="reward_flat_age"
            title="Age"
            filterParams={filtersState}
            helpText="Age groups that have been issued the most reward vouchers."
          />
        )}
        {tenantName !== TENANTS.RAZER && (
          <DonutPercentageChart
            identifier="reward_flat_gender"
            title="Gender"
            filterParams={filtersState}
            helpText="Gender groups that have been issued reward vouchers."
            innerLabel="Total Users"
          />
        )}
        {tenantName !== TENANTS.RAZER && (
          <PizzaPieChart
            identifier="reward_flat_race"
            title="Ethnicity"
            filterParams={filtersState}
            helpText="Ethnicity groups that have been issued reward vouchers."
          />
        )}
        {tenantName !== TENANTS.RAZER && (
          <DonutPercentageChart
            identifier="reward_flat_user_plan"
            title="User Plans"
            filterParams={filtersState}
            helpText="Which user plans have been issued the most reward vouchers."
            innerLabel="Total Users"
          />
        )}
        <VSGraph
          identifier="reward_flat_new_vs_returning_user"
          title="New vs Returning Users based on Issued Rewards"
          filterParams={filtersState}
          helpText="Percentages of first time users vs returning users have been issued the reward vouchers"
        />
      </GridRenderer>
    </PageContent>
  );
};

export default memo(Rewards);
