import React from 'react';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Field } from 'formik';

interface Props {}

const formItemLayout = {
  labelAlign: 'right',
  labelCol: {
    xs: { span: 24 },
    md: { span: 6 },
    lg: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 14 },
    lg: { span: 16 }
  }
} as const;

const AudienceEssentials = (props: Props) => {
  return (
    <>
      <Field name="name">
        {({ field, meta }) => {
          const hasError = meta.error !== undefined;
          return (
            <FormItem
              label="Audience name"
              validateStatus={meta.error ? 'error' : undefined}
              hasFeedback={!meta.error}
              help={hasError && <>{meta.error}</>}
              required
              htmlFor={field.name}
              {...formItemLayout}
            >
              <Input
                allowClear
                placeholder="Enter audience name"
                autoFocus
                id={field.name}
                {...field}
              />
            </FormItem>
          );
        }}
      </Field>
    </>
  );
};

export default AudienceEssentials;
