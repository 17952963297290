import React, { useState, useMemo, useCallback, memo, useEffect } from 'react';
import { Card, Spin, Radio, Tooltip, Empty } from 'antd';
import { Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip as ChartTooltip
} from 'recharts';

import DatePartSelect, { DatePart } from 'containers/Analytics/components/DatePartSelect';
import { useMetabase, useAnalyticsContext, useCard } from 'containers/Analytics/hooks';
import { abbreviateNumber, getDatePart, colorGenerator } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import LoyaltyTooltip from './LoyaltyTooltip';
import { getLineChartMembershipOverTimeData } from './dummyData';

function truncate(input) {
  const limit = 50;
  if (input.length > limit) {
    return input.substring(0, limit) + '...';
  }
  return input;
}

const CampaignsEngagementGraph: React.FC<{
  identifier: string[];
  filterParams?: object;
  yAxisLabel?: string;
}> = ({ identifier, filterParams, yAxisLabel = 'Stamp Cards Issued' }) => {
  const [activeLegend, setActiveLegend] = useState(null);
  const [activeTab, setActiveTab] = useState('Engagements');
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const color = colorGenerator();

  const QUESTIONS_IDENTIFIERS = {
    Engagements: identifier[0],
    Views: identifier[1],
    Completions: identifier[2]
  };

  const dateParams = useMemo(() => ({ datepart }), [datepart]);
  const dataParams = useMemo(
    () => ({ ...filterParams, ...dateParams }),
    [filterParams, dateParams]
  );
  // const dataParams = { ...dateParams, ...filterParams };

  const { card_id: questionId } = useCard(QUESTIONS_IDENTIFIERS[activeTab]);
  const { isDemoMode, dateRange } = useAnalyticsContext();
  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );
  const hasError = tokenError || dataError;
  const tabs = ['Engagements', 'Views', 'Completions'];
  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');
  const onDatepartChange = useCallback((val: DatePart) => setDatepart(val), []);

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
    // eslint-disable-next-line
  }, [diff]);

  const onActiveTabChange = useCallback(evt => {
    setActiveTab(evt.target.value);
  }, []);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  let d = data?.data?.rows.map((row: Array<[number, string, string]>) => ({
    user_count: row[0],
    loyalty_tier: row[2],
    time: row[1]
  }));

  if (isDemoMode) {
    d = getLineChartMembershipOverTimeData(dateRange[0], dateRange[1], 1, datepart);
  }

  const tiers = useMemo(() => _.chain(d).groupBy('loyalty_tier').keys().sort().value(), [d]);

  const groupedLoyaltyTiers = useMemo(
    () =>
      _.chain(d)
        .groupBy('time')
        .map(x =>
          _.reduce(x, (acc, val) => ({ ...acc, [val.loyalty_tier]: val.user_count }), {
            time: x[0].time // time is the same since it is the groupby key
          })
        )
        .value(),
    [d]
  );

  const onMouseEnter = useCallback(legend => {
    const { dataKey } = legend;
    setActiveLegend(dataKey);
  }, []);

  const onMouseLeave = useCallback(_ => {
    setActiveLegend(null);
  }, []);

  const renderFormatter = useCallback((value: null | string) => {
    return (
      <Tooltip title={value}>
        <span
          style={{
            fontSize: 12,
            color: '#8c8c8c',
            cursor: 'pointer'
          }}
        >
          {truncate(value)}
        </span>
      </Tooltip>
    );
  }, []);

  const renderGraph = () => {
    let graph;
    if (!_.isEmpty(groupedLoyaltyTiers)) {
      graph = (
        <LineChart data={groupedLoyaltyTiers}>
          <CartesianGrid
            vertical={false}
            strokeDasharray="0 0"
            fillOpacity="0.5"
            stroke="#f5f5f5"
          />
          <XAxis
            dataKey="time"
            stroke="#9b9b9b"
            padding={{ left: 16, right: 16 }}
            tick={{ strokeWidth: 1, fontSize: 11, fill: '#9b9b9b', dy: 16 }}
            tickSize={3}
            tickFormatter={(tick: string) => moment(tick).format('MMM D')}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#d9d9d9', fontSize: 12 }}
            tickFormatter={(tick: number) => abbreviateNumber(tick)}
            label={{
              value: yAxisLabel,
              angle: -90,
              fill: '#595959',
              fontSize: 11,
              fillOpacity: 0.5,
              dx: -24
            }}
          />
          <ChartTooltip
            animationEasing="ease-in-out"
            wrapperStyle={{
              boxShadow: '0px 0px 12px rgba(0,0,0,.08)',
              border: 'none',
              background: '#ffffff',
              zIndex: 1
            }}
            content={<LoyaltyTooltip title={activeTab} showTotal={true} />}
            formatter={value => value.toLocaleString()}
            cursor={{ stroke: '#8c8c8c', fillOpacity: 0.5, strokeWidth: 1 }}
            itemStyle={{ fontSize: 12 }}
            labelStyle={{ display: 'none', visibility: 'hidden', pointerEvents: 'none' }}
          />
          <Legend
            iconType="line"
            iconSize={12}
            wrapperStyle={{
              height: '100%',
              overflowY: 'scroll',
              paddingLeft: 12
            }}
            width={360}
            formatter={renderFormatter}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            layout="vertical"
            verticalAlign="top"
            align="right"
          />
          {tiers.map((tier: string, index: number) => (
            <Line
              type="monotone"
              key={tier}
              dataKey={tier}
              stroke={color.next().value}
              strokeOpacity={!activeLegend ? 1 : activeLegend === tier ? 1 : 0.1}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      );
    } else {
      graph = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
      <ResponsiveContainer debounce={500} height={232}>
        {graph}
      </ResponsiveContainer>
    );
  };

  return (
    <Card
      title="Performance Over Time"
      bodyStyle={{ paddingTop: '0px' }}
      headStyle={{ borderBottom: '0px', paddingTop: '15px', paddingLeft: '25px' }}
    >
      <Row justify="space-between" style={{ marginBottom: '4rem' }}>
        <Radio.Group name="tab" value={activeTab} onChange={onActiveTabChange}>
          {tabs.map(t => (
            <Radio.Button value={t} key={t}>
              {_.startCase(t)}
            </Radio.Button>
          ))}
        </Radio.Group>

        <DatePartSelect value={datepart} onChange={onDatepartChange} />
      </Row>

      {hasError && <Error retry={onRetry} />}
      {!d && !hasError && (
        <Row justify="center" align="middle" style={{ height: '250px' }}>
          <Spin size="large" />
        </Row>
      )}
      <div style={{ marginBottom: '30px' }}>{d && renderGraph()}</div>
    </Card>
  );
};

export default memo(CampaignsEngagementGraph);
