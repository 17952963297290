import React, { useContext } from 'react';
import _isNumber from 'lodash/isNumber';
import styled, { css } from 'styled-components';

export const GridContext = React.createContext({
  gutter: 8,
  perRow: 3,
  breakpoint: 1199,
  breaks: false
});

export const GridRenderer = ({ children, gutter, perRow, breakpoint = 1199, breaks = false }) => {
  let usableGutter = 8;
  if (_isNumber(gutter) && gutter > 0) {
    usableGutter = gutter / 2;
  }
  let items = 3;
  if (perRow > 0) {
    items = perRow;
  }

  return (
    <GridRow gutter={usableGutter}>
      <GridContext.Provider
        value={{ gutter: usableGutter, perRow: items, breakpoint: breakpoint, breaks: breaks }}
      >
        {children}
      </GridContext.Provider>
    </GridRow>
  );
};

interface GridItemProps {
  children: JSX.Element;
  itemsPerRow?: number;
  occupies?: number;
}

export const GridItem = ({ children, itemsPerRow = 0, occupies = 1 }: GridItemProps) => {
  const { gutter, perRow, breakpoint, breaks } = useContext(GridContext);

  return (
    <Gridded
      gutter={gutter}
      perRow={itemsPerRow > 0 ? itemsPerRow : perRow}
      occupies={occupies}
      breakpoint={breakpoint}
      breaks={breaks}
    >
      {children}
    </Gridded>
  );
};

const GridRow = styled.div<{ gutter: number }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${props => props.gutter}px;
`;

interface GriddedProps {
  gutter: number;
  perRow: number;
  occupies: number;
  breaks: any;
  breakpoint: number;
}

const Gridded = styled.div<GriddedProps>`
  flex-basis: calc(${props => `${(100 / props.perRow) * props.occupies}% - ${props.gutter * 2}px`});
  max-width: calc(${props => `${(100 / props.perRow) * props.occupies}% - ${props.gutter * 2}px`});
  margin: ${props => `0 ${props.gutter}px ${props.gutter * 2}px`};

  > * {
    height: 100%;
  }

  ${props =>
    props.breaks &&
    css`
      @media screen and (max-width: ${props.breakpoint}px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    `}
`;
