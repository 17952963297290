import React, { useCallback, useMemo } from 'react';
import { Card, Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../../components/Error';
import GraphCardExtra from '../../../components/GraphCardExtra';
import { ToolTipProps } from '../HistogramGraph';
import { getPieData } from '../dummyData';
import Percent from './Percent';

interface PizzaPieChartProps {
  identifier: string;
  title: string;
  helpText?: string;
  filterParams?: any;
}

const PizzaPieChart = ({ identifier, title, helpText, filterParams }: PizzaPieChartProps) => {
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, isDataValidating, token } =
    useMetabase(questionId, dataParams);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <Graph data={data} title={title} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const RADIAN = Math.PI / 180;

const renderPercentLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent < 0.06) return null;
  return (
    <text
      x={x}
      y={y}
      // dy={5}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize="11px"
      fontSizeAdjust="0.58"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Graph = ({ data, title }) => {
  const color = colorGenerator();
  const { isDemoMode } = useAnalyticsContext();

  if (_isEmpty(data?.data?.rows) && !isDemoMode)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  let d = data?.data?.rows.map(row => ({
    value: row[0],
    name: row[1],
    color: color.next().value
  }));
  if (isDemoMode) {
    d = getPieData(title.toLowerCase());
  }
  const totalCount = d ? d.reduce((acc, x) => acc + x.value, 0) : 0;
  const percents = d.map(x => ({
    name: x.name,
    percent: x.value / totalCount,
    ...x
  }));
  // sort ascending for legend
  percents.sort((a, b) => b.percent - a.percent);
  return (
    <>
      <ResponsiveContainer width={'65%'}>
        <PieChart>
          <Pie dataKey="value" data={d} fill="#fb7901" labelLine={false} label={renderPercentLabel}>
            {d.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={entry.color} />;
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
      <div style={{ width: '35%' }}>
        {percents.map((percent, index) => (
          <Percent key={index} {...percent} />
        ))}
      </div>
    </>
  );
};

export default PizzaPieChart;

export const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  if (active && payload && payload.length > 0) {
    const bgColor = payload[0].payload.fill;
    return (
      <div
        style={{
          background: bgColor,
          color: '#ffffff',
          padding: '0.5rem',
          borderRadius: 2,
          boxShadow:
            ' 0 13.2px 10.2px rgba(0, 0, 0, 0.012), 0 44.2px 34.4px rgba(0, 0, 0, 0.029), 0 198px 154px rgba(0, 0, 0, 0.07)'
        }}
      >
        <div>{payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#e4e4e4', fontSize: 11 }}>{_startCase(payload[0].name)}</div>
      </div>
    );
  }
  return null;
};
