import React, { memo } from 'react';
import { Input, Button } from 'antd';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import ModalFormFooter from 'components/Form/ModalFormFooter';
import FormItem from 'components/Form/StyledFormItem';

const validationSchema = yup.object().shape({
  ratio_description: yup.string().required('Description is required'),
  image_section: yup.string().required('Section name is required')
});

const MediaForm = ({ media, onSave, onCancel }) => {
  const initialValues = media ? media : { ratio_description: '', image_section: '' };
  const onSubmit = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      ratio_description:
        values['ratio_description']?.charAt(0).toLowerCase() + values['ratio_description'].slice(1)
    };
    onSave(updatedValues);
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ isSubmitting, resetForm }) => (
        <Form>
          <Field name="ratio_description">
            {({ field, meta }) => {
              const hasError = meta.error !== undefined;
              const isValid = meta.touched && !meta.error;
              return (
                <FormItem
                  label="Media Name"
                  validateStatus={meta.error && meta.touched ? 'error' : undefined}
                  hasFeedback={isValid}
                  help={hasError && meta.touched && <>{meta.error}</>}
                  required
                >
                  <Input allowClear placeholder="Your media name" {...field} />
                </FormItem>
              );
            }}
          </Field>
          <Field name="image_section">
            {({ field, meta }) => {
              const hasError = meta.error !== undefined;
              const isValid = meta.touched && !meta.error;
              return (
                <FormItem
                  label="Value"
                  validateStatus={meta.error && meta.touched ? 'error' : undefined}
                  hasFeedback={isValid}
                  help={hasError && meta.touched && <>{meta.error}</>}
                  required
                >
                  <Input allowClear placeholder="Your media value" {...field} />
                </FormItem>
              );
            }}
          </Field>
          <ModalFormFooter>
            <Button
              type="default"
              onClick={() => {
                resetForm();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button htmlType="submit" loading={isSubmitting} type="primary">
              Save
            </Button>
          </ModalFormFooter>
        </Form>
      )}
    </Formik>
  );
};

MediaForm.propTypes = {
  media: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default memo(MediaForm);
