import React from 'react';
import { Layout, Typography } from 'antd';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ZealLogo from 'containers/App/pages/zeal_logo.png';
import { ReactComponent as PerxLogo } from 'pages/public/login_logo.svg';

import HeroImg from '../main_image.jpg';

const isZeal = hostname => hostname.includes('bltz') || hostname.includes('zeal');

const PublicPage = ({ title, subtitle, form }) => {
  return (
    <Layout>
      <Layout.Content>
        <Row align="middle">
          <Sider>
            <PerxLogoWrapper>
              {isZeal(window.location.hostname) ? (
                <img src={ZealLogo} alt="Zeal Logo" width="50px" />
              ) : (
                <PerxLogo />
              )}
            </PerxLogoWrapper>
            <Typography.Title level={1}>{title}</Typography.Title>
            <Typography.Text type="secondary">{subtitle}</Typography.Text>
            <SideForm>{form}</SideForm>
          </Sider>
          <Content>
            <Wrapper>
              <MainCopy>
                <div>Influence and drive</div>
                <div>
                  customer actions <Emphasis>that matter</Emphasis>
                </div>
              </MainCopy>
              <HeroImage>
                <img
                  src={HeroImg}
                  alt="Customers Engagement"
                  style={{ width: '100%', objectFit: 'cover' }}
                />
              </HeroImage>
            </Wrapper>
          </Content>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

const Sider = styled.aside`
  background: #ffffff;
  padding: 2rem;
  align-self: center;
  height: auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 40%;
  }
  @media (min-width: 992px) {
    width: 30rem;
  }
  @media (min-height: 580px) {
    height: 100vh;
  }
`;

const Content = styled.div`
  flex: 2;
  flex-grow: 1;
  align-self: center;
  max-height: 100vh;
`;

const MainCopy = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  color: #000000;
  margin-bottom: 1rem;
  line-height: 3rem;
`;

const Emphasis = styled.span`
  font-weight: 500;
`;

const Wrapper = styled.div`
  padding: 9vh 5vw;
  text-align: center;
`;

const SideForm = styled.div`
  margin-top: 2.5rem;
`;

const PerxLogoWrapper = styled.div`
  margin: 4vh 0;
  max-width: 7.7rem;
  height: auto;
  @media (min-width: 768px) {
    margin: 8vh 0;
  }
`;

const HeroImage = styled.div`
  max-width: 60vh;
  margin: 0 auto;
  @media (max-width: 992px) {
    max-width: 50vh;
  }
`;

PublicPage.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  form: PropTypes.node.isRequired
};

PublicPage.defaultProps = {
  title: '',
  subtitle: ''
};

export default PublicPage;
