import styled from 'styled-components';

const StyledTitle = styled.strong<{ textAlign?: string; display?: string }>`
  font-size: 25px;
  font-weight: normal;
  color: #000;
  text-align: ${({ textAlign }) => textAlign || ''};
  display: ${({ display }) => display || ''};
`;

export default StyledTitle;
