import React from 'react';
import { Typography } from 'antd';
import groupBy from 'lodash/groupBy';
import styled from 'styled-components';

import IconCheckbox from 'components/IconCheckbox';

const { Title } = Typography;

const TargetingCriteria = ({ activeKeys, options = [] }) => {
  const groupedByCategory = groupBy(options, 'category');

  return (
    <div>
      {Object.keys(groupedByCategory).map(category => (
        <CategoryContainer key={category}>
          <Title level={5}>{category}</Title>
          <OptionsContainer>
            {groupedByCategory[category].map(option => (
              <IconCheckbox
                key={option.value}
                value={option.value}
                onChange={e => {
                  option.onChange(e.target.checked);
                }}
                checked={activeKeys.includes(option.value)}
                icon={option.icon}
                label={option.label}
              />
            ))}
          </OptionsContainer>
        </CategoryContainer>
      ))}
    </div>
  );
};

const CategoryContainer = styled.div`
  margin-bottom: 16px;
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export default TargetingCriteria;
