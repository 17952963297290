import { takeLatest, put, call, select } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';
import { EventEmitter, EVENTS } from 'utils/events';
import { checkPayloadForFile } from 'utils/prepareFormData';

import {
  searchCustomerFail,
  getCustomerVouchersSuccess,
  getCustomerVouchersFail,
  getCustomersSuccess,
  getCustomersFail,
  searchCustomerSuccess,
  issueVoucherSuccess
} from './actions';
import {
  GET_CUSTOMER,
  GET_CUSTOMER_VOUCHERS,
  SEARCH_CUSTOMER,
  UPDATE_CUSTOMER,
  ACTIVATE_CUSTOMER,
  DEACTIVATE_CUSTOMER,
  GET_CUSTOMERS
} from './constants';

const searchCustomer = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getCustomers = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const getCustomerVouchers = (url, params, headers) => {
  return api({
    url: url,
    headers: headers,
    params: params
  });
};

const updateCustomer = (url, body, headers) => {
  return api({
    url: url,
    headers: headers,
    body: body,
    method: 'put'
  });
};

const postAPICall = (url, body, headers) => {
  body = checkPayloadForFile(body, headers);
  return api({
    url: url,
    headers: headers,
    body: body,
    method: 'post'
  });
};

function* customersFind(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(searchCustomer, `${API_ROOT}/v4/dash/customers`, params, headers);
    yield put(searchCustomerSuccess(response.data));
  } catch (e) {
    yield put(searchCustomerFail(e.data));
  }
}

function* customerVouchersGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      getCustomerVouchers,
      `${API_ROOT}/v4/dash/customers/${params.customer_id}/vouchers`,
      params,
      headers
    );
    yield put(getCustomerVouchersSuccess(response.data));
  } catch (e) {
    yield put(getCustomerVouchersFail(e.data));
  }
}

function* customersGet(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(getCustomers, `${API_ROOT}/v4/dash/customers`, params, headers);
    yield put(getCustomersSuccess(response.data));
  } catch (e) {
    yield put(getCustomersFail(e.data));
  }
}

function* issueRewards(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };
  try {
    const response = yield call(
      postAPICall,
      `${API_ROOT}/v4/dash/customers/${params.customer_id}/vouchers/issue_vouchers`,
      params,
      headers
    );
    yield put(issueVoucherSuccess(response.data));
    EventEmitter.dispatch(EVENTS.REWARD_ISSUED, response.data);
  } catch (e) {
    EventEmitter.dispatch(EVENTS.REWARD_ISSUE_ERROR, e.data);
    yield put(getCustomersFail(e.data));
  }
}

function* customerSaga() {
  yield takeLatest(GET_CUSTOMER, commonSaga);
  yield takeLatest(GET_CUSTOMER_VOUCHERS, customerVouchersGet);
  yield takeLatest(SEARCH_CUSTOMER, customersFind);
  //@ts-ignore
  yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(ACTIVATE_CUSTOMER, commonSaga);
  yield takeLatest(DEACTIVATE_CUSTOMER, commonSaga);
  yield takeLatest(GET_CUSTOMERS, customersGet);
  yield takeLatest('ISSUE_REWARDS', issueRewards);
}

export default customerSaga;
