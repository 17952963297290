import useSWR from 'swr';

import { fetcher } from './utils';

interface IMappingItem {
  id: string;
  name: string;
}

interface IMappingResponse {
  data: IMappingItem[];
}

const revalidateConfig = {
  revalidateIfStale: false,
  revalidateOnFocus: false
};

export const useGenderList = () => {
  const { data } = useSWR<IMappingResponse>(
    '/v4/dash/simple/personal_property_mappings/gender',
    fetcher,
    revalidateConfig
  );

  return { data: data?.data };
};

export const useEthnicityList = () => {
  const { data } = useSWR<IMappingResponse>(
    '/v4/dash/simple/personal_property_mappings/race',
    fetcher,
    revalidateConfig
  );

  return { data: data?.data };
};

export const useCityList = () => {
  const { data } = useSWR<IMappingResponse>(
    '/v4/dash/simple/personal_property_mappings/cities',
    fetcher,
    revalidateConfig
  );

  return { data: data?.data };
};

export const useStateList = () => {
  const { data } = useSWR<IMappingResponse>(
    '/v4/dash/simple/personal_property_mappings/states',
    fetcher,
    revalidateConfig
  );

  return { data: data?.data };
};

export interface ICustomMappingItem {
  key: string;
  label: string;
  type?: string;
  selectors?: string;
  mappings: Array<{
    id: string;
    name: string;
  }>;
}

export interface ICustomMappingResponse {
  data: ICustomMappingItem[];
}

export const useCustomPropertyList = () => {
  const { data } = useSWR<ICustomMappingResponse>(
    '/v4/dash/simple/personal_property_mappings/custom',
    fetcher,
    revalidateConfig
  );

  return { data: data?.data };
};
