const initialState = {
  loading: false,
  reward_transaction: null
};

export default function rewardTransactionReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_REWARD_TRANSACTION': {
      return {
        ...state,
        loading: true
      };
    }
    case 'GET_REWARD_TRANSACTION_SUCCESS': {
      return {
        ...state,
        loading: false,
        reward_transaction: action.payload.data
      };
    }

    default:
      return state;
  }
}
