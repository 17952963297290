import useSWR from 'swr';

import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export interface IMerchant {
  id: number;
  name: string;
  images: {}[];
  state: string;
  created_at: string;
  tags: {
    id: number;
    name: string;
    description: string;
  }[];
  activatable: boolean;
  deactivatable: boolean;
  is_featured: boolean;
  categories: any[];
  name_en: string;
  name_th: null | string;
  name_zh: null | string;
  description_en: null | string;
  description_th: null | string;
  description_zh: null | string;
}

export const useMerchant = (id: number) => {
  const { data, error, isLoading } = useSWR<IDetailResponse<IMerchant>>(
    id ? `/v4/dash/merchants/${id}` : null,
    fetcher
  );

  return { data: data?.data, error, isLoading };
};

export const useMerchantList = (params: object) => {
  const { data, error, isLoading } = useSWR<IPaginatedResponse<IMerchant>>(
    `/v4/dash/merchants?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, error, isLoading };
};
