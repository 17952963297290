import produce from 'immer';

import { UNLOCK_USER_FAIL, UNLOCK_USER_SUCCESS } from 'containers/Setting/constants';

import {
  AMEND_AUDIENCE,
  AMEND_AUDIENCE_FAIL,
  AMEND_AUDIENCE_SUCCESS,
  SET_AUDIENCE_ID,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  GET_FTP_FILE_LIST,
  GET_FTP_FILE_LIST_FAIL,
  GET_FTP_FILE_LIST_SUCCESS,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAIL,
  CREATE_LABEL,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAIL,
  UPDATE_LABEL,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_FAIL,
  DEACTIVATE_USER_GROUP,
  DEACTIVATE_USER_GROUP_SUCCESS,
  DEACTIVATE_USER_GROUP_FAIL,
  ACTIVATE_USER_GROUP,
  ACTIVATE_USER_GROUP_SUCCESS,
  ACTIVATE_USER_GROUP_FAIL,
  DEACTIVATE_ROLE,
  DEACTIVATE_ROLE_SUCCESS,
  DEACTIVATE_ROLE_FAIL,
  ACTIVATE_ROLE,
  ACTIVATE_ROLE_SUCCESS,
  ACTIVATE_ROLE_FAIL
} from './constants';

interface SettingsState {
  audienceId: any;
  roles: any;
  users: any;
  ftpFileList: any;
  ftpFiles?: any;
  user: any;
  labels: any;
  merchants: any;
  meta: any;
  loading: boolean;
}
const initialState: SettingsState = {
  audienceId: null,
  roles: null,
  users: [],
  ftpFileList: [],
  user: null,
  loading: false,
  labels: null,
  merchants: [],
  meta: {}
};

function settingReducer(state: SettingsState = initialState, action): SettingsState {
  switch (action.type) {
    case GET_FTP_FILE_LIST:
    case GET_LABELS:
    case CREATE_LABEL:
    case UPDATE_LABEL:
    case GET_ROLES:
    case GET_USERS:
    case GET_USER:
    case USER_CREATE:
    case USER_UPDATE:
    case DELETE_ROLE:
    case ACTIVATE_USER:
    case DEACTIVATE_USER:
    case AMEND_AUDIENCE:
    case ACTIVATE_USER_GROUP:
    case DEACTIVATE_USER_GROUP:
    case DEACTIVATE_ROLE:
    case ACTIVATE_ROLE: {
      return {
        ...state,
        loading: true
      };
    }

    case CREATE_LABEL_SUCCESS:
    case CREATE_LABEL_FAIL:
    case UPDATE_LABEL_SUCCESS:
    case UPDATE_LABEL_FAIL:
    case DELETE_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case AMEND_AUDIENCE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        meta: action.payload.meta
      };
    }

    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }

    case USER_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }

    case UNLOCK_USER_SUCCESS:
    case ACTIVATE_USER_SUCCESS:
    case ACTIVATE_USER_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }

    case DEACTIVATE_USER_SUCCESS:
    case DEACTIVATE_USER_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }
    case ACTIVATE_ROLE_SUCCESS:
    case DEACTIVATE_ROLE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case USER_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.data
      };
    }

    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        roles: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }

    case GET_FTP_FILE_LIST_SUCCESS: {
      return produce(state, draftState => {
        draftState.loading = false;
        draftState.ftpFiles = {
          data: action.payload.data,
          meta: action.payload.meta
        };
      });
    }

    case GET_LABELS_SUCCESS: {
      return produce(state, draft => {
        draft.loading = false;
        draft.labels = {
          data: action.payload.data,
          meta: action.payload.meta
        };
      });
    }

    case GET_FTP_FILE_LIST_FAIL:
    case GET_LABELS_FAIL:
    case GET_ROLES_FAILED:
    case GET_USER_FAILED:
    case USER_CREATE_FAIL:
    case GET_USERS_FAILED:
    case USER_UPDATE_FAIL:
    case DELETE_ROLE_FAIL:
    case DEACTIVATE_USER_FAIL:
    case ACTIVATE_USER_FAIL:
    case AMEND_AUDIENCE_FAIL:
    case ACTIVATE_USER_GROUP_FAIL:
    case DEACTIVATE_USER_GROUP_FAIL:
    case DEACTIVATE_ROLE_FAIL:
    case UNLOCK_USER_FAIL:
    case ACTIVATE_ROLE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_AUDIENCE_ID: {
      return {
        ...state,
        audienceId: action.payload,
        loading: false
      };
    }
    default:
      return state;
  }
}

export default settingReducer;
