import { takeLatest } from 'redux-saga/effects';

import { commonSaga } from '../../middleware/api';
import {
  CREATE_CAMPAIGN,
  GET_TAGS,
  GET_LOYALTY_LIST,
  GET_REWARDS,
  GET_CAMPAIGNS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCES,
  GET_CONDITIONS,
  EDIT_CAMPAIGN,
  SEARCH_CAMPAIGN,
  SEARCH_REWARD,
  ORDER_CAMPAIGN,
  DEACTIVATE_CAMPAIGN,
  ACTIVATE_CAMPAIGN
} from './constants';

function* campaignSaga() {
  yield takeLatest(GET_TAGS, commonSaga);
  yield takeLatest(GET_LOYALTY_LIST, commonSaga);
  yield takeLatest(GET_REWARDS, commonSaga);
  yield takeLatest(CREATE_CAMPAIGN, commonSaga);
  yield takeLatest(GET_CAMPAIGNS, commonSaga);
  yield takeLatest(EDIT_CAMPAIGN, commonSaga);
  yield takeLatest(GET_AUDIENCE_INFO, commonSaga);
  yield takeLatest(GET_AUDIENCES, commonSaga);
  yield takeLatest(GET_CONDITIONS, commonSaga);
  yield takeLatest(SEARCH_CAMPAIGN, commonSaga);
  yield takeLatest(SEARCH_REWARD, commonSaga);
  yield takeLatest(ORDER_CAMPAIGN, commonSaga);
  yield takeLatest(DEACTIVATE_CAMPAIGN, commonSaga);
  yield takeLatest(ACTIVATE_CAMPAIGN, commonSaga);
}

export default campaignSaga;
