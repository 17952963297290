import React, { createContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { message, Button } from 'antd';
import { Formik, Form } from 'formik';
import _pickBy from 'lodash/pickBy';

import { BottomBar, FormDebug } from 'components';
import { PageContent } from 'components/page';
import { useRouter, useTypedSelector } from 'hooks';
import { doesFilterHaveValue } from 'utils/audience';
import API from 'utils/request';
import serializeFormData from 'utils/serializeFormData';

import { Audience } from './steps';
import { validationSchema } from './validationSchema';

const defaultValues = {
  name: '',
  user_groups: [],
  filters: {}
};

export const FormContext = createContext({ isEditMode: false, locale: 'en' });

interface AudienceFormProps {
  initialValues?: any;
  isEditMode?: boolean;
}

const AudienceForm = ({ initialValues = {}, isEditMode = false }: AudienceFormProps) => {
  const { history } = useRouter();
  const params = useParams<{ id: string }>();
  const selectedLocale = useTypedSelector(state => state.global?.selectedLocale);

  const onSubmit = async values => {
    try {
      const nonEmptyFilters = _pickBy(values.filters, doesFilterHaveValue);

      const cleanValues = {
        // _payload is used for more convenient and reliable submitting nested parts of FormData.
        // The properties containing files (like user_groups in this case)
        // shouldn't be included into _payload
        _payload: JSON.stringify({
          name: values.name,
          filters: nonEmptyFilters
        }),
        user_groups: values.user_groups.map(group => ({
          ...group,
          id: group.id || null,
          file: group.file || null
        }))
      };

      const formData = serializeFormData(cleanValues);

      if (isEditMode) {
        await API.put(`/v4/dash/v4_audiences/${params.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        message.success('Audience updated');
        history.push(`/p/audiences/show/${params.id}`);
      } else {
        const response = await API.post(`/v4/dash/v4_audiences`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        const id = response?.data?.data.id;

        message.success('Audience successfully created');
        history.push(id ? `/p/audiences/show/${id}` : `/p/audiences/show/list`);
      }
    } catch (error) {
      if (isEditMode) {
        message.error('Error updating the Audience.');
      } else {
        message.error('Error creating the Audience.');
      }
    }
  };

  const mergedInitialValues = useMemo(
    () => ({ ...defaultValues, ...initialValues }),
    [initialValues]
  );

  return (
    <FormContext.Provider value={{ isEditMode, locale: selectedLocale }}>
      <PageContent>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={mergedInitialValues}
          validateOnChange
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <Audience isEditMode={isEditMode} />
              {process.env.NODE_ENV === 'development' && <FormDebug />}
              <BottomBar>
                <Button size="large" type="default" onClick={history.goBack}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  style={{ marginLeft: 8 }}
                >
                  {isEditMode ? 'Update' : 'Create'}
                </Button>
              </BottomBar>
            </Form>
          )}
        </Formik>
      </PageContent>
    </FormContext.Provider>
  );
};

export default AudienceForm;
