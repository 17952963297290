import React, { useMemo, useCallback } from 'react';
import { Card, Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';

import { GridItem } from 'components/Display/GridRenderer';
import { useMetabase, useCard, useAnalyticsContext } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../../components/Error';
import GraphCardExtra from '../../../components/GraphCardExtra';
import { getDonutData } from '../dummyData';
import DonutChart from './DonutChart';
import Percent from './Percent';

interface DonutChartProps {
  identifier: string;
  title: string;
  innerLabel: string;
  helpText?: string;
  filterParams?: any;
}

const DonutPercentageChart = ({
  identifier,
  title,
  helpText,
  filterParams,
  innerLabel
}: DonutChartProps) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate, isDataValidating, token } =
    useMetabase(questionId, dataParams);
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;

  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <Graph data={data} title={title} innerLabel={innerLabel} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const Graph = ({ data, title, innerLabel }) => {
  const color = colorGenerator();
  const { isDemoMode } = useAnalyticsContext();
  if (_isEmpty(data?.data?.rows) && !isDemoMode)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  let d = data?.data?.rows.map(row => ({ value: row[0], name: row[1] }));

  if (isDemoMode) {
    d = getDonutData(title.toLowerCase());
  }

  const totalCount = d ? d.reduce((acc, gender) => acc + gender.value, 0) : 0;
  const percents = d
    ? d.map(gender => ({
        name: gender.name,
        percent: gender.value / totalCount,
        color: color.next().value
      }))
    : [];
  // sort ascending for legend
  percents.sort((a, b) => b.percent - a.percent);

  return (
    <DonutChart
      data={d}
      label={innerLabel}
      totalCount={totalCount}
      legend={percents.map((percent, index) => (
        <Percent key={index} {...percent} />
      ))}
    />
  );
};

export default DonutPercentageChart;
