import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Campaign';

export const CREATE_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CAMPAIGN`;
export const CREATE_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CAMPAIGN_SUCCESS`;
export const CREATE_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_CAMPAIGN_FAIL`;

export const ACTIVATE_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CAMPAIGN`;
export const ACTIVATE_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CAMPAIGN_SUCCESS`;
export const ACTIVATE_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ACTIVATE_CAMPAIGN_FAIL`;

export const DEACTIVATE_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CAMPAIGN`;
export const DEACTIVATE_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CAMPAIGN_SUCCESS`;
export const DEACTIVATE_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_CAMPAIGN_FAIL`;

export const ORDER_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CAMPAIGN`;
export const ORDER_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CAMPAIGN_SUCCESS`;
export const ORDER_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/ORDER_CAMPAIGN_FAIL`;

export const GET_CAMPAIGNS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGNS`;
export const GET_CAMPAIGNS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGNS_SUCCESS`;
export const GET_CAMPAIGNS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CAMPAIGNS_FAIL`;

export const GET_REWARDS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS`;
export const GET_REWARDS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_SUCCESS`;
export const GET_REWARDS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_FAIL`;

export const GET_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS`;
export const GET_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_SUCCESS`;
export const GET_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_FAIL`;

export const GET_AUDIENCES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES`;
export const GET_AUDIENCES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_SUCCESS`;
export const GET_AUDIENCES_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_FAIL`;

export const GET_AUDIENCE_INFO = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO`;
export const GET_AUDIENCE_INFO_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_SUCCESS`;
export const GET_AUDIENCE_INFO_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_FAILED`;

export const GET_LOYALTY_LIST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST`;
export const GET_LOYALTY_LIST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST_SUCCESS`;
export const GET_LOYALTY_LIST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST_FAIL`;

export const EDIT_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/EDIT_CAMPAIGN`;
export const EDIT_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/EDIT_CAMPAIGN_SUCCESS`;
export const EDIT_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/EDIT_CAMPAIGN_FAIL`;

export const SEARCH_CAMPAIGN = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CAMPAIGN`;
export const SEARCH_CAMPAIGN_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CAMPAIGN_SUCCESS`;
export const SEARCH_CAMPAIGN_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_CAMPAIGN_FAIL`;

export const SEARCH_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD`;
export const SEARCH_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_SUCCESS`;
export const SEARCH_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_FAIL`;

export const GET_CONDITIONS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CONDITIONS`;
export const GET_CONDITIONS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CONDITIONS_SUCCESS`;
export const GET_CONDITIONS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_CONDITIONS_FAIL`;
