import React from 'react';
import styled from 'styled-components';

import { colors } from 'assets/styles/commonStyle';

import imageSrc from './refresh.svg';

const ReloadMessage = () => {
  return (
    <PageContainer>
      <Image alt="refresh" src={imageSrc} />
      <Headline>Reload Page</Headline>
      <div>Update in progress, try reloading the page to get us back!</div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  background-color: white;
`;

const Headline = styled.h1`
  color: ${colors.colorGrayDark};
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 12px;
`;

const Image = styled.img`
  width: 124px;
  height: 124px;
  margin-bottom: 2rem;
`;

export default ReloadMessage;
