import merge from 'lodash/merge';

import {
  CREATE_CATALOGUE,
  CREATE_CATALOGUE_FAIL,
  CREATE_CATALOGUE_SUCCESS,
  ACTIVATE_CATALOGUE,
  ACTIVATE_CATALOGUE_FAIL,
  ACTIVATE_CATALOGUE_SUCCESS,
  DEACTIVATE_CATALOGUE,
  DEACTIVATE_CATALOGUE_FAIL,
  DEACTIVATE_CATALOGUE_SUCCESS,
  ORDER_CATALOGUE,
  ORDER_CATALOGUE_FAIL,
  ORDER_CATALOGUE_SUCCESS,
  GET_CATALOGUES,
  GET_CATALOGUES_SUCCESS,
  GET_CATALOGUES_FAIL,
  GET_CATALOGUE,
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE_FAIL,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  SEARCH_CATALOGUE,
  SEARCH_CATALOGUE_SUCCESS,
  SEARCH_CATALOGUE_FAIL,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_FAIL,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED
} from './constants';

const initialState = {
  loading: false,
  catalogues: [],
  catalogue: {},
  rewards: {
    data: [],
    meta: {}
  },
  audiences: [],
  audience: {},
  reward: {},
  tags: []
};

function catalogueReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TAGS:
    case GET_REWARDS:
    case GET_AUDIENCES:
    case GET_AUDIENCE_INFO:
    case GET_CATALOGUE:
    case CREATE_CATALOGUE:
    case SEARCH_CATALOGUE:
    case SEARCH_REWARD:
    case DEACTIVATE_CATALOGUE:
    case ORDER_CATALOGUE:
    case ACTIVATE_CATALOGUE:
    case GET_CATALOGUES: {
      return {
        ...state,
        error: {},
        loading: true
      };
    }
    case GET_REWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }
    case GET_AUDIENCES_FAIL:
    case GET_AUDIENCE_INFO_FAILED:
    case GET_CATALOGUES_FAIL:
    case GET_REWARDS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_CATALOGUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        catalogues: action.payload.data
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tags: merge([], state.tags, action.payload.data)
      };
    }
    case GET_TAGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: true,
        catalogue: action.payload.data
      };
    }
    case GET_CATALOGUE_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CREATE_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case CREATE_CATALOGUE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case ORDER_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ORDER_CATALOGUE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        catalogues: action.payload.data
      };
    }
    case SEARCH_CATALOGUE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case ACTIVATE_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        catalogue: action.payload.data
      };
    }
    case ACTIVATE_CATALOGUE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case DEACTIVATE_CATALOGUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        catalogue: action.payload.data
      };
    }
    case DEACTIVATE_CATALOGUE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }
    case SEARCH_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_AUDIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        audiences: action.payload.data
      };
    }
    case GET_AUDIENCE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        audience: action.payload.data
      };
    }
    default:
      return state;
  }
}

export default catalogueReducer;
