import React from 'react';
import { Control, useController } from 'react-hook-form';
import { DatePicker, DatePickerProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import moment from 'moment-timezone';

import RHFItem from './RHFItem';

type RHFItemDatePickerProps = DatePickerProps & {
  control: Control<any, any>;
  name: string;
  label?: string;
  placeholder?: string;
  formItemProps?: FormItemProps;
  /**
   * Meanwhile the `format` prop used to set format for showing the value in UI,
   * the `momentFormat` is supposed to define the format of the stored value
   */
  momentFormat?: string;
};

const RHFItemDatePicker = ({
  control,
  name,
  label,
  placeholder,
  formItemProps,
  momentFormat = 'DD-MM-YYYY',
  ...props
}: RHFItemDatePickerProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={label} htmlFor={name} {...formItemProps}>
      <DatePicker
        aria-label={label}
        value={field.value ? moment(field.value, momentFormat) : null}
        onChange={val => field.onChange(moment.isMoment(val) ? val.format(momentFormat) : null)}
        onBlur={field.onBlur}
        style={{ width: '100%' }}
        placeholder={placeholder}
        format="MM/DD/YYYY"
        {...props}
      />
    </RHFItem>
  );
};

export default RHFItemDatePicker;
