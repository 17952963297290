import API from 'utils/request';

export const getMedias = () => {
  return API.get('/v4/dash/simple/custom_image_ratios').then(res => res.data);
};
export const postMedia = payload => {
  return API.post('/v4/dash/custom_image_ratios', payload).then(res => res.data);
};
export const putMedia = (id, payload) => {
  return API.put(`/v4/dash/custom_image_ratios/${id}`, payload).then(res => res.data);
};
export const deleteMedia = (id): Promise<void> => {
  return API.delete(`/v4/dash/custom_image_ratios/${id}`);
};
