import React from 'react';
import {
  Route,
  Switch as RouteSwitch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { Muted } from 'styles';

import { DATE_FORMAT } from 'appConstants';
import { PageHeader } from 'components/page';
import DatePartSelect from 'containers/Analytics/components/DatePartSelect';
import { usePermissions, useTenant } from 'hooks';
import ForbiddenPage from 'pages/public/AccessDenied';
import { StepsLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';

import { AnalyticsProvider } from './AnalyticsProvider';
import CustomSpin from './components/CustomSpin';
import DemoAlert from './components/DemoAlert';
import { useAnalyticsContext } from './hooks';
import { Campaigns, Merchants, Overview, Performance, Rewards } from './pages';
import Loyalties from './pages/Loyalties';

const DateRangePicker = ({ showPreviousPeriod = false }) => {
  const { dateRange, onDateRateChange } = useAnalyticsContext();

  const diff = moment(dateRange[1]).diff(dateRange[0], 'day') + 1;
  const prevRange = [
    moment(dateRange[0]).subtract(diff, 'day'),
    moment(dateRange[1]).subtract(diff, 'day')
  ];

  return (
    <div>
      <DatePicker.RangePicker
        value={dateRange}
        onChange={onDateRateChange}
        format={DATE_FORMAT}
        disabledDate={current => current && current > moment().endOf('day')}
        allowClear={false}
      />
      {showPreviousPeriod && (
        <div style={{ textAlign: 'center' }}>
          <Muted>
            Compared to {prevRange[0].format(DATE_FORMAT)} - {prevRange[1].format(DATE_FORMAT)}
          </Muted>
        </div>
      )}
    </div>
  );
};

const AnalyticsHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const { features } = useTenant();
  const { isDemoMode, datePart, onDatePartChange } = useAnalyticsContext();

  let featureTabs = [
    { key: 'rewards', label: 'Rewards' },
    { key: 'merchants', label: 'Merchants' },
    { key: 'loyalties', label: 'Loyalty' },
    { key: 'campaigns', label: 'Campaigns' },
    { key: 'performance', label: 'Performance' },
    { key: 'quicksight', label: 'QuickSight' }
  ].filter(tab => features[tab.key]);

  if (isDemoMode) {
    featureTabs = featureTabs.filter(tab => features[tab.key]);
  }

  const navigationTabs = [{ key: 'overview', label: 'Overview' }, ...featureTabs];
  const currentTabLocation = location.pathname.split('business_intelligence')[1].split('/')[1];
  const isOverviewOrCampaign =
    currentTabLocation === 'overview' || currentTabLocation === 'campaigns';

  return (
    <PageHeader
      title={'Analytics'}
      customAction={() => (
        <div style={{ display: 'flex' }}>
          <DateRangePicker showPreviousPeriod={isOverviewOrCampaign} />
          {isOverviewOrCampaign && (
            <DatePartSelect
              onChange={onDatePartChange}
              value={datePart}
              style={{ marginLeft: '0.5rem', width: 'auto' }}
            />
          )}
        </div>
      )}
      tabs={navigationTabs}
      onTabChange={tab => {
        history.push(`${match.url}/${tab}`);
      }}
      defaultTab={currentTabLocation}
      isSticky
    />
  );
};

const Analytics = () => {
  const match = useRouteMatch();

  const { loading: permissionsLoading, canView } = usePermissions('business_intelligence');

  if (permissionsLoading) {
    return <StepsLoading />;
  }

  if (!canView) {
    return <ForbiddenPage />;
  }

  let featureRoutes = [
    {
      key: 'rewards',
      route: <Route key={'rewards'} path={`${match.path}/rewards`} component={Rewards} />
    },
    {
      key: 'merchants',
      route: <Route key={'merchants'} path={`${match.path}/merchants`} component={Merchants} />
    },
    {
      key: 'loyalties',
      route: <Route key={'loyalties'} path={`${match.path}/loyalties`} component={Loyalties} />
    },
    {
      key: 'campaigns',
      route: <Route key={'campaigns'} path={`${match.path}/campaigns`} component={Campaigns} />
    },
    {
      key: 'performance',
      route: (
        <Route key={'performance'} path={`${match.path}/performance`} component={Performance} />
      )
    }
  ];

  return (
    <AnalyticsProvider>
      <StickyContainer>
        <DemoAlert />
        <AnalyticsHeader />
        <CustomSpin>
          <div
            className="container"
            style={{ overflow: 'visible', position: 'relative', flex: '1 1 auto' }}
          >
            <RouteSwitch>
              <Route path={`${match.path}/overview`} component={Overview} />
              {featureRoutes.map(r => r.route)}
              <Route component={NotFoundPage} />
            </RouteSwitch>
          </div>
        </CustomSpin>
      </StickyContainer>
    </AnalyticsProvider>
  );
};

export default Analytics;

const StickyContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;
