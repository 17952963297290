import React, { memo } from 'react';
import { Menu } from 'antd';

import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAuthUser } from 'hooks';

const GraphCardMenu: React.FC<{ questionId: number; token: string; dataParams: any }> = ({
  questionId
}) => {
  const { email } = useAuthUser();

  return (
    <ActionsMenu
      menu={
        <Menu>
          {email && email.endsWith('@perxtech.com') && (
            <Menu.Item key="debug">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                View Metabase Card
              </a>
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default memo(GraphCardMenu);
