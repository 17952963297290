import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  AMEND_AUDIENCE_FAIL,
  AMEND_AUDIENCE_SUCCESS,
  AMEND_AUDIENCE,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,
  GET_FTP_FILE_LIST,
  GET_FTP_FILE_LIST_SUCCESS,
  GET_FTP_FILE_LIST_FAIL,
  CREATE_LABEL,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAIL,
  UPDATE_LABEL,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_FAIL,
  GET_LABELS,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAIL,
  DELETE_LABELS,
  DELETE_LABELS_SUCCESS,
  DELETE_LABELS_FAIL,
  DEACTIVATE_USER_GROUP,
  DEACTIVATE_USER_GROUP_SUCCESS,
  DEACTIVATE_USER_GROUP_FAIL,
  ACTIVATE_USER_GROUP,
  ACTIVATE_USER_GROUP_SUCCESS,
  ACTIVATE_USER_GROUP_FAIL,
  UNLOCK_USER,
  UNLOCK_USER_SUCCESS,
  UNLOCK_USER_FAIL,
  DEACTIVATE_ROLE,
  DEACTIVATE_ROLE_SUCCESS,
  DEACTIVATE_ROLE_FAIL,
  ACTIVATE_ROLE,
  ACTIVATE_ROLE_SUCCESS,
  ACTIVATE_ROLE_FAIL
} from './constants';

export const amendAudience = payload => {
  return {
    type: AMEND_AUDIENCE,
    payload
  };
};

export const amendAudienceSuccess = payload => {
  return {
    type: AMEND_AUDIENCE_SUCCESS,
    payload
  };
};

export const amendAudienceFail = error => {
  return {
    type: AMEND_AUDIENCE_FAIL,
    error
  };
};

export const getRoles = payload => {
  return {
    type: GET_ROLES,
    endpoint: `/v4/dash/roles`,
    types: [GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED],
    params: { ...payload, size: 1000 }, // TODO remove it when Roles table will have pagination
    notification: {
      // loading: () => message.loading('Loading form data...', 0),
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      }
    }
  };
};

export const getUsers = payload => {
  return {
    type: GET_USERS,
    endpoint: `/v4/dash/users`,
    types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILED],
    // FIXME: a hack to load all users for now, wait till backend implements search function for setting/users
    params: { ...payload, size: 1000 },
    notification: {
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      },
      loading: () => {
        message.loading('Loading form data...', DURATION_TIMER);
      }
    }
  };
};

export const getFTPFiles = payload => ({
  type: GET_FTP_FILE_LIST,
  endpoint: '/v4/dash/ftp_file_imports',
  types: [GET_FTP_FILE_LIST, GET_FTP_FILE_LIST_SUCCESS, GET_FTP_FILE_LIST_FAIL],
  params: { ...payload, size: payload.size || 10, page: payload.page || 1 },
  notification: {
    error: () => {
      message.error('Error retrieving files', DURATION_TIMER);
    },
    loading: () => {
      message.loading('Retrieving file list', DURATION_TIMER);
    }
  }
});

export const getLabels = (payload?) => ({
  type: GET_LABELS,
  endpoint: '/v4/dash/labels',
  types: [GET_LABELS, GET_LABELS_SUCCESS, GET_LABELS_FAIL],
  params: payload,
  notification: {
    error: () => {
      message.error('Error fetching labels', DURATION_TIMER);
    },
    loading: () => {
      // message.loading('Loading labels', DURATION_TIMER);
    }
  }
});

export const createLabel = (payload, callback) => {
  return {
    type: CREATE_LABEL,
    endpoint: `/v4/dash/labels`,
    method: 'post',
    types: [CREATE_LABEL, CREATE_LABEL_SUCCESS, CREATE_LABEL_FAIL],
    callback: callback,
    notification: {
      success: response => {
        message.success(response.data.title + ' was successfully created', DURATION_TIMER);
      },
      error: e => {
        message.error(e.message, DURATION_TIMER);
      }
    },
    nextAction: getLabels(),
    formdata: payload
  };
};

export const updateLabel = (payload, callback) => {
  return {
    type: UPDATE_LABEL,
    endpoint: `/v4/dash/labels/${payload.id}`,
    method: 'put',
    types: [UPDATE_LABEL, UPDATE_LABEL_SUCCESS, UPDATE_LABEL_FAIL],
    callback: callback,
    notification: {
      success: response => {
        message.success(response.data.title + ' was successfully updated', DURATION_TIMER);
      },
      error: e => {
        message.error(e.message, DURATION_TIMER);
      }
    },
    nextAction: getLabels(),
    formdata: payload
  };
};

export const deleteLabel = label => ({
  type: DELETE_LABELS,
  endpoint: `/v4/dash/labels/${label.id}`,
  types: [DELETE_LABELS, DELETE_LABELS_SUCCESS, DELETE_LABELS_FAIL],
  method: 'delete',
  params: label,
  notification: {
    error: err => {
      message.error(err.message || 'Something went wrong!', DURATION_TIMER);
    }
  },
  nextAction: getLabels()
});

export const deleteMultiLabels = labels => ({
  type: GET_LABELS,
  endpoint: `/v4/dash/labels?${labels.map(e => 'ids[]=' + e).join('&')}`,
  types: [DELETE_LABELS, DELETE_LABELS_SUCCESS, DELETE_LABELS_FAIL],
  method: 'delete',
  params: null,
  notification: {
    error: err => {
      message.error(err.message || 'Something went wrong!', DURATION_TIMER);
    }
  },
  nextAction: getLabels()
});

export const getUser = payload => {
  return {
    type: GET_USER,
    endpoint: `/v4/dash/users/${payload.id}`,
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAILED],
    params: payload,
    notification: {
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      },
      loading: () => {
        message.loading('Loading form data...', DURATION_TIMER);
      }
    }
  };
};

export const createUser = payload => {
  return {
    type: USER_CREATE,
    endpoint: `/v4/dash/users`,
    types: [USER_CREATE, USER_CREATE_SUCCESS, USER_CREATE_FAIL],
    method: 'post',
    params: payload,
    notification: {
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      }
    }
  };
};

export const updateUser = payload => {
  return {
    type: USER_UPDATE,
    endpoint: `/v4/dash/users/${payload.id}`,
    types: [USER_UPDATE, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL],
    method: 'put',
    params: payload,
    notification: {
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      }
    }
  };
};

export const deleteRole = (payload, callBack) => {
  return {
    type: DELETE_ROLE,
    endpoint: `/v4/dash/roles/${payload.id}`,
    types: [DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAIL],
    method: 'delete',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    callback: callBack
  };
};

export const deactivateUser = (payload, callback) => {
  return {
    type: DEACTIVATE_USER,
    endpoint: `/v4/dash/users/${payload.id}/deactivate`,
    types: [DEACTIVATE_USER, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAIL],
    method: 'patch',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    callback
  };
};

export const activateUser = (payload, callback) => {
  return {
    type: ACTIVATE_USER,
    endpoint: `/v4/dash/users/${payload.id}/activate`,
    types: [ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAIL],
    method: 'patch',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    callback
  };
};

export const unlockUser = (payload, callback) => {
  return {
    type: UNLOCK_USER,
    endpoint: `/v4/dash/users/${payload.id}/unlock`,
    types: [UNLOCK_USER, UNLOCK_USER_SUCCESS, UNLOCK_USER_FAIL],
    method: 'patch',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    callback
  };
};

export const deactivateUserGroup = (payload, nextAction) => {
  return {
    type: DEACTIVATE_USER_GROUP,
    endpoint: `/v4/dash/access_groups/${payload.id}/deactivate`,
    types: [DEACTIVATE_USER_GROUP, DEACTIVATE_USER_GROUP_SUCCESS, DEACTIVATE_USER_GROUP_FAIL],
    method: 'patch',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    nextAction: nextAction()
  };
};

export const deactivateRole = (payload, nextAction) => {
  return {
    type: DEACTIVATE_ROLE,
    endpoint: `/v4/dash/roles/${payload.id}/deactivate`,
    types: [DEACTIVATE_ROLE, DEACTIVATE_ROLE_SUCCESS, DEACTIVATE_ROLE_FAIL],
    method: 'put',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    nextAction: nextAction()
  };
};

export const activateUserGroup = (payload, nextAction) => {
  return {
    type: ACTIVATE_USER_GROUP,
    endpoint: `/v4/dash/access_groups/${payload.id}/activate`,
    types: [ACTIVATE_USER_GROUP, ACTIVATE_USER_GROUP_SUCCESS, ACTIVATE_USER_GROUP_FAIL],
    method: 'patch',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    nextAction: nextAction()
  };
};

export const activateRole = (payload, nextAction) => {
  return {
    type: ACTIVATE_ROLE,
    endpoint: `/v4/dash/roles/${payload.id}/activate`,
    types: [ACTIVATE_ROLE, ACTIVATE_ROLE_SUCCESS, ACTIVATE_ROLE_FAIL],
    method: 'put',
    params: payload,
    notification: {
      error: e => {
        message.error(e.message || 'Something Went Wrong!', DURATION_TIMER);
      }
    },
    nextAction: nextAction()
  };
};
