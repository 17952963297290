import React, { useMemo, useCallback } from 'react';
import { Card, Empty, Spin } from 'antd';
import { Row } from 'antd';
import _ from 'lodash';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid
} from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import GraphCardMenu from 'containers/Analytics/components/GraphCardMenu';
import { useCard, useMetabase, useAnalyticsContext } from 'containers/Analytics/hooks';
import { abbreviateNumber, colorGenerator, showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import LoyaltyTooltip from './LoyaltyTooltip';
import {
  stackedHistogramAgeData,
  stackedHistogramGenderData,
  stackedHistogramRaceData,
  stackedHistogramLoyaltyPrimaryState,
  stackedHistogramUserPlan,
  getStackedHistogramData
} from './dummyData';

interface ICampaignStackedHistogramProps {
  identifier: string;
  title: string;
  yAxisLabel?: string;
  filterParams?: object;
  legend1?: string;
  legend2?: string;
}

const CampaignStackedHistogram: React.FC<ICampaignStackedHistogramProps> = ({
  identifier,
  title,
  yAxisLabel = 'Stamp Cards Issued',
  filterParams,
  legend1 = 'Engagements',
  legend2 = 'Completions'
}) => {
  const { isDemoMode, cards } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { data, tokenError, token, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );
  const color = colorGenerator();
  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  // eslint-disable-next-line
  let d = [];
  if (isDemoMode) {
    if (identifier === 'loyalty_gender') {
      d = stackedHistogramGenderData;
    } else if (identifier === 'loyalty_age') {
      d = stackedHistogramAgeData;
    } else if (identifier === 'loyalty_race') {
      d = stackedHistogramRaceData;
    } else if (identifier === 'loyalty_primary_state' || identifier === 'loyalty_primary_cluster') {
      d = stackedHistogramLoyaltyPrimaryState;
    } else if (identifier === 'loyalty_user_plan') {
      d = stackedHistogramUserPlan;
    } else if (identifier.includes('gender')) {
      d = getStackedHistogramData('gender');
    } else if (identifier.includes('age')) {
      d = getStackedHistogramData('age');
    }
  } else {
    data?.data?.rows.map(row => d.push({ tier: legend1, count: row[0], attribute: row[2] }));
    data?.data?.rows.map(row => d.push({ tier: legend2, count: row[1], attribute: row[2] }));
  }

  const tiers = useMemo(() => _.chain(d).groupBy('tier').keys().sort().value(), [d]);

  const groupedData = useMemo(
    () =>
      _.chain(d)
        .groupBy('attribute')
        .map(x =>
          _.reduce(
            x,
            (acc, val) => ({
              ...acc,
              [val.tier]: val.count
            }),
            {
              attribute: x[0].attribute
            }
          )
        )
        .value(),
    [d]
  );

  const renderLegend = (value: string) => {
    return <span style={{ fontSize: 12, color: '#8c8c8c', cursor: 'pointer' }}>{value}</span>;
  };

  const renderChart = () => {
    let graph;
    if (!_.isEmpty(groupedData)) {
      graph = (
        <ResponsiveContainer>
          <BarChart data={groupedData}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="0 0"
              fillOpacity="0.5"
              stroke="#f5f5f5"
            />
            <XAxis
              dataKey="attribute"
              stroke="#9b9b9b"
              padding={{ left: 16, right: 16 }}
              tick={{ strokeWidth: 1, fontSize: 12, fill: '#9b9b9b', dy: 16 }}
              tickSize={3}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#d9d9d9', fontSize: 12, textAnchor: 'middle', dx: -16 }}
              tickFormatter={(tick: number) => abbreviateNumber(tick)}
              label={{
                value: yAxisLabel,
                angle: -90,
                fill: '#595959',
                fontSize: 12,
                fillOpacity: 0.5,
                dx: -24
              }}
            />
            <Tooltip
              animationEasing="ease-in-out"
              content={<LoyaltyTooltip title={title} />}
              cursor={{
                fill: '#e8e8e8'
              }}
              wrapperStyle={{
                boxShadow: '0px 0px 12px rgba(0,0,0,.08)',
                border: 'none',
                background: '#ffffff'
              }}
              itemStyle={{ fontSize: 12 }}
              labelStyle={{ display: 'none', visibility: 'hidden', pointerEvents: 'none' }}
            />
            <Legend
              iconType="circle"
              iconSize={11}
              height={36}
              wrapperStyle={{
                bottom: -16
              }}
              formatter={renderLegend}
            />
            {tiers.map((tier: string, index: number) => (
              <Bar
                key={`${tier}-${index}`}
                dataKey={tier}
                fill={color.next().value}
                stackId={groupedData[0].attribute}
                barSize={30}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      );
    } else {
      graph = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return graph;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        style={{ marginBottom: '1rem' }}
        headStyle={{ borderBottom: '0px', padding: '14px 40px 16px 25px' }}
        bodyStyle={{ height: 300 }}
        extra={<GraphCardMenu questionId={questionId} token={token} dataParams={dataParams} />}
      >
        {hasError && <Error retry={onRetry} />}
        {!isDemoMode && !data && !hasError && (
          <Row justify="center" align="middle" style={{ height: '300px' }}>
            <Spin size="large" />
          </Row>
        )}
        {((!hasError && !isDemoMode && data) || isDemoMode) && renderChart()}
      </Card>
    </GridItem>
  );
};

export default CampaignStackedHistogram;
