import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Reward';

export const SELECT_SCHEDULE = `${PROJECT_NAME}/${CONTAINER_NAME}/SELECT_SCHEDULE`;

export const CREATE_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_REWARD`;
export const CREATE_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_REWARD_SUCCESS`;
export const CREATE_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_REWARD_FAIL`;

export const APPROVE_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/APPROVE_REWARD`;
export const APPROVE_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/APPROVE_REWARD_SUCCESS`;
export const APPROVE_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/APPROVE_REWARD_FAIL`;

export const DEACTIVATE_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_REWARD`;
export const DEACTIVATE_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_REWARD_SUCCESS`;
export const DEACTIVATE_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DEACTIVATE_REWARD_FAIL`;

export const SEARCH_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD`;
export const SEARCH_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_SUCCESS`;
export const SEARCH_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_REWARD_FAIL`;

export const UPDATE_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_REWARD`;
export const UPDATE_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_REWARD_SUCCESS`;
export const UPDATE_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_REWARD_FAIL`;

export const GET_REWARDS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS`;
export const GET_REWARDS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_SUCCESS`;
export const GET_REWARDS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARDS_FAIL`;

export const GET_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD`;
export const GET_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD_SUCCESS`;
export const GET_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_REWARD_FAIL`;

export const GET_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS`;
export const GET_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_SUCCESS`;
export const GET_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_FAIL`;

export const GET_BRANDS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_BRANDS`;
export const GET_BRANDS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_BRANDS_SUCCESS`;
export const GET_BRANDS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_BRANDS_FAIL`;

export const GET_AUDIENCES = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES`;
export const GET_AUDIENCES_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_SUCCESS`;
export const GET_AUDIENCES_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCES_FAIL`;

export const GET_MERCHANTS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS`;
export const GET_MERCHANTS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS_SUCCESS`;
export const GET_MERCHANTS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS_FAIL`;

export const GET_LOYALTY_LIST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST`;
export const GET_LOYALTY_LIST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST_SUCCESS`;
export const GET_LOYALTY_LIST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_LOYALTY_LIST_FAIL`;

export const UPLOAD_AUDIENCE = `${PROJECT_NAME}/${CONTAINER_NAME}/UPLOAD_AUDIENCE`;
export const UPLOAD_AUDIENCE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPLOAD_AUDIENCE_SUCCESS`;

export const DUPLICATE_REWARD = `${PROJECT_NAME}/${CONTAINER_NAME}/DUPLICATE_REWARD`;
export const DUPLICATE_REWARD_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DUPLICATE_REWARD_SUCCESS`;
export const DUPLICATE_REWARD_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DUPLICATE_REWARD_FAIL`;

export const GET_AUDIENCE_INFO = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO`;
export const GET_AUDIENCE_INFO_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_SUCCESS`;
export const GET_AUDIENCE_INFO_FAILED = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUDIENCE_INFO_FAILED`;

export const GET_PARTNER = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PARTNER`;
export const GET_PARTNER_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PARTNER_SUCCESS`;
export const GET_PARTNER_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_PARTNER_FAIL`;
