import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Please enter a name'),
  filters: yup
    .object()
    .optional()
    .shape({
      earned_badges: yup
        .array()
        .nullable()
        .of(
          yup.object({
            criteria: yup.object({
              eq: yup.number().nullable().required('Please select a badge')
            })
          })
        ),
      campaign_enrolment: yup
        .array()
        .nullable()
        .of(
          yup.object({
            criteria: yup.object({
              eq: yup.number().nullable().required('Please select a campaign')
            })
          })
        ),
      loyalty_points_balance: yup
        .object()
        .nullable()
        .shape({
          criteria: yup.object({
            eq: yup.number().nullable(),
            gteq: yup.number().nullable(),
            lteq: yup.number().nullable().min(yup.ref('gteq'), 'Invalid range')
          })
        }),
      leaderboard_position: yup
        .array()
        .nullable()
        .of(
          yup.object({
            criteria: yup.object({
              eq: yup.number().nullable(),
              gteq: yup.number().nullable(),
              lteq: yup.number().nullable().min(yup.ref('gteq'), 'Invalid range')
            })
          })
        ),
      earned_rewards: yup
        .array()
        .optional()
        .of(
          yup.object({
            criteria: yup.object({
              eq: yup.number().nullable().required('Please select a reward')
            })
          })
        ),
      days_past_dues: yup
        .array()
        .optional()
        .of(
          yup.object({
            criteria: yup.object({
              gteq: yup.number().nullable(),
              lteq: yup.number().nullable().min(yup.ref('gteq'), 'Invalid range')
            })
          })
        )
    })
});
