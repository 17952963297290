import React, { memo, useCallback, useMemo } from 'react';
import { Card, Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { Funnel, FunnelChart, LabelList, ResponsiveContainer, Tooltip } from 'recharts';

import { GridItem } from 'components/Display/GridRenderer';
import Error from 'containers/Analytics/components/Error';
import GraphCardExtra from 'containers/Analytics/components/GraphCardExtra';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { CustomTooltip } from 'containers/Analytics/pages/graphs/PieCharts/PizzaPieChart';
import { GraphContent } from 'containers/Analytics/styles';
import { showCard } from 'containers/Analytics/utils';

import { funnelChartDefaultColor, getDefaultFunnelChartData } from '../dummyData';

const FunnelGraph = ({ data, isDemoMode }) => {
  let d = (data?.data?.rows || [])
    .map((row, index) => ({
      value: row[1],
      name: row[0],
      fill: funnelChartDefaultColor[index]
    }))
    .sort((a, b) => b.value - a.value);

  if (isDemoMode) {
    d = getDefaultFunnelChartData().map((item, index) => ({
      ...item,
      fill: funnelChartDefaultColor[index]
    }));
  }

  const isEmptyAllValue = d.every(({ value }) => !value);

  if (_isEmpty(d) || isEmptyAllValue) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ResponsiveContainer>
      <FunnelChart>
        {/*@ts-ignore*/}
        <Tooltip content={<CustomTooltip />} />
        <Funnel dataKey="value" data={d} isAnimationActive lastShapeType={'rectangle'}>
          <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
        </Funnel>
      </FunnelChart>
    </ResponsiveContainer>
  );
};

const FunnelGraphWrapper = ({ identifier, title, filterParams, helpText }) => {
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, dataRevalidate, isDataValidating, token } = useMetabase(
    questionId,
    dataParams
  );
  const { dateRange, isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    dataRevalidate();
  }, [dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    if (hasError) return <Error retry={onRetry} />;
    if (isDataValidating) return <Spin size="large" />;
    return <FunnelGraph data={data} isDemoMode={isDemoMode} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        extra={
          <GraphCardExtra
            questionId={questionId}
            token={token}
            dateRange={dateRange}
            dataParams={dataParams}
            helpText={helpText}
          />
        }
      >
        <GraphContent height="300">{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

export default memo(FunnelGraphWrapper);
