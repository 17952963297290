import React from 'react';
import { useFormikContext, useField } from 'formik';
import { formLayout } from 'styles';

import FormItem from 'components/Form/StyledFormItem';
import { CategorySelect } from 'components/selects';

interface Props {
  usage: string;
  label?: string;
  name?: string;
  dataTestid?: string;
}

const CategoriesField = ({
  usage,
  label = 'Categories',
  name = 'categories',
  dataTestid
}: Props) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onValueChange = val => formik.setFieldValue(field.name, val);

  return (
    <FormItem
      label={label}
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
      data-testid={dataTestid}
    >
      <CategorySelect
        multiple
        usage={usage}
        placeholder="Please select your categories"
        value={field.value}
        onChange={onValueChange}
      />
    </FormItem>
  );
};

export default CategoriesField;
