import React, { useCallback, useMemo } from 'react';
import { Card, Spin, Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { GridItem } from 'components/Display/GridRenderer';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardMenu from '../../components/GraphCardMenu';
import {
  tableLoyaltyEarnedBurnedPointsPerTier,
  tablePrimaryClusters,
  tableTopMerchants,
  tableCampaignOverview
} from './dummyData';

const TableGraph: React.FC<{
  identifier: string;
  title: string;
  tableProps: TableProps<any>;
  helpText?: string;
  filterParams?: any;
  n?: number | null;
  height?: string;
  itemsPerRow?: number;
}> = ({ identifier, title, filterParams, n = 5, height, tableProps, itemsPerRow }) => {
  const { card_id: questionId } = useCard(identifier);
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { data, dataError, token, tokenError, dataRevalidate, isDataValidating } = useMetabase(
    questionId,
    dataParams
  );

  const { isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    dataRevalidate();
  }, [dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    let d;
    let loading = true;
    if (isDemoMode) {
      if (identifier === 'merchant_top_merchant') {
        d = tableTopMerchants;
      } else if (identifier === 'loyalty_flat_earned_burned_points_per_tier') {
        d = tableLoyaltyEarnedBurnedPointsPerTier;
      } else if (identifier === 'campaign_overview_flat_table') {
        d = tableCampaignOverview;
      } else {
        d = tablePrimaryClusters;
      }
      loading = false;
    } else {
      d = data;
      loading = !data || !token;
    }

    if (loading) return <Spin size={'large'} />;

    if ((hasError || (d && d.status === 'failed')) && !isDataValidating) {
      return <Error retry={onRetry} />;
    }

    if (_isEmpty(height)) {
      height = '100%';
    }

    return <Graph data={d} loading={loading} n={n} tableProps={tableProps} height={height} />;
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem itemsPerRow={itemsPerRow}>
      <Card
        title={title}
        bodyStyle={{ padding: '10px' }}
        extra={<GraphCardMenu questionId={questionId} token={token} dataParams={dataParams} />}
      >
        <GraphContent nomargin>{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const Graph: React.FC<{
  data: any;
  loading: boolean;
  n: number;
  tableProps: TableProps<any>;
  height: string;
}> = ({ data, loading, n, tableProps, height }) => {
  let d = [];
  if (!_isEmpty(data)) {
    d = data?.data?.rows
      .map((x, idx: number) => [idx + 1, ...x])
      .slice(0, n ? n : data?.data?.rows.length);
  }

  return (
    <Table
      loading={loading}
      dataSource={d}
      size="middle"
      pagination={false}
      style={{ height, marginTop: 1 }}
      {...tableProps}
    />
  );
};

TableGraph.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  filterParams: PropTypes.object,
  n: PropTypes.number,
  tableProps: PropTypes.object.isRequired,
  height: PropTypes.string
};

export default TableGraph;
