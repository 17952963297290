import {
  GET_AUTHORIZATION,
  GET_AUTHORIZATION_SUCCESS,
  GET_AUTHORIZATION_FAIL,
  GET_TENANT_VIEW_CONFIG,
  GET_TENANT_VIEW_CONFIG_SUCCESS,
  GET_TENANT_VIEW_CONFIG_FAIL
} from './constants';

const initialState = {
  loading: false,
  sections: {}
};

function abilityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUTHORIZATION:
      return {
        ...state,
        loading: true
      };
    case GET_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        loading: true,
        sections: action.payload.data
      };
    case GET_AUTHORIZATION_FAIL:
      return {
        ...state,
        loading: false
      };
    case GET_TENANT_VIEW_CONFIG:
      return {
        ...state,
        loading: true
      };
    case GET_TENANT_VIEW_CONFIG_SUCCESS:
      return {
        ...state,
        loading: true,
        viewConfig: action.payload
      };
    case GET_TENANT_VIEW_CONFIG_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
export default abilityReducer;
