import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import _isEqual from 'lodash/isEqual';
import _isNull from 'lodash/isNull';
import _isObject from 'lodash/isObject';
import _startCase from 'lodash/startCase';
import _transform from 'lodash/transform';

import Cell from './Cell';

const difference = (object, base) => {
  const changes = (object, base) =>
    _transform(object, (result, value, key) => {
      if (!_isEqual(value, base[key])) {
        result[key] = _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  return changes(object, base);
};

const renderRow = (key, data) => {
  return <Col span={10}>{data ? <Cell propertyName={key} data={data} /> : '-'}</Col>;
};

interface ChangesRowProps {
  propertyName: string;
  data: (number | string | null | object | boolean)[];
}

const ChangesRow = ({ data, propertyName }: ChangesRowProps) => {
  const [oldVal, newVal] = data;

  if (_isObject(oldVal) && _isObject(newVal) && propertyName !== 'properties') {
    // handles things like `display_properties` where each instant is an object
    // objects will contain the ever key and value paris, we only want to display key,values that have been altered
    const delta = difference(newVal, oldVal);
    return (
      <Row gutter={16} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        {Object.keys(delta).map(key => {
          // console.log('old', oldVal?.[key] || '-', 'new', newVal?.[key] || '-');
          const oldV = oldVal?.[key] || null;
          const newV = newVal?.[key] || null;
          return (
            <Fragment key={key}>
              <Col span={4} style={{ fontWeight: 600 }}>
                {_startCase(key)}
              </Col>
              {renderRow(key, oldV)}
              {renderRow(key, newV)}
            </Fragment>
          );
        })}
      </Row>
    );
  }

  // [null, { xxx: yyy }]
  if (_isObject(newVal) && (_isNull(oldVal) || !_isObject(oldVal))) {
    return (
      <>
        {Object.keys(newVal).map(key => {
          return (
            <Row gutter={16} key={key} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Col span={4} style={{ fontWeight: 600 }}>
                {_startCase(key)}
              </Col>
              {renderRow(key, oldVal)}
              {renderRow(key, newVal[key])}
            </Row>
          );
        })}
      </>
    );
  } else if (_isObject(oldVal) && (_isNull(newVal) || !_isObject(oldVal))) {
    // [{ xxx: yyy }, null]
    return (
      <>
        {Object.keys(oldVal).map(key => {
          return (
            <Row gutter={16} key={key} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Col span={4} style={{ fontWeight: 600 }}>
                {_startCase(key)}
              </Col>
              {renderRow(key, oldVal[key])}
              {renderRow(key, newVal)}
            </Row>
          );
        })}
      </>
    );
  } else {
    // [xxx, yyy]
    return (
      <Row gutter={16} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col span={4} style={{ fontWeight: 600 }}>
          {_startCase(propertyName)}
        </Col>
        {renderRow(propertyName, oldVal)}
        {renderRow(propertyName, newVal)}
      </Row>
    );
  }
};

export default ChangesRow;
