import { Row } from 'antd';
import styled, { css } from 'styled-components';

export const Number = styled.div`
  display: inline-block;
  font-size: 1.875rem;
  line-height: 2.1875rem;
  color: #000000;
`;

export const Description = styled.div`
  display: inline-block;
  font-size: 0.875rem;
  color: #737373;
  line-height: 1;
`;

export const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  height: 100%;
  min-height: 500px;
  overflow-x: auto;
`;

export const GraphCard = styled.div`
  height: 360px;
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e8e8e8;
`;

export const GraphTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
`;

export const GraphContent = styled.div<{ height?: string; fontSize?: string; nomargin?: boolean }>`
  margin-top: ${props => (props.nomargin ? `0px` : `1rem`)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => {
    if (props.height) return `${props.height}px`;
    return 'calc(100% - 3rem)';
  }};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    font-weight: bold;
  }
`;

export const CardsRow = styled(Row)<{ shadow?: string }>`
  background: #ffffff;
  border: none;
  border-radius: 8px;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  justify-content: space-between;
  box-shadow: 0px 0px 6px 1px #cfcfcf;
  padding: 2.6rem 0;
  flex-flow: row wrap;
  > * {
    position: relative;
  }
  > *:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 1px;
    border-right: 1px solid #f0f0f0;
    transform: translate(0, -50%);
  }
  > *:first-child:after {
    border-right: none;
  }
  > * {
    &:last-child {
      border-right: none;
    }
  }
`;

export const ColorDot = styled.div`
  background-color: ${props => props.color};
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

export const CardTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

export const GraphHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const GraphHeaderExtra = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
