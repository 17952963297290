import React from 'react';
import _startCase from 'lodash/startCase';
import moment from 'moment-timezone';
import { TableMainText, TableSecondaryText } from 'styles';

import { DB_RECORD_DATETIME_FORMAT } from 'appConstants';
import { TableTimestamp } from 'components';
import ListTableComponent from 'components/ListTableComponent';
import Report from 'containers/Setting/Uploads/Report';

export const getOutcomeType = type => {
  if (type === 'Campaign') return 'Campaign';
  else if (type === 'Reward::Campaign') return 'Reward';
  else if (type === 'StoredValue::Campaign') return 'Points';
  else if (type === 'VoucherCodeInventoryBatch') return 'Batch';
  else if (type === 'PrizeSet' || type === 'prize set') return 'prize_set';
  return _startCase(type);
};

interface LedgerListProps {
  id: number;
}

const LedgerList = ({ id }: LedgerListProps) => {
  const columns = [
    {
      title: 'Date and time',
      key: 'created_at',
      render: (_, record) => <TableTimestamp timestamp={record.created_at} />
    },
    {
      title: 'User Email',
      key: 'performed_by',
      dataIndex: 'performed_by'
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount'
    },
    {
      title: 'From',
      key: 'source_name',
      width: 250,
      render: (_, record) => (
        <div>
          <TableMainText>{record.source_name}</TableMainText>
          <TableSecondaryText>{getOutcomeType(record.source_type)}</TableSecondaryText>
          <TableSecondaryText>ID: {record.source_id}</TableSecondaryText>
        </div>
      )
    },
    {
      title: 'To',
      key: 'destination_name',
      width: 250,
      render: (_, record) => (
        <div>
          <TableMainText>{record.destination_name}</TableMainText>
          <TableSecondaryText>{getOutcomeType(record.destination_type)}</TableSecondaryText>
          <TableSecondaryText>ID: {record.destination_id}</TableSecondaryText>
        </div>
      )
    }
  ];

  const downloadComponent = (
    <Report
      path={`/v4/dash/inventories/${id}/ledger/download`}
      id={moment().format(DB_RECORD_DATETIME_FORMAT)}
      name={'inventory_ledger'}
      fileType={'csv'}
      isButton={true}
      buttonText={'Download Ledger'}
    />
  );

  return (
    <div data-testid="LedgerList">
      <ListTableComponent
        table={{
          columns: columns
        }}
        apiUrl={`/v4/dash/inventories/${id}/ledger`}
        modalChildComponent={downloadComponent}
      />
    </div>
  );
};

export default LedgerList;
