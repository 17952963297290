import React, { PureComponent } from 'react';
import { Popover as antPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import styled from 'styled-components';

interface Action {
  title: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

interface antPopoverProps extends PopoverProps {
  onclick: React.MouseEventHandler<HTMLAnchorElement>;
}
export interface AntPopoverProps extends PopoverProps {
  actionList?: Action[] | any;
  button?: any;
  placement?: string | any;
  record?: any;
  text?: any;
  disabled?: boolean;
}

export interface PopoverState {
  visible: boolean;
}

const innerContent = 'ant-popover-inner-content';

const AntPopover = styled(antPopover).attrs({ innerContent })<antPopoverProps>`
  cursor: pointer;
`;

const ListWrapper = styled.ul`
  margin: -12px 0;
  padding: 0;
`;

const ListItem = styled.li<{ disabled?: boolean }>`
  background: #fff;
  padding: 0;
  margin: 0 -16px;
  list-style-type: none;
  min-width: 150px;
  font-weight: 300;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? 'rgba(0,0,0,.25)' : 'inherit')};
  text-decoration: none;
  &:hover {
    background: ${({ disabled }) => (disabled ? 'auto' : '#f1f1f1;')};
  }
`;

const PopoverTitle = styled.div`
  padding: 7px 10px;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export class Popover extends PureComponent<AntPopoverProps, PopoverState> {
  state = {
    visible: false
  };

  renderContent = () => {
    const { actionList } = this.props;
    return (
      <ListWrapper>
        {actionList &&
          actionList.map((item: any, index: number) => {
            return (
              <ListItem
                key={index}
                onClick={
                  !item.disabled
                    ? evt => {
                        this.hide();
                        item.onClick(evt);
                      }
                    : undefined
                }
                disabled={item.disabled}
              >
                <PopoverTitle>
                  {item.title || null}
                  {item.content || null}
                </PopoverTitle>
              </ListItem>
            );
          })}
      </ListWrapper>
    );
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    const { button, placement } = this.props;
    return (
      <div data-testid="popover-button">
        <AntPopover
          onclick={this.hide}
          open={this.state.visible}
          onOpenChange={this.handleVisibleChange}
          placement={placement ?? 'left'}
          title=""
          content={this.renderContent()}
          trigger="click"
        >
          {button}
        </AntPopover>
      </div>
    );
  }
}

export default Popover;
