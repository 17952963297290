import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { Empty, Radio, Spin } from 'antd';
import { Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
  Tooltip,
  CartesianGrid,
  Line
} from 'recharts';

import { DB_RECORD_DATETIME_FORMAT } from 'appConstants';
import DatePartSelect, { DatePart } from 'containers/Analytics/components/DatePartSelect';
import Error from 'containers/Analytics/components/Error';
import useAnalyticsContext from 'containers/Analytics/useAnalyticsContext';
import useCard from 'containers/Analytics/useCard';
import { getDatePart } from 'containers/Analytics/utils';
import { useLocalization, useMetabase } from 'hooks';

import { TickProps, ToolTipProps } from './HistogramGraph';
import { getMerchantOverviewData } from './dummyData';

const QUESTIONS_IDENTIFIERS = {
  views: 'merchant_merchant_breakdown_by_date_view',
  rewards: 'merchant_merchant_breakdown_by_date_reward_issued',
  revenue: 'merchant_merchant_breakdown_by_date_revenue'
};

const MerchantsOverviewGraph = ({ filterParams }) => {
  const [datepart, setDatepart] = useState(DatePart.Daily);
  const [tab, setTab] = useState('views');
  const [, setTooltipMessage] = useState('Total no. of GET rewards.');

  const dataParams = useMemo(() => ({ datepart, ...filterParams }), [datepart, filterParams]);
  const { card_id: questionId } = useCard(QUESTIONS_IDENTIFIERS[tab]);
  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );

  const { dateRange, isDemoMode } = useAnalyticsContext();
  const start = moment(dateRange[0]);
  const end = moment(dateRange[1]);
  const diff = end.diff(start, 'days');

  const onTabChange = useCallback(evt => {
    setTab(evt.target.value);
    changeTooltipMessage(evt.target.value);
  }, []);
  const onDatepartChange = useCallback(val => setDatepart(val), []);

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const hasError = tokenError || dataError;

  const changeTooltipMessage = targetTab => {
    switch (targetTab) {
      case 'views':
        setTooltipMessage('Total no. of GET rewards.');
        break;
      case 'rewards':
        setTooltipMessage('Total no. of paid rewards within a specific timeframe.');
        break;
      case 'revenue':
        setTooltipMessage(
          'Total no. of paid rewards X total selling price within a specific timeframe.'
        );
        break;
      default:
        setTooltipMessage('Total no. of GET rewards.');
        break;
    }
  };

  useEffect(() => {
    setDatepart(getDatePart(diff, datepart));
    // eslint-disable-next-line
  }, [diff]);

  let d;
  if (isDemoMode) {
    d = getMerchantOverviewData(dateRange[0], dateRange[1], tab, datepart);
    console.log(d);
  } else {
    d = data;
  }

  return (
    <div
      style={{
        width: '100%',
        padding: '2rem',
        backgroundColor: '#ffffff',
        border: '1px solid #e8e8e8'
      }}
    >
      <Row justify="space-between" style={{ marginBottom: '4rem' }}>
        <Radio.Group name="tab" value={tab} onChange={onTabChange}>
          <Radio.Button value="views">Views</Radio.Button>
          <Radio.Button value="rewards">Rewards</Radio.Button>
          <Radio.Button value="revenue">Revenue</Radio.Button>
        </Radio.Group>
        <DatePartSelect value={datepart} onChange={onDatepartChange} />
      </Row>

      <div
        style={{
          height: 300,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '0.4rem'
        }}
      >
        {hasError && <Error retry={onRetry} />}
        {!d && !hasError && <Spin size="large" />}
        {d && <Graph loading={!d} data={d} diff={diff} datepart={datepart} />}
      </div>
    </div>
  );
};

interface GraphProps {
  loading;
  data;
  diff;
  datepart;
}

const Graph = memo(({ loading, data, diff, datepart }: GraphProps) => {
  if (_isEmpty(data?.data?.rows)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  const d = (data?.data?.rows).map(row => ({ value: row[0], date: row[1] }));
  return (
    <ResponsiveContainer>
      <LineChart data={d}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" stroke="#e4e4e4" />
        <XAxis
          dataKey="date"
          interval={getInterval(diff, datepart)}
          tick={<CustomDateTick />}
          padding={{ left: 16, right: 16 }}
          stroke="#9b9b9b"
        />
        <YAxis axisLine={false} tickLine={false} tick={<CustomCountTick />} width={80} />
        <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#fb7901' }} />
        <Line
          dataKey="value"
          stroke="#fb7901"
          activeDot={{ r: diff > 30 ? 2 : 5 }}
          dot={{ r: diff > 30 ? 2 : 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
});

export default memo(MerchantsOverviewGraph);

const CustomTooltip = ({ active, payload, label, ...props }: ToolTipProps) => {
  const { timezone } = useLocalization();
  if (active && payload && payload.length > 0) {
    return (
      <div style={{ background: '#fb7901', padding: '0.5rem', color: '#ffffff', borderRadius: 2 }}>
        <div>{payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#e4e4e4', fontSize: 11 }}>
          {moment(label).tz(timezone).format(DB_RECORD_DATETIME_FORMAT)}
        </div>
      </div>
    );
  }
  return null;
};

const CustomCountTick = ({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dx={-40} fill="#777" fontSize="12" textAnchor="middle">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

const CustomDateTick = ({ x, y, payload }: TickProps) => {
  const { timezone } = useLocalization();
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={24} fill="#777" fontSize="12" textAnchor="middle">
        {moment(payload.value).tz(timezone).format(DB_RECORD_DATETIME_FORMAT)}
      </text>
    </g>
  );
};

const getInterval = (diff, datepart) => {
  if (datepart === 'hour') {
    if (diff <= 0) return 0;
    else return 'preserveStartEnd';
  } else if (datepart === 'day') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 5;
    else if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else if (datepart === 'week') {
    if (diff <= 10) return 0;
    else if (diff <= 30) return 2;
    else if (diff <= 50) return 5;
    else return 'preserveStartEnd';
  } else if (datepart === 'month') {
    if (diff <= 100) return 10;
    else return 'preserveStartEnd';
  } else {
    return 'preserveStartEnd';
  }
};
