import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DeleteIconButton = props => {
  return <StyledIcon {...props} />;
};

const StyledIcon = styled(DeleteOutlined)`
  cursor: pointer;
  svg {
    fill: #ff4d4f;
    &:hover {
      fill: #ff7875;
    }
  }
`;

export default DeleteIconButton;
