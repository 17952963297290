import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Input, Button, Alert } from 'antd';
import { Formik, Field, Form } from 'formik';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import * as yup from 'yup';

import FormItem from 'components/Form/StyledFormItem';
import {
  validateResetPassword,
  validateFirstTimeChangepassword,
  initChangePassword,
  resetPassword
} from 'containers/App/actions';
import {
  makeSelectIsValidPassword,
  makeSelectError,
  makeSelectInitPasswordChanged,
  makeSelectResetPassword,
  makeSelectInitChangevalidToken,
  makeSelectTokenError,
  makeSelectLoading
} from 'containers/App/selectors';

import PublicPage from './components/PublicPage';

const Content = styled.section`
  & input {
    padding: 0px;
  }
`;

const Padlock = styled.p`
  padding: 20px 0;
  display: flex;
  font-size: 14px;

  & a {
    color: #9b9b9b;
  }
`;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    // character requirements are now validated on the backend
    // .min(8, 'Password is too short (minimum is 8 characters)')
    .required('Please input your Password!'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'The passwords that you entered are inconsistent!')
    .required('Please input your Password!')
});

interface ResetPasswordFormProps extends RouteComponentProps {
  isValidReset: any;
  isValidInitChangePwd: any;
  isResetPassWord: any;
  isInitPasswordChanged: any;
  resetPassword: (values: any) => void;
  changePassword: (values: any) => void;
  validateResetPassword: (params: { reset_password_token: string }) => void;
  validateInviteToken: (params: { invitation_token: string }) => void;
  error: any;
  loading: boolean;
  tokenError: any;
}

interface ResetPasswordFormState {
  confirmDirty: boolean;
  isValidReset: boolean;
  isValidInitialChangePassword: boolean;
}

class ResetPasswordForm extends React.Component<ResetPasswordFormProps, ResetPasswordFormState> {
  state = {
    confirmDirty: false,
    isValidReset: true,
    isValidInitialChangePassword: true
  };

  handleSubmit = (values, bag) => {
    const { resetPassword, changePassword } = this.props;
    if (this.props.isValidReset) {
      values['reset_password_token'] = this.props.location.search.replace('?', '').split('=')[1];
      resetPassword(values);
    } else if (this.props.isValidInitChangePwd) {
      values['invitation_token'] = this.props.location.search.replace('?', '').split('=')[1];
      changePassword(values);
    }
  };

  componentDidMount() {
    if (this.props.location.search.includes('reset_password_token')) {
      try {
        let token = this.props.location.search.replace('?', '').split('=')[1];
        this.props.validateResetPassword({ reset_password_token: token });
      } catch (e) {
        this.setState({ isValidReset: false });
      }
      // this.setState({isValidReset:(this.props.isValidReset)})
      this.setState({ isValidReset: true });
    } else if (this.props.location.search.includes('invitation_token')) {
      try {
        let token = this.props.location.search.replace('?', '').split('=')[1];
        this.props.validateInviteToken({ invitation_token: token });
        // validate invite token here
      } catch (e) {
        this.setState({ isValidInitialChangePassword: false });
      }
      this.setState({ isValidInitialChangePassword: true });
    } else {
      this.setState({ isValidReset: false });
      this.props.history.push('set-password');
    }
  }

  componentDidUpdate() {
    if (this.props.isResetPassWord) {
      if (!('status' in this.props.isResetPassWord)) {
        this.props.history.push('/signin');
      }
    } else if (this.props.isInitPasswordChanged) {
      if (!('status' in this.props.isInitPasswordChanged)) {
        this.props.history.push('/signin');
      }
    }
  }

  render() {
    const { error, loading, tokenError } = this.props;

    return (
      <Formik
        initialValues={{
          password: '',
          confirm_password: ''
        }}
        onSubmit={this.handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Content>
            {!tokenError ? (
              <Form className="login-form">
                {!!error && (
                  <Alert
                    style={{ marginBottom: '0.5rem' }}
                    message={error?.data?.message}
                    type={'error'}
                    showIcon
                    closable
                  />
                )}
                <Field name="password">
                  {({ field, meta }) => (
                    <FormItem
                      label="Password"
                      validateStatus={meta.error && meta.touched ? 'error' : null}
                      hasFeedback={meta.touched && !meta.error}
                      help={meta.error && meta.touched ? <>{meta.error}</> : null}
                      required
                    >
                      <Input.Password
                        allowClear
                        id="password"
                        label="Password"
                        placeholder="Please enter a new password"
                        {...field}
                      />
                    </FormItem>
                  )}
                </Field>
                <Field name="confirm_password">
                  {({ field, meta }) => (
                    <FormItem
                      label="Confirm Password"
                      validateStatus={meta.error && meta.touched ? 'error' : null}
                      hasFeedback={meta.touched && !meta.error}
                      help={meta.error && meta.touched ? <>{meta.error}</> : null}
                      required
                    >
                      <Input.Password
                        allowClear
                        id="confirm_password"
                        label="Confirm Password"
                        placeholder="Please repeat password"
                        {...field}
                      />
                    </FormItem>
                  )}
                </Field>
                <Button
                  style={{ width: '100%', margin: 0, marginTop: '1.5rem' }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={loading}
                >
                  {this.props.isValidReset &&
                    !('status' in this.props.isValidReset) &&
                    `Reset Password`}
                  {this.props.isValidInitChangePwd &&
                    !('status' in this.props.isValidInitChangePwd) &&
                    `Set Password`}
                </Button>
              </Form>
            ) : (
              error &&
              'status' in error && <Alert message={error?.data?.message} type={'error'} showIcon />
            )}
            <Padlock>
              <Link to="/signin">Back to Login</Link>
            </Padlock>
          </Content>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: reset => dispatch(resetPassword(reset)),
  validateResetPassword: token => dispatch(validateResetPassword(token)),
  validateInviteToken: token => dispatch(validateFirstTimeChangepassword(token)),
  changePassword: token => dispatch(initChangePassword(token))
});

const selectIsValid = state => makeSelectIsValidPassword(state);
const selectResetPassword = state => makeSelectResetPassword(state);
const selectInitValueValid = state => makeSelectInitChangevalidToken(state);
const selectInitPasswordChanged = state => makeSelectInitPasswordChanged(state);
const selectError = state => makeSelectError()(state);
const selectTokenError = state => makeSelectTokenError()(state);
const selectLoading = state => makeSelectLoading()(state);
const mapStateToProps = createStructuredSelector({
  isValidReset: selectIsValid,
  isResetPassWord: selectResetPassword,
  isValidInitChangePwd: selectInitValueValid,
  isInitPasswordChanged: selectInitPasswordChanged,
  error: selectError,
  tokenError: selectTokenError,
  loading: selectLoading
});

const ResetPassword = memo((props: ResetPasswordFormProps) => {
  const { isValidReset, isValidInitChangePwd } = props;
  const title = (
    <div>
      {isValidReset && !('status' in isValidReset) && `Change Password`}

      {isValidInitChangePwd && !('status' in isValidInitChangePwd) && `Set a Password`}
    </div>
  );
  return <PublicPage title={title} form={<ResetPasswordForm {...props} />} />;
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
