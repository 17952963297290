import React, { useState } from 'react';
import { message, Button, Input } from 'antd';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';

import { API_ROOT, PERX_MAIL_DOMAIN } from 'appConstants';
import FormItem from 'components/Form/StyledFormItem';

import PublicPage from './components/PublicPage';

const InviteTenantForm = () => {
  const [emailString, setEmailString] = useState('');

  const onSubmit = async values => {
    values.email = emailString + PERX_MAIL_DOMAIN;
    try {
      await axios.post(`${API_ROOT}/v4/dash/oauth_applications`, values, {
        headers: { 'Content-Type': 'application/json' }
      });
      message.success('Invitation sent successfully. Please check the email');
    } catch (error) {
      if (error?.response?.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Error inviting the tenant');
      }
    }
  };

  const changeTenantName = (event, field) => {
    field.onChange(event);
  };

  const changeEmail = (event, form) => {
    form.setFieldValue('email', `${event.target.value}`);
    setEmailString(event.target.value);
  };

  const validationSchema = yup.object().shape({
    tenant_name: yup.string().required('Tenant name is required'),
    email: yup
      .string()
      // .email('Invalid email address')
      // .min(emailString.length + 1, 'Email is required')
      .required('Email is required')
  });

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{
        tenant_name: '',
        email: ''
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="tenant_name">
            {({ field, meta }) => {
              const hasError = meta.error !== undefined;
              const isValid = meta.touched && !meta.error;
              return (
                <FormItem
                  label="Tenant Name"
                  htmlFor="tenant_name"
                  validateStatus={meta.error && meta.touched ? 'error' : undefined}
                  hasFeedback={isValid}
                  help={hasError && meta.touched && <>{meta.error}</>}
                  required
                >
                  <Input
                    {...field}
                    id="tenant_name"
                    allowClear
                    placeholder="Please enter a tenant name"
                    onChange={value => changeTenantName(value, field)}
                  />
                </FormItem>
              );
            }}
          </Field>
          <Field name="email">
            {({ field, meta, form }) => {
              const hasError = meta.error !== undefined;
              const isValid = meta.touched && !meta.error;
              return (
                <FormItem
                  label="Email"
                  htmlFor="email"
                  validateStatus={meta.error && meta.touched ? 'error' : undefined}
                  hasFeedback={isValid}
                  help={hasError && meta.touched && <>{meta.error}</>}
                  required
                >
                  <Input
                    {...field}
                    id="email"
                    allowClear
                    placeholder="Please enter an email address"
                    value={emailString}
                    addonAfter={PERX_MAIL_DOMAIN}
                    onChange={value => changeEmail(value, form)}
                  />
                </FormItem>
              );
            }}
          </Field>
          <Button
            style={{ width: '100%', margin: 0, marginTop: '1.5rem' }}
            size="large"
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
          >
            Send Invitation
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const InviteTenant = () => {
  return <PublicPage title="Invite Tenant" form={<InviteTenantForm />} />;
};

export default InviteTenant;

//add-limitation-to-email-on-invite-tenant-page
