import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  CREATE_CATALOGUE,
  CREATE_CATALOGUE_SUCCESS,
  CREATE_CATALOGUE_FAIL,
  ACTIVATE_CATALOGUE,
  ACTIVATE_CATALOGUE_SUCCESS,
  ACTIVATE_CATALOGUE_FAIL,
  DEACTIVATE_CATALOGUE,
  DEACTIVATE_CATALOGUE_SUCCESS,
  DEACTIVATE_CATALOGUE_FAIL,
  UPDATE_CATALOGUE,
  UPDATE_CATALOGUE_SUCCESS,
  UPDATE_CATALOGUE_FAIL,
  ORDER_CATALOGUE,
  ORDER_CATALOGUE_SUCCESS,
  ORDER_CATALOGUE_FAIL,
  GET_CATALOGUES,
  GET_CATALOGUES_SUCCESS,
  GET_CATALOGUES_FAIL,
  GET_CATALOGUE,
  GET_CATALOGUE_SUCCESS,
  GET_CATALOGUE_FAIL,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_SORTED_REWARDS_STORE,
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  SAVE_SORTED_REWARDS_STORE,
  SEARCH_CATALOGUE,
  SEARCH_CATALOGUE_FAIL,
  SEARCH_CATALOGUE_SUCCESS,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL
} from './constants';
import {
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_FAILED,
  GET_AUDIENCE_INFO_SUCCESS
} from './constants';

export const catalogueCreate = (payload, history) => {
  return {
    type: CREATE_CATALOGUE,
    payload,
    history
  };
};

export const catalogueCreateSuccess = payload => {
  return {
    type: CREATE_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueCreateFail = error => {
  return {
    type: CREATE_CATALOGUE_FAIL,
    error
  };
};

export const catalogueActivate = payload => {
  return {
    type: ACTIVATE_CATALOGUE,
    payload
  };
};

export const catalogueActivateSuccess = payload => {
  return {
    type: ACTIVATE_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueActivateFail = error => {
  return {
    type: ACTIVATE_CATALOGUE_FAIL,
    error
  };
};

export const catalogueDeactivate = payload => {
  return {
    type: DEACTIVATE_CATALOGUE,
    payload
  };
};

export const catalogueDeactivateSuccess = payload => {
  return {
    type: DEACTIVATE_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueDeactivateFail = error => {
  return {
    type: DEACTIVATE_CATALOGUE_FAIL,
    error
  };
};

export const catalogueUpdate = (payload, history) => {
  return {
    type: UPDATE_CATALOGUE,
    payload,
    history
  };
};

export const catalogueUpdateSuccess = payload => {
  return {
    type: UPDATE_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueUpdateFail = error => {
  return {
    type: UPDATE_CATALOGUE_FAIL,
    error
  };
};

export const catalogueOrder = payload => {
  return {
    type: ORDER_CATALOGUE,
    endpoint: `/v4/dash/catalogs/ordering`,
    types: [ORDER_CATALOGUE, ORDER_CATALOGUE_SUCCESS, ORDER_CATALOGUE_FAIL],
    method: 'patch',
    notification: {
      error: () => {
        message.error('Failed to Order Catalogue!', DURATION_TIMER);
      }
    },
    nextAction: cataloguesGet(),
    params: payload
  };
};

export const catalogueOrderSuccess = payload => {
  return {
    type: ORDER_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueOrderFail = error => {
  return {
    type: ORDER_CATALOGUE_FAIL,
    error
  };
};

export const sortedRewardsSaveStore = payload => {
  return {
    type: SAVE_SORTED_REWARDS_STORE,
    payload
  };
};

export const sortedRewardsGetStore = payload => {
  return {
    type: GET_SORTED_REWARDS_STORE,
    payload
  };
};

export const cataloguesGet = (payload?) => {
  return {
    type: GET_CATALOGUES,
    payload
  };
};

export const cataloguesGetSuccess = payload => {
  return {
    type: GET_CATALOGUES_SUCCESS,
    payload
  };
};

export const cataloguesGetFail = error => {
  return {
    type: GET_CATALOGUES_FAIL,
    error
  };
};

export const catalogueGet = payload => {
  return {
    type: GET_CATALOGUE,
    payload
  };
};

export const catalogueGetFail = payload => {
  return {
    type: GET_CATALOGUE_FAIL,
    payload
  };
};

export const catalogueGetSuccess = payload => {
  return {
    type: GET_CATALOGUE_SUCCESS,
    payload
  };
};

export const rewardsGet = payload => {
  return {
    type: GET_REWARDS,
    payload
  };
};

export const rewardsGetSuccess = payload => {
  return {
    type: GET_REWARDS_SUCCESS,
    payload
  };
};

export const rewardsGetFail = error => {
  return {
    type: GET_REWARDS_FAIL,
    error
  };
};

export const tagsGet = (payload?) => {
  return {
    type: GET_TAGS,
    payload
  };
};

export const tagsGetSuccess = payload => {
  return {
    type: GET_TAGS_SUCCESS,
    payload
  };
};

export const tagsGetFail = error => {
  return {
    type: GET_TAGS_FAIL,
    error
  };
};

export const catalogueSearch = payload => {
  return {
    type: SEARCH_CATALOGUE,
    payload
  };
};

export const catalogueSearchSuccess = payload => {
  return {
    type: SEARCH_CATALOGUE_SUCCESS,
    payload
  };
};

export const catalogueSearchFail = error => {
  return {
    type: SEARCH_CATALOGUE_FAIL,
    error
  };
};
export const searchReward = payload => {
  return {
    type: SEARCH_REWARD,
    payload
  };
};

export const searchRewardSuccess = payload => {
  return {
    type: SEARCH_REWARD_SUCCESS,
    payload
  };
};

export const searchRewardFail = payload => {
  return {
    type: SEARCH_REWARD_FAIL,
    payload
  };
};

export const audienceInfoGet = payload => {
  return {
    type: GET_AUDIENCE_INFO,
    endpoint: `/v4/dash/simple/audiences`,
    types: [GET_AUDIENCE_INFO, GET_AUDIENCE_INFO_SUCCESS, GET_AUDIENCE_INFO_FAILED],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};
