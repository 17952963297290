import { Layout } from 'antd';
import styled from 'styled-components';

import { GridContainer } from 'components/Layout/common';

export const Logo = styled.div`
  max-height: 4rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Profile = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 80px;
    height: auto;
    border-radius: 50%;
    margin: 1rem;
  }
`;

export const UserEmail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  strong {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Sider = styled(Layout.Sider)`
  &&& {
    .ant-menu-item-selected {
      background-color: transparent;
      a {
        ::before {
          width: 6px;
          background-color: #ff7f00;
        }
      }
      &:focus {
        outline: auto 1px -webkit-focus-ring-color;
      }
    }
  }
`;

export const Circle = styled.div<{ isGrayLite?: boolean; isGrayLink?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #9b9b9b;
  color: ${({ isGrayLite }) => (isGrayLite ? '#9B9B9B' : 'white')};
  background: ${({ isGrayLite }) => (isGrayLite ? '#E4E4E4' : '#9B9B9B')};
`;

export const StyledGridContainer = styled(GridContainer)<{
  isBlackLink?: boolean;
  isGrayLink?: boolean;
}>`
  ${({ isGrayLink }) =>
    isGrayLink &&
    `
    cursor: pointer;
    color: #9B9B9B;
    transition: color .3s ease;

    &:hover {
      color: black;
    }
  `}
  ${({ isBlackLink }) =>
    isBlackLink &&
    `
    cursor: pointer;
    color: black;
    transition: color .3s ease;

    &:hover {
      color: #9B9B9B;
    }
  `}
`;
