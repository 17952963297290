import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;
export const Chart = styled.section`
  flex: 1;
`;
export const Content = styled.section`
  flex: 1;
`;
export const H3 = styled.h3`
  font-weight: 500;
  margin-bottom: 1rem;
`;
export const Subtitle = styled.h4`
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
`;
export const Section = styled.section`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const FilterList = styled.div`
  margin-left: 1rem;
`;
