import React, { useReducer, useEffect, useCallback, useState } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Modal, Tabs, Tag } from 'antd';
import produce from 'immer';
import _capitalize from 'lodash/capitalize';
import { TableMainText, TableSecondaryText } from 'styles';

import { useCampaignList } from 'api/campaigns';
import { SMALL_PAGE_SIZE } from 'appConstants';
import { TableTimestamp, SearchBar, StatusTag } from 'components';
import { TableWithNoWrapHeader } from 'components/StyledTable';
import { IAnyCampaign } from 'containers/Campaign/types';

interface CampaignSelectModalState {
  state: any;
  page: any;
  order: any;
  size: any;
  sort_by: any;
  search_string: any;
  campaign_type: any;
  eligible_user_account_id: any;
}
const paramsReducer = (state: CampaignSelectModalState, action): CampaignSelectModalState =>
  produce(state, draft => {
    switch (action.type) {
      case 'CAMPAIGN_TYPE':
        // VS-4423
        if (action.value === 'quiz' || action.value === 'survey') {
          // if quiz or survey is selected
          draft['campaign_type'] = 'game';
          draft['game_type'] = action.value;
        } else {
          // other campaign types
          draft['campaign_type'] = action.value;
          delete draft['game_type'];
        }
        break;
      case 'STATE':
        draft.state = action.value === 'all' ? null : action.value;
        break;
      case 'TABLE':
        draft.page = action.pagination;
        if (Object.keys(action.sorter).length) {
          draft['sort_by'] = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        }

        if (action.size) {
          draft.size = action.size;
        }

        break;
      case 'SEARCH':
        draft['search_string'] = action.value;
        break;
      default:
        break;
    }
  });

interface CampaignSelectModalProps {
  campaignType?: string;
  hasMultiRowSelect?: boolean;
  tabs?: string[];
  eligableUserAccountId?: number;
  alreadyChosenCampaignsKeys?: number[];
  otherParams?: Record<any, any>;
}

const CampaignSelectModal = NiceModal.create(
  ({
    campaignType = null,
    hasMultiRowSelect = false,
    tabs,
    eligableUserAccountId = null,
    alreadyChosenCampaignsKeys = [],
    otherParams = {}
  }: CampaignSelectModalProps) => {
    const modal = useModal();
    const [selectedCampaignKeys, setSelectedCampaignKeys] = useState(alreadyChosenCampaignsKeys);
    const [params, paramsDispatch] = useReducer(paramsReducer, {
      state: !tabs ? null : tabs[0] === 'all' ? null : tabs[0],
      page: 1,
      order: 'asc',
      sort_by: null,
      search_string: '',
      size: SMALL_PAGE_SIZE,
      campaign_type: campaignType,
      eligible_user_account_id: eligableUserAccountId,
      ...otherParams
    });
    const { data, meta, isLoading } = useCampaignList(params);

    const onTableChange = useCallback((pagination, sorter) => {
      paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter });
    }, []);

    const onTabChange = tab => paramsDispatch({ type: 'STATE', value: tab });

    const onSelectCampaignChange = useCallback(selectedCampaignKeys => {
      setSelectedCampaignKeys(selectedCampaignKeys);
    }, []);

    useEffect(() => {
      paramsDispatch({ type: 'CAMPAIGN_TYPE', value: campaignType });
    }, [campaignType]);

    const handleOk = () => {
      if (hasMultiRowSelect) {
        modal.resolve(selectedCampaignKeys);
        modal.hide();
      } else {
        modal.resolve();
        modal.hide();
      }
    };

    return (
      <Modal
        {...antdModalV5(modal)}
        onOk={handleOk}
        maskClosable={false}
        width={1200}
        title="Select a Campaign"
        okText="Add Campaign"
        cancelText="Cancel"
      >
        <div data-testid="CampaignSelectModal">
          <SearchBar
            onSearch={value => paramsDispatch({ type: 'SEARCH', value })}
            placeholder="Find a campaign by name, type or merchant"
            style={{ marginBottom: '0.5rem' }}
          />
          {tabs && (
            <Tabs
              activeKey={!params.state ? 'all' : params.state}
              onChange={onTabChange}
              tabBarStyle={{ marginBottom: 0 }}
              items={tabs.map(tab => ({ key: tab, label: _capitalize(tab) }))}
            />
          )}
          <TableWithNoWrapHeader
            scroll={{ y: 430 }}
            rowKey={'id'}
            loading={isLoading}
            columns={[
              {
                title: 'Name',
                key: 'name',
                render: (_, record: IAnyCampaign) => (
                  <>
                    <TableMainText>{record.name}</TableMainText>
                    <TableSecondaryText>ID: {record.id}</TableSecondaryText>
                  </>
                )
              },
              {
                title: 'Status',
                key: 'state',
                dataIndex: 'state',
                render: state => <StatusTag state={state} />
              },
              {
                title: 'Tags',
                key: 'tags',
                render: (_, record: IAnyCampaign) => (
                  <>
                    {record.tags?.map(tag => (
                      <Tag key={tag.id}>{tag.name}</Tag>
                    ))}
                  </>
                )
              },
              {
                title: 'Start Date',
                key: 'start_date',
                render: (_, record) => <TableTimestamp timestamp={record['start_date']} />
              },
              {
                title: 'End Date',
                key: 'end_date',
                render: (_, record) => <TableTimestamp timestamp={record['end_date']} />
              }
            ]}
            dataSource={data}
            onChange={onTableChange}
            onRow={
              !hasMultiRowSelect
                ? (record, _) => ({
                    onClick: () => {
                      modal.resolve(record);
                      modal.hide();
                    }
                  })
                : null
            }
            rowSelection={
              hasMultiRowSelect
                ? {
                    selectedRowKeys: selectedCampaignKeys,
                    onChange: onSelectCampaignChange
                  }
                : null
            }
            showHeader
            pagination={{
              showQuickJumper: true,
              current: params.page,
              position: ['bottomRight'],
              defaultPageSize: SMALL_PAGE_SIZE,
              total: meta?.total_count || 0
            }}
          />
        </div>
      </Modal>
    );
  }
);

export default CampaignSelectModal;
