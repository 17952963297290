import React, { memo } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Card, Upload, message, Button } from 'antd';
import { useField, FieldArray } from 'formik';
import produce from 'immer';
import { formLayout } from 'styles';

import { FormItem } from 'components/Form/FormikFields';
import ImageCard from 'containers/Campaign/components/ImageCard';

interface Props {
  name?: string;
  isCard?: boolean;
  dataTestid?: string;
}

const UploadPhotoField = ({ name = 'images', isCard = true, dataTestid }: Props) => {
  const [field] = useField(name);

  const onBeforeUpload = file => {
    return false;
    // TODO: Check if updating antd gets this to work4.x
    // return validateImageFile(file) ? false : Upload.LIST_IGNORE;
  };

  const onDeleteImage = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);
  };

  const onChooseSection =
    (arrayHelpers, index) =>
    ({ key }) => {
      const newImg = produce(field.value[index], draft => {
        draft.section = key;
      });
      arrayHelpers.replace(index, newImg);
    };

  const onChange =
    arrayHelpers =>
    ({ file }) => {
      const reader = new FileReader();
      reader.onload = () => {
        arrayHelpers.push({ filename: file.name, file: reader.result, section: '' });
      };
      reader.onerror = error => message.error('Image error');
      reader.readAsDataURL(file);
    };

  const ImagesField = (
    <FieldArray
      name={name}
      validateOnChange={false}
      render={arrayHelpers => (
        <>
          <Upload
            name=""
            listType="picture"
            beforeUpload={onBeforeUpload}
            onChange={onChange(arrayHelpers)}
            showUploadList={false}
            accept="image/*"
          >
            <Button type="primary">
              <UploadOutlined />
              Upload a File
            </Button>
          </Upload>
          {field?.value?.map((image, index) => {
            return (
              <ImageCard
                key={index}
                {...image}
                onChooseSection={onChooseSection(arrayHelpers, index)}
                onDelete={onDeleteImage(arrayHelpers, index)}
              />
            );
          })}
        </>
      )}
    />
  );

  if (isCard) {
    return (
      <Card title="Upload Photo" style={{ marginBottom: '1rem' }}>
        <FormItem label="Upload Multiple Images" {...formLayout} data-testid={dataTestid}>
          {ImagesField}
        </FormItem>
      </Card>
    );
  }

  return <FormItem label="Upload Multiple Images">{ImagesField}</FormItem>;
};

export default memo(UploadPhotoField);
