import React, { memo, useMemo, useCallback } from 'react';
import { Table, Card } from 'antd';
import { TableProps } from 'antd/lib/table';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { GridItem } from 'components/Display/GridRenderer';
import { useMetabase, useCard, useAnalyticsContext } from 'containers/Analytics/hooks';
import { GraphContent } from 'containers/Analytics/styles';
import { showCard } from 'containers/Analytics/utils';

import Error from '../../components/Error';
import GraphCardMenu from '../../components/GraphCardMenu';
import {
  tablePrimaryClusters,
  tableTopMerchants,
  tableLoyaltyEarnedBurnedPointsPerTier,
  tableCampaignEngagementRate,
  getEngagementCompletionData
} from './dummyData';

const CampaignTableGraph: React.FC<{
  identifier: string;
  title: string;
  tableProps: TableProps<any>;
  helpText?: string;
  filterParams?: any;
  n?: number;
  minHeight?: string;
  pagination?: boolean;
  scrollX?: boolean;
}> = ({ identifier, title, filterParams, n, minHeight, tableProps, pagination, scrollX }) => {
  const { card_id: questionId } = useCard(identifier);
  const dataParams = useMemo(() => filterParams, [filterParams]);
  const { data, dataError, token, tokenError, dataRevalidate, isDataValidating } = useMetabase(
    questionId,
    dataParams
  );
  const { isDemoMode, cards } = useAnalyticsContext();

  const onRetry = useCallback(() => {
    dataRevalidate();
  }, [dataRevalidate]);

  const hasError = tokenError || dataError;
  const renderContent = () => {
    let d;
    let loading = true;
    if (isDemoMode) {
      switch (identifier) {
        case 'campaign_flat_stamp_roi':
          d = tableTopMerchants;
          break;
        case 'merchant_top_merchant':
          d = tableTopMerchants;
          break;
        case 'loyalty_flat_earned_burned_points_per_tier':
          d = tableLoyaltyEarnedBurnedPointsPerTier;
          break;
        case 'campaign_flat_stamp_cluster':
        case 'campaign_flat_game_cluster':
        case 'campaign_flat_game_state':
        case 'campaign_flat_stamp_state':
          d = getEngagementCompletionData();
          break;
        case 'campaign_flat_game_top_campaign_by_performance':
          d = tableCampaignEngagementRate(true);
          break;
        case 'campaign_flat_stamp_top_campaign_by_performance':
          d = tableCampaignEngagementRate();
          break;
        default:
          d = tablePrimaryClusters;
      }
      loading = false;
    } else {
      d = data;
      loading = !data || !token;
    }

    if (hasError && !isDataValidating) return <Error retry={onRetry} />;

    if (_isEmpty(minHeight)) {
      minHeight = '100%';
    }
    return (
      <Graph
        data={d}
        loading={loading}
        n={n}
        tableProps={tableProps}
        minHeight={minHeight}
        pagination={pagination}
        scrollX={scrollX}
      />
    );
  };

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  return (
    <GridItem>
      <Card
        title={title}
        bodyStyle={{ padding: '20px 20px 0 20px', fontSize: '16px', fontWeight: 500 }}
        headStyle={{ borderBottom: '0px', paddingLeft: '25px', paddingRight: '40px' }}
        style={{ marginBottom: '1rem' }}
        extra={<GraphCardMenu questionId={questionId} token={token} dataParams={dataParams} />}
      >
        <GraphContent>{renderContent()}</GraphContent>
      </Card>
    </GridItem>
  );
};

const CustomTable = styled(Table)<{ scrollX?: boolean }>`
  .ant-table-header-column {
    margin-left: 25px;
    max-width: 250px;
  }
  .ant-table-middle
    > .ant-table-content
    > ${props => (props.scrollX ? '.ant-table-scroll>' : null)}.ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td {
    max-width: 250px;
    padding-left: 25px;
    padding-right: 0px;
    max-height: 60px;
  }
  .ant-table-middle
    > .ant-table-content
    > ${props => (props.scrollX ? '.ant-table-scroll>' : null)}.ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th {
    padding: 12px 0px 12px 0px;
  }
  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    max-height: 60px;
  }
`;

const Graph: React.FC<{
  data: any;
  loading: boolean;
  n: number;
  tableProps: TableProps<any>;
  minHeight: string;
  pagination: boolean;
  scrollX: boolean;
}> = memo(({ data, loading, n, tableProps, minHeight, pagination, scrollX }) => {
  // Round floats to two decimal places, 2.2243 -> 2.22
  const formatNumber = (list: (string | number)[]) => {
    return list.map((item: string | number) => {
      if (typeof item === 'number' && !isNaN(item) && item % 1 !== 0) {
        return parseFloat(item.toFixed(2));
      }
      return item;
    });
  };
  let d = [];
  if (!_isEmpty(data)) {
    d = data?.data?.rows
      .map((x, idx: number) => [idx + 1, ...formatNumber(x)])
      .slice(0, n ? n : data?.data?.rows.length);
  }

  return (
    <CustomTable
      scrollX={scrollX}
      loading={loading}
      dataSource={d}
      size="middle"
      pagination={pagination ? { pageSize: 5 } : false}
      style={{ marginTop: 1, minHeight: minHeight }}
      {...tableProps}
    />
  );
});

CampaignTableGraph.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  filterParams: PropTypes.object,
  n: PropTypes.number,
  tableProps: PropTypes.object.isRequired,
  minHeight: PropTypes.string,
  pagination: PropTypes.bool,
  scrollX: PropTypes.bool
};

export default memo(CampaignTableGraph);
