import styled from 'styled-components';

import { colors } from 'assets/styles/commonStyle';

export const StyledTag = styled.div`
  padding: 0.3125rem 1rem;
  margin-right: 0.3125rem;
  background: #e6ebf0;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  display: inline;
  &:last-child {
    margin-right: 0;
  }
`;

export const PageHeader = styled.h1`
  line-height: 20px;
  vertical-align: center;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
`;

interface TableMainTextProps {
  fontSize?: string;
  textAlign?: string;
  display?: string;
}

export const TableMainText = styled.div<TableMainTextProps>`
  color: #000000;
  font-weight: 500;
  line-height: 1.5;
  font-size: ${({ fontSize }) => fontSize || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  display: ${({ display }) => display || ''};
`;

export const TableSecondaryText = styled.div<{ display?: string; fontSize?: string }>`
  color: #9b9b9b;
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  line-height: 22px;
  display: ${({ display }) => display || ''};
`;

export const StyledLink = styled.span`
  color: ${colors.base};
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

export const Muted = styled.span`
  color: #9b9b9b;
  font-size: 0.75rem;
`;
