import { message } from 'antd';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { UNLOCK_USER } from 'containers/Setting/constants';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';
import { checkPayloadForFile } from 'utils/prepareFormData';

import { amendAudienceFail, amendAudienceSuccess } from './actions';
import {
  AMEND_AUDIENCE,
  GET_ROLES,
  USER_UPDATE,
  USER_CREATE,
  GET_USERS,
  GET_USER,
  DELETE_ROLE,
  DEACTIVATE_USER,
  ACTIVATE_USER,
  GET_FTP_FILE_LIST,
  GET_LABELS,
  CREATE_LABEL,
  UPDATE_LABEL,
  DELETE_LABELS,
  DEACTIVATE_USER_GROUP,
  ACTIVATE_USER_GROUP,
  DEACTIVATE_ROLE,
  ACTIVATE_ROLE
} from './constants';

// import API from 'utils/request';

const amendAudience = (url, body, headers) => {
  body = checkPayloadForFile(body, headers);
  return api({
    url,
    body,
    headers,
    method: 'post'
  });
};

function* audienceAmend(action) {
  const authUser = yield select(makeSelectAuthUser());
  const data = action.payload || {};

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(
      amendAudience,
      `${API_ROOT}/v4/dash/rewards/amend_audience?audience_id=${data.audienceId}`,
      data,
      headers
    );
    yield put(amendAudienceSuccess(response.data));
  } catch (e) {
    message.error(e.data.message || 'something went wrong');
    yield put(amendAudienceFail(e.data));
  }
}

// function* createMediaSaga() {
//   try {
//     const response = yield call(API.post, '/v4/dash/custom_image_ratios');
//   } catch (error) {
//     //@ts-ignore
//     yield put();
//   }
// }

function* settingSaga() {
  yield takeLatest(AMEND_AUDIENCE, audienceAmend);
  yield takeLatest(GET_FTP_FILE_LIST, commonSaga);
  yield takeLatest(GET_LABELS, commonSaga);
  yield takeLatest(GET_ROLES, commonSaga);
  yield takeLatest(GET_USERS, commonSaga);
  yield takeLatest(GET_USER, commonSaga);
  yield takeLatest(USER_CREATE, commonSaga);
  yield takeLatest(USER_UPDATE, commonSaga);

  yield takeLatest(DEACTIVATE_USER, commonSaga);
  yield takeLatest(ACTIVATE_USER, commonSaga);
  yield takeLatest(UNLOCK_USER, commonSaga);

  yield takeLatest(DELETE_ROLE, commonSaga);
  yield takeLatest(CREATE_LABEL, commonSaga);
  yield takeLatest(UPDATE_LABEL, commonSaga);
  yield takeLatest(DELETE_LABELS, commonSaga);

  yield takeLatest(DEACTIVATE_ROLE, commonSaga);
  yield takeLatest(ACTIVATE_ROLE, commonSaga);

  yield takeLatest(DEACTIVATE_USER_GROUP, commonSaga);
  yield takeLatest(ACTIVATE_USER_GROUP, commonSaga);
}
export default settingSaga;
