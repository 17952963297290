import React from 'react';
import { Control, useController } from 'react-hook-form';
import { InputNumber, InputNumberProps, Typography } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import RHFItem from './RHFItem';

interface RHFItemInputNumberProps extends InputNumberProps {
  name: string;
  flag?: boolean;
  control?: Control<any, any>;
  label?: string;
  formItemProps?: FormItemProps;
}

const RHFItemInputNumber = ({
  control,
  name,
  label,
  flag,
  formItemProps,
  ...props
}: RHFItemInputNumberProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={flag ? null : label} htmlFor={name} {...formItemProps}>
      {flag ? (
        <Typography.Text style={{ fontWeight: 600 }}>
          {label}&nbsp;&nbsp;
          <InputNumber id={name} {...field} {...props} />
          &nbsp;&nbsp;points for every correct answer
        </Typography.Text>
      ) : (
        <InputNumber id={name} prefix={<span />} {...field} {...props} />
      )}
    </RHFItem>
  );
};

export default RHFItemInputNumber;
