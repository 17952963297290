import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  GET_LOYALTY_LIST,
  GET_LOYALTY_LIST_SUCCESS,
  GET_LOYALTY_LIST_FAIL,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_LOYALTY,
  GET_LOYALTY_SUCCESS,
  GET_LOYALTY_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_FAIL,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_FAIL,
  EDIT_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN,
  SEARCH_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN_FAIL,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  ORDER_CAMPAIGN,
  ORDER_CAMPAIGN_FAIL,
  ORDER_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN,
  ACTIVATE_CAMPAIGN_SUCCESS,
  ACTIVATE_CAMPAIGN_FAIL
} from './constants';

export const getLoyaltyPrograms = () => ({ type: 'GET_LOYALTY_PROGRAMS' });

export const campaignCreate = (payload, history) => {
  return {
    type: CREATE_CAMPAIGN,
    payload,
    history
  };
};

export const campaignCreateSuccess = payload => {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignCreateFail = error => {
  return {
    type: CREATE_CAMPAIGN_FAIL,
    error
  };
};

export const campaignDeactivate = payload => {
  return {
    type: DEACTIVATE_CAMPAIGN,
    payload
  };
};

export const campaignDeactivateSuccess = payload => {
  return {
    type: DEACTIVATE_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignDeactivateFail = error => {
  return {
    type: DEACTIVATE_CAMPAIGN_FAIL,
    error
  };
};

export const campaignActivate = payload => {
  return {
    type: ACTIVATE_CAMPAIGN,
    payload
  };
};

export const campaignActivateSuccess = payload => {
  return {
    type: ACTIVATE_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignActivateFail = error => {
  return {
    type: ACTIVATE_CAMPAIGN_FAIL,
    error
  };
};

export const campaignUpdate = (payload, history) => {
  return {
    type: EDIT_CAMPAIGN,
    payload,
    history
  };
};

export const campaignUpdateSuccess = payload => {
  return {
    type: EDIT_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignUpdateFail = error => {
  return {
    type: EDIT_CAMPAIGN_FAIL,
    error
  };
};

export const campaignGet = payload => {
  return {
    type: GET_LOYALTY,
    payload
  };
};

export const campaignGetSuccess = payload => {
  return {
    type: GET_LOYALTY_SUCCESS,
    payload
  };
};

export const campaignGetFail = error => {
  return {
    type: GET_LOYALTY_FAIL,
    error
  };
};

//TAGS for rewards fetch here
export const tagsGet = payload => {
  return {
    type: GET_TAGS,
    payload
  };
};

export const tagsGetSuccess = payload => {
  return {
    type: GET_TAGS_SUCCESS,
    payload
  };
};

export const tagsGetFail = error => {
  return {
    type: GET_TAGS_FAIL,
    error
  };
};

//CAMPAIGN ORDER
export const campaignOrder = payload => {
  return {
    type: ORDER_CAMPAIGN,
    payload
  };
};

export const campaignOrderSuccess = payload => {
  return {
    type: ORDER_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignOrderFail = error => {
  return {
    type: ORDER_CAMPAIGN_FAIL,
    error
  };
};

//TAGS for rewards fetch here
export const campaignsGet = payload => {
  return {
    type: GET_CAMPAIGNS,
    payload
  };
};

export const campaignsGetSuccess = payload => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload
  };
};

export const campaignsGetFail = error => {
  return {
    type: GET_CAMPAIGNS_FAIL,
    error
  };
};

//TAGS for loyalty fetch here
export const loyaltylistGet = payload => {
  return {
    type: GET_LOYALTY_LIST,
    payload
  };
};

export const loyaltylistGetSuccess = payload => {
  return {
    type: GET_LOYALTY_LIST_SUCCESS,
    payload
  };
};

export const loyaltylistGetFail = error => {
  return {
    type: GET_LOYALTY_LIST_FAIL,
    error
  };
};

export const rewardsGet = payload => {
  return {
    type: GET_REWARDS,
    payload
  };
};
//Rewards
export const rewardsGetSuccess = payload => {
  return {
    type: GET_REWARDS_SUCCESS,
    payload
  };
};

export const rewardsGetFail = error => {
  return {
    type: GET_REWARDS_FAIL,
    error
  };
};

//TAGS for rewards fetch here
export const audiencesGet = payload => {
  return {
    type: GET_AUDIENCES,
    payload
  };
};

export const audiencesGetSuccess = payload => {
  return {
    type: GET_AUDIENCES_SUCCESS,
    payload
  };
};

export const audiencesGetFail = error => {
  return {
    type: GET_AUDIENCES_FAIL,
    error
  };
};

//TAGS for rewards fetch here
export const audienceInfoGet = payload => {
  return {
    type: GET_AUDIENCE_INFO,
    payload
  };
};

export const audienceInfoGetSuccess = payload => {
  return {
    type: GET_AUDIENCE_INFO_SUCCESS,
    payload
  };
};

export const audienceInfoGetFail = error => {
  return {
    type: GET_AUDIENCE_INFO_FAILED,
    error
  };
};

export const campaignSearch = payload => {
  return {
    type: SEARCH_CAMPAIGN,
    payload
  };
};

export const campaignSearchSuccess = payload => {
  return {
    type: SEARCH_CAMPAIGN_SUCCESS,
    payload
  };
};

export const campaignSearchFail = error => {
  return {
    type: SEARCH_CAMPAIGN_FAIL,
    error
  };
};

export const searchReward = payload => {
  return {
    type: SEARCH_REWARD,
    payload
  };
};

export const searchRewardSuccess = payload => {
  return {
    type: SEARCH_REWARD_SUCCESS,
    payload
  };
};

export const searchRewardFail = payload => {
  return {
    type: SEARCH_REWARD_FAIL,
    payload
  };
};
