import { landingPageInitialDisplayProperties } from 'components/Phone/placeholders';
import { cdnURL } from 'utils/helpers';

export const placeholder = {
  static: {
    landing_page: landingPageInitialDisplayProperties.static,
    display_campaign_as: 'stamp_card',
    card_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/background_image.png'),
        filename: 'background_image.png'
      }
    },
    card_background_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/card_background_image.png'),
        filename: 'card_background_image.png'
      }
    },
    thumbnail_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/thumbnail_image.png'),
        filename: 'thumbnail_image.png'
      }
    },
    gift_active_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/gift_active.png'),
        filename: 'gift_active.png'
      }
    },
    gift_inactive_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/gift_inactive.png'),
        filename: 'gift_inactive.png'
      }
    },
    stamp_active_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/stamp_active.png'),
        filename: 'stamp_active.png'
      }
    },
    stamp_inactive_image: {
      type: 'image',
      value: {
        image_url: cdnURL('stamp/stamp_inactive.png'),
        filename: 'stamp_inactive.png'
      }
    },
    button_Bg_colour: '#fee800',
    button_text_colour: '#000000',
    rows: 4,
    cols: 2
  },
  translatable: {
    landing_page: landingPageInitialDisplayProperties.translatable,
    header: {
      value: {
        title: 'Start collecting stamps!',
        description: 'Enjoy your membership reward'
      },
      type: 'text',
      title: 'Start collecting stamps!',
      description: 'Enjoy your membership reward.'
    },
    button_text: 'Start collecting!',
    button_external_link: null
  }
};
