import { takeLatest, put, call, all } from 'redux-saga/effects';

import API from 'utils/request';

import { getPermissionsSuccess, getPermissionsError } from './actions';
import { GET_PERMISSIONS } from './constants';

function* getPermissions(action) {
  try {
    const response = yield call(API.get, '/v4/dash/authorizations');
    yield put(getPermissionsSuccess(response.data));
  } catch (error) {
    yield put(getPermissionsError(error.data));
  }
}

function* permissionsSaga() {
  yield all([takeLatest(GET_PERMISSIONS, getPermissions)]);
}

export default permissionsSaga;
