import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { FormItem, InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import { FItemSelect } from 'components/formik';
import RewardSelectModal from 'components/modals/RewardSelectModal';
import { policyOptions } from 'containers/Audience/utils';
import SelectedReward from 'containers/Rule/components/SelectedReward';
import { verticalLayout } from 'utils/formItemLayouts';

const Reward = () => {
  const modal = useModal(RewardSelectModal);
  const formik = useFormikContext<any>();

  const fieldPath = 'filters.earned_rewards[0].criteria';
  const selectedRewardId = _get(formik.values, `${fieldPath}.eq`);

  const selectReward = () =>
    modal.show().then((reward: any) => {
      formik.setFieldTouched(`${fieldPath}.eq`);
      formik.setFieldValue(`${fieldPath}.eq`, reward.id);
    });

  const deleteReward = () => {
    formik.setFieldValue(`${fieldPath}.eq`, undefined);
  };

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
      </FlexContainer>
      <FormItem {...verticalLayout} label="Users who earned the reward:">
        {selectedRewardId && (
          <SelectedReward id={selectedRewardId} entity="rewards" onDelete={deleteReward} />
        )}
        <Button type="primary" ghost onClick={selectReward}>
          {selectedRewardId ? 'Change' : 'Select'} reward
        </Button>
      </FormItem>
    </>
  );
};

export default Reward;
