import { cdnURL } from 'utils/helpers';

export const Backgrounds = [
  {
    filename: 'royal_purple.png',
    image_url: cdnURL('royal_purple.png')
  },
  {
    filename: 'natural_wood.png',
    image_url: cdnURL('natural_wood.png')
  },
  {
    filename: 'football_field.png',
    image_url: cdnURL('football_field.png')
  }
];

export const Rewards = [
  {
    filename: 'reward_icon.png',
    image_url: cdnURL('reward_icon.png')
  }
];
