import React from 'react';
import { Control, useController } from 'react-hook-form';
import { DatePicker, DatePickerProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

import RHFItem from './RHFItem';

type RHFItemDateTimePickerProps = DatePickerProps & {
  control: Control<any, any>;
  name: string;
  label?: string;
  placeholder?: string;
  formItemProps?: FormItemProps;
};

const RHFItemDateTimePicker = ({
  control,
  name,
  label,
  placeholder,
  formItemProps,
  ...props
}: RHFItemDateTimePickerProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={label} htmlFor={name} {...formItemProps}>
      <DatePicker
        aria-label={label}
        onBlur={field.onBlur}
        style={{ width: '100%' }}
        placeholder={placeholder}
        // @ts-ignore
        showTime
        {...props}
      />
    </RHFItem>
  );
};

export default RHFItemDateTimePicker;
