import { useEffect, useRef } from 'react';

const usePreviousValue = newValue => {
  const ref = useRef();

  useEffect(() => {
    ref.current = newValue;
  });

  return ref.current;
};

export default usePreviousValue;
