import { createSelector } from 'reselect';

const selectPermissions = state => state.permissions;

const makeActionsSelector = () =>
  createSelector(
    selectPermissions,
    (_, resource_name) => resource_name,
    (permissions, resource_name) => {
      return permissions.permissions[resource_name]?.actions || [];
    }
  );

const makeRestrictedActionsSelector = () =>
  createSelector(
    selectPermissions,
    (_, resource_name) => resource_name,
    (permissions, resource_name) => permissions.permissions[resource_name]?.restricted_actions || []
  );

const makeRestrictedFieldsSelector = () =>
  createSelector(
    selectPermissions,
    (_, resource_name) => resource_name,
    (permissions, resource_name) => permissions.permissions[resource_name]?.restricted_fields || []
  );

export { makeActionsSelector, makeRestrictedActionsSelector, makeRestrictedFieldsSelector };
