import React from 'react';

import { TransferRequestStatus } from 'api/bdo/transfer-requests';
import { StatusTag } from 'components';

import { getColorForStatus } from '../utils';

interface RequestStatusTagProps {
  state: TransferRequestStatus;
}

const RequestStatusTag = (props: RequestStatusTagProps) => (
  <StatusTag {...props} style={{ backgroundColor: getColorForStatus(props.state) }} />
);

export default RequestStatusTag;
