import { GET_PERMISSIONS, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE } from './constants';

const initialState = {
  loading: false,
  permissions: {},
  meta: {}
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        loading: true
      };
    case GET_PERMISSIONS_SUCCESS: {
      // convert it into a map with key = resource_name
      const permissions = {};
      action.payload.data.permissions.forEach(x => {
        permissions[x.resource_name] = {
          actions: x.actions || [],
          restricted_actions: x.restricted_actions || [],
          restricted_fields: x.restricted_fields || []
        };
      });
      return {
        ...state,
        permissions,
        loading: false,
        meta: action.payload.data.meta
      };
    }
    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default permissionsReducer;
