import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const authUserSelector = createSelector(
  state => state.global,
  global => global.authUser
);

const useAuth = () => useSelector(authUserSelector);

export default useAuth;
