import React from 'react';
import { Checkbox, Col, Divider, Row, Select } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import moment from 'moment';

import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';

const Birthday: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <>
      <FlexContainer>
        <label style={{ marginBottom: 8 }}>includes</label>
        <Select
          mode="multiple"
          value={_get(values, 'filters.birthmonth[0].criteria[0].values')}
          showArrow={true}
          allowClear
          placeholder="Birth month"
          onChange={values => {
            setFieldValue('filters.birthmonth[0].criteria[0].values', values);
          }}
          filterOption={(inputValue, option) =>
            (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
        >
          {moment.months().map((month, index) => {
            return (
              <Select.Option key={index} value={String(index + 1)}>
                {month}
              </Select.Option>
            );
          })}
        </Select>
      </FlexContainer>
      <Divider />
      <Row>
        <Col span={12}>
          <div className="spaced">
            <Label dark bold>
              Current Birth Month
            </Label>
          </div>
          <FlexContainer>
            <Checkbox
              checked={values.filters?.current_birthmonth?.[0]?.criteria?.[0]?.values || false}
              onChange={evt => {
                setFieldValue('filters.current_birthmonth', [
                  {
                    type: 'current_birthmonth',
                    criteria: [{ values: evt.target.checked }]
                  }
                ]);
              }}
            >
              Only During Month Of Birth
            </Checkbox>
          </FlexContainer>
        </Col>
        <Col span={12}>
          <div className="spaced">
            <Label dark bold>
              Has birthday
            </Label>
          </div>
          <FlexContainer>
            <Checkbox
              checked={values.filters?.current_birthday?.[0]?.criteria?.[0]?.values || false}
              onChange={evt => {
                setFieldValue('filters.current_birthday', [
                  {
                    type: 'current_birthday',
                    criteria: [{ values: evt.target.checked }]
                  }
                ]);
              }}
            >
              On Birthday
            </Checkbox>
          </FlexContainer>
        </Col>
      </Row>
    </>
  );
};

export default Birthday;
