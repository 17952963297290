import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Tag';

export const GET_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS`;
export const GET_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_SUCCESS`;
export const GET_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_FAIL`;

export const SEARCH_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_TAGS`;
export const SEARCH_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_TAGS_SUCCESS`;
export const SEARCH_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_TAGS_FAIL`;

export const DELETE_TAG = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_TAG`;
export const DELETE_TAG_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_TAG_SUCCESS`;
export const DELETE_TAG_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_TAG_FAIL`;
