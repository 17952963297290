import { Tag } from 'antd';
import styled from 'styled-components';

const StyledTag = styled(Tag.CheckableTag)`
  &&& {
    background-color: #d8d8d8;
    color: #9b9b9b;
    line-height: 2.25rem;
    cursor: pointer;
    font-size: 14px;
    padding: 0 1rem;
    &:hover {
      color: #9b9b9b;
    }
    &.ant-tag-checkable-checked {
      background-color: #0d517e;
      color: #ffffff;
    }
  }
`;

export default StyledTag;
