const initialState = {
  loading: false,
  log: {}
};

function downloadLogReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL_FOR_LOG_DOWNLOAD': {
      return {
        ...state,
        log: action.url ? action : {}
      };
    }
    default:
      return state;
  }
}

export default downloadLogReducer;
