import React from 'react';
import styled from 'styled-components';

import DeleteIconButton from 'components/DeleteIconButton';

interface FilterFormAudienceProps {
  title: string;
  icon: string;
  children: React.ReactNode;
  onDeleteButtonClick: React.MouseEventHandler<HTMLElement>;
  hideDeleteButton?: boolean;
}

const FilterFormAudienceContainer = (props: FilterFormAudienceProps) => {
  const { icon, children, title, onDeleteButtonClick } = props;

  return (
    <FilterFormAudienceWrapper>
      <ImgCol>
        <Img src={icon} />
        <Title>{title}</Title>
      </ImgCol>
      <ChildrenContent>{children}</ChildrenContent>
      {!props.hideDeleteButton && (
        <DeleteIconButton
          onClick={onDeleteButtonClick}
          style={{
            position: 'absolute',
            top: '24px',
            right: '24px'
          }}
        />
      )}
    </FilterFormAudienceWrapper>
  );
};

const FilterFormAudienceWrapper = styled.div`
  position: relative;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  padding-right: 64px;
`;
const ImgCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  justify-items: flex-start;
  margin-right: 16px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.006em;
  text-transform: uppercase;
`;
const Img = styled.img`
  display: block;
  width: 40px;
  height: 40px;
`;
const ChildrenContent = styled.div`
  flex-grow: 1;
`;

export default FilterFormAudienceContainer;
