import React from 'react';
import { Link } from 'react-router-dom';

const Forbidden = () => {
  return (
    <div
      style={{
        position: `relative`,
        top: `25vh`,
        textAlign: `center`,
        cursor: 'no-drop',
        pointerEvents: 'none'
      }}
    >
      <h1> 403 Forbidden </h1>
      <p>
        <Link className="btn btn-primary" to="#">
          You dont have permission to view this resource. Please contact an administrator.
        </Link>
      </p>
    </div>
  );
};

export default Forbidden;
