import useSWR from 'swr';

import { ILoyalty } from 'containers/Loyalty/api';
import { IDetailResponse, IPaginatedResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export const useLoyaltyProgram = (id: number) => {
  const { data } = useSWR<IDetailResponse<ILoyalty>>(
    id ? `/v4/dash/loyalty_programs/${id}` : null,
    fetcher
  );

  return { data: data?.data };
};

export const useLoyaltyProgramList = (params?: object) => {
  const { data, isLoading } = useSWR<IPaginatedResponse<ILoyalty>>(
    `/v4/dash/loyalty_programs?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, isLoading };
};
