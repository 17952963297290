import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from 'components/Layout/common';
import { makeSelectLabel, makeSelectCategory } from 'containers/App/selectors';

const CategoryDetail = ({ id, locale = 'en' }: { id: number; locale?: string }) => {
  const category = useSelector(state => makeSelectCategory()(state, id));
  const parentCategory = useSelector(state => makeSelectCategory()(state, category?.parent_id));

  if (category) {
    return (
      <Tag>
        {!!parentCategory && (
          <>{parentCategory[`title_${locale}`] ?? parentCategory[`title_en`]} &gt; </>
        )}
        {category[`title_${locale}`] ?? category[`title_en`]}
      </Tag>
    );
  }
  return null;
};

const LabelDetail = ({ id }: { id: number }) => {
  const selectLabel = useMemo(makeSelectLabel, []);
  const label = useSelector(state => selectLabel(state, id));

  if (label && label.parentLabel) {
    return (
      <Tag>
        {label.parentLabel} &gt; {label.title}
      </Tag>
    );
  } else if (label) {
    return <Tag>{label.title}</Tag>;
  }
  return null;
};

export { CategoryDetail, LabelDetail };
