import React from 'react';
import { useFormikContext, useField } from 'formik';
import { formLayout } from 'styles';

import FormItem from 'components/Form/StyledFormItem';
import { LabelSelect } from 'components/selects';

interface Props {
  usage: string;
  label?: string;
  name?: string;
  dataTestid?: string;
  useLayout?: boolean;
}

const LabelsField = ({
  usage,
  label = 'Labels',
  name = 'labels',
  useLayout = true,
  dataTestid
}: Props) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onValueChange = val => formik.setFieldValue(field.name, val);

  return (
    <FormItem
      label={label}
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...(useLayout ? formLayout : {})}
      data-testid={dataTestid}
    >
      <LabelSelect
        multiple
        usage={usage}
        placeholder="Please select your labels"
        value={field.value}
        onChange={onValueChange}
      />
    </FormItem>
  );
};

export default LabelsField;
