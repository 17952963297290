import React, { useReducer } from 'react';
import { Tabs } from 'antd';
import produce from 'immer';
import PropTypes from 'prop-types';
import { TableMainText, TableSecondaryText } from 'styles';

import { DEFAULT_PAGE_SIZE, FEATURES } from 'appConstants';
import { SearchBar, StyledTable, TableTimestamp } from 'components';
import MenuIcon from 'components/Header/MenuIcon';
import { useAxios, useFeatureFlags } from 'hooks';

interface CustomerRewardsTableState {
  state: any;
  page: any;
  sort_by: any;
  order: any;
  size: any;
  search_string: any;
  customer_id: any;
}
const paramsReducer = (state: CustomerRewardsTableState, action): CustomerRewardsTableState =>
  produce(state, draft => {
    switch (action.type) {
      case 'STATE':
        draft.state = action.value;
        break;
      case 'TABLE':
        draft.page = action.pagination;

        if (action.sorter) {
          draft.sort_by = action.sorter.columnKey;
          draft.order = action.sorter.order === 'ascend' ? 'asc' : 'desc';
        }

        if (action.size) {
          draft.size = action.size;
        }
        break;

      case 'SEARCH':
        draft.search_string = action.value;
        break;
      default:
        break;
    }
  });

const CustomerRewardsTable = ({ token, customerId }) => {
  const [params, paramsDispatch] = useReducer(paramsReducer, {
    state: 'issued',
    page: 1,
    order: null,
    sort_by: null,
    search_string: null,
    size: DEFAULT_PAGE_SIZE,
    customer_id: customerId
  });
  const [loading, response] = useAxios(`/v4/dash/customers/${customerId}/vouchers`, {
    headers: { Authorization: `Bearer ${token}` },
    params,
    //@ts-ignore
    external: true
  });

  const features = useFeatureFlags();
  const showRewardsGifting = features[FEATURES.REWARDS_GIFTING.key];

  const onTableChange = (pagination, fitlers, sorter) => {
    paramsDispatch({ type: 'TABLE', pagination: pagination.current, sorter });
  };

  const onPageSizeChange = (current, pageSize) => {
    paramsDispatch({ type: 'TABLE', pagination: current, size: pageSize });
  };

  const onTabChange = tab => paramsDispatch({ type: 'STATE', value: tab });

  return (
    <div style={{ marginTop: '1rem' }}>
      <SearchBar
        //@ts-ignore
        onSearch={value => paramsDispatch({ type: 'SEARCH', value })}
        placeholder="Find a reward voucher by name, by type or merchant"
      />
      <Tabs
        activeKey={params.state}
        onChange={onTabChange}
        items={[
          { label: `Issued (${response?.meta.issued_count})`, key: 'issued' },
          ...(showRewardsGifting
            ? [{ label: `Gifted (${response?.meta.gifted_count})`, key: 'gifted' }]
            : []),
          { label: `Redeemed (${response?.meta.redeemed_count})`, key: 'redeemed' },
          { label: `Voided (${response?.meta?.voided_count})`, key: 'void' },
          { label: `Expired (${response?.meta.expired_count})`, key: 'expired' }
        ]}
      />

      <StyledTable<any>
        rowKey={x => x.id}
        loading={loading}
        selectedRowKeys={[]}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          pageSize: params.size,
          total: response?.meta?.total_count || 0,
          onShowSizeChange: onPageSizeChange
        }}
        showHeader
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
              <>
                <TableMainText>{text}</TableMainText>
                <TableSecondaryText>ID: {record.id}</TableSecondaryText>
              </>
            )
          },
          ...(showRewardsGifting
            ? [
                {
                  title: '',
                  dataIndex: 'given_to',
                  key: 'given_to',
                  render: (_, record) => {
                    if (record.given_by && record.given_to) {
                      return <MenuIcon src="gifted" />;
                    }
                    return null;
                  }
                }
              ]
            : []),
          {
            title: 'Merchant',
            dataIndex: 'merchant_name',
            key: 'merchant_name'
          },
          {
            title: 'Valid From',
            dataIndex: 'valid_from',
            key: 'valid_from',
            // @ts-ignore
            render: (_, record) => <TableTimestamp timestamp={record.valid_from} />
          },
          {
            title: 'Valid To',
            dataIndex: 'valid_to',
            key: 'valid_to',
            // @ts-ignore
            render: (_, record) => <TableTimestamp timestamp={record.valid_to} />
          },
          {
            title: 'Voucher Code',
            dataIndex: 'voucher_code',
            key: 'voucher_code',
            render: (_, record) => record.voucher_code
          }
        ]}
        dataSource={response?.data || []}
        onChange={onTableChange}
      />
    </div>
  );
};

CustomerRewardsTable.propTypes = {
  customerId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired
};

export default CustomerRewardsTable;
