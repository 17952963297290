import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DraggableRow = ({ index, moveRow, style, className, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: 'row',
    collect: monitor => {
      const { index: dragIndex }: any = monitor.getItem() || {};
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: { index: any }) => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type: 'row',
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DraggableRow;
