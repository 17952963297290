import React, { useMemo } from 'react';
import { TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';

import { useLabelList } from 'api/labels';

interface LabelSelectProps extends TreeSelectProps<number | number[]> {
  usage: string;
}

export const LabelSelect: React.FC<LabelSelectProps> = ({
  placeholder = 'Please select a category',
  value,
  usage,
  ...props
}) => {
  const { data, isLoading, error } = useLabelList({
    usage,
    size: 10000
  });

  const treeData = useMemo(() => {
    const options = data?.map(({ id, title, children = [] }) => ({
      id,
      key: id,
      value: id,
      label: title,
      fullTitle: title,

      children: children.map(sub => ({
        id: sub.id,
        key: sub.id,
        value: sub.id,
        title: sub.title,
        fullTitle: `${title} > ${sub.title}`
      }))
    }));

    return options || [];
  }, [data]);

  if (error) {
    return null;
  }

  return (
    <TreeSelect
      multiple
      value={value}
      loading={isLoading}
      placeholder={placeholder}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      disabled={isLoading}
      treeNodeLabelProp="fullTitle"
      onChange={props.onChange}
      showArrow
      {...props}
      showSearch
      filterTreeNode={(inputValue, treeNode) =>
        treeNode.fullTitle.toLowerCase().includes(inputValue.toLowerCase())
      }
      onBlur={e => e.preventDefault()}
    />
  );
};

export default LabelSelect;
