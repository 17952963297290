import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { FormItem, InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { FItemInputNumber, FItemSelect } from 'components/formik';
import { LeaderboardSelectModal } from 'components/modals';
import { policyOptions } from 'containers/Audience/utils';
import SelectedLeaderboard from 'containers/Rule/components/SelectedLeaderboard';
import { verticalLayout } from 'utils/formItemLayouts';

const Leaderboard = () => {
  const modal = useModal(LeaderboardSelectModal);

  const formik = useFormikContext<any>();
  const fieldPath = 'filters.leaderboard_position[0].criteria';

  const selectedLeaderboardId = _get(formik.values, `${fieldPath}.eq`);

  const selectLeaderboard = () =>
    modal.show().then((campaign: any) => {
      formik.setFieldTouched(`${fieldPath}.eq`);
      formik.setFieldValue(`${fieldPath}.eq`, campaign.id);
    });

  const deleteLeaderboard = () => {
    formik.setFieldValue(`${fieldPath}.eq`, undefined);
  };

  return (
    <>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
            style={{ width: 160 }}
          />
        </InputLimitWidth>
      </FlexContainer>
      <FormItem {...verticalLayout} label="Users who hold positions in the leaderboard:">
        {selectedLeaderboardId && (
          <SelectedLeaderboard id={selectedLeaderboardId} onDelete={deleteLeaderboard} />
        )}
        <Button type="primary" ghost onClick={selectLeaderboard}>
          {selectedLeaderboardId ? 'Change' : 'Select'} leaderboard
        </Button>
      </FormItem>
      <FormItem {...verticalLayout} label="Position">
        <div style={{ display: 'flex' }}>
          <FItemInputNumber
            aria-label="Position from"
            name={`${fieldPath}.gteq`}
            min={1}
            step={1}
          />
          <Label bold style={{ margin: '0 0.5rem' }}>
            to
          </Label>
          <FItemInputNumber aria-label="Position to" name={`${fieldPath}.lteq`} min={1} step={1} />
        </div>
      </FormItem>
    </>
  );
};

export default Leaderboard;
