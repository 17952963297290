import React, { useMemo } from 'react';
import { TreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';

import { useCategoryList } from 'api/categories';

interface CategorySelectProps extends TreeSelectProps<number> {
  usage?: string;
}

export const CategorySelect: React.FC<CategorySelectProps> = ({
  placeholder = 'Please select a category',
  value,
  usage,
  ...props
}) => {
  const { data, isLoading, error } = useCategoryList({
    usage,
    size: 10000
  });

  const treeData = useMemo(() => {
    const options = data?.map(({ id, title_en, children = [] }) => ({
      id,
      key: id,
      value: id,
      label: title_en,
      fullTitle: title_en,

      children: children.map(sub => ({
        id: sub.id,
        key: sub.id,
        value: sub.id,
        title: sub.title_en,
        fullTitle: `${title_en} > ${sub.title_en}`
      }))
    }));

    return options || [];
  }, [data]);

  if (error) {
    return null;
  }

  return (
    <TreeSelect
      multiple
      value={value}
      loading={isLoading}
      placeholder={placeholder}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      disabled={isLoading}
      treeNodeLabelProp="fullTitle"
      onChange={props.onChange}
      showArrow
      {...props}
      showSearch
      filterTreeNode={(inputValue, treeNode) =>
        treeNode.fullTitle.toLowerCase().includes(inputValue.toLowerCase())
      }
      {...props}
      onBlur={e => e.preventDefault()}
    />
  );
};

export default CategorySelect;
