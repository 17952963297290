import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Ability';

export const GET_AUTHORIZATION = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUTHORIZATION`;
export const GET_AUTHORIZATION_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUTHORIZATION_SUCCESS`;
export const GET_AUTHORIZATION_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_AUTHORIZATION_FAIL`;

export const GET_TENANT_VIEW_CONFIG = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TENANT_VIEW_CONFIG`;
export const GET_TENANT_VIEW_CONFIG_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TENANT_VIEW_CONFIG_SUCCESS`;
export const GET_TENANT_VIEW_CONFIG_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TENANT_VIEW_CONFIG_FAIL`;
