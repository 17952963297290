import React, { ReactNode, memo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';

interface Action {
  title: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export interface ActionsMenuProps {
  actionsList?: Action[] | any;
  menu?: ReactNode | MenuProps;
}

const renderMenu = (actionsList: Action[]) => {
  let menuItems = [];
  if (actionsList) {
    menuItems = actionsList.map((action: any, index: number) => {
      return (
        <Menu.Item key={index}>
          <button onClick={action.onClick} style={{ color: 'blue', textDecoration: 'underline' }}>
            {action.title}
          </button>
        </Menu.Item>
      );
    });
  }
  return <Menu items={menuItems} />;
};

const ActionsMenu = (props: ActionsMenuProps) => {
  const { actionsList, menu } = props;

  return (
    <Dropdown menu={!menu ? renderMenu(actionsList) : menu} trigger={['click']}>
      <MoreOutlined style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default memo(ActionsMenu);
