import { takeLatest, all } from 'redux-saga/effects';

import { commonSaga } from 'middleware/api';

import {
  CREATE_MERCHANT,
  UPDATE_MERCHANT,
  GET_MERCHANTS,
  GET_SIMPLE_MERCHANTS,
  SEARCH_MERCHANT,
  UPLOAD_MERCHANT_LOCATION,
  GET_MERCHANT_BRANCH_LIST,
  GET_MERCHANT_BRANCH,
  POST_MERCHANT_BRANCH,
  PATCH_MERCHANT_BRANCH,
  PUT_MERCHANT_BRANCH,
  DELETE_MERCHANT_BRANCH,
  DELETE_ALL_MERCHANT_BRANCH,
  GET_MERCHANT_ACTIVATE_DEACTIVATE
} from './constants';

function* merchantSaga() {
  yield all([
    takeLatest(CREATE_MERCHANT, commonSaga),
    takeLatest(UPDATE_MERCHANT, commonSaga),
    takeLatest(GET_MERCHANTS, commonSaga),
    takeLatest(GET_SIMPLE_MERCHANTS, commonSaga),
    takeLatest(SEARCH_MERCHANT, commonSaga),
    takeLatest(UPLOAD_MERCHANT_LOCATION, commonSaga),
    takeLatest(GET_MERCHANT_BRANCH_LIST, commonSaga),
    takeLatest(GET_MERCHANT_BRANCH, commonSaga),
    takeLatest(POST_MERCHANT_BRANCH, commonSaga),
    takeLatest(PATCH_MERCHANT_BRANCH, commonSaga),
    takeLatest(PUT_MERCHANT_BRANCH, commonSaga),
    takeLatest(DELETE_MERCHANT_BRANCH, commonSaga),
    takeLatest(DELETE_ALL_MERCHANT_BRANCH, commonSaga),
    takeLatest(GET_MERCHANT_ACTIVATE_DEACTIVATE, commonSaga)
  ]);
}

export default merchantSaga;
