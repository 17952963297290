import styled from 'styled-components';

export const Container = styled.div``;
export const Icon = styled.img`
  width: 28px;
  height: 28px;
  margin-top: 14px;
  margin-bottom: 12px;
`;
export const Label = styled.label`
  width: 73px;
  height: 82px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  &:hover + ${Label} {
    background-color: #f8f8f8;
  }
  &:checked + ${Label} {
    background-color: #f3fbff;
    border-color: #2665ee;
  }
  &:focus-visible + ${Label} {
    border-color: #508bfa;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(38, 101, 238, 0.2);
  }
`;
export const LabelText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.006em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
`;
