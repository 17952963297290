import React, { memo } from 'react';
import { Card, Checkbox, Select } from 'antd';
import { useFormikContext, useField } from 'formik';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { formLayout } from 'styles';

import DateTimeField from 'components/Form/DateTimeField';
import FormItem from 'components/Form/StyledFormItem';

const BeginsAtField = ({ name }) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onValueChange = timestamp => {
    // maybe debounce it ?
    if (timestamp) {
      formik.setFieldValue(field.name, timestamp);
    } else {
      formik.setFieldValue(field.name, undefined);
    }
    formik.setFieldTouched(field.name);
  };

  return (
    <FormItem
      label="Start Date"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <DateTimeField value={field.value} onChange={onValueChange} />
    </FormItem>
  );
};

const EndsAtField = ({ name }) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onValueChange = timestamp => {
    if (timestamp) {
      formik.setFieldValue(field.name, timestamp);
    } else {
      formik.setFieldValue(field.name, undefined);
    }
    formik.setFieldTouched(field.name);
  };

  const onCheckboxToggle = evt => {
    if (evt.target.checked) {
      formik.setFieldValue(field.name, null);
    } else {
      formik.setFieldValue(field.name, moment());
    }
  };

  return (
    <FormItem
      label="End Date"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      extra={
        <Checkbox checked={!_get(formik.values, field.name)} onChange={onCheckboxToggle}>
          No end date
        </Checkbox>
      }
      {...formLayout}
    >
      <DateTimeField
        value={field.value}
        disabled={!_get(formik.values, field.name)}
        onChange={onValueChange}
      />
    </FormItem>
  );
};

const timezones = moment.tz.names();
let offsetTmz: Array<{ value: string; display: string }> = [];
for (let i in timezones) {
  offsetTmz.push({
    value: timezones[i],
    display: `(GMT${moment.tz(timezones[i]).format('Z')}) ${timezones[i]}`
  });
}
offsetTmz.sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0));
const TIMEZONES = offsetTmz;

const TimezoneField = ({ name = 'timezone' }) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  const hasError = meta.error !== undefined;
  const isValid = meta.touched && !meta.error;

  const onChange = val => formik.setFieldValue(field.name, val);
  const onBlur = () => formik.setFieldTouched(field.name);

  return (
    <FormItem
      label="Timezone"
      colon={false}
      validateStatus={meta.error && meta.touched ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError && meta.touched && <>{meta.error}</>}
      {...formLayout}
    >
      <Select value={field.value} onChange={onChange} onBlur={onBlur}>
        {TIMEZONES.map(tz => (
          <Select.Option key={tz.value} value={tz.value}>
            {tz.display}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

interface DurationFieldProps {
  cardTitle: string;
  startKey?: string;
  endKey?: string;
  noTimezone?: boolean;
}

const DurationField = ({
  cardTitle,
  startKey = 'start_date',
  endKey = 'end_date',
  noTimezone = false
}: DurationFieldProps) => {
  return (
    <Card title={cardTitle} style={{ marginBottom: '1rem' }}>
      <BeginsAtField name={startKey} />
      <EndsAtField name={endKey} />
      {!noTimezone && <TimezoneField />}
    </Card>
  );
};

export default memo(DurationField);
