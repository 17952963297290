import React from 'react';
import { Select } from 'antd';

import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import { useAxios } from 'hooks';

interface Props {
  getValue: (key: string) => any;
  setValue: (key: string, value: any) => void;
}

const EmailTags = (props: Props) => {
  const { getValue, setValue } = props;
  const [, response] = useAxios('/v4/dash/simple/personal_property_mappings/email_tags');

  return (
    <FlexContainer>
      <div>
        <InputLimitWidth>
          <Select
            value={getValue('filters.email_tags.criteria.policy')}
            onSelect={value => setValue('filters.email_tags.criteria.policy', value)}
            placeholder="Includes"
          >
            <Select.Option value="include">includes</Select.Option>
            <Select.Option value="exclude">excludes</Select.Option>
          </Select>
        </InputLimitWidth>
      </div>
      <div style={{ flex: '1 1 auto' }}>
        <Select
          value={getValue('filters.email_tags.criteria.json_values')}
          mode="multiple"
          showArrow={true}
          onChange={value => setValue('filters.email_tags.criteria.json_values', value)}
          placeholder="Select email tags"
        >
          {response?.data.map((emailTag, index) => {
            return (
              <Select.Option value={`${emailTag?.name}`} key={index}>
                {emailTag?.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </FlexContainer>
  );
};

export default EmailTags;
