let hasFile = function (body) {
  let hasFileFlag = false;
  if (Object.prototype.toString.call(body) === '[object Array]') {
    for (let i = 0, len = body.length; i < len; i++) {
      hasFileFlag = hasFileFlag || hasFile(body[i]);
      if (hasFileFlag) {
        return true;
      }
    }
  } else if (typeof body === 'object' && Object.prototype.toString.call(body) === '[object File]') {
    return true;
  } else if (typeof body === 'object') {
    for (let k in body) {
      hasFileFlag = hasFileFlag || hasFile(body[k]);
      if (hasFileFlag) {
        return true;
      }
    }
  }
  return hasFileFlag;
};

let stripFile = function (body) {
  if (body === null || body === undefined) {
    return null;
  }
  if (Object.prototype.toString.call(body) === '[object Array]') {
    let arr = [];
    for (let i = 0, len = body.length; i < len; i++) {
      if (
        !(
          typeof body[i] === 'object' && Object.prototype.toString.call(body[i]) === '[object File]'
        )
      ) {
        arr.push(stripFile(body[i]));
      }
    }
    return arr;
  } else if (typeof body === 'object' && Object.prototype.toString.call(body) === '[object File]') {
    return null;
  } else if (typeof body === 'object') {
    // leave moment objects alone
    if (body?._isAMomentObject) {
      return body;
    }

    let hash = {};
    for (let k in body) {
      if (
        !(
          typeof body[k] === 'object' && Object.prototype.toString.call(body[k]) === '[object File]'
        )
      ) {
        hash[k] = stripFile(body[k]);
      }
    }
    return hash;
  } else {
    return body;
  }
};

export const checkPayloadForFile = (body, headers) => {
  if (hasFile(body)) {
    body['_payload'] = JSON.stringify(stripFile(body));
    let formData = prepareFormPayload(body, { fileOnly: true });
    body = formData;
    if (headers) {
      headers['Content-Type'] = 'multipart/form-data';
    }
  }
  return body;
};

export const prepareFormPayload = function (body, options) {
  let fileOnly = options && options.fileOnly ? options.fileOnly : false;
  let formData = options && options.formData ? options.formData : new FormData();
  let prefix = options && options.prefix ? options.prefix : '';

  if ((prefix.match(/^_/) || prefix.match(/\[_/)) && prefix !== '_payload') {
    return formData;
  }

  if (Object.prototype.toString.call(body) === '[object Array]') {
    if (body.length === 0) {
      if (!fileOnly) {
        formData.append(prefix, '');
      }
    } else {
      for (let i = 0, len = body.length; i < len; i++) {
        let newKey = (prefix + '[]').replace(/^\[([^\]]+)\]/, '$1');
        if (
          typeof body[i] === 'object' &&
          !(Object.prototype.toString.call(body[i]) === '[object File]') &&
          !(Object.prototype.toString.call(body[i]) === '[object Array]')
        ) {
          if (!fileOnly) {
            formData.append(newKey + '[_newrecord]', '');
          }
        }
        prepareFormPayload(body[i], {
          formData: formData,
          prefix: newKey,
          fileOnly: fileOnly
        });
      }
    }
  } else if (typeof body === 'object' && Object.prototype.toString.call(body) === '[object File]') {
    formData.append(prefix, body);
  } else if (typeof body === 'object') {
    for (let k in body) {
      let newKey = (prefix + '[' + k + ']').replace(/^\[([^\]]+)\]/, '$1');
      prepareFormPayload(body[k], {
        formData: formData,
        prefix: newKey,
        fileOnly: fileOnly
      });
    }
  } else {
    if (!fileOnly || prefix === '_payload') {
      formData.append(prefix, body);
    }
  }

  return formData;
};

export const toFormData = async (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        // toFormData(obj[property], fd, formKey);
        // } else if (typeof obj[property] === 'object' && obj[property] instanceof File) {
        //   // a File object
        //   const base64 = await getBase64(obj[property]);
        //   fd.append(formKey, base64);
      } else {
        // if it's a string or a file
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const getBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
