import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { StepsLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';

import BDOTransferRequest from './BDOTransferRequest';
import BDOTransferRequestsList from './BDOTransferRequestList';

const TransferRequests = () => {
  const match = useRouteMatch();

  return (
    <Suspense fallback={<StepsLoading />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/list`} component={BDOTransferRequestsList} />
        <Route exact path={`${match.url}/show/:id`} component={BDOTransferRequest} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default TransferRequests;
