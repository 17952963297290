import React, { Fragment } from 'react';
import { InputNumber, Select } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import moment from 'moment';

import { ICustomMappingItem } from 'api/mappings';
import { DatePicker, InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';

interface Props {
  fieldMapping: ICustomMappingItem;
}

const CustomFilterField = ({ fieldMapping }: Props) => {
  const { values, setFieldValue } = useFormikContext();
  const c = fieldMapping;

  let control;

  if (c.type === 'number' && c.selectors === 'range') {
    control = (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 1 50%' }}>
          <InputNumber
            style={{ width: '100%' }}
            name={`filters.${c.key}[0].criteria[0].gteq`}
            value={_get(values, `filters.${c.key}[0].criteria[0].gteq`)}
            onChange={value => setFieldValue(`filters.${c.key}[0].criteria[0].gteq`, value)}
          />
        </div>
        <div>&nbsp;–&nbsp;</div>
        <div style={{ flex: '0 1 50%' }}>
          <InputNumber
            style={{ width: '100%' }}
            name={`filters.${c.key}[0].criteria[0].lteq`}
            value={_get(values, `filters.${c.key}[0].criteria[0].lteq`)}
            onChange={value => setFieldValue(`filters.${c.key}[0].criteria[0].lteq`, value)}
          />
        </div>
      </div>
    );
  } else if (c.type === 'date' && c.selectors === 'range') {
    control = (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 1 50%' }}>
          <DatePicker
            value={
              _get(values, `filters.${c.key}[0].criteria[0].gteq`)
                ? moment(_get(values, `filters.${c.key}[0].criteria[0].gteq`))
                : undefined
            }
            onChange={(d, dateString) =>
              setFieldValue(`filters.${c.key}[0].criteria[0].gteq`, dateString)
            }
            style={{ width: '100%' }}
          />
        </div>
        <div>&nbsp;–&nbsp;</div>
        <div style={{ flex: '0 1 50%' }}>
          <DatePicker
            value={
              _get(values, `filters.${c.key}[0].criteria[0].lteq`)
                ? moment(_get(values, `filters.${c.key}[0].criteria[0].lteq`))
                : undefined
            }
            onChange={(d, dateString) =>
              setFieldValue(`filters.${c.key}[0].criteria[0].lteq`, dateString)
            }
            style={{ width: '100%' }}
          />
        </div>
      </div>
    );
  } else {
    control = (
      <Select
        value={_get(values, `filters.${c.key}[0].criteria[0].values`)}
        mode="multiple"
        showArrow={true}
        allowClear
        onChange={val => {
          setFieldValue(`filters.${c.key}[0].criteria[0].values`, val);
        }}
        filterOption={(inputValue, option) =>
          (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
        data-testid={`${c.key}__select`}
      >
        {c?.mappings
          .filter(x => x.name !== null && x.id !== null)
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
          })
          .map(mapping => (
            <Select.Option key={mapping.id} value={mapping.id}>
              {mapping.name}
            </Select.Option>
          ))}
      </Select>
    );
  }

  return (
    <Fragment key={c.key}>
      <div className="spaced">
        <Label dark bold>
          Select {c.label}:
        </Label>
      </div>

      <FlexContainer>
        <div>
          <InputLimitWidth>
            <Select
              value={_get(values, `filters.${c.key}[0].criteria[0].policy`)}
              onSelect={value => {
                setFieldValue(`filters.${c.key}[0].criteria[0].policy`, value);
              }}
              placeholder="includes"
            >
              <Select.Option value="include">includes</Select.Option>
              <Select.Option value="exclude">excludes</Select.Option>
            </Select>
          </InputLimitWidth>
        </div>
        <div style={{ flex: '1 1 auto' }}>{control}</div>
      </FlexContainer>
    </Fragment>
  );
};

export default CustomFilterField;
