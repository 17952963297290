export const OUTCOME_TYPE = {
  BADGE: 'badge',
  /** 'reward' type is going to be deprecated in favor of Reward::Campaign */
  REWARD: 'reward',
  /** 'points' type is going to be deprecated in favor of StoredValue::Campaign */
  POINT: 'points',
  PRIZE_SET: 'prize_set',
  ADD_PRIZE_SET: 'add_prize_set',
  PRIZE_SET_REWARD: 'prize_set_reward',
  PRIZE_SET_POINT: 'prize_set_points',
  CUSTOM: 'custom',
  REWARD_CAMPAIGN: 'Reward::Campaign',
  STORED_VALUE_CAMPAIGN: 'StoredValue::Campaign'
} as const;

export const OUTCOME_TITLE = {
  [OUTCOME_TYPE.REWARD]: 'Reward',
  [OUTCOME_TYPE.PRIZE_SET]: 'Prize set',
  [OUTCOME_TYPE.POINT]: 'Points',
  [OUTCOME_TYPE.BADGE]: 'Badge'
};
