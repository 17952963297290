import styled from 'styled-components';

import { colors } from 'assets/styles/commonStyle';

const StyledLink = styled.a`
  color: ${colors.base};
  transition: opacity 0.3s ease;

  &:hover {
    color: ${colors.base};
    text-decoration: underline;
    opacity: 0.8;
  }
`;

export default StyledLink;
