import * as Sentry from '@sentry/react';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';

import { getAbilityFailed, getAbilitySuccess, getViewConfig } from './actions';
import { GET_AUTHORIZATION, GET_TENANT_VIEW_CONFIG } from './constants';

const fetchAbility = (url, params, headers) => {
  return api({
    url,
    headers,
    params
  });
};

function* getAbility(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser?.bearer_token}`
  };

  try {
    const response = yield call(
      fetchAbility,
      `${API_ROOT}/v4/dash/authorizations`,
      params,
      headers
    );

    yield put(getAbilitySuccess(response.data));
    if (process.env.NODE_ENV === 'production') {
      Sentry.configureScope(scope => {
        const email = localStorage.getItem('email');
        if (email) {
          scope.setUser({ email: email });
        }
      });
    }
    yield put(getViewConfig());
  } catch (e) {
    yield put(getAbilityFailed(e));
  }
}

function* abilitySaga() {
  yield all([
    takeLatest(GET_AUTHORIZATION, getAbility),
    takeLatest(GET_TENANT_VIEW_CONFIG, commonSaga)
  ]);
}
export default abilitySaga;
