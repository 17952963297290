import merge from 'lodash/merge';

import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  GET_LOYALTY_LIST,
  GET_LOYALTY_LIST_FAIL,
  GET_LOYALTY_LIST_SUCCESS,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_LOYALTY,
  GET_LOYALTY_SUCCESS,
  GET_LOYALTY_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_FAIL,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_FAIL,
  SEARCH_CAMPAIGN,
  SEARCH_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN_FAIL,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  ORDER_CAMPAIGN,
  ORDER_CAMPAIGN_FAIL,
  ORDER_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN_SUCCESS,
  ACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN
} from './constants';

const initialState = {
  loading: false,
  catalogues: [],
  catalogue: {},
  rewards: {
    data: [],
    meta: {}
  },
  campaign: {},
  tags: [],
  brands: [],
  campaigns: [],
  audiences: [],
  audience: {}
};

function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TAGS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        tags: merge([], state.tags, action.payload.data)
      };
    }
    case GET_TAGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case CREATE_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case CREATE_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case EDIT_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case EDIT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case EDIT_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case DEACTIVATE_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case DEACTIVATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaign: action.payload.data
      };
    }
    case DEACTIVATE_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case ACTIVATE_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIVATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaign: action.payload.data
      };
    }
    case ACTIVATE_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_LOYALTY_LIST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_LOYALTY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loyalty_list: action.payload.data
      };
    }
    case GET_LOYALTY_LIST_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_REWARDS: {
      return {
        ...state
        // loading: true,
      };
    }
    case GET_REWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }
    case GET_REWARDS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_CAMPAIGNS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaigns: action.payload.data
      };
    }
    case GET_CAMPAIGNS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_LOYALTY: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_LOYALTY_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaign: action.payload.data
      };
    }
    case GET_LOYALTY_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case ORDER_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case ORDER_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ORDER_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_AUDIENCES: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_AUDIENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        audiences: action.payload.data
      };
    }
    case GET_AUDIENCES_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case GET_AUDIENCE_INFO: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_AUDIENCE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        audience: action.payload.data
      };
    }
    case GET_AUDIENCE_INFO_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_CAMPAIGN: {
      return {
        ...state,
        loading: true
      };
    }
    case SEARCH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        campaigns: action.payload.data
      };
    }
    case SEARCH_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case SEARCH_REWARD: {
      return {
        ...state,
        loading: true
      };
    }
    case SEARCH_REWARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        rewards: {
          data: action.payload.data,
          meta: action.payload.meta
        }
      };
    }

    case SEARCH_REWARD_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export default campaignReducer;
