import { IBrand } from 'api/brands';
import API from 'utils/request';
import { IPaginatedResponse } from 'utils/types';

export interface IBrandsResponse extends IPaginatedResponse<IBrand> {}

export interface IBrandResponse {
  data: IBrand;
}

async function getBrands(params: { [k: string]: number | string }): Promise<IBrandsResponse> {
  const res = await API.get<IBrandsResponse>('/v4/dash/brands', { params });
  return res.data;
}

async function postBrand(payload: { name: string }): Promise<IBrandResponse> {
  const res = await API.post<IBrandResponse>('/v4/dash/brands', payload);
  return res.data;
}

async function putBrand(id: string | number, payload: IBrand): Promise<IBrandResponse> {
  const res = await API.put(`/v4/dash/brands/${id}`, payload);
  return res.data;
}

function deleteBrand(id: string | number): Promise<void> {
  return API.delete(`/v4/dash/brands/${id}`);
}

export { getBrands, postBrand, putBrand, deleteBrand };
