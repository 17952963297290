import { createSelector } from 'reselect';

const selectAuthorization = state => state.ability;

const makeSelectAuthorization = createSelector(
  selectAuthorization,
  globalState => globalState.sections
);

export const makeSelectViewConfig = () => {
  return createSelector([selectAuthorization], globalState => globalState.viewConfig);
};

export { selectAuthorization, makeSelectAuthorization };
