import React from 'react';
import _startCase from 'lodash/startCase';

import { GridRenderer } from 'components/Display/GridRenderer';
import {
  Stamps as StampsIcon,
  Games as GamesIcon,
  Referral as ReferralIcon,
  Quiz as QuizIcon,
  Survey as SurveyIcon,
  Rewards as RewardIcon
} from 'containers/Campaign/components/CampaignSelector/Icons';

import { FirstCell } from '../Loyalties';
import { OverviewWrapper as CampaignWrapper, SectionTitle } from '../Overview';
import { TableGraph } from '../graphs';
import ComboGraph from '../graphs/ComboGraph/ComboGraph';
import AreaGraphSection from '../graphs/ComboGraph/components/AreaGraphSection';
import ValuesSection from '../graphs/ComboGraph/components/ValuesSection';

export const campaignOverviewTypes = [
  {
    title: 'Stamps performance',
    ctaText: 'View stamp campaigns',
    pathname: '/p/business_intelligence/campaigns/stamps',
    icon: <StampsIcon width="25" height="25" />,
    valueIdentifier: 'campaign_overview_flat_stamp_kpi',
    graphIdentifier: 'campaign_overview_flat_stamp_breakdown'
  },
  {
    title: 'Games performance',
    ctaText: 'View game campaigns',
    pathname: '/p/business_intelligence/campaigns/games',
    icon: <GamesIcon width="25" height="25" />,
    valueIdentifier: 'campaign_overview_flat_game_kpi',
    graphIdentifier: 'campaign_overview_flat_game_breakdown'
  },
  {
    title: 'Referrals performance',
    ctaText: 'View referrals campaigns',
    pathname: '/p/business_intelligence/campaigns/referrals',
    icon: <ReferralIcon width="25" height="25" />,
    valueIdentifier: '',
    graphIdentifier: ''
  },
  {
    title: 'Quizzes performance',
    ctaText: 'View quiz campaigns',
    pathname: '/p/business_intelligence/campaigns/quizzes',
    icon: <QuizIcon width="25" height="25" />,
    valueIdentifier: '',
    graphIdentifier: ''
  },
  {
    title: 'Surveys performance',
    ctaText: 'View survey campaigns',
    pathname: '/p/business_intelligence/campaigns/surveys',
    icon: <SurveyIcon width="25" height="25" />,
    valueIdentifier: '',
    graphIdentifier: ''
  },
  {
    title: 'Reward promos performance',
    ctaText: 'View reward promo campaigns',
    pathname: '/p/business_intelligence/campaigns/rewardpromo',
    icon: <RewardIcon width="25" height="25" />,
    valueIdentifier: '',
    graphIdentifier: ''
  }
];

const LandingPage = ({ history }) => {
  return (
    <CampaignWrapper>
      <section>
        <SectionTitle>Performance by campaign type</SectionTitle>
        <GridRenderer perRow={3} gutter={16} breaks>
          {campaignOverviewTypes.map(campaign => (
            <ComboGraph
              sections={[
                <ValuesSection
                  identifier={campaign.valueIdentifier}
                  showChange
                  demoValues={[
                    {
                      name: 'Active campaigns',
                      value: [2, 5],
                      previous: [1, 4]
                    },
                    {
                      name: 'Unique views',
                      value: [30, 50],
                      previous: [-5, 10]
                    }
                  ]}
                />,
                <AreaGraphSection
                  identifier={campaign.graphIdentifier}
                  withFill={false}
                  demoDataRange={[100, 500]}
                  demoLines={['views', 'engagements', 'completions']}
                />
              ]}
              title={campaign.title}
              icon={campaign.icon}
              key={campaign.title}
              ctaText={campaign.ctaText}
              ctaAction={() => {
                history.push(campaign.pathname);
              }}
              withDatePart
            />
          ))}
        </GridRenderer>
      </section>
      <section style={{ marginTop: '1rem', height: '430px' }}>
        <SectionTitle>List of active campaigns</SectionTitle>
        <GridRenderer perRow={1} gutter={16}>
          <TableGraph
            identifier="campaign_overview_flat_table"
            title="Campaign Details"
            itemsPerRow={1}
            n={null}
            tableProps={{
              rowKey: (r: any) => String(r[0]),
              scroll: { y: 320 },
              columns: [
                {
                  title: <FirstCell>No.</FirstCell>,
                  key: 'campaign_index',
                  render: rowText => <FirstCell>{String(rowText?.[0])}</FirstCell>,
                  width: 100
                },
                {
                  title: 'Campaign Name',
                  key: 'campaign_name',
                  render: rowText => String(rowText?.[1]) || 0,
                  width: '100%'
                },
                {
                  title: 'Campaign Type',
                  key: 'campaign_type',
                  render: rowText => _startCase(String(rowText?.[2])) || 0,
                  width: 150
                },
                {
                  title: 'Unique Views',
                  key: 'unique_views',
                  render: rowText => String(rowText?.[3]) || 0,
                  width: 150
                },
                {
                  title: 'Engagement',
                  key: 'engagement',
                  render: rowText => String(rowText?.[4]) || 0,
                  width: 150
                }
              ]
            }}
          />
        </GridRenderer>
      </section>
    </CampaignWrapper>
  );
};

export default LandingPage;
