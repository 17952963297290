import axios, { AxiosResponse } from 'axios';

import { fixDateWithTimezoneParam } from 'components/MagicTimezonePicker';
import { API_ROOT } from 'containers/App/constants';
import api from 'utils/api';
import { checkPayloadForFile } from 'utils/prepareFormData';
import API from 'utils/request';

export interface ILoyalty {
  id: number;
  state: string;
  name: string;
  customer: string;
  enrolled_customers: number;
  issued_date: null | string;
  updated_at: string;
  disabled?: boolean;
}

export interface ILoyaltiesResponse {
  data: ILoyalty[];
  meta: {
    count: number;
    size: number;
    total_pages: number;
    page: number;
  };
}

export interface ICondition {
  operator: string;
  selected_values;
  attribute: string;
}
export interface ITransactionRulesResponse {
  data: {
    name: string;
    state: string;
    conditions: ICondition[];
    begins_at: string;
    id: number;
    ends_at: string;
  }[];
  meta: {
    count: number;
  };
}
//TODO: rename below function to LoyaltyProgram
export const getCampaign = (id, headers) => {
  return api({
    url: `${API_ROOT}/v4/dash/loyalty_programs/${id}`,
    headers: headers
  });
};

//TODO: merge the below 3 functions and rename Campaign to LoyaltyProgram
export const getCampaigns = (params, headers) => {
  return api<ILoyaltiesResponse>({
    url: `${API_ROOT}/v4/dash/loyalty_programs/`,
    headers: headers,
    params: params
  });
};

export const searchCampaigns = (params, headers): Promise<AxiosResponse<ILoyaltiesResponse>> => {
  return api<ILoyaltiesResponse>({
    url: `${API_ROOT}/v4/dash/loyalty_programs/`,
    headers: headers,
    params: params
  });
};

export const fetchLoyaltyPrograms = async (): Promise<ILoyalty[]> => {
  const programs = await API.get<ILoyaltiesResponse>('/v4/dash/loyalty_programs', {
    // the "size" query param is added to cover the maximum number of loyalty items.
    // if the total number of loyalty items exceeds 500, some of the latest items
    // might miss from the backend. TODO: consult with BE team regarding this.
    params: { size: 500 }
  });
  // we do not care about meta stuffs from loyalty list since we are gonna manipulate just
  // one loyalty program object anyway.
  return programs.data.data;
};

export const getLoyaltyList = headers => {
  return api({
    url: `${API_ROOT}/v4/dash/simple/loyalty`,
    headers: headers
  });
};

//TODO rename below functions from campaign to LoyaltyProgram
export const orderCampaign = (body, headers) => {
  return axios
    .patch(`${API_ROOT}/v4/dash/loyalty_programs/ordering`, body, { headers })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const deactivateCampaign = (id, body, headers) => {
  return axios
    .patch(`${API_ROOT}/v4/dash/loyalty_programs/${id}/deactivate`, body, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const activateCampaign = (id, body, headers) => {
  return axios
    .patch(`${API_ROOT}/v4/dash/loyalty_programs/${id}/activate`, body, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const createCampaign = (body, headers) => {
  // fixDateWithTimezoneParam(body, "start_date", "start_time");
  // fixDateWithTimezoneParam(body, "end_date", "end_time");
  if (!body['include_audience_file']) {
    delete body['include_audience_file'];
  }
  if (!body['exclude_audience_file']) {
    delete body['exclude_audience_file'];
  }

  body = checkPayloadForFile(body, headers);
  return axios
    .post(`${API_ROOT}/v4/dash/loyalty_programs`, body, { headers })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const updateCampaign = (id, body, headers) => {
  fixDateWithTimezoneParam(body, 'start_date', 'start_time');
  fixDateWithTimezoneParam(body, 'end_date', 'end_time');
  if (!body['include_audience_file']) {
    delete body['include_audience_file'];
  }
  if (!body['exclude_audience_file']) {
    delete body['exclude_audience_file'];
  }

  body = checkPayloadForFile(body, headers);
  return axios
    .put(`${API_ROOT}/v4/dash/loyalty_programs/${id}`, body, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

//TODO: move to tags api
export const getTags = headers => {
  return api({ url: `${API_ROOT}/v4/dash/simple/tags?size=1000`, headers: headers });
};

//TOD merge the below 2 functions and move it to rewards api
export const searchReward = (params, headers) => {
  return api({
    url: `${API_ROOT}/v4/dash/rewards`,
    headers: headers,
    params: params
  });
};

export const getRewards = (params, headers) => {
  return api({
    url: `${API_ROOT}/v4/dash/rewards`,
    params: params,
    headers: headers
  });
};

//TODO: merge the below 2 functions and move to audiences api
export const getAudienceList = headers => {
  return api({
    url: `${API_ROOT}/v4/dash/simple/audiences`,
    headers: headers
  });
};

export const getAudienceInfo = (headers, params?) => {
  return api({
    url: `${API_ROOT}/v4/dash/simple/audiences`,
    headers: headers,
    params: params
  });
};
