import React from 'react';
import { Divider, Select } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { useCityList, useStateList } from 'api/mappings';
import { TENANTS } from 'appConstants';
import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { useTenant } from 'hooks';

import CustomFilterField from './CustomFilterField';

interface LocationProps {
  customFiltersData: any[];
}

const Location = ({ customFiltersData }: LocationProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const tenantConfig = useTenant();

  const { data: cities = [] } = useCityList();
  const { data: states = [] } = useStateList();

  if (
    tenantConfig.name === TENANTS.TRANSCYCLE ||
    tenantConfig.name === TENANTS.TRANSCYCLE_PREPROD
  ) {
    return (
      <>
        {customFiltersData?.map(c => (
          <CustomFilterField key={c.key} fieldMapping={c} />
        ))}
      </>
    );
  }

  return (
    <>
      <div className="spaced">
        <Label dark bold>
          Select City:
        </Label>
      </div>
      <FlexContainer>
        <div>
          <InputLimitWidth>
            <Select
              value={_get(values, 'filters.city[0].criteria[0].policy')}
              onSelect={value => {
                setFieldValue('filters.city[0].criteria[0].policy', value);
              }}
              placeholder="includes"
              data-testid="cities-include-select"
            >
              <Select.Option value="include" data-testid="cities-include-select-include">
                includes
              </Select.Option>
              <Select.Option value="exclude" data-testid="cities-include-select-exclude">
                excludes
              </Select.Option>
            </Select>
          </InputLimitWidth>
        </div>
        <div style={{ flex: '1 1 auto' }}>
          <Select
            value={_get(values, 'filters.city[0].criteria[0].values')}
            mode="multiple"
            showArrow={true}
            allowClear
            placeholder={'Select cities'}
            onChange={values => {
              setFieldValue('filters.city[0].criteria[0].values', values);
            }}
            filterOption={(inputValue, option) =>
              (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
          >
            {cities.map(city => (
              <Select.Option key={city.id} value={city.id}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </FlexContainer>
      <Divider />
      <div className="spaced">
        <Label dark bold>
          Select State:
        </Label>
      </div>
      <FlexContainer>
        <div>
          <InputLimitWidth>
            <Select
              value={_get(values, 'filters.states[0].criteria[0].policy')}
              onSelect={value => {
                setFieldValue('filters.states[0].criteria[0].policy', value);
              }}
              placeholder="includes"
              data-testid="states-include-select"
            >
              <Select.Option value="include" data-testid="states-include-select-include">
                includes
              </Select.Option>
              <Select.Option value="exclude" data-testid="states-include-select-exclude">
                excludes
              </Select.Option>
            </Select>
          </InputLimitWidth>
        </div>
        <div style={{ flex: '1 1 auto' }}>
          <Select
            value={_get(values, 'filters.states[0].criteria[0].values')}
            mode="multiple"
            showArrow={true}
            placeholder={'Select state'}
            allowClear
            onChange={value => {
              setFieldValue('filters.states[0].criteria[0].values', value);
            }}
            filterOption={(inputValue, option) =>
              (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
          >
            {states.map(state => (
              <Select.Option key={state.id} value={state.id}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </FlexContainer>
    </>
  );
};

export default Location;
