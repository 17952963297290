import { useAuthUser, useTenant } from 'hooks';

export const useBDOLoyaltyEnabled = () => {
  const tenant = useTenant();

  const isBDOClient = ['bdo', 'bdo_rewards_uat', 'bdo_preprod'].includes(tenant?.name);

  return isBDOClient;
};

export const useBDOPermissions = () => {
  const authUser = useAuthUser();
  const roles = authUser?.roles || [];

  const approverRoles = [
    'Checker',
    'Approver',
    'Bulk action - Checker',
    'Super Admin',
    'tenant admin',
    'Tenant Admin'
  ];
  const makerRoles = [
    'Maker 1',
    'Maker 2',
    'Maker, Bulk action - Maker',
    'Super Admin',
    'tenant admin',
    'Tenant Admin'
  ];
  const cardActionRole = [
    'Bulk action - Maker',
    'Bulk action - Checker',
    'Maker 1',
    'Maker 2',
    'Approver',
    'Super Admin',
    'tenant admin',
    'Tenant Admin'
  ];

  const canApproveRequest = roles.some(role => approverRoles.includes(role.name));
  const canCreateRequest = roles.some(role => makerRoles.includes(role.name));
  const canPerformCardActions = roles.some(role => cardActionRole.includes(role.name));

  return { canApproveRequest, canCreateRequest, canPerformCardActions };
};
