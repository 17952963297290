import _isArray from 'lodash/isArray';
import _isNil from 'lodash/isNil';

export const doesFilterHaveValue = (item: any, key: string) => {
  if (key === 'custom_attributes') {
    return item?.[0]?.criteria?.length > 0;
  }

  const criteria =
    item?.[0]?.criteria?.[0] || item?.[0]?.criteria || item?.criteria?.[0] || item?.criteria;

  const criteriaHasValue =
    (_isArray(criteria) && criteria.length > 0) ||
    criteria?.values?.length > 0 ||
    criteria?.values === true ||
    criteria?.data?.length > 0 ||
    !(_isNil(criteria?.eq) || (_isArray(criteria?.eq) && criteria?.eq?.length === 0)) ||
    !_isNil(criteria?.value) ||
    !_isNil(criteria?.loyalty_tier_ids) ||
    !_isNil(criteria?.json_values) ||
    (!_isNil(criteria?.gteq) && !_isNil(criteria?.lteq));

  return criteriaHasValue;
};
