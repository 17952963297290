import React, { Suspense } from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { usePermissions } from 'hooks';
import { StepsLoading } from 'pages/public/Loading';
import NotFoundPage from 'pages/public/NotFoundPage';

import AudienceCreatePage from './pages/AudienceCreatePage';
import AudienceEditPage from './pages/AudienceEditPage';
import AudienceListPage from './pages/AudienceListPage';
import AudienceShowPage from './pages/AudienceShowPage';

const Audiences = () => {
  const match = useRouteMatch();
  const { canEdit, canView } = usePermissions('audiences');
  return (
    <Suspense fallback={<StepsLoading />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
        {canView && <Route path={`${match.url}/list`} component={AudienceListPage} />}
        {canEdit && <Route path={`${match.url}/create`} component={AudienceCreatePage} />}
        {canView && <Route exact path={`${match.url}/show/:id`} component={AudienceShowPage} />}
        {canEdit && <Route exact path={`${match.url}/edit/:id`} component={AudienceEditPage} />}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default Audiences;
