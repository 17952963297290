import React, { memo, useState } from 'react';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message } from 'antd';
import styled from 'styled-components';

import { postMedia } from 'containers/Setting/General/Media/api';
import MediaModal from 'containers/Setting/General/Media/mediaModal';
import { useAxios } from 'hooks';

interface ImageCardProps {
  url: string;
  onDelete: () => void;
  onChooseSection: () => void;
  file: string;
  filename: string;
  section: string;
}

const ImageCard = ({ url, file, filename, section, onDelete, onChooseSection }: ImageCardProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [, imageRatios, , refetch] = useAxios('/v4/dash/simple/custom_image_ratios', {
    params: { size: 1000, page: 1 }
  });

  const onSave = reqData => {
    postMedia(reqData)
      .then(() => {
        message.success('Successfully created media.');
        refetch();
        setModalOpen(false);
      })
      .catch(() => message.error('Error creating new media.'));
  };

  return (
    <>
      <Wrapper>
        <img
          src={url || file}
          alt={filename}
          title={filename}
          style={{ maxHeight: 70, display: 'inline-block', maxWidth: 200 }}
        />
        <div>
          <span style={{ fontWeight: 'bold' }}>Set as: </span>
          <Dropdown
            overlay={
              <Menu selectedKeys={[section]} onClick={onChooseSection}>
                {imageRatios?.data.map(image => {
                  return <Menu.Item key={image.image_section}>{image.ratio_description}</Menu.Item>;
                })}
                <Menu.Item onClick={() => setModalOpen(true)}>Add image section</Menu.Item>
              </Menu>
            }
          >
            <span className="ant-dropdown-link">
              {imageRatios?.data.find(x => x.image_section === section)?.ratio_description}
              <DownOutlined />
            </span>
          </Dropdown>
        </div>
        <DeleteOutlined onClick={onDelete} style={{ cursor: 'pointer' }} />
      </Wrapper>
      <MediaModal modalOpen={modalOpen} onSave={onSave} closeModal={() => setModalOpen(false)} />
    </>
  );
};

const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 70px;

  > div {
    padding: 0 10px;
  }

  & > i {
    width: 45px;
    height: 45px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
  }
`;

export default memo(ImageCard);
