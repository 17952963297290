import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background: #ffffff;
  border: solid 1px #e9e9e9;
  width: 100%;
  left: 0;
  z-index: 10;
  padding: 1rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface BottomBarProps {
  children: ReactNode;
}

export const BottomBar = ({ children }: BottomBarProps) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
);

export default BottomBar;
