import React from 'react';
import { Empty } from 'antd';
import { Col, Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Label, Tooltip } from 'recharts';

import { colorGenerator } from 'containers/Analytics/utils';

const DonutChart = ({
  data,
  dataKey = 'value',
  label,
  totalCount = 0,
  legend,
  chartWidth = 15,
  legendWidth = 9,
  customTooltip = <CustomTooltip />
}) => {
  const color = colorGenerator();

  let chart;
  if (!_isEmpty(data)) {
    chart = (
      <Row align="middle" style={{ height: '100%', width: '100%' }}>
        <Col style={{ height: '300px' }} span={chartWidth}>
          <ResponsiveContainer>
            <PieChart>
              <Pie dataKey={dataKey} data={data} innerRadius={'64%'} paddingAngle={3}>
                <Label
                  width={30}
                  position="center"
                  content={<CustomLabel count={totalCount} label={label} />}
                />
                {data.map((entry, index: number) => (
                  <Cell key={`cell-${index}`} fill={color.next().value} />
                ))}
              </Pie>
              <Tooltip content={customTooltip} />
            </PieChart>
          </ResponsiveContainer>
        </Col>
        <Col
          span={legendWidth}
          style={{
            overflow: 'auto',
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {legend}
        </Col>
      </Row>
    );
  } else {
    chart = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  return chart;
};

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  colors: PropTypes.object,
  totalCount: PropTypes.number,
  colorList: PropTypes.array,
  chartWidth: PropTypes.number,
  legendWidth: PropTypes.number,
  customTooltip: PropTypes.element
};

export default DonutChart;

const CustomTooltip = props => {
  if (props.active && props.payload && props.payload.length > 0) {
    const bgColor = props.payload[0].payload.fill;
    return (
      <div
        style={{
          background: bgColor,
          color: '#ffffff',
          padding: '0.5rem',
          borderRadius: 2,
          boxShadow:
            ' 0 13.2px 10.2px rgba(0, 0, 0, 0.012), 0 44.2px 34.4px rgba(0, 0, 0, 0.029), 0 198px 154px rgba(0, 0, 0, 0.07)'
        }}
      >
        <div>{props.payload[0].value.toLocaleString()}</div>
        <div style={{ color: '#e4e4e4', fontSize: 11 }}>{_startCase(props.payload[0].name)}</div>
      </div>
    );
  }
  return null;
};

const CustomLabel = ({ count, label, ...props }) => {
  const { cx, cy } = props.viewBox;
  return (
    <text x={cx} y={cy - 15} fill="#3d405c" textAnchor="middle" dominantBaseline="central">
      <tspan y={cy - 8} alignmentBaseline="middle" fontSize="10" fill="#9a9b9a">
        {label}
      </tspan>
      <tspan fontSize="18" fontWeight="bold" x={cx} y={cy + 10}>
        {count.toLocaleString()}
      </tspan>
    </text>
  );
};
