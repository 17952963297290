import { Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

export default styled(Header)`
  &.ant-layout-header {
    width: 100%;
    z-index: 100;
    background-color: #fff;
    box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 0 15px 0 0;
    .trigger {
      width: 80px;
      height: 60px;
      font-size: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .logo {
      display: block;
      margin-left: 5px;

      img {
        display: inherit;
      }
    }
  }
`;

export const UserWrapper = styled.div`
  margin: 0px -17px 0px -16px;

  .text {
    padding: 8px 25px;
    margin-bottom: 0;
  }

  span {
    cursor: pointer;
    display: block;
    padding: 8px 15px;
    :hover {
      background: #eee;
    }
  }
`;
