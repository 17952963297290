import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import abilityReducer from 'containers/Ability/reducer';
import { SIGNOUT_USER_SUCCESS } from 'containers/App/constants';
import globalReducer from 'containers/App/reducer';
import bulkActionReducer from 'containers/BulkAction/reducer';
// TODO: inject reducer through containers
import campaignReducer from 'containers/Campaign/reducer';
import catalogueReducer from 'containers/Catalogue/reducer';
import categoryReducer from 'containers/Category/reducer';
import customerReducer from 'containers/Customer/reducer';
import downloadLogReducer from 'containers/DownloadLogModal/reducer';
import loyaltyReducer from 'containers/Loyalty/reducer';
import merchantReducer from 'containers/Merchant/reducer';
import permissionReducer from 'containers/Permissions/reducer';
import rewardReducer from 'containers/Reward/reducer';
import rewardTransactionReducer from 'containers/RewardTransaction/reducer';
import settingReducer from 'containers/Setting/reducer';
import tagReducer from 'containers/Tag/reducer';

import { PerxState } from 'MyTypes';

export const appReducer = combineReducers({
  global: globalReducer,
  form: formReducer,
  campaign: campaignReducer,
  bulkAction: bulkActionReducer,
  catalogue: catalogueReducer,
  merchant: merchantReducer,
  reward: rewardReducer,
  loyalty: loyaltyReducer,
  ability: abilityReducer,
  customer: customerReducer,
  tag: tagReducer,
  category: categoryReducer,
  settings: settingReducer,
  reward_transaction: rewardTransactionReducer,
  log: downloadLogReducer,
  permissions: permissionReducer
  // ...injectedReducers
});

export default function createReducer(injectedReducers) {
  return rootReducer;
}

const rootReducer = (state: PerxState, action): PerxState => {
  if (action.type === SIGNOUT_USER_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};
