import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const userTenantsSelector = createSelector(
  state => state.global,
  global => global.user_tenants
);

const useUserTenants = () => {
  return useSelector(userTenantsSelector);
};

export default useUserTenants;
