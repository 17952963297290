import styled, { css } from 'styled-components';

import { metrics } from '../../assets/styles/commonStyle';

interface LabelProps {
  uppercase?: boolean;
  size?: string;
  nomargin?: boolean;
  color?: string;
  fullwidth?: boolean;
  interact?: boolean;
  padding?: boolean;
  margin?: string;
  bold?: boolean;
  align?: string;
  block?: boolean;
  /** Deprecated prop? */
  dark?: boolean;
  /** Deprecated prop? */
  normal?: boolean;
  /** Deprecated prop? */
  type?: string;
}

const Label = styled.label<LabelProps>`
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
  font-size: ${({ size }) => (size ? size : '0.875rem')};
  ${({ nomargin }) =>
    !nomargin &&
    css`
      margin-right: 10px;
    `};
  color: #353535;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ fullwidth }) =>
    fullwidth &&
    css`
      display: block;
    `};
  ${({ interact }) =>
    interact &&
    css`
      cursor: pointer;
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${metrics.padding.base}px 0;
    `};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `};
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `};
  ${({ block }) =>
    block &&
    css`
      display: block;
    `};
`;

export { Label };
export default Label;
