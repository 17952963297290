import React, { useState, useCallback } from 'react';
import { Button, DatePicker } from 'antd';
import { Col, Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useImmerReducer } from 'use-immer';

import { DATE_FORMAT } from 'appConstants';
import { TENANTS } from 'appConstants';
import { GridRenderer } from 'components/Display/GridRenderer';
import { PageContent } from 'components/page';
import { MerchantSelect } from 'components/selects';
import CheckableTag from 'containers/Analytics/components/CheckableTag';
import { CardsRow } from 'containers/Analytics/styles';
import { useTenant } from 'hooks';

import AnalyticsCard from '../components/AnalyticsCard';
import {
  HistogramGraph,
  DonutPercentageChart,
  PizzaPieChart,
  TopNGraph,
  MerchantsOverviewGraph,
  CampaignTableGraph
} from './graphs';

const initialState = {};

const reducer = (draft, action) => {
  switch (action.type) {
    case 'SET_MERCHANT':
      return void (draft['merchant_account_id'] = action.payload);
    case 'SET_ACCOUNT_CREATED':
      draft.account_created_start_date = action.payload[0];
      draft.account_created_end_date = action.payload[1];
      break;
    case 'RESET':
    default:
      return initialState;
  }
};

// https://docs.google.com/spreadsheets/d/1DND78WnnuRE7wOiE5xFKbKzKnmnQowPW5qsiz6DXDKM/edit#gid=0

const Merchants = () => {
  const [merchantFilters, dispatch] = useImmerReducer(reducer, initialState);
  const [showMerchant, setShowMerchant] = useState(false);
  const [showLocation] = useState(false);
  const [showAccountCreated, setShowAccountCreated] = useState(false);
  const { name: tenantName } = useTenant();

  const [filtersState, setFiltersState] = useState({});
  const onApplyFilters = useCallback(() => {
    setFiltersState({
      ...merchantFilters,
      merchant_account_id: merchantFilters?.merchant_account_id
        ? merchantFilters?.merchant_account_id.join(',')
        : null,
      account_created_start_date: merchantFilters?.account_created_start_date
        ? merchantFilters?.account_created_start_date.format('YYYY-MM-DD')
        : null,
      account_created_end_date: merchantFilters?.account_created_end_date
        ? merchantFilters?.account_created_end_date.format('YYYY-MM-DD')
        : null
    });
  }, [merchantFilters]);

  return (
    <PageContent>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div style={{ background: '#e7ebf2', padding: '1.25rem' }}>
            <div>
              <CheckableTag checked={showMerchant} onChange={() => setShowMerchant(prev => !prev)}>
                Merchant
              </CheckableTag>
              {/* <CheckableTag checked={showLocation} onChange={() => setShowLocation(prev => !prev)}>
                Location
              </CheckableTag> */}
              <CheckableTag
                checked={showAccountCreated}
                onChange={() => setShowAccountCreated(prev => !prev)}
              >
                Date Joined
              </CheckableTag>
            </div>

            {showMerchant && (
              <Row
                align="middle"
                style={{
                  margin: '1.5rem 0'
                }}
              >
                <Col span={6}>Merchant</Col>
                <Col span={18}>
                  <MerchantSelect
                    value={merchantFilters?.merchant_account_id}
                    mode="multiple"
                    filterOption={false}
                    placeholder="Filter by merchants"
                    onChange={merchantIds => {
                      dispatch({ type: 'SET_MERCHANT', payload: merchantIds });
                    }}
                  />
                </Col>
              </Row>
            )}
            {showLocation && (
              <Row
                align="middle"
                style={{
                  margin: '1.5rem 0'
                }}
              ></Row>
            )}
            {showAccountCreated && (
              <Row
                align="middle"
                style={{
                  paddingTop: '1.5rem'
                }}
              >
                <Col span={6}>Account Created</Col>
                <Col span={18}>
                  <DatePicker.RangePicker
                    value={[
                      merchantFilters?.account_created_start_date,
                      merchantFilters?.account_created_end_date
                    ]}
                    format={DATE_FORMAT}
                    onChange={dates => dispatch({ type: 'SET_ACCOUNT_CREATED', payload: dates })}
                    disabledDate={current => current && current > moment().endOf('day')}
                  />
                </Col>
              </Row>
            )}
            {(showMerchant || showLocation || showAccountCreated) && (
              <Row justify="end" style={{ marginTop: '1rem' }}>
                <Button
                  type="primary"
                  ghost
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    dispatch({ type: 'RESET' });
                    setFiltersState({});
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  disabled={_isEmpty(merchantFilters)}
                  onClick={onApplyFilters}
                >
                  Apply
                </Button>
              </Row>
            )}
          </div>
        </Col>
        <Col xs={24}>
          <MerchantsOverviewGraph filterParams={filtersState} />
        </Col>
      </Row>

      <CardsRow style={{ alignItems: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
        <AnalyticsCard
          identifier="merchant_avg_merchant_revenue"
          description="Avg. Merchant Revenue"
          filterParams={filtersState}
        />
        <AnalyticsCard
          identifier="merchant_avg_revenue_per_reward"
          description="Avg. Revenue per Rewards"
          filterParams={filtersState}
        />
        <AnalyticsCard
          identifier="merchant_total_merchant_view"
          description="Total Merchant View Count"
          isPercentage
          filterParams={filtersState}
        />
        <AnalyticsCard
          identifier="merchant_redemption_rate"
          description="Redemption Rate"
          isPercentage
          filterParams={filtersState}
        />
      </CardsRow>
      <GridRenderer perRow={1} gutter={16}>
        <CampaignTableGraph
          identifier="merchant_top_merchant"
          title="Top Merchants"
          tableProps={{
            rowKey: r => r?.[0],
            columns: [
              {
                title: 'Merchant',
                key: 'merchant',
                render: data => data?.[1] || '-'
              },
              {
                title: 'Total Revenue',
                key: 'total_revenue',
                render: data => data?.[2] && data[2].toLocaleString()
              },
              {
                title: 'Revenue per Reward',
                key: 'revenue_per_reward',
                render: data => data?.[3] && data[3].toLocaleString()
              },
              {
                title: 'Currency',
                key: 'currency',
                render: data => data?.[4] && data[4]
              },
              {
                title: 'Total Rewards',
                key: 'total_rewards',
                render: data => data?.[5] && data[5].toLocaleString()
              },
              {
                title: 'Total Vouchers Issued',
                key: 'total_voucher_issued',
                render: data => data?.[6] && data[6].toLocaleString()
              },
              {
                title: 'Total Vouchers Redeemed',
                key: 'total_vouchers_redeemed',
                dataIndex: '[7]',
                render: data => data?.[7] && data[7].toLocaleString()
              }
            ]
          }}
          pagination={true}
          n={5}
          filterParams={filtersState}
        />
      </GridRenderer>
      <GridRenderer perRow={2} gutter={16}>
        {/*TODO: Coordinate with backend/CS to require City/City Name when tenants bulk upload merchant data */}
        {/*<MapChart questionId={321} title="Location" filterParams={filtersState} />*/}
        <TopNGraph
          identifier="overview_flat_top_merchant"
          title="Top 5 Popular Merchants"
          helpText="Top 5 popular merchants based on the number of vouchers issued"
        />
        <TopNGraph
          identifier="merchant_top_catalog"
          title="Top Catalogs"
          filterParams={filtersState}
        />
        <TopNGraph
          identifier="merchant_popular_reward_issued"
          title="Popular Rewards – Issued"
          filterParams={filtersState}
        />
        {tenantName !== TENANTS.RAZER && (
          <HistogramGraph identifier="merchant_age" title="Age" filterParams={filtersState} />
        )}
        {tenantName !== TENANTS.RAZER && (
          <DonutPercentageChart
            identifier="merchant_gender"
            title="Gender"
            filterParams={filtersState}
            innerLabel="Total Users"
          />
        )}
        {tenantName !== TENANTS.RAZER && (
          <PizzaPieChart identifier="merchant_race" title="Ethnicity" filterParams={filtersState} />
        )}
      </GridRenderer>
    </PageContent>
  );
};

export default Merchants;
