import React from 'react';
import { Select } from 'antd';
import { Col, Row } from 'antd';

const GAME_TYPES = [
  { label: 'Shake The Tree', value: 'ShakeTheTree' },
  { label: 'Hit The Pinata', value: 'HitThePinata' }
  // { label: 'Scratch Card', value: 'ScratchCard' },
  // { label: 'Spin The Wheel', value: 'SpinTheWheel' }
];

const GameTypeFilter = ({ values, onChange }: { values: string[]; onChange(values): any }) => {
  return (
    <Row style={{ margin: '1.5rem 0' }} align="middle">
      <Col span={6}>Game type</Col>
      <Col span={18}>
        <Select
          size="large"
          placeholder="All Games"
          onChange={onChange}
          style={{ width: '100%' }}
          value={values}
        >
          <Select.Option value={''}>All Games</Select.Option>
          {GAME_TYPES.map(type => (
            <Select.Option key={type.value} value={type.value}>
              {type.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default GameTypeFilter;
