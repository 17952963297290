import React from 'react';
import styled from 'styled-components';

import imageSrc from './under-construction.svg';

interface ErrorFallbackProps {
  eventId?: string;
}

const supportLink = 'https://support.perxtech.com/hc/en-us/requests/new';
const mailLink = 'mailto:support@perxtech.com';

const ErrorFallback = ({ eventId }: ErrorFallbackProps) => {
  return (
    <PageContainer>
      <div>
        <img alt="under construction" src={imageSrc} />
        <h1>An error occurred, but we’ll fix it!</h1>
        <p>Let’s try the following:</p>
        <div>
          <ol>
            <li>Try hitting back or reload the page.</li>
            <li>If you typed in an address, recheck the path, maybe you missed a slash?</li>
            <li>
              If nothing works, please <a href={supportLink}>create a support ticket</a> or{' '}
              <a href={mailLink}>email us</a> with more details.
            </li>
          </ol>
        </div>
        <p>
          Not sure what happened? <a href="/">Return to the Dashboard</a>
        </p>
        {eventId && <SecondaryText>SENTRY ID: {eventId}</SecondaryText>}
      </div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 24px;
`;
const SecondaryText = styled.div`
  color: #8c8c8c;
`;

export default ErrorFallback;
