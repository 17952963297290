import React, { useCallback } from 'react';
import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Modal } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { TableMainText, TableSecondaryText } from 'styles';

import { useLeaderboardList } from 'api/leaderboards';
import { SMALL_PAGE_SIZE } from 'appConstants';
import StatusTag from 'components/StatusTag';
import TableTimestamp from 'components/TableTimestamp';
import { useListState } from 'hooks';

interface LeaderboardSelectModalProps {}

const columns: ColumnProps<any>[] = [
  {
    title: 'Name',
    key: 'name',
    render: (_, record: any) => (
      <>
        <TableMainText>{record.internal_name}</TableMainText>
        <TableSecondaryText>ID: {record.id}</TableSecondaryText>
      </>
    )
  },
  {
    title: 'Start date',
    dataIndex: 'start_date',
    key: 'start_date',
    width: 150,
    render: value => <TableTimestamp timestamp={value} />
  },
  {
    title: 'End date',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 150,
    sorter: true,
    render: value => <TableTimestamp timestamp={value} />
  },
  {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: state => <StatusTag state={state} />
  }
];

const LeaderboardSelectModal = NiceModal.create((props: LeaderboardSelectModalProps) => {
  const modal = useModal();

  const { handleTableChange, pagination, params } = useListState({
    state: 'active',
    size: SMALL_PAGE_SIZE
  });

  const { data, meta, isLoading } = useLeaderboardList(params);

  const handleOk = () => {
    modal.resolve();
    modal.hide();
  };

  const chooseMultiple = false;
  const shouldSubmitOnRowClick = !chooseMultiple;

  const rowProps = useCallback(
    (record, _) => ({
      onClick: () => {
        if (shouldSubmitOnRowClick) {
          modal.resolve(record);
          modal.hide();
        }
      },
      style: { cursor: shouldSubmitOnRowClick ? 'pointer' : 'auto' }
    }),
    [shouldSubmitOnRowClick, modal]
  );

  return (
    <Modal
      {...antdModalV5(modal)}
      onOk={handleOk}
      title="Select a leaderboard"
      width={1200}
      okButtonProps={shouldSubmitOnRowClick ? { style: { display: 'none' } } : null}
    >
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={{ ...pagination, total: meta?.count || 0 }}
        onRow={rowProps}
      />
    </Modal>
  );
});

export default LeaderboardSelectModal;
