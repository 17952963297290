import { useAxios } from 'hooks';
import { IPaginatedResponse } from 'utils/types';

export interface IRole {
  id: number;
  name: string;
  state: string;
  permissions: {}[];
}

export interface IRolesResponse extends IPaginatedResponse<IRole> {}

export const useRoles = () =>
  useAxios<IRolesResponse>('/v4/dash/roles', { params: { size: 1000 } });
