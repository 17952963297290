import { Button } from 'antd';
import styled from 'styled-components';

import { TransferRequestStatus } from 'api/bdo/transfer-requests';

import { getColorForStatus } from '../utils';

export const StyledButton = styled(Button)<{ state: TransferRequestStatus }>`
  background-color: ${({ state }) => getColorForStatus(state)};
  border-color: ${({ state }) => getColorForStatus(state)};

  &:focus,
  &:hover {
    border-color: ${({ state }) => getColorForStatus(state)};
    background-color: ${({ state }) => getColorForStatus(state)};
    opacity: 0.85;
  }
`;
