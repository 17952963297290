import React from 'react';
import { Modal } from 'antd';

import MediaForm from '../forms/MediaForm';

const AddMedia = ({ selectedMedia = null, modalOpen, onSave, closeModal }) => {
  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      open={modalOpen}
      title={selectedMedia ? 'Update Media' : 'Add New Media'}
      footer={null}
      onCancel={closeModal}
    >
      <MediaForm media={selectedMedia} onSave={onSave} onCancel={closeModal} />
    </Modal>
  );
};
export default AddMedia;
