import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Tabs } from 'antd';
import styled from 'styled-components';

interface ITab {
  key: string;
  label: string;
}
interface SubHeaderProps {
  subTabs?: ITab[];
  defaultSubTab?: string;
}

const SubHeader: React.FC<SubHeaderProps> = ({ subTabs, defaultSubTab }) => {
  const history = useHistory();

  const [activeSubTab, setActiveSubTab] = useState(
    subTabs
      ? subTabs.find(el => el?.key === defaultSubTab)
        ? defaultSubTab
        : subTabs[0].key
      : null
  );

  const changeSubTab = (selectedTab: string) => {
    setActiveSubTab(selectedTab);
    history.push(`/p/business_intelligence/campaigns/${selectedTab}`);
  };

  return (
    <HeaderContainer tabs={!!subTabs}>
      {subTabs && Array.isArray(subTabs) && (
        <Tabs
          defaultActiveKey={subTabs[0]?.key}
          activeKey={activeSubTab}
          onChange={changeSubTab}
          tabBarStyle={{ marginBottom: 0, marginTop: '0rem' }}
          items={subTabs.map(t => ({ label: t.label, key: t.key }))}
        />
      )}
    </HeaderContainer>
  );
};

export default SubHeader;

const HeaderContainer = styled.div<{ tabs?: boolean }>`
  width: calc(100vw - 209px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(-50vw + 100px);
  margin-right: -50vw;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding: ${props => (props.tabs ? '1rem 2.5rem 0' : '2rem')};
  overflow: visible;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
