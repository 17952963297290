import React, { useCallback, useState } from 'react';
import { SelectProps, Spin } from 'antd';

import { useCustomerIdentifierList } from 'api/identifier';
import { Select } from 'components/Layout/Form';

interface IdentifierSelectProps extends SelectProps {
  showId?: boolean;
  params?: {
    customer_id: any[];
  };
}

export const IdentifierSelect = ({ params, showId, ...props }: IdentifierSelectProps) => {
  const [search, setSearch] = useState(null);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const { data = [], isLoading } = useCustomerIdentifierList({ search_string: search, ...params });

  const options = data.map(item => ({
    value: item.id,
    label: showId ? `${item.customer_id} (ID: ${item.id})` : item.customer_id
  }));

  return (
    <Select
      onBlur={e => e.preventDefault()}
      onSearch={handleSearch}
      options={options}
      loading={isLoading}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      {...props}
    />
  );
};

export default IdentifierSelect;
