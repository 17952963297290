import { Modal as antModal } from 'antd';
import styled from 'styled-components';

import { metrics } from '../../assets/styles/commonStyle';
import { componentAdjustment } from '../Layout/common';

export const Modal = styled(antModal)<{ uppercase?: boolean }>`
  top: 50px !important;
  ${componentAdjustment};

  .ant-modal-body {
    padding-left: 25px;
    padding-right: 25px;
  }

  .ant-modal-header {
    padding-left: 25px;
    padding-right: 25px;
  }

  .ant-modal-footer {
    border: 0;
    border-top: 1px solid #e3e3e3;

    .ant-btn {
      text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
      padding: ${metrics.padding.base - 1}px ${metrics.padding.large - 1}px !important;
      height: auto !important;
    }
  }

  .ant-modal-close {
    right: 5px;
  }
`;

Modal.defaultProps = {
  uppercase: true
};

export default Modal;
