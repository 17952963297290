import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';

const A = styled.a`
  padding: 0 0.5em;
`;
interface IReportProps {
  authUser: any;
  path: any;
  id: any;
  name: any;
  fileType: any;
  isButton: any;
  buttonText: any;
}
class Report extends Component<IReportProps> {
  static propTypes = {
    path: PropTypes.string,
    name: PropTypes.string
  };

  _handleClick = (path, id, name, fileType) => evt => {
    evt.preventDefault();

    const headers = {
      Authorization: `Bearer ${this.props.authUser.bearer_token}`
    };

    axios
      .get(`${API_ROOT}${path}`, {
        headers,
        responseType: 'blob'
      })
      .then(response => {
        // such a hacky way, but ¯\_(ツ)_/¯
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}_${id}.${fileType || 'xlsx'}`);
        link.dispatchEvent(
          new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
        );
      })
      .catch(error => {
        // TODO: handle error
      });
  };

  render() {
    const { path, id, name, fileType, isButton, buttonText = 'Button' } = this.props;

    if (isButton) {
      return (
        <Button
          type={'primary'}
          size={'large'}
          onClick={this._handleClick(path, id, name, fileType)}
        >
          {buttonText}
        </Button>
      );
    }
    return (
      <A title={name} onClick={this._handleClick(path, id, name, fileType)}>
        {name}
      </A>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  authUser: makeSelectAuthUser()
});

export default connect(mapStateToProps)(Report);
