import React from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { FormItemProps } from 'antd/lib/form/FormItem';

import { FormItem } from 'components/Layout/Form';

interface Props extends FormItemProps {
  fieldState?: ControllerFieldState;
  children: React.ReactNode;
}

const RHFItem = ({ fieldState, ...props }: Props) => {
  const { error, isTouched } = fieldState || {};
  const hasError = error !== undefined && isTouched;
  const isValid = !error && isTouched;

  return (
    <FormItem
      validateStatus={hasError ? 'error' : undefined}
      hasFeedback={isValid}
      help={hasError ? error.message : undefined}
      colon={false}
      {...props}
    />
  );
};

export default RHFItem;
