import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import ErrorImage from './error.png';

const Img = styled.img`
  max-height: 190px;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  display: inline-block;
  color: #515762;
  font-size: 1.125em;
`;

interface ErrorProps {
  showImage?: boolean;
  retry: () => void;
}

const Error = ({ showImage = true, retry }: ErrorProps) => (
  <Wrapper>
    {showImage && <Img src={ErrorImage} alt="Failed to load data" />}
    <Text>Oops, Something went wrong.</Text>
    <Button type="link" icon={<ReloadOutlined />} onClick={retry}>
      Refresh
    </Button>
  </Wrapper>
);

export default Error;
