import React from 'react';
import { Empty, Spin } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from 'styled-components';

const COLORS = ['#2E41CE', '#2665EE', '#009AFF', '#8DCEFF', '#DF923D', '#DA7835'] as const;

type ColorType = (typeof COLORS)[number];

function* stackedColorGenerator(): Generator<ColorType> {
  let i = 0;
  while (true) {
    yield COLORS[i];
    i = (i + 1) % COLORS.length;
  }
}

const StackedBarChart = ({ graphData, labels, height = 200 }) => {
  const color = stackedColorGenerator();
  let graphs = [];

  const firstRow = graphData?.[0];
  if (!!firstRow) {
    const keys = Object.keys(firstRow);
    const graphKeys = keys.filter(key => key !== 'name');

    graphs = graphKeys.map(graphKey => ({
      name: graphKey,
      color: color.next().value
    }));
  }

  const CustomTooltip = props => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const sorted = [...payload].sort((a, b) => b.value - a.value);
      const total = sorted.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0);

      return (
        <TooltipWrapper>
          <h4>{label}</h4>
          <div>
            {sorted.map((line, index) => (
              <LegendWrapper key={index}>
                <Indicator color={line.fill} />{' '}
                {`${labels[line.name] || _startCase(line.name)} : ${line.value}`}
              </LegendWrapper>
            ))}
            <Total>Total: {total}</Total>
          </div>
        </TooltipWrapper>
      );
    }

    return null;
  };

  return (
    <Spin spinning={!graphData}>
      {!_isEmpty(graphData) ? (
        <ResponsiveContainer height={height}>
          <BarChart data={graphData}>
            <CartesianGrid strokeDasharray={'1 5'} horizontal={false} />
            <XAxis dataKey={'name'} />
            <YAxis allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            {graphs.map((g, index) => (
              <Bar
                key={index}
                dataKey={g.name}
                fill={g.color}
                stackId={graphData[0].name}
                barSize={30}
              />
            ))}
            <Legend
              verticalAlign={'top'}
              height={63}
              payload={graphs.map(g => ({
                value: labels[g.name] || _startCase(g.name),
                type: 'square',
                id: g.name,
                color: g.color
              }))}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Spin>
  );
};

export default StackedBarChart;

const TooltipWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 1rem;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Indicator = styled.div`
  display: inline-block;
  background: ${props => (props.color ? props.color : '#8884d8')};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 4px;
`;

const Total = styled.div`
  margin-top: 0.5rem;
  font-weight: 400;
`;
