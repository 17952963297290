import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableMainText, TableSecondaryText } from 'styles';

import { StatusTag, TableTimestamp } from 'components';
import DeleteIconButton from 'components/DeleteIconButton';
import { useGetDetail } from 'hooks';

const SelectedReward = ({ id, entity, onDelete }) => {
  const item = useGetDetail(id, entity);
  if (item) {
    return (
      <Wrapper>
        <Row justify="space-around" align="middle" gutter={8}>
          <Col span={6}>
            <Item>
              <TableMainText>{item.data.name}</TableMainText>
              <TableSecondaryText>ID:&nbsp;{item.data.id}</TableSecondaryText>
            </Item>
          </Col>
          <Col span={3}>
            <StatusTag state={item.data.state} />
          </Col>
          <Col span={5}>
            <TableMainText>{item.data.merchant?.name}</TableMainText>
          </Col>
          <Col span={4}>
            <TableTimestamp timestamp={item.data.begins_at} />
          </Col>
          <Col span={4}>
            <TableTimestamp timestamp={item.data.ends_at} />
          </Col>
          <Col span={2}>
            <DeleteIconButton onClick={onDelete} />
          </Col>
        </Row>
      </Wrapper>
    );
  }
  return null;
};

SelectedReward.propTypes = {
  id: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default SelectedReward;

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin: 1rem 0;
  &:first-child {
    margin-top: 0;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;
