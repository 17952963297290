import React from 'react';
import { Col, Row, Select } from 'antd';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';

import { useEthnicityList } from 'api/mappings';

const Race: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  const { data: ethnicities = [] } = useEthnicityList();

  return (
    <>
      <Row gutter={8}>
        <Col span={6}>
          <Select
            value={_get(values, 'filters.race[0].criteria[0].policy')}
            onSelect={value => {
              setFieldValue('filters.race[0].criteria[0].policy', value);
            }}
            placeholder="includes"
            data-testid="race-include-select"
          >
            <Select.Option value="include" data-testid="race-include-select-include">
              includes
            </Select.Option>
            <Select.Option value="exclude" data-testid="race-include-select-exclude">
              excludes
            </Select.Option>
          </Select>
        </Col>
        <Col span={18}>
          <Select
            mode="multiple"
            showArrow
            allowClear
            value={_get(values, 'filters.race[0].criteria[0].values')}
            onChange={values => {
              setFieldValue('filters.race[0].criteria[0].values', values);
            }}
            filterOption={(inputValue, option) =>
              (option.props.children as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
            placeholder="Select ethnicity"
          >
            {ethnicities?.map(race => (
              <Select.Option key={race.id} value={race.id}>
                {race.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </>
  );
};

export default Race;
