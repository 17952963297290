import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import styled from 'styled-components';
import * as yup from 'yup';

import { RHFItemInput } from 'components/rhf';
import { forgetPassword } from 'containers/App/actions';
import { makeSelectIsEmailSentOut } from 'containers/App/selectors';
import { useTypedSelector } from 'hooks';

import PublicPage from './components/PublicPage';

const Padlock = styled.p`
  padding: 20px 0;
  display: flex;
  font-size: 14px;

  & a {
    color: #9b9b9b;
  }
`;

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { xs: 24 },
  wrapperCol: { xs: 24 }
} as const;

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Please input your email!')
});

const selectisEmailSentOut = state => makeSelectIsEmailSentOut(state);

const ForgetPasswordForm = () => {
  const dispatch = useDispatch();
  const isEmailSentOut = useTypedSelector(selectisEmailSentOut);

  const { control, handleSubmit } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = values => {
    dispatch(forgetPassword(values));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <RHFItemInput
        formItemProps={{ ...formItemLayout }}
        control={control}
        name="email"
        label="Enter your email"
        suffix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Email"
        size="large"
      />
      <Button
        style={{ width: '100%', margin: 0, marginTop: '1.5rem' }}
        type="primary"
        htmlType="submit"
        size="large"
      >
        Send Link
      </Button>
      <Padlock>
        {isEmailSentOut &&
          `Instructions to reset your password have been sent out, please check your email`}
        {!isEmailSentOut && <Link to="signin">Back to Login</Link>}
      </Padlock>
    </form>
  );
};

const ForgetPassword = () => {
  const { pathname } = useLocation();

  let title: string, subtitle: string;

  if (pathname === '/forgot-password') {
    title = 'Forgot your password?';
    subtitle = 'Enter your email address and we’ll send you a link to reset your password.';
  } else if (pathname === '/set-password') {
    title = 'Set your password';
    subtitle = 'Enter your email address and we’ll send you a link to set a new password.';
  }

  return <PublicPage title={title} subtitle={subtitle} form={<ForgetPasswordForm />} />;
};

export default ForgetPassword;
