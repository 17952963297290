import axios, { AxiosResponse } from 'axios';

import { API_ROOT } from 'containers/App/constants';
import api from 'utils/api';
import { checkPayloadForFile } from 'utils/prepareFormData';
import API from 'utils/request';
import { IPaginatedResponse } from 'utils/types';

export enum CatalogState {
  active = 'active',
  approved = 'approved',
  inactive = 'inactive',
  ended = 'ended',
  draft = 'draft'
}

export interface ICatalog {
  id: number;
  name: string;
  description: string | null;
  state: CatalogState;
  begins_at: string;
  ends_at: null;
  images: any[];
  ordering: number | null;
  activatable: boolean;
  deactivatable: boolean;
  terms_and_conditions: string;
  og_title: string;
  og_description: string;
  og_image: any;
  al_ios_url: any;
  al_android_url: any;
  og_url: any;
  categories: any[];
  tags: any[];
}
export interface ICatalogsResponse extends IPaginatedResponse<ICatalog> {}

export interface ICatalogResponse {
  data: ICatalog;
}
export interface ICatalogResponse2 {
  data: ICatalog;
  meta: { count: number };
}

//TODO merge those 3 functions. As it is all doing the same thing.
export const fetchCatalogs = async (params: object): Promise<ICatalogsResponse> => {
  const res = await API.get<ICatalogsResponse>('/v4/dash/catalogs', { params });
  return res.data;
};
export const getCatalogues = (headers): Promise<AxiosResponse<ICatalogsResponse>> => {
  return api<ICatalogsResponse>({
    url: `${API_ROOT}/v4/dash/catalogs`,
    headers: headers
  });
};
export const searchCatalogues = (params, headers): Promise<AxiosResponse<ICatalogsResponse>> => {
  return api<ICatalogsResponse>({
    url: `${API_ROOT}/v4/dash/catalogs/`,
    headers: headers,
    params: params
  });
};

export const changeCatalogOrder = async (recordA: ICatalog, ordering: number = 1) => {
  if (ordering < 1) {
    return Promise.reject('Ordering cannot be less than 1');
  }
  return await API.patch('/v4/dash/catalogs/ordering', {
    catalogs: [
      {
        id: recordA.id,
        ordering: ordering
      }
    ]
  });
};

export const createCatalogue = (body, headers) => {
  // fixDateWithTimezoneParam(body, "begins_at", "begins_time");
  // fixDateWithTimezoneParam(body, "ends_at", "ends_time");

  body = checkPayloadForFile(body, headers);
  return axios
    .post(`${API_ROOT}/v4/dash/catalogs`, body, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const updateCatalogue = (id: number, body, headers) => {
  // fixDateWithTimezoneParam(body, "begins_at", "begins_time");
  // fixDateWithTimezoneParam(body, "ends_at", "ends_time");

  body = checkPayloadForFile(body, headers);
  return axios
    .put(`${API_ROOT}/v4/dash/catalogs/${id}`, body, {
      headers
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

// NL unused
// const orderCatalogue = (url, body, headers) => {
//   return axios
//     .patch(url, body, {
//       headers
//     })
//     .then(response => {
//       return response;
//     })
//     .catch(error => {
//       throw error.response;
//     });
// };

export const getCatalogue = (id: number, headers): Promise<AxiosResponse<ICatalogResponse2>> => {
  return api({
    url: `${API_ROOT}/v4/dash/catalogs/${id}`,
    headers: headers
  });
};

export const activateCatalogue = (
  id: number,
  headers?
): Promise<AxiosResponse<ICatalogResponse>> => {
  return API.patch<ICatalogResponse>(
    `${API_ROOT}/v4/dash/catalogs/${id}/activate`,
    { id },
    { headers }
  )
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const deactivateCatalogue = (
  id: number,
  headers?
): Promise<AxiosResponse<ICatalogResponse>> => {
  return API.patch<ICatalogResponse>(
    `${API_ROOT}/v4/dash/catalogs/${id}/deactivate`,
    { id },
    { headers }
  )
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

export const approveCatalogue = (
  id: number,
  headers?
): Promise<AxiosResponse<ICatalogResponse>> => {
  return API.patch<ICatalogResponse>(
    `${API_ROOT}/v4/dash/catalogs/${id}/approve`,
    { id },
    { headers }
  )
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error.response;
    });
};

// TODO: the below functions should be defined and typed within the rewards and tags sections
export const getRewards = (params, headers) => {
  return api({
    url: `${API_ROOT}/v4/dash/rewards`,
    params: params,
    headers: headers
  });
};

export const searchReward = (params, headers) => {
  return api({
    url: `${API_ROOT}/v4/dash/rewards`,
    headers: headers,
    params: params
  });
};

export const getTags = headers => {
  return api({
    url: `${API_ROOT}/v4/dash/simple/tags?size=1000`,
    headers: headers
  });
};
