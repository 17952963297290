import { takeLatest, put, call, select } from 'redux-saga/effects';

import { API_ROOT } from 'containers/App/constants';
import { makeSelectAuthUser } from 'containers/App/selectors';
import { commonSaga } from 'middleware/api';
import api from 'utils/api';

import { getProgressListSuccess, getProgressListFail } from './actions';
import { POST_BULK_FILE, GET_PROGRESS_LIST } from './constants';

// const uploadBulk = (url, body, headers) => {
//   body = checkPayloadForFile(body, headers)
//   return api({
//     url: url,
//     body: body,
//     headers: headers,
//     method: 'post',
//   })
// }

const fetchProgress = (url, params, headers) => {
  return api({
    url,
    headers,
    params
  });
};

function* getProgress(action) {
  const authUser = yield select(makeSelectAuthUser());
  const params = action.payload || {};

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.bearer_token}`
  };

  try {
    const response = yield call(fetchProgress, `${API_ROOT}/v4/dash/file_imports`, params, headers);
    yield put(getProgressListSuccess(response.data));
  } catch (e) {
    yield put(getProgressListFail(e.data));
  }
}

// function* postBulkUpload(action) {
//   const authUser = yield select(makeSelectAuthUser())
//   let data = action.payload

//   let headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${authUser.bearer_token}`,
//   }

//   let cancelMessage
//   try {
//     cancelMessage = message.loading('Submitting data...', 0)
//     const response = yield call(
//       uploadBulk,
//       `${API_ROOT}/v4/dash/file_imports`,
//       data,
//       headers
//     )

//     setTimeout(cancelMessage, 200)

//     yield put(postBulkFileSuccess(response.data))
//     window.location = `/dashboard/p/bulkaction`
//   } catch (e) {
//     setTimeout(cancelMessage, 200)
//     message.error(e.data.message || 'something went wrong')
//     yield put(postBulkFileFail(e.data))
//   }
// }

function* bulkUploadSaga() {
  yield takeLatest(POST_BULK_FILE, commonSaga);
  yield takeLatest(GET_PROGRESS_LIST, getProgress);
}

export default bulkUploadSaga;
