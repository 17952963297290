import React from 'react';
import { Control, useController } from 'react-hook-form';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';

import { Select } from 'components/Layout/Form';

import RHFItem from './RHFItem';

interface RHFItemSelectProps extends SelectProps {
  name: string;
  control?: Control<any, any>;
  label?: string;
  formItemProps?: FormItemProps;
}

const RHFItemSelect = ({ control, name, label, formItemProps, ...props }: RHFItemSelectProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem htmlFor={name} fieldState={fieldState} label={label} {...formItemProps}>
      <Select
        id={name}
        onChange={field.onChange}
        value={field.value}
        onBlur={field.onBlur}
        showArrow
        {...props}
      />
    </RHFItem>
  );
};

export default RHFItemSelect;
