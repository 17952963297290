import { Steps as AntSteps } from 'antd';
import styled from 'styled-components';

import { colors } from '../assets/styles/commonStyle';

const Steps = styled(AntSteps)`
  margin: 1.5rem 0;
  z-index: 1;
  position: relative !important;
  overflow: hidden;

  .ant-steps-item {
    height: 60px;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: none;
  }

  .ant-steps-item-title {
    font-weight: 400 !important;
    position: absolute;
    top: 35px;
    transform: translateX(-50%);
    padding: 0;
    &::after {
      top: -19px;
    }
  }

  .ant-steps-item-icon {
    z-index: 2;
  }

  .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
    color: ${colors.grey.base} !important;
  }
  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: ${colors.base} !important;
  }
  .ant-steps-item {
    margin-right: 0 !important;
    overflow: visible;
  }
  .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: ${colors.grey.base};
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: ${colors.grey.base};
    border-color: ${colors.grey.base};
  }
`;

Steps.defaultProps = {
  labelPlacement: 'vertical'
};

export { Steps };
export default Steps;
