import React, { memo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { GridRenderer } from 'components/Display/GridRenderer';
import MenuIcon from 'components/Header/MenuIcon';
import { PageContent } from 'components/page';
import ComboGraph from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import AreaGraphSection from 'containers/Analytics/pages/graphs/ComboGraph/components/AreaGraphSection';
import ValuesSection from 'containers/Analytics/pages/graphs/ComboGraph/components/ValuesSection';
import DemographicsGraph from 'containers/Analytics/pages/graphs/DemographicsGraph/DemographicsGraph';
import ActiveUsers from 'containers/Analytics/pages/graphs/UserGraphs/ActiveUsers';
import ReturningUsers from 'containers/Analytics/pages/graphs/UserGraphs/ReturningUsers';
import UserValuesCard from 'containers/Analytics/pages/graphs/UserGraphs/UserValuesCard';

import { HeatMapGraph } from './graphs';
import LoyaltyComboGraph from './graphs/LoyaltyComboGraph/LoyaltyComboGraph';

const Overview = () => {
  const history = useHistory();

  return (
    <OverviewWrapper>
      <section>
        <SectionTitle>Your Users</SectionTitle>
        <GridRenderer perRow={3} gutter={16} breaks>
          <UserValuesCard
            identifier={'overview_flat_registered_user'}
            title={'Total Registered Users'}
          />
          <ActiveUsers occupies={2} />
          <DemographicsGraph
            identifierOptionsMapping={{
              age: 'overview_flat_age',
              gender: 'overview_flat_gender',
              ethnicity: 'overview_flat_race'
            }}
          />
          <ReturningUsers occupies={2} />
        </GridRenderer>
      </section>
      <section>
        <SectionTitle>User Engagement</SectionTitle>
        <GridRenderer perRow={3} gutter={16} breaks>
          <ComboGraph
            sections={[
              <ValuesSection
                identifier={'overview_flat_voucher_issue_redeem_kpi'}
                showChange
                demoValues={[
                  {
                    name: 'Total Voucher Issued',
                    value: [1500, 2500],
                    previous: [10, 25]
                  },
                  {
                    name: 'Total Voucher Redeemed',
                    value: [100, 200],
                    previous: [5, 15]
                  }
                ]}
              />,
              <AreaGraphSection
                identifier={'overview_flat_voucher_issue_redeem_overtime'}
                withFill={false}
                demoLines={[
                  'issued_count',
                  'redeemed_count',
                  'issued_user_count',
                  'redeemed_user_count'
                ]}
              />
            ]}
            title={'Vouchers'}
            withDatePart
          />
          <ComboGraph
            occupies={2}
            sections={[
              <ValuesSection
                identifier={'overview_flat_transaction_and_user_kpi'}
                showChange
                demoValues={[
                  {
                    name: 'Transaction Count',
                    value: [6000, 11000],
                    previous: [5, 15]
                  },
                  {
                    name: 'User Count',
                    value: [1000, 5000],
                    previous: [-5, 10]
                  }
                ]}
              />,
              <AreaGraphSection
                identifier={'overview_flat_transaction_and_user_overtime'}
                withFill={false}
                demoLines={['transaction_count', 'user_count']}
              />
            ]}
            title={'Transactions'}
            withDatePart
          />
          <LoyaltyComboGraph />
          <HeatMapGraph
            identifier="overview_flat_popular_engagement_day"
            title="Popular Engagement Days"
            helpText="Which specific day and timing that the users have the highest engagement on the platform. The level of engagement is indicated by the intensity of the colour spectrum."
            isEngagement
          />
        </GridRenderer>
      </section>
      <section>
        <SectionTitle>Your Activities</SectionTitle>
        <GridRenderer perRow={3} gutter={16} breaks>
          <ComboGraph
            sections={[
              <ValuesSection identifier={'overview_flat_campaign_kpi'} />,
              <AreaGraphSection
                identifier={'overview_flat_campaign_breakdown'}
                withFill={false}
                demoLines={['active_campaign']}
              />
            ]}
            title={'Campaigns'}
            icon={<MenuIcon src={'campaigns'} />}
            ctaText={'View campaign analytics'}
            ctaAction={() => {
              history.push('/p/business_intelligence/campaigns');
            }}
          />
          <ComboGraph
            sections={[
              <ValuesSection identifier={'overview_flat_reward_kpi'} />,
              <AreaGraphSection
                identifier={'overview_flat_reward_breakdown'}
                withFill={false}
                demoLines={['active_reward']}
              />
            ]}
            title={'Rewards'}
            icon={<MenuIcon src={'rewards'} />}
            ctaText={'View rewards analytics'}
            ctaAction={() => {
              history.push('/p/business_intelligence/rewards');
            }}
          />
          <ComboGraph
            sections={[
              <ValuesSection identifier={'overview_flat_rule_kpi'} />,
              <AreaGraphSection
                identifier={'overview_flat_rule_breakdown'}
                withFill={false}
                demoLines={['active_rule']}
              />
            ]}
            title={'Automation Rules'}
            icon={<MenuIcon src={'rules'} />}
            ctaText={''}
            ctaAction={() => {}}
            ctaStyle={{ display: 'none' }}
          />
        </GridRenderer>
      </section>
    </OverviewWrapper>
  );
};

export const SectionTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const OverviewWrapper = styled(PageContent)`
  .ant-card {
    .ant-card-body {
      padding: 1rem 1.5rem;
    }
  }
`;

export default memo(Overview);
