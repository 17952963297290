import { PROJECT_NAME } from 'containers/App/constants';

const CONTAINER_NAME = 'Merchant';

export const CREATE_MERCHANT = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_MERCHANT`;
export const CREATE_MERCHANT_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_MERCHANT_SUCCESS`;
export const CREATE_MERCHANT_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/CREATE_MERCHANT_FAIL`;

export const UPDATE_MERCHANT = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_MERCHANT`;
export const UPDATE_MERCHANT_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_MERCHANT_SUCCESS`;
export const UPDATE_MERCHANT_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPDATE_MERCHANT_FAIL`;

export const GET_MERCHANTS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS`;
export const GET_MERCHANTS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS_SUCCESS`;
export const GET_MERCHANTS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANTS_FAIL`;

export const GET_SIMPLE_MERCHANTS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_SIMPLE_MERCHANTS`;
export const GET_SIMPLE_MERCHANTS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_SIMPLE_MERCHANTS_SUCCESS`;
export const GET_SIMPLE_MERCHANTS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_SIMPLE_MERCHANTS_FAIL`;

export const GET_MERCHANT_ACTIVATE_DEACTIVATE = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_ACTIVATE_DEACTIVATE`;
export const GET_MERCHANT_ACTIVATE_DEACTIVATE_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_ACTIVATE_DEACTIVATE_SUCCESS`;
export const GET_MERCHANT_ACTIVATE_DEACTIVATE_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_ACTIVATE_DEACTIVATE_FAIL`;

export const GET_TAGS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS`;
export const GET_TAGS_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_SUCCESS`;
export const GET_TAGS_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_TAGS_FAIL`;

export const SEARCH_MERCHANT = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_MERCHANT`;
export const SEARCH_MERCHANT_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_MERCHANT_FAIL`;
export const SEARCH_MERCHANT_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/SEARCH_MERCHANT_SUCCESS`;

export const UPLOAD_MERCHANT_LOCATION = `${PROJECT_NAME}/${CONTAINER_NAME}/UPLOAD_MERCHANT_LOCATION`;
export const UPLOAD_MERCHANT_LOCATION_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/UPLOAD_MERCHANT_LOCATION_FAIL`;
export const UPLOAD_MERCHANT_LOCATION_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/UPLOAD_MERCHANT_LOCATION_SUCCESS`;

//Actions for Child Branchs under merchants
export const GET_MERCHANT_BRANCH_LIST = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH_LIST`;
export const GET_MERCHANT_BRANCH_LIST_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH_LIST_FAIL`;
export const GET_MERCHANT_BRANCH_LIST_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH_LIST_SUCCESS`;

//GET branch detail via merchant id
export const GET_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH`;
export const GET_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH_FAIL`;
export const GET_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/GET_MERCHANT_BRANCH_SUCCESS`;

export const POST_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_MERCHANT_BRANCH`;
export const POST_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_MERCHANT_BRANCH_FAIL`;
export const POST_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/POST_MERCHANT_BRANCH_SUCCESS`;

export const PATCH_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/PATCH_MERCHANT_BRANCH`;
export const PATCH_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/PATCH_MERCHANT_BRANCH_FAIL`;
export const PATCH_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/PATCH_MERCHANT_BRANCH_SUCCESS`;

export const PUT_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/PUT_MERCHANT_BRANCH`;
export const PUT_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/PUT_MERCHANT_BRANCH_FAIL`;
export const PUT_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/PUT_MERCHANT_BRANCH_SUCCESS`;

export const DELETE_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_MERCHANT_BRANCH`;
export const DELETE_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_MERCHANT_BRANCH_FAIL`;
export const DELETE_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_MERCHANT_BRANCH_SUCCESS`;

export const DELETE_ALL_MERCHANT_BRANCH = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ALL_MERCHANT_BRANCH`;
export const DELETE_ALL_MERCHANT_BRANCH_FAIL = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ALL_MERCHANT_BRANCH_FAIL`;
export const DELETE_ALL_MERCHANT_BRANCH_SUCCESS = `${PROJECT_NAME}/${CONTAINER_NAME}/DELETE_ALL_MERCHANT_BRANCH_SUCCESS`;
