import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Formik, Field, Form } from 'formik';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import * as yup from 'yup';

import { useSWRUtils } from 'api/utils';
import FormItem from 'components/Form/StyledFormItem';
import { userSignIn } from 'containers/App/actions';
import { CANCEL_OTP } from 'containers/App/constants';
import { useTypedSelector } from 'hooks';

import PublicPage from './components/PublicPage';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Email is required'),
  password: yup.string().required('Password is required'),
  otp: yup.number().typeError('OTP should be a number')
});

const SignInForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { clearCacheData } = useSWRUtils();
  const otpRequired = useTypedSelector(state => state?.global?.otpRequired);
  const isLoading = useTypedSelector(state => state?.global?.loading);
  const [code] = useQueryParam('code', StringParam);
  const [codeTenant] = useQueryParam('tenant', StringParam);
  const [urlError] = useQueryParam('error', NumberParam);
  const isCodeSignin = !!code && !!codeTenant;

  useEffect(() => {
    if (!!code && !!codeTenant) {
      clearCacheData();

      dispatch(userSignIn({ code, tenant: codeTenant }, history, location, () => {}, 'code'));
    }
    // eslint-disable-next-line
  }, [code, codeTenant]);

  const onSubmit = (values, bag) => {
    clearCacheData();

    dispatch(userSignIn(values, history, location, bag.setSubmitting));
  };

  const cancelOTP = () => {
    dispatch({ type: CANCEL_OTP });
  };

  if (isCodeSignin) {
    if (isLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined />
          <h3>Logging in</h3>
        </div>
      );
    } else {
      return (
        <div>
          <h3>Login unsuccessful. Please try again.</h3>
        </div>
      );
    }
  }

  if (urlError === 403) {
    return (
      <div>
        <h3>Access Forbidden! Please contact your administrator to enable access.</h3>
      </div>
    );
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: ''
      }}
    >
      {({ handleReset }) => (
        <Form>
          {!otpRequired && (
            <>
              <Field name="email">
                {({ field, meta }) => {
                  const hasError = meta.error !== undefined;
                  const isValid = meta.touched && !meta.error;
                  return (
                    <FormItem
                      label="Email"
                      htmlFor="email"
                      validateStatus={meta.error && meta.touched ? 'error' : undefined}
                      hasFeedback={isValid}
                      help={hasError && meta.touched && <>{meta.error}</>}
                      labelCol={{ sm: { span: 24 } }}
                      labelAlign="left"
                      required
                    >
                      <Input
                        allowClear
                        id="email"
                        placeholder="Please enter an email address"
                        {...field}
                      />
                    </FormItem>
                  );
                }}
              </Field>
              <Field name="password">
                {({ field, meta }) => {
                  const hasError = meta.error !== undefined;
                  const isValid = meta.touched && !meta.error;
                  return (
                    <FormItem
                      label="Password"
                      htmlFor="password"
                      validateStatus={meta.error && meta.touched ? 'error' : undefined}
                      hasFeedback={isValid}
                      help={hasError && meta.touched && <>{meta.error}</>}
                      labelCol={{ sm: { span: 24 } }}
                      labelAlign="left"
                      required
                    >
                      <Input.Password
                        allowClear
                        id="password"
                        label="Password"
                        placeholder="Please enter a password"
                        {...field}
                      />
                    </FormItem>
                  );
                }}
              </Field>
            </>
          )}

          {otpRequired && (
            <>
              <div>Please check your email and enter the OTP below to log in.</div>
              <Field name={'otp'}>
                {({ field, meta }) => {
                  const hasError = meta.error !== undefined;
                  const isValid = meta.touched && !meta.error;
                  return (
                    <FormItem
                      label="OTP"
                      htmlFor="otp"
                      validateStatus={meta.error && meta.touched ? 'error' : undefined}
                      hasFeedback={isValid}
                      help={hasError && meta.touched && <>{meta.error}</>}
                      labelCol={{ sm: { span: 24 } }}
                      labelAlign="left"
                      required
                    >
                      <Input
                        allowClear
                        id="otp"
                        label="OTP"
                        placeholder="Enter your OTP"
                        style={{ width: '100%' }}
                        {...field}
                      />
                    </FormItem>
                  );
                }}
              </Field>
              {!isLoading && (
                <span
                  onClick={() => {
                    handleReset();
                    cancelOTP();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Back to login
                </span>
              )}
            </>
          )}
          <Button
            style={{ width: '100%', margin: 0, marginTop: '1.5rem' }}
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            Login
          </Button>
          <div style={{ marginTop: '1rem' }}>
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
          {/* Hide until other things will be prepared */}
          {/* <div style={{ marginTop: '1rem' }}>
            <Link to="/registration">Don't have an account? Create now</Link>
          </div> */}
        </Form>
      )}
    </Formik>
  );
};

const SignIn = () => {
  return <PublicPage title="Log In" form={<SignInForm />} />;
};

export default SignIn;
