import styled, { css } from 'styled-components';

import { metrics, colors } from '../../assets/styles/commonStyle';

export const space = props => ({
  margin: props.margin || '',
  marginBottom: props.marginBottom || '',
  marginLeft: props.marginLeft || '',
  marginRight: props.marginRight || '',
  padding: props.padding || '',
  paddingBottom: props.paddingBottom || '',
  paddingLeft: props.paddingLeft || '',
  paddingRight: props.paddingRight || '',
  paddingTop: props.paddingTop || ''
});

export const CenterContainer = styled.div`
  text-align: center;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;
export const AbsoluteContainer = styled.div`
  position: absolute;
`;

export const Container = styled.div`
  display: flex;
`;
export const CarouselWrapper = styled.section`
  width: 30%;
  background: #fff;
  margin-right: ${metrics.margin.extraLarge}px;
  overflow: hidden;
  img {
    width: auto;
  }
`;

export const Content = styled.section`
  flex: 1;
`;

interface PaddingContainerProps {
  padding?: string | number;
}

export const PaddingContainer = styled.div<PaddingContainerProps>`
  padding: ${props => props.padding || metrics.padding.large}px 0;
`;

interface FlexContainerProps {
  center?: boolean;
  alignRight?: boolean;
  horizolCenter?: boolean;
  paddingVertical?: string | number;
  width?: string;
  flex?: string | number;
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  padding?: string | number;
  hasSpaces?: boolean;
  hasMiddleSpaces?: boolean;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-wrap: wrap;
  ${props => (props.center ? 'align-items : center' : '')};
  justify-content: ${props => (props.alignRight ? 'flex-end' : 'flex-start')};
  ${props => (props.horizolCenter ? 'justify-content : center' : '')};
  padding: ${props =>
      props.paddingVertical !== undefined ? props.paddingVertical : metrics.padding.small}px
    ${props => props.padding || 0}px;
  width: ${props => props.width};
  flex: ${props => props.flex || 'none'};
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`};
  ${props => props.alignItems && `align-items: ${props.alignItems};`};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => (props.hasSpaces ? 'margin: 0 -5px;' : '')}
  ${props => (props.hasMiddleSpaces ? 'margin: 0 -15px;' : '')}

  & > .ant-col {
    ${props => (props.hasSpaces ? 'padding: 0 5px;' : '')}
    ${props => (props.hasMiddleSpaces ? 'padding: 0 15px;' : '')}
  }
`;

interface GridContainerProps {
  inline?: boolean;
  gap?: string;
  columns?: string;
  rows?: string;
  alignItems?: string;
  justifyContent?: string;
  justifyItems?: string;
  margin?: string;
  padding?: string;
}

export const GridContainer = styled.div<GridContainerProps>`
  display: ${props => (props.inline ? 'inline-grid' : 'grid')};
  grid-gap: ${props => (props.gap ? props.gap : '')};
  grid-template-columns: ${props => (props.columns ? props.columns : '')};
  grid-template-rows: ${props => (props.rows ? props.rows : '')};
  align-items: ${props => (props.alignItems ? props.alignItems : '')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : '')};
  justify-items: ${props => (props.justifyItems ? props.justifyItems : '')};
  margin: ${props => (props.margin ? props.margin : '')};
  padding: ${props => (props.padding ? props.padding : '')};
`;

export const FloatContainer = styled(FlexContainer)`
  width: ${props => props.width}px;
  flex: ${props => (props.width ? 'none' : 1)};
`;

export const SpaceBetweenContainer = styled(FlexContainer)`
  align-items: ${props => (props.center ? 'center' : 'normal')};
  justify-content: space-between;
`;

export const FloatLeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin-right: ${metrics.margin.large}px;
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;

export const TextWrap = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled.div`
  padding: 5px 15px;
  margin-right: 5px;
  background: #e6ebf0;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  display: inline;
  &:last-child {
    margin-right: 0;
  }
`;

export const CircleContainer = styled.div`
  border-radius: 50%;
  margin: 10px;
  overflow: hidden;
  background: #556080;
`;

export const componentAdjustment = css`
  & .check-box-list {
    svg {
      height: 30px !important;
      width: 30px !important;
    }
    label {
      line-height: 1.1;
    }
    .ant-checkbox-wrapper {
      position: relative;
    }
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
      position: absolute;
      width: 100%;
      top: 20px;
      left: 0;
      display: flex;
      justify-content: center;
    }
    .ant-checkbox-checked + * {
      svg {
        fill: #777 !important;
      }
      label {
        color: #999 !important;
      }
    }
    .ant-checkbox-checked {
      background: #e6ebf0;
      border: 1px solid transparent !important;
    }
  }
  & .ant-time-picker-input {
  }

  & .RichTextEditor__root___2QXK- {
  }

  & .ant-time-picker-icon:after {
    color: #333 !important;
  }

  & .ant-select-selection {
  }

  & .ant-select-selection {
  }

  & .ant-radio-button-wrapper {
    height: 40px;
    padding: 5px 15px;
  }

  & .ant-time-picker {
    margin-right: ${metrics.margin.base}px;
  }
  .hidden {
    opacity: 0;
    position: absolute;
    left: -100vw;
  }
  .reward-wrapper .ant-radio-button-wrapper {
    height: auto !important;

    width: auto !important;
    border: solid 1px #e6ebf0 !important;
    text-align: center;
    padding: 20px;
    line-height: 40px;
    svg {
      padding-left: 0 !important;
    }
  }

  .spaced {
    margin-bottom: 10px;
  }
  .spaced-extra {
    margin-bottom: 30px;
  }
  .full-width {
    .ant-select {
      width: 100%;
    }
  }

  .ant-tabs-bar {
    margin: 0 0 -1px 0;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-ink-bar {
      background-color: ${colors.base} !important;
      bottom: 2px;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 300;
    color: #9b9b9b;
    :hover {
      color: inherit;
    }
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #000 !important;
  }
  .ant-calendar-picker {
    margin-right: ${metrics.margin.base}px;
  }

  .ant-select-selection__clear,
  .ant-select-arrow {
    top: 50%;
  }
`;

export const SelectFilterType = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 0 10px 10px 0;

      .ant-checkbox + span {
        width: 100% !important;
      }
    }
  }
`;

export const PageHeader = styled.div({
  marginBottom: '20px'
});

export const FilePreview = styled.div`
  position: relative;
  display: inline-grid;
  grid-template-columns: 15px auto 15px;
  align-items: center;
  grid-gap: 10px;
  border: 1px solid #fb7901;
  font-size: 14px;
  padding: 0 15px;
  border-radius: 2px;
  font-weight: normal;
  height: 44px;
  text-transform: uppercase;

  & > p {
    padding-right: 20px;
    margin: 0;
  }

  .anticon-delete {
    cursor: pointer;
    color: #fb7901;
  }
`;
