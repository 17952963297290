import useSWR from 'swr';

import { ISimpleListResponse } from 'utils/types';

import { buildQuery, fetcher } from './utils';

export interface IBrand {
  id: number;
  name: string;
}

export const useSimpleBrandList = (params?: object) => {
  const { data, isLoading, mutate } = useSWR<ISimpleListResponse<IBrand>>(
    `/v4/dash/simple/brands?${buildQuery(params)}`,
    fetcher
  );

  return { data: data?.data, isLoading, mutate };
};
