import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Input, InputProps } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { TextAreaProps } from 'antd/lib/input';

import RHFItem from './RHFItem';

interface RHFItemTextAreaProps extends TextAreaProps {
  control: Control<any, any>;
  name: string;
  label?: string;
  formItemProps?: FormItemProps;
  inputProps?: InputProps;
}

const RHFItemTextArea = ({
  control,
  name,
  label,
  formItemProps,
  ...props
}: RHFItemTextAreaProps) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <RHFItem fieldState={fieldState} label={label} htmlFor={name} {...formItemProps}>
      <Input.TextArea id={name} {...field} {...props} />
    </RHFItem>
  );
};

export default RHFItemTextArea;
