import { useMemo } from 'react';

import { useTypedSelector } from 'hooks';

import {
  makeActionsSelector,
  makeRestrictedActionsSelector,
  makeRestrictedFieldsSelector
} from './selectors';

const usePermissions = (
  resourceName: string
): {
  loading: boolean;
  actions: string[];
  restrictedActions: string[];
  restrictedFields: string[];
  canView: boolean;
  canEdit: boolean;
  hasDraftPermission: boolean;
} => {
  const actionsSelector = useMemo(makeActionsSelector, []);
  const actions = useTypedSelector(state => actionsSelector(state, resourceName));

  const restrictedActionsSelector = useMemo(makeRestrictedActionsSelector, []);
  const restrictedActions = useTypedSelector(state =>
    restrictedActionsSelector(state, resourceName)
  );

  const loading = useTypedSelector(state => state.permissions.loading);

  const restrictedFieldsSelector = useMemo(makeRestrictedFieldsSelector, []);
  const restrictedFields = useTypedSelector(state => restrictedFieldsSelector(state, resourceName));

  const canView = actions.includes('view');
  const canEdit = actions.includes('edit') && !restrictedFields.includes('state');
  const hasDraftPermission = actions.includes('edit') && restrictedFields.includes('state');

  return {
    loading,
    actions,
    restrictedActions,
    restrictedFields,
    canView,
    canEdit,
    hasDraftPermission
  };
};

export default usePermissions;
