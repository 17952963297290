import useTenant from './useTenant';

const useLocalization = () => {
  const { localization } = useTenant();

  return {
    ...localization,
    supportedLocales: localization?.availableLocales?.platformSupportedLocales ?? [],
    locales: localization?.availableLocales?.locales ?? []
  };
};

export default useLocalization;
