import { InputNumberProps } from 'antd';

import { formatCurrency, formatNumber } from 'utils/formatters';

import useFeatureFlags from './useFeatureFlags';

/**
 * The hook exists to help implementing decimal loyalty points on the platform.
 * All decimal points are stored as `x * 100` integers in db.
 *
 * @returns `formatPoints`: function for points formatting
 * @returns `pointsInputProps`: props which should be passed to any points number input
 */
export const usePointsFormat = () => {
  const { loyalty_points_decimals } = useFeatureFlags();

  const formatPoints = (value: number) =>
    loyalty_points_decimals ? formatCurrency(value / 100) : formatNumber(value);

  const pointsInputProps: Partial<InputNumberProps<number>> = loyalty_points_decimals
    ? {
        formatter: (number: number) => String(number / 100),
        parser: (string: string) => parseFloat(string) * 100,
        step: 0.01
      }
    : {
        step: 1
      };

  return { formatPoints, pointsInputProps };
};

export default usePointsFormat;
