import React from 'react';
import { Radio } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { RadioGroupProps } from 'antd/lib/radio';
import { useField, useFormikContext } from 'formik';

import FItem from './FItem';

interface FItemRadioGroupProps extends RadioGroupProps {
  name: string;
  children: React.ReactNode;
  label?: string;
  formItemProps?: FormItemProps;
  radioGroupProps?: RadioGroupProps;
}

const FItemRadioGroup = ({
  name,
  children,
  label,
  formItemProps,
  radioGroupProps,
  ...otherProps
}: FItemRadioGroupProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <FItem meta={meta} label={label} {...formItemProps}>
      <Radio.Group
        value={field.value}
        onChange={e => setFieldValue(name, e.target.value)}
        onBlur={field.onBlur}
        {...otherProps}
        {...radioGroupProps}
      >
        {children}
      </Radio.Group>
    </FItem>
  );
};

export default FItemRadioGroup;
