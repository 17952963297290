import _forIn from 'lodash/forIn';
import _get from 'lodash/get';

export const groupFilters = obj => {
  const map = new Map();
  _forIn(obj, (v, k) => {
    if (Array.isArray(v)) {
      const arrayInclude = [];
      const arrayExclude = [];
      v.forEach(item => {
        let itemPolicy;
        if (Array.isArray(item.criteria)) {
          itemPolicy = _get(item, 'criteria[0].policy', 'include');
        } else {
          itemPolicy = _get(item, 'criteria.policy', 'include');
        }
        if (itemPolicy === 'include') {
          arrayInclude.push(item);
        } else {
          arrayExclude.push(item);
        }
      });
      if (arrayInclude.length) {
        const collection = map.get('include');
        if (!collection) {
          map.set('include', { [k]: arrayInclude });
        } else {
          collection[k] = arrayInclude;
        }
      }
      if (arrayExclude.length) {
        const collection = map.get('exclude');
        if (!collection) {
          map.set('exclude', { [k]: arrayExclude });
        } else {
          collection[k] = arrayExclude;
        }
      }
    } else {
      const key = _get(v, 'criteria.policy', 'include');
      const collection = map.get(key);
      if (!collection) {
        map.set(key, { [k]: v });
      } else {
        collection[k] = v;
      }
    }
  });
  return map;
};
