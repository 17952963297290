import React from 'react';
import { Checkbox, Col, Radio, Row } from 'antd';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { InputLimitWidth } from 'components/Layout/Form';
import { FlexContainer } from 'components/Layout/common';
import Label from 'components/Text/Label';
import { FItemInputNumber, FItemSelect, FItemDatePicker } from 'components/formik';
import { policyOptions } from 'containers/Audience/utils';

interface CheckboxFilterProps {
  name: string;
  label: string;
  labels?: [string, string];
}

const CheckboxFilter = ({
  name,
  label,
  labels: [enabledLabel, disabledLabel] = ['Enabled', 'Disabled']
}: CheckboxFilterProps) => {
  const { setFieldValue, values } = useFormikContext();

  const fieldPath = `filters.${name}[0].criteria`;

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      setFieldValue(`filters.${name}`, [
        {
          type: 'value',
          criteria: {
            eq: 'true',
            policy: 'include'
          }
        }
      ]);
    } else {
      setFieldValue(fieldPath, null);
    }
  };

  const handleRadioChange = e => {
    setFieldValue(`${fieldPath}.eq`, e.target.value);
  };

  const filterValue = get(values, `${fieldPath}.eq`);
  const filterSelected = !isNil(filterValue);

  return (
    <Row style={{ marginBottom: '1rem' }}>
      <Col xs={8}>
        <Label bold>
          <Checkbox checked={filterSelected} onChange={handleCheckboxChange} />
          &nbsp;
          {label}
        </Label>
      </Col>
      {filterSelected && (
        <Col xs={16}>
          <Radio.Group style={{ width: '100%' }} onChange={handleRadioChange} value={filterValue}>
            <Row>
              <Col xs={12}>
                <Radio value="true" aria-label={`${label}: ${enabledLabel}`}>
                  {enabledLabel}
                </Radio>
              </Col>
              <Col xs={12}>
                <Radio value="false" aria-label={`${label}: ${disabledLabel}`}>
                  {disabledLabel}
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Col>
      )}
    </Row>
  );
};

const paymentStatusOptions = [
  { value: 'UPCOMING', label: 'Upcoming' },
  { value: 'DUE_NOW', label: 'Due now' },
  { value: 'FUTURE', label: 'Future' },
  { value: 'OVERDUE', label: 'Overdue' },
  { value: 'NONE', label: 'None' }
];

const PaymentStatusFilter = () => {
  const fieldPath = `filters.payment_status[0].criteria`;
  const { setFieldValue } = useFormikContext();

  const handleSelectChange = val => {
    setFieldValue(`filters.payment_status`, [
      {
        type: 'value',
        criteria: {
          eq: val,
          policy: 'include'
        }
      }
    ]);
  };

  return (
    <div>
      <Label bold>Payment status</Label>
      <div style={{ display: 'flex' }}>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
        <FItemSelect
          mode="multiple"
          name={`${fieldPath}.eq`}
          placeholder="Select from list"
          options={paymentStatusOptions}
          formItemProps={{ style: { flex: '0 1 100%' } }}
          onChange={handleSelectChange}
          allowClear
        />
      </div>
    </div>
  );
};

const DaysPastDuesFilter = () => {
  const fieldPath = `filters.days_past_dues[0].criteria`;
  const { setFieldValue, values } = useFormikContext();

  const handleInputNumberChange = (val, inputName) => {
    setFieldValue(`filters.days_past_dues`, [
      {
        type: 'value',
        criteria: {
          gteq: inputName === 'gteq' ? val : get(values, `${fieldPath}.gteq`) || null,
          lteq: inputName === 'lteq' ? val : get(values, `${fieldPath}.lteq`) || null,
          policy: 'include'
        }
      }
    ]);
  };
  return (
    <div>
      <Label bold>Days past dues</Label>
      <FlexContainer>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
        <FItemInputNumber
          name={`${fieldPath}.gteq`}
          formItemProps={{ style: { width: 160 } }}
          style={{ width: '100%' }}
          step={1}
          min={-365}
          onChange={val => handleInputNumberChange(val, 'gteq')}
        />
        <Label bold style={{ margin: '0 8px' }}>
          to
        </Label>
        <FItemInputNumber
          name={`${fieldPath}.lteq`}
          formItemProps={{ style: { width: 160 } }}
          style={{ width: '100%' }}
          step={1}
          max={3000}
          onChange={val => handleInputNumberChange(val, 'lteq')}
        />
      </FlexContainer>
    </div>
  );
};

const UnpaidEmiDateFilter = ({ name, label }) => {
  const fieldPath = `filters.${name}[0].criteria`;
  const { setFieldValue, values } = useFormikContext();

  const handleDateChangeInput = (val, inputName) => {
    setFieldValue(`filters.${name}`, [
      {
        type: 'value',
        criteria: {
          gteq:
            inputName === 'gteq'
              ? val?.format('DD-MM-YYYY')
              : get(values, `${fieldPath}.gteq`) || null,
          lteq:
            inputName === 'lteq'
              ? val?.format('DD-MM-YYYY')
              : get(values, `${fieldPath}.lteq`) || null,
          policy: 'include'
        }
      }
    ]);
  };

  return (
    <div>
      <Label bold>{label}</Label>
      <div style={{ display: 'flex' }}>
        <InputLimitWidth>
          <FItemSelect
            name={`${fieldPath}.policy`}
            placeholder="includes"
            options={policyOptions}
          />
        </InputLimitWidth>
        <FItemDatePicker
          aria-label={label}
          name={`${fieldPath}.gteq`}
          placeholder="Select Date"
          format={'DD-MM-YYYY'}
          onChange={val => handleDateChangeInput(val, 'gteq')}
        />
        <Label bold style={{ margin: '0 8px' }}>
          to
        </Label>
        <FItemDatePicker
          aria-label={label}
          name={`${fieldPath}.lteq`}
          placeholder="Select Date"
          format={'DD-MM-YYYY'}
          onChange={val => handleDateChangeInput(val, 'lteq')}
        />
      </div>
    </div>
  );
};

const PaymentBased = () => {
  return (
    <div style={{ maxWidth: 600 }}>
      <Label bold> Include users with:</Label>
      <CheckboxFilter name="is_upi_enabled" label="UPI" />
      <CheckboxFilter name="is_scan_pay_enabled" label="Scan pay" />
      <CheckboxFilter name="is_bbps_enabled" label="BBPS" />
      <CheckboxFilter name="is_kyc_complete" label="KYC" labels={['Complete', 'Incomplete']} />
      <CheckboxFilter name="is_current_address_present" label="Current address" />
      <PaymentStatusFilter />
      <DaysPastDuesFilter />
      <UnpaidEmiDateFilter name="bnpl_unpaid_emi_date" label="BNPL Unpaid EMI Date" />
      <UnpaidEmiDateFilter name="instaloan_unpaid_emi_date" label="Instaloan Unpaid EMI Date" />
    </div>
  );
};

export default PaymentBased;
