import { shallowEqual } from 'react-redux';

import { LIVE_TENANTS } from 'appConstants';

import useTypedSelector from './useTypedSelector';

const useTenant = () => {
  const tenant = useTypedSelector(state => state.global?.tenantConfig, shallowEqual);
  return { ...tenant, isLiveTenant: LIVE_TENANTS?.includes(tenant?.name) };
};

export default useTenant;
