import React, { useCallback, useContext } from 'react';
import { Spin } from 'antd';
import _startCase from 'lodash/startCase';

import { GridItem, GridRenderer } from 'components/Display/GridRenderer';
import Error from 'containers/Analytics/components/Error';
import NumberDisplay from 'containers/Analytics/components/NumberDisplay/NumberDisplay';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import { campaignOverviewTypes } from 'containers/Analytics/pages/Campaign/LandingPage';
import { ComboContext } from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import { getDemoValues, showCard } from 'containers/Analytics/utils';

interface Props {
  identifier: string;
  showChange?: boolean;
  demoValues?: {
    name: string;
    value: [number, number];
    previous?: [number, number];
  }[];
}

const ValuesSection = ({ identifier, showChange = false, demoValues }: Props) => {
  const { isDemoMode, cards, dateRange, datePart } = useAnalyticsContext();
  const { card_id: questionId } = useCard(identifier);

  // kpiFilter explicitly sets datepart to null since identifiers with kpi don't
  // need datepart as its query parameter.
  const { extraData, kpiFilter } = useContext(ComboContext);
  const { data, tokenError, dataError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    kpiFilter
  );

  const hasError = tokenError || dataError;

  const isCampaignOverviewPage =
    campaignOverviewTypes.findIndex(val => val.valueIdentifier === identifier) !== -1;

  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  let graphData = [];
  if (isDemoMode) {
    graphData =
      demoValues?.map(v => {
        const showPrevious = !!v.previous;
        const value = getDemoValues(v.value, v.previous, dateRange, datePart);

        if (showPrevious) {
          return {
            display_name: _startCase(v.name),
            value: value[0],
            previous: value[1]
          };
        } else {
          return {
            display_name: _startCase(v.name),
            value: value
          };
        }
      }) || [];
  } else if (!data) {
    graphData = [];
  } else {
    const d = data.data;

    extraData.setNoActiveCampaign(false);

    if (d) {
      if (showChange) {
        graphData = [
          {
            display_name: _startCase(d.cols[0].display_name),
            value: d.rows[0][0],
            previous: d.rows[0][1]
          },
          {
            display_name: _startCase(d.cols[2].display_name),
            value: d.rows[0][2],
            previous: d.rows[0][3]
          }
        ];
      } else {
        graphData = [
          { display_name: _startCase(d.cols[1].display_name), value: d.rows[0][1] },
          { display_name: _startCase(d.cols[0].display_name), value: d.rows[0][0] }
        ];
      }
    }
  }

  if (!showCard(cards, identifier) && !isDemoMode) {
    return null;
  }

  if (isCampaignOverviewPage) {
    // if there are no active campaigns in the response data...
    if ((data?.data?.rows?.length === 0 || data?.data?.rows?.[0][0] === null) && !isDemoMode) {
      extraData.setNoActiveCampaign(true);
      return null;
    }
  }

  return (
    <>
      {hasError ? (
        <Error retry={onRetry} />
      ) : (
        <GridRenderer perRow={2} gutter={16}>
          {(!isDemoMode && data) || isDemoMode ? (
            graphData.map((_, index) => (
              <GridItem key={index}>
                <NumberDisplay
                  value={_.value || 0}
                  description={_.display_name}
                  previousValue={_.previous}
                  identifier={identifier}
                />
              </GridItem>
            ))
          ) : (
            <GridItem occupies={2}>
              <Spin style={{ margin: 'auto' }} />
            </GridItem>
          )}
        </GridRenderer>
      )}
    </>
  );
};

export default ValuesSection;
