import {
  GET_PROGRESS_LIST,
  GET_PROGRESS_LIST_SUCCESS,
  GET_PROGRESS_LIST_FAIL,
  POST_BULK_FILE,
  POST_BULK_FILE_SUCCESS,
  POST_BULK_FILE_FAIL
} from './constants';

const initialState = {
  loading: false,
  progressList: [],
  uploadResponse: {}
};

function bulkActionReducer(state = initialState, action) {
  switch (action.type) {
    case POST_BULK_FILE:
    case GET_PROGRESS_LIST: {
      return {
        ...state,
        loading: true,
        progressList: [],
        uploadResponse: {}
      };
    }

    case GET_PROGRESS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        progressList: action.payload.data
      };
    }
    case POST_BULK_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        uploadResponse: action.payload.data
      };
    }

    case POST_BULK_FILE_FAIL:
    case GET_PROGRESS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        progressList: [],
        uploadResponse: {}
      };
    }
    default:
      return state;
  }
}

export default bulkActionReducer;
