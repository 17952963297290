import React, { memo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { TableMainText, TableSecondaryText } from 'styles';

import { DATE_FORMAT, TIME_FORMAT, TIMEZONE_FORMAT } from 'appConstants';
import { useLocalization } from 'hooks';

const TableTimestamp: React.FC<{ timestamp: any; timezone?: string }> = ({
  timestamp = null,
  timezone
}) => {
  const { timezone: tenantTimezone } = useLocalization();
  const timezoneToUse = timezone || tenantTimezone;

  if (timestamp) {
    const m = timezoneToUse ? moment(timestamp).tz(timezoneToUse) : moment(timestamp);

    return (
      <div>
        <TableMainText>{m.format(DATE_FORMAT)}</TableMainText>
        <TableSecondaryText>
          {m.format(TIME_FORMAT)} {m.format(TIMEZONE_FORMAT)}
        </TableSecondaryText>
      </div>
    );
  }
  return <>'-'</>;
};

TableTimestamp.propTypes = {
  timestamp: PropTypes.string
};

export default memo(TableTimestamp);
