import React, { useCallback, useMemo } from 'react';
import { Spin } from 'antd';
import _groupBy from 'lodash/groupBy';
import _snakeCase from 'lodash/snakeCase';
import _startCase from 'lodash/startCase';
import moment from 'moment';

import { GridItem, GridRenderer } from 'components/Display/GridRenderer';
import { DatePart } from 'containers/Analytics/components/DatePartSelect';
import Error from 'containers/Analytics/components/Error';
import NumberDisplay from 'containers/Analytics/components/NumberDisplay/NumberDisplay';
import { useAnalyticsContext, useCard, useMetabase } from 'containers/Analytics/hooks';
import ComboGraph from 'containers/Analytics/pages/graphs/ComboGraph/ComboGraph';
import StackedBarChart from 'containers/Analytics/pages/graphs/GraphOnly/StackedBarChart';
import { getLineDemoData, showCard } from 'containers/Analytics/utils';

interface ReturningUsersProps {
  filterParams?: object;
  occupies?: number;
}

const ReturningUsers: React.FC<ReturningUsersProps> = ({ filterParams, occupies = 2 }) => {
  const identifier = 'overview_flat_user_browse_repeatedly';
  const returningIdentifier = 'overview_flat_user_browse_repeatedly_kpi';
  const avgReturningIdentifier = 'overview_flat_user_browse_repeatedly_avg_kpi';

  const { isDemoMode, cards, datePart, dateRange } = useAnalyticsContext();

  const dataParams = useMemo(
    () => ({
      ...filterParams,
      datepart: datePart
    }),
    [filterParams, datePart]
  );

  //Returning Users Values START
  const { card_id: valuesId } = useCard(returningIdentifier);
  const {
    data: valuesResponse,
    dataError: valuesDataError,
    tokenError: valuesTokenError,
    tokenRevalidate: valuesTokenRevalidate,
    dataRevalidate: valuesDataRevalidate
  } = useMetabase(valuesId, filterParams);

  const hasValuesError = valuesDataError || valuesTokenError;
  const valuesRetry = useCallback(() => {
    valuesTokenRevalidate();
    valuesDataRevalidate();
  }, [valuesTokenRevalidate, valuesDataRevalidate]);

  const valuesData = valuesResponse?.data?.rows?.[0];
  //Returning Users Values END

  //Average Returning Users Values START
  const { card_id: KPIId } = useCard(avgReturningIdentifier);
  const {
    data: KPIResponse,
    dataError: KPIDataError,
    tokenError: KPITokenError,
    tokenRevalidate: KPITokenRevalidate,
    dataRevalidate: KPIDataRevalidate
  } = useMetabase(KPIId, dataParams);

  const hasKPIError = KPIDataError || KPITokenError;
  const KPIRetry = useCallback(() => {
    KPITokenRevalidate();
    KPIDataRevalidate();
  }, [KPITokenRevalidate, KPIDataRevalidate]);

  const KPIData = KPIResponse?.data?.rows?.[0];
  //Average Returning Users Values END

  //Line Data START
  const { card_id: questionId } = useCard(identifier);
  const { data, dataError, tokenError, tokenRevalidate, dataRevalidate } = useMetabase(
    questionId,
    dataParams
  );

  const hasError = tokenError || dataError;
  const onRetry = useCallback(() => {
    tokenRevalidate();
    dataRevalidate();
  }, [tokenRevalidate, dataRevalidate]);

  const grhDemoData = useMemo(() => {
    if (isDemoMode) {
      return {
        data: getLineDemoData(
          dateRange,
          datePart,
          [14000, 28000],
          ['> 10', '8-10', '5-7', '2-4', 'Only 1 time']
        ),
        labels: {
          'Only 1 time': 'Only 1 time',
          '> 10': '> 10',
          '2-4': '2-4',
          '5-7': '5-7',
          '8-10': '8-10'
        }
      };
    }
  }, [datePart, dateRange, isDemoMode]);

  const d = data?.data;
  const grhDataLabels = useMemo(() => {
    if (!isDemoMode) {
      return getDataAndLabels(d, datePart);
    }
  }, [d, datePart, isDemoMode]);

  /**
   *  Sample graphData = [
   *    { name: '05 Apr', only_1_time: 1, ... },
   *    { name: '06 Apr', only_1_time: 14, ... },
   *    ...
   *  ]
   */
  const { data: graphData, labels } = isDemoMode ? grhDemoData : grhDataLabels;
  //Line Data END

  return (
    <ComboGraph
      occupies={occupies}
      title={'Returning users'}
      sections={[
        <GridRenderer perRow={2} gutter={16}>
          {showCard(cards, returningIdentifier) && (
            <GridItem>
              <Spin spinning={!hasValuesError && !valuesData}>
                {hasValuesError ? (
                  <Error retry={valuesRetry} />
                ) : (
                  <NumberDisplay
                    value={valuesData?.[0]}
                    previousValue={valuesData?.[1]}
                    description={'Total Returning Users'}
                  />
                )}
              </Spin>
            </GridItem>
          )}
          {showCard(cards, avgReturningIdentifier) && (
            <GridItem>
              <Spin spinning={!hasKPIError && !KPIData}>
                {hasKPIError ? (
                  <Error retry={KPIRetry} />
                ) : (
                  <NumberDisplay
                    value={KPIData?.[0]}
                    previousValue={KPIData?.[1]}
                    description={'Average this period'}
                  />
                )}
              </Spin>
            </GridItem>
          )}
        </GridRenderer>,
        ...(hasError
          ? [<Error retry={onRetry} />]
          : [
              <StackedBarChart
                graphData={!data && !isDemoMode ? null : graphData}
                labels={labels}
                height={200}
              />
            ])
      ]}
    />
  );
};

export default ReturningUsers;

type Row = [number, string, string];

interface Entry {
  name: string;
  [key: string]: number | string;
}

interface InputData {
  rows: Row[];
  cols: { name: string }[];
}

interface DataLabels {
  data: Entry[];
  labels: {
    [key: string]: string;
  };
}

export const getDataAndLabels = (data: InputData, period: DatePart): DataLabels => {
  /**
   * @example
   * data = {
   *   rows: [
   *     [number, string, '2022-04-05T00:00:00Z'],
   *     [number, string, '2022-04-06T05:00:00Z'],
   *     ...
   *   ],
   *   cols: [
   *     {name: string, ...},
   *     {name: string, ...},
   *     {name: string, ...}
   *   ]
   * }
   */

  // Used for ordering months
  const allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  let graphData = [];

  // Exactly the same as "data.rows", but it will be sorted in
  // 'dd' ascending order, 'dd' taken from yyyy-mm-ddTxx:xx:xxZ
  const rowData = [...(data?.rows || [])].sort((a, b) =>
    moment(a[a.length - 1]).diff(moment(b[b.length - 1]), period)
  ) as Row[];

  /**
   * @example
   * byMonth = {
   *   05_Apr_2022: [ [number1, string1, '2022-04-05T00:00:00Z'] ],
   *   06_Apr_2022: [
   *       [number2, string2, '2022-04-06T00:00:00Z'],
   *       [number3, string3, '2022-04-06T00:00:00Z']
   *   ],
   *   ...
   * }
   */
  interface ByMonth {
    [key: string]: Row[];
  }

  const byMonth = _groupBy(rowData, r => {
    return moment(r[2]).format('DD_MMM_YYYY');
  }) as ByMonth;

  // byMonthKeyArr = ["05_Apr_2022", "06_Apr_2022", ...]
  const byMonthKeyArr = Object.keys(byMonth);

  // byYear = { 2022:  ["05_Apr_2022", "06_Apr_2022", ...] }
  const byYear = _groupBy(byMonthKeyArr, k => k.split('_')[2]);
  const byYearKeyArr = Object.keys(byYear);

  /**
   * Get the value of the property with maximum length, from byMonth obj.
   * The max length could 1 or 2 or more.
   * eg. maxEntries = [
   *       [number2, string2, '2022-04-06T00:00:00Z'],
   *       [number3, string3, '2022-04-06T00:00:00Z']
   *       ...
   *     ]
   */
  let maxEntries: Row[] = [];

  for (const key in byMonth) {
    if (byMonth.hasOwnProperty(key)) {
      if (byMonth[key].length > maxEntries.length) {
        maxEntries = byMonth[key];
      }
    }
  }

  const labels = {};
  const allGraphKeys: string[] = [];

  /**
   * @example
   * labels = {
   *  "Only 1 time" : "Only 1 time",
   *  ">10" : ">10",
   *   ...
   * }
   *
   * allGraphKeys = ["Only 1 time", ">10", ...]
   */
  maxEntries.forEach(e => {
    const label = e[1];
    const key = _snakeCase(label);
    allGraphKeys.push(key);
    labels[key] = label;
  });

  for (const year in byYear) {
    if (byYear.hasOwnProperty(year)) {
      byYear[year].sort((a, b) => {
        const aMonth = a.split('_')[1];
        const bMonth = b.split('_')[1];
        return allMonths.indexOf(aMonth) - allMonths.indexOf(bMonth);
      });
    }
  }

  byYearKeyArr.sort();
  const numberOfYears = byYearKeyArr.length;

  byYearKeyArr.forEach(year => {
    const dataKeys = byYear[year];

    //For each year, process the data
    dataKeys.forEach(DD_MMM_YYYY => {
      //create an entry for each month
      const monthData = byMonth[DD_MMM_YYYY] as Row[];

      let xAxisLabel: string;
      switch (period) {
        case DatePart.Daily:
          xAxisLabel =
            numberOfYears > 1 ? _startCase(DD_MMM_YYYY) : _startCase(DD_MMM_YYYY.substring(0, 6));
          break;
        case DatePart.Weekly:
          xAxisLabel =
            numberOfYears > 1 ? _startCase(DD_MMM_YYYY) : _startCase(DD_MMM_YYYY.substring(0, 6));
          break;
        case DatePart.Monthly:
          xAxisLabel =
            numberOfYears > 1
              ? _startCase(DD_MMM_YYYY.substring(3, 11))
              : _startCase(DD_MMM_YYYY.substring(3, 6));
          break;
        default:
          xAxisLabel = _startCase(DD_MMM_YYYY);
          break;
      }

      /**
       * @example
       * entry = {
       *   name: string,
       *   "only_1_time" : number,
       *   ">10" : number,
       *   ...
       *  }
       */

      const entry: Entry = {
        name: xAxisLabel
      };

      monthData.forEach(row => {
        entry[_snakeCase(row[1])] = row[0];
      });

      const entryKeys = Object.keys(entry);
      const missingKeys = allGraphKeys.filter(k => entryKeys.indexOf(k) === -1);

      missingKeys.forEach(k => {
        entry[k] = 0;
      });

      graphData.push(entry);
    });
  });

  return { data: graphData, labels: labels };
};
