import useSWR from 'swr';

import { buildQuery, fetcher } from 'api/utils';
import { IPaginatedResponse } from 'utils/types';

export type AuditLogChange = [string | number | null, string | number | null];

export interface ITransferRequest {
  changes: {
    id: AuditLogChange;
    amount: AuditLogChange;
    sender: AuditLogChange;
    receiver: AuditLogChange;
    updated_at: AuditLogChange;
    updated_by: AuditLogChange;
    request_type: AuditLogChange;
    state: AuditLogChange;
  };
  created_at;
}

export const useBDOTransferRequestAuditLog = (id: number | string, params: object) => {
  const { data, error, isLoading, mutate } = useSWR<IPaginatedResponse<ITransferRequest>>(
    id ? `/v4/dash/changes2/point_transfer_requests/${id}?${buildQuery(params)}` : null,
    fetcher
  );

  return { data: data?.data, meta: data?.meta, error, isLoading, mutate };
};
